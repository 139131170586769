/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, useState, useEffect } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import { Grid } from "@mui/material";
import { Loader } from "@fas/ui-core";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { useParams } from "react-router-dom";
import configureStore from "./store";
import PwaIcon from "../../containers/PwaIcon";
import type { UseState } from "./types/CreatePwaIcon.types";
import type { PwaIconShort } from "../../components/PwaIconsList/types/PwaIconsList.types";
import PwaIconApi from "../../services/pwaIconApi";
import Notification from "../../components/Notification";
import externalRedirect from "../../components/App/ExternalRedirect";

let pwaIcon: PwaIconShort;

const CreatePwaIcon: StatelessFunctionalComponent<{}> = () => {
  const [isLoading, setIsLoading]: UseState<boolean> = useState(true);

  const { id }: { id: ?string } = useParams();

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      return;
    }

    PwaIconApi
      .getPwaIconById(id)
      .then(({ data: response }: Response<mixed, { data: PwaIconShort[], totalCount: number }>) => {
        [pwaIcon] = response.data;
        setIsLoading(false);
      })
      .catch((): mixed => externalRedirect({ to: "/pwaIcons" }));
  }, []); // eslint-disable-line

  return (
    <DocumentTitle title={id ? "Update Pwa Icon" : "Create Pwa Icon"}>
      <>
        {isLoading && <Loader loading={isLoading} size={35} mt={37} />}
        {!isLoading && (
          <Provider store={configureStore(pwaIcon)}>
            <Grid container>
              <Grid spacing={3} item container justifyContent="center" alignItems="center">
                <Grid item xs={8}>
                  <PwaIcon />
                </Grid>
              </Grid>
            </Grid>
            <Notification />
          </Provider>
        )}
      </>
    </DocumentTitle>
  );
};

export default CreatePwaIcon;
