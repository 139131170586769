// @flow
import {
  Record,
  Map,
  List,
} from "immutable";
import type {
  RecordOf,
  RecordFactory,
  Map as MapType,
} from "immutable";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import {
  SET_CONDITIONS,
  SET_VALUES_LISTS,
  ADD_CONDITION,
  ADD_CONDITION_GROUP,
  CHANGE_CONDITION_GROUP_OPERATOR,
  DELETE_CONDITION_RULE,
  DELETE_CONDITION_GROUP,
  CHANGE_CONDITION_RULE,
  CHANGE_CONDITION_OPERATOR,
  CHANGE_CONDITION_VALUE,
} from "../../helpers/constants";
import type {
  Actions,
  SetConditions,
  SetValuesLists,
  AddCondition,
  AddConditionGroup,
  ChangeConditionGroupOperator,
  DeleteConditionRule,
  DeleteConditionGroup,
  ChangeConditionRule,
  ChangeConditionOperator,
  ChangeConditionValue,
} from "../../actions/segmentConditions";
import type {
  ConditionGroup,
  ConditionRule,
  Value,
  Rule,
} from "../../selectors/segmentConditions/types";

export type DefaultConditionsState = MapType<string, RecordOf<$Shape<ConditionGroup & ConditionRule>>>

export const makeGroup: RecordFactory<ConditionGroup> = Record({
  type: "group",
  groupOperator: "AND",
  children: [],
  parentId: null,
});

export const makeRule: RecordFactory<ConditionRule> = Record({
  operator: "",
  parentId: "",
  type: "rule",
  name: "",
  value: "",
});

const defaultConditionsState: DefaultConditionsState = Map({
  "1": makeGroup(),
});

type DefaultState = {|
  conditions: MapType<string, RecordOf<$Shape<ConditionGroup & ConditionRule>>>,
  rulesList: MapType<string, Rule>,
  valuesLists: MapType<string, Value[]>,
|}

const defaultState: DefaultState = {
  conditions: defaultConditionsState,
  rulesList: Map(),
  valuesLists: Map(),
};

export type State = RecordOf<DefaultState>;

export const makeState: RecordFactory<DefaultState> = Record(defaultState);

export const initialState: State = makeState({});

export function initAntifraudTriggerFormState(): State {
  return initialState;
}

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_CONDITIONS: {
      const { conditions }: SetConditions = action;
      const conditionsState: DefaultConditionsState = Map(
        Object
          .keys(conditions)
          .reduce((acc: { [string]: RecordOf<$Shape<ConditionGroup & ConditionRule>> }, key: string) => {
            const condition = conditions[key];
            return {
              ...acc,
              [key]: condition.type === "group" ? makeGroup(condition) : makeRule(condition),
            };
          }, {})
      );
      return state.set("conditions", conditionsState.size > 0 ? conditionsState : defaultConditionsState);
    }
    case SET_VALUES_LISTS: {
      const { valuesLists }: SetValuesLists = action;
      return state.set("valuesLists", Map(valuesLists));
    }
    case ADD_CONDITION: {
      const { groupId }: AddCondition = action;
      return state.withMutations((newState: State) => {
        const idList: List<string> = List(newState.get("conditions").keySeq());
        const newId: string = getNextId(idList);
        // $FlowFixMe
        newState.setIn(["conditions", newId], makeRule({
          operator: "",
          name: "",
          value: "",
          parentId: groupId,
          type: "rule",
        }));
        newState.updateIn(["conditions", groupId, "children"], (conditions: string[]): string[] => [...conditions, newId]);
      });
    }
    case ADD_CONDITION_GROUP: {
      const { groupId }: AddConditionGroup = action;
      return state.withMutations((newState: State) => {
        const idList: List<string> = List(newState.get("conditions").keySeq());
        const newId: string = getNextId(idList);
        // $FlowFixMe
        newState.setIn(["conditions", newId], makeGroup({
          groupOperator: "AND",
          parentId: groupId,
          type: "group",
          children: [],
        }));
        newState.updateIn(["conditions", groupId, "children"], (conditions: string[]): string[] => [...conditions, newId]);
      });
    }
    case CHANGE_CONDITION_GROUP_OPERATOR: {
      const { id, value }: ChangeConditionGroupOperator = action;
      return state.setIn(["conditions", id, "groupOperator"], value);
    }
    case DELETE_CONDITION_RULE: {
      const { id }: DeleteConditionRule = action;
      return state.withMutations((newState: State) => {
        // $FlowFixMe
        const parentId: string = newState.getIn(["conditions", id, "parentId"]);
        newState.deleteIn(["conditions", id]);
        newState.updateIn(["conditions", parentId, "children"], (children: string[]): string[] => children.filter((conditionId: string): boolean => conditionId !== id));
      });
    }
    case DELETE_CONDITION_GROUP: {
      const { id }: DeleteConditionGroup = action;
      return state.withMutations((newState: State) => {
        // $FlowFixMe
        const parentId: string = newState.getIn(["conditions", id, "parentId"]);
        const rulesList: string[] = newState.getIn(["conditions", id, "children"], []);

        rulesList.forEach((ruleId: string) => {
          newState.deleteIn(["conditions", ruleId]);
        });

        newState.deleteIn(["conditions", id]);
        newState.updateIn(["conditions", parentId, "children"], (children: string[]): string[] => children.filter((conditionId: string): boolean => conditionId !== id));
      });
    }
    case CHANGE_CONDITION_RULE: {
      const { id, rule }: ChangeConditionRule = action;
      return state.withMutations((newState: State) => {
        newState.setIn(["conditions", id, "name"], rule.value || "");
        newState.setIn(["conditions", id, "operator"], "");
        newState.setIn(["conditions", id, "value"], "");
      });
    }
    case CHANGE_CONDITION_OPERATOR: {
      const { id, operator }: ChangeConditionOperator = action;
      return state.setIn(["conditions", id, "operator"], operator);
    }
    case CHANGE_CONDITION_VALUE: {
      const { id, value }: ChangeConditionValue = action;
      return state.setIn(["conditions", id, "value"], value);
    }
    default:
      return state;
  }
};
