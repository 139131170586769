// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import fetchMessagesList from "../../sagas/getMessagesList";
import deleteMessages from "../../sagas/deleteMessages";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import { MESSAGES_LIST_TABLE } from "../../helpers/constants";
import MessageApi from "../../services/messageApi";
import { getMessagesListSaga } from "../../actions/messagesList";
import type { MessageListItem } from "../../components/MessagesList/types/MessagesList.types";

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<MessageListItem, MessageListItem>({
  tableKey: MESSAGES_LIST_TABLE,
  updateMethod: MessageApi.updateMessage,
  getListAction: getMessagesListSaga,
  convert: (item: MessageListItem) => ({ ...item }),
  dictionaryName: "Message",
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(fetchMessagesList),
    call(changeIsActiveSaga),
    call(deleteMessages),
  ]);
}
