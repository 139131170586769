// @flow
import React from "react";
import {
  BrowserRouter, Route, Routes, Navigate,
} from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { mtuDefaultTheme as theme } from "@fas/ui-themes";
import LoginForm from "../../containers/LoginForm";
import SignUpForm from "../../containers/SignUpForm";
import { PrivateRoute } from "../PrivateRoute";
import HomePage from "../HomePage";
import MenuComponents from "../../helpers/Menu";
import AccessControl from "../AccessControl";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignUpForm />} />
            <Route path="/motivationContent/preview" element={() => <></>} />

            <Route path="/" element={<Navigate to="/campaigns" />} />

            <Route element={<PrivateRoute component={HomePage} />}>
              {MenuComponents.map(({
                title, permission, components,
              }) => components.map(({ component: Component, path }) => (
                <Route
                  path={path}
                  element={(
                    <AccessControl
                      key={title}
                      permission={permission}
                    >
                      <Component />
                    </AccessControl>
                  )}
                />
              )))}
            </Route>

            <Route path="*" element={<Navigate to="/404" />} />
            <Route path="/404" element={() => <>404 page</>} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
