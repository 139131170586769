// @flow
import {
  DateBasedFromUI,
  DateBasedToUI,
} from "./dateBased";
import {
  ExistsBasedFromUI,
  ExistsBasedToUI,
} from "./existsBased";
import {
  ManualvalueBasedFromUI,
  ManualvalueBasedToUI,
} from "./manualvalueBased";
import {
  MultivalueBasedFromUI,
  MultivalueBasedToUI,
} from "./multivalueBased";

export {
  fromUIOperatorMapper,
  toUIOperatorMapper,
} from "./operatorMappers";

export interface ToUI<U, S> {
  rule: S;
  convert(): U;
}

export interface FromUI<U, S> {
  rule: U;
  convert(): S;
}

export const toUIFactory = (rule: any) => {
  const field = Object.keys(rule)[0];

  if ([
    "createdAt",
    "lastSeenAt",
    "lastSendAt",
    "lastClickAt",
    "lastIncomingMessageAt",
  ].includes(field)) {
    return new DateBasedToUI(rule);
  }

  if ([
    "hasRegistration",
    "hasPayment",
  ].includes(field)) {
    return new ExistsBasedToUI(rule);
  }

  if ([
    "browser",
    "platform",
    "placement",
    "device",
    "WebPushCampaign",
    "hardwarevendor",
    "hardwarename",
  ].includes(field)) {
    return new MultivalueBasedToUI(rule);
  }

  return new ManualvalueBasedToUI(rule);
};

export const fromUIFactory = (rule: any) => {
  if ([
    "createdAt",
    "lastSeenAt",
    "lastSendAt",
    "lastClickAt",
    "lastIncomingMessageAt",
  ].includes(rule.field)) {
    return new DateBasedFromUI(rule);
  }

  if ([
    "hasRegistration",
    "hasPayment",
  ].includes(rule.field)) {
    return new ExistsBasedFromUI(rule);
  }

  if ([
    "browser",
    "platform",
    "placement",
    "device",
    "WebPushCampaign",
    "hardwarevendor",
    "hardwarename",
  ].includes(rule.field)) {
    return new MultivalueBasedFromUI(rule);
  }

  return new ManualvalueBasedFromUI(rule);
};
