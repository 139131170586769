// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import Notification from "../../components/Notification";
import configureStore from "./store";
import PwaViewContainer from "../../containers/PwaMotivationView";

const PwaMotivationContentView: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="Pwa Motivation Content view">
    <Provider store={configureStore()}>
      <PwaViewContainer />
      <Notification />
    </Provider>
  </DocumentTitle>
);
export default PwaMotivationContentView;
