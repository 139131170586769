/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  takeEvery,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { AxiosXHR } from "axios";
import moment from "moment";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import {
  getTablePage,
} from "@fas/ui-framework/lib/redux/selectors/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  GET_LIMIT_LIST_SAGA,
  LIMIT_STAT_TABLE,
} from "../../helpers/constants";
import { setLimitStatList, changeLimitStatLoading } from "../../actions/limitStatList";
import SegmentApi from "../../services/segmentApi";
import type { LimitStatItem } from "../../reducers/limitStatList";
import {
  getLimitStatDateFilter,
  getLimitStatSegmentFilter,
  getCheckedGroupBy,
} from "../../selectors/limits";
import type { SegmentListItem } from "../../components/SegmentsList";
import type { StatDate } from "../../reducers/limitStatFilters";

export function* makeFetch(): Saga<void> {
  yield put(setLoading(LIMIT_STAT_TABLE, true));

  try {
    const page: number = yield select(getTablePage, LIMIT_STAT_TABLE);
    const limit: number = 30;
    const groupBy: string[] = yield select(getCheckedGroupBy);
    const group: string = groupBy
      .map((value: string): string => (value === "segment" ? "WebPushSegment" : value))
      .join(",");

    const segments: SegmentListItem[] = yield select(getLimitStatSegmentFilter);
    const WebPushSegments: string = segments.map(({ id }: SegmentListItem): string => id).join(",");

    const date: StatDate = yield select(getLimitStatDateFilter);

    if (!group || !date.startDate || !date.endDate) {
      yield put(changeLimitStatLoading(false));
      return;
    }

    const from: number = moment(date.startDate).startOf("day").valueOf();
    const to: number = moment(date.endDate).endOf("day").valueOf();

    const params: {
      page: number,
      limit: number,
      WebPushSegments?: string,
      from: number,
      to: number,
      group: string
    } = {
      page,
      limit,
      group,
      from,
      to,
    };

    if (WebPushSegments) {
      params.WebPushSegments = WebPushSegments;
    }

    const { data: response }: AxiosXHR<{
      data: LimitStatItem[], totalCount: number
    }> = yield call(SegmentApi.getLimitStatList, params);

    yield put(setLimitStatList(response.data));

    yield put(setTableData(LIMIT_STAT_TABLE, response.data));
    yield put(changeTableItemsTotalAmount(LIMIT_STAT_TABLE, response.totalCount));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
    // eslint-disable-next-line
    console.error(err);
  }
  finally {
    yield put(setLoading(LIMIT_STAT_TABLE, false));
  }
}

export default function* watchFetchLimitStatListSaga(): Saga<void> {
  yield takeEvery(GET_LIMIT_LIST_SAGA, makeFetch);
}
