/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers, applyMiddleware, createStore, compose,
} from "redux";
import type { Dispatch, CombinedReducer, Store } from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import type { OutputSelector } from "reselect";

import notifications, { initNotificationsState, type State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";

import { tableReducer as tables, initTableState, type TableState } from "@fas/ui-framework/lib/redux/reducers/table";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";

import loading, { initLoadingState, type State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";

import { MESSAGES_LIST_TABLE } from "../../helpers/constants";

import mainSaga from "./saga";

import type { Actions as MessagesListActions } from "../../actions/messagesList";

export type MessagesListLoadingKeys = typeof MESSAGES_LIST_TABLE;

export type State = $ReadOnly<{
  notifications: NotificationsState,
  tables: TableState,
  loading: LoadingState<MessagesListLoadingKeys>,
}>;

export const getLoading: OutputSelector<
  State,
  MessagesListLoadingKeys,
  boolean
> = createLoadingSelector<MessagesListLoadingKeys>();
export type Actions = MessagesListActions
  | TableActions
  | LoadingActions<MessagesListLoadingKeys>
  | NotificationsState;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  tables,
  loading,
});

export const mapState: () => State = () => ({
  notifications: initNotificationsState(),
  tables: initTableState({
    [MESSAGES_LIST_TABLE]: { pageSize: 20 },
  }),
  loading: initLoadingState({
    MESSAGES_LIST_TABLE: false,
  }),
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  })
    : compose;

  const store: Store<State, Actions> = createStore<
    State,
    Actions,
    Dispatch<Actions>
  >(
    reducers,
    mapState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
