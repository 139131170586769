// @flow
/* eslint-disable import/max-dependencies */
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { createCachedSelector } from "re-reselect";
import {
  Map,
  Record,
  type RecordOf,
  type Map as MapType,
} from "immutable";
import type { Group } from "@fas/ui-core/lib/FlatQueryBuilder";
import type { DropdownItem } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import type { Operator } from "../../helpers/QueryBuilder";
import type {
  ConditionRule,
  ConditionGroup,
  SelectedRule,
  StoreWithQueryBuilder,
  CounditionErrors,
  Rule,
  Value,
  ValueTypes,
} from "./types";
import type { DefaultConditionsState } from "../../reducers/segmentConditions/reducer";

export const getRules: OutputSelector<
  StoreWithQueryBuilder, *, { [string]: Rule }
> = createSelector(
  (state: StoreWithQueryBuilder): MapType<string, Rule> => state.conditions.get("rulesList"),
  (rulesList: MapType<string, Rule>): { [string]: Rule } => rulesList.toObject()
);

export const getConditions: OutputSelector<
  StoreWithQueryBuilder, *, { [string]: ConditionRule | ConditionGroup }
> = createSelector(
  (state: StoreWithQueryBuilder): DefaultConditionsState => state.conditions.get("conditions"),
  // $FlowFixMe
  (conditions: DefaultConditionsState): { [string]: ConditionRule | ConditionGroup } => conditions.toJS()
);

const getConditionById: (
  state: StoreWithQueryBuilder, id: string
) => RecordOf<$Shape<ConditionGroup & ConditionRule>> = (
  state, id
): RecordOf<$Shape<ConditionGroup & ConditionRule>> => state.conditions.getIn(["conditions", id], Record({})());

export const getGroupByIdSelector: OutputSelector<StoreWithQueryBuilder, *, Group> = createCachedSelector(
  getConditionById,
  (condition: RecordOf<ConditionGroup>): Group => {
    const { children, groupOperator, type }: ConditionGroup = condition.toObject();

    const targetings: Array<string> = children || [];
    return ({
      groupOperator,
      type,
      targetings,
    });
  }
)(
  (state: StoreWithQueryBuilder, id: string): string => id
);

export const getRuleByIdSelector: OutputSelector<
  StoreWithQueryBuilder, *, SelectedRule
> = createCachedSelector(
  [
    getRules,
    getConditionById,
  ],
  (ruleList: { [string]: Rule }, rule: RecordOf<ConditionRule>): SelectedRule => {
    const {
      name = "",
      ...ruleObj
    }: ConditionRule = rule.toObject();
    const ruleFromDic: Rule | typeof undefined = ruleList[name];
    const label: string = ruleFromDic ? ruleFromDic.label : name;
    const valueType: ValueTypes = ruleFromDic ? ruleFromDic.valueType : "none";
    const dictionaryName: string | typeof undefined = ruleFromDic && ruleFromDic.dictionaryName;

    return ({
      ...ruleObj,
      name,
      label,
      valueType,
      dictionaryName,
    });
  }
)(
  (state: StoreWithQueryBuilder, id: string): string => id
);

export const getRuleList: OutputSelector<StoreWithQueryBuilder, *, Array<DropdownItem>> = createSelector(
  (state: StoreWithQueryBuilder): MapType<string, Rule> => state.conditions.get("rulesList"),
  (rules: MapType<string, Rule>): Array<DropdownItem> => {
    const rulesRaw: { [string]: Rule } = rules.toObject();
    return Object.keys(rulesRaw).map((key: string): DropdownItem => ({ value: key, label: rulesRaw[key].label }));
  }
);

export const getValuesList: OutputSelector<StoreWithQueryBuilder, *, Array<DropdownItem>> = createCachedSelector(
  (state: StoreWithQueryBuilder, ruleName: string): Value[] => state.conditions
    .getIn(["valuesLists", ruleName], []),
  (values: Value[]): Array<DropdownItem> => values.map((value: Value): DropdownItem => (typeof value === "object"
    ? { label: value.name, value: value.id }
    : { value, label: value }
  ))
)(
  (state: StoreWithQueryBuilder, ruleName: string): string => ruleName
);

export const getOperatorsList: OutputSelector<StoreWithQueryBuilder, *, Array<DropdownItem>> = createCachedSelector(
  (state: StoreWithQueryBuilder, ruleName: string): Operator[] => state.conditions
    .getIn(["rulesList", ruleName, "operators"], []),
  (operators: Operator[]): DropdownItem[] => operators
    .map(({ key, value }: Operator): DropdownItem => ({ label: key, value }))
)(
  (state: StoreWithQueryBuilder, ruleName: string): string => ruleName
);

export const getConditionsErrorById: OutputSelector<StoreWithQueryBuilder, *, CounditionErrors> = createCachedSelector(
  // $FlowFixMe
  (state: StoreWithQueryBuilder, id: string): Map<string, mixed> => state.errors.getIn(["conditions", id], Map({})),
  // $FlowFixMe
  (error: Map<string, mixed>): CounditionErrors => error.toObject()
)(
  (state: StoreWithQueryBuilder, id: string): string => id
);

export const getGroupErrorById: OutputSelector<StoreWithQueryBuilder, *, string> = createCachedSelector(
  (state: StoreWithQueryBuilder, id: string): string => state.errors
    // $FlowFixMe
    .getIn(["conditions", id, "children", "message"], ""),
  (error: string): string => error
)(
  (state: StoreWithQueryBuilder, id: string): string => id
);
