// @flow
import {
  CHANGE_LIMIT_STAT_FILTER,
} from "../../helpers/constants";
import type { DefaultState } from "../../reducers/limitStatFilters";

export type ChangeLimitStatFilter = {
  type: typeof CHANGE_LIMIT_STAT_FILTER,
  key: $Keys<DefaultState>,
  value: mixed,
};

export type Actions = ChangeLimitStatFilter;

// eslint-disable-next-line import/prefer-default-export
export const changeLimitStatFilter: ($Keys<DefaultState>, mixed) => ChangeLimitStatFilter = (key, value) => ({
  type: CHANGE_LIMIT_STAT_FILTER,
  key,
  value,
});
