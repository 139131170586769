/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers, applyMiddleware, createStore, compose,
} from "redux";
import type { Dispatch, CombinedReducer, Store } from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";

import notifications, { initNotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import loading, { initLoadingState, type State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import type{ Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import mainSaga from "./saga";

import pwaOffline, { initCreatePwaOfflinePageState as initPwaOffState } from "../../reducers/createPwaOfflinePage";
import type { State as PwaOffState } from "../../reducers/createPwaOfflinePage";

import type { GetPwaOfflinePageSaga } from "../../actions/createPwaOfflinePage";
import { PWA_OFFLINE_FORM_LOADING } from "../../helpers/constants";

export type State = $ReadOnly<{
  notifications: NotificationsState,
  pwaOffline: PwaOffState,
  loading: LoadingState<typeof PWA_OFFLINE_FORM_LOADING>,
}>;

export type Actions = GetPwaOfflinePageSaga | LoadingActions<typeof PWA_OFFLINE_FORM_LOADING>;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  pwaOffline,
  loading,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export function mapState(): State {
  return {
    notifications: initNotificationsState(),
    pwaOffline: initPwaOffState(),
    loading: initLoadingState({ [PWA_OFFLINE_FORM_LOADING]: false }),
  };
}

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  })
    : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
