// @flow
/* eslint-disable import/max-dependencies */
import type { Saga } from "redux-saga";
import {
  put,
  call,
  takeEvery,
} from "redux-saga/effects";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { setDictionary } from "../../actions/dictionary";
import { GET_DICTIONARY_SAGA } from "../../helpers/constants";
import { getDictionaryLoadingKey } from "../../helpers/generators";
import type { GetDictionarySaga } from "../../actions/dictionary";
import {
  getBrowserLanguagesList,
  getPwaIcons,
  getPlacementsList,
  getData1List,
  getTags,
} from "../../services/dictionaries";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

/**
 * Non-standard dictionary dropdown routes
 */
const dictionaryMethodMap: { [string]: * } = {
  browserLanguages: getBrowserLanguagesList,
  pwaIcons: getPwaIcons,
  placements: getPlacementsList,
  data1List: getData1List,
  tags: getTags,
};

export function* makeFetch(action: GetDictionarySaga): Saga<void> {
  const { dictionaryName }: GetDictionarySaga = action;
  try {
    yield put(setLoading(getDictionaryLoadingKey(dictionaryName), true));

    const method: * = dictionaryMethodMap[dictionaryName];

    const data: DropDownObjItemType[] = yield call(method, dictionaryName);

    yield put(setDictionary({ [dictionaryName]: data }));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(getDictionaryLoadingKey(dictionaryName), false));
  }
}

export default function* watchGetDropdownsStaticSaga(): Saga<void> {
  yield takeEvery(GET_DICTIONARY_SAGA, (makeFetch: Function));
}
