/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { getDictionarySaga } from "../../actions/dictionary";
import { FORM_KEY_MESSAGE_GROUP, ADD_TAG_SAGA } from "../../helpers/constants";
import MessageGroupApi from "../../services/messageGroupApi";
import type { AddTagSaga } from "../../actions/messageGroupsList";

export function* makeFetch(action: AddTagSaga): Saga<void> {
  const { payload: newTag }: AddTagSaga = action;
  try {
    yield call(MessageGroupApi.saveTag, newTag);
    yield put(setFormData(FORM_KEY_MESSAGE_GROUP, { tag: newTag }));
    yield put(addNotification({ message: `New tag (${newTag}) saved successfully`, severity: "success" }));
    yield put(getDictionarySaga("tags"));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage || "Failed to save tag", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export default function* watchAddData1(): Saga<void> {
  yield takeEvery(ADD_TAG_SAGA, (makeFetch: Function));
}
