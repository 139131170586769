// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import SegmentsTable from "../../components/SegmentsList";
import Notification from "../../components/Notification";
import TableActions, { type TableActionsProps } from "../../containers/TablesActions";
import { SEGMENTS_LIST_TABLE } from "../../helpers/constants";
import { changeIsActiveSaga, deleteSaga } from "../../actions/tableActions";

const actionsProps: TableActionsProps<typeof SEGMENTS_LIST_TABLE> = {
  tableKey: SEGMENTS_LIST_TABLE,
  editPath: "/segments/edit/:id",
  viewPath: "/segments/view/:id",
  onDelete: deleteSaga,
  onChangeIsActive: changeIsActiveSaga,
};

const SegmentsList: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="Segments">
    <Provider store={configureStore()}>
      <SegmentsTable TableActions={<TableActions {...actionsProps} />} />
      <Notification />
    </Provider>
  </DocumentTitle>
);

export default SegmentsList;
