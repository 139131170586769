// @flow
import {
  GET_SCHEDULES_SAGA,
} from "../../helpers/constants";

export type GetSchedulesSaga = {|
  type: typeof GET_SCHEDULES_SAGA,
|};

export type Actions = GetSchedulesSaga;

export const getSchedulesSaga: () => GetSchedulesSaga = () => ({ type: GET_SCHEDULES_SAGA });
