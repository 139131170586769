// @flow
import { Cookies } from "react-cookie";
import User from "@fas/ui-framework/lib/services/user";
import Acl, { type Permission, type Role } from "../Acl";

class UserService extends User {
  constructor() {
    super();

    this.setUserPermissions();
  }

  setUserPermissions() {
    const cookie: Cookies = new Cookies();
    const roles: Role[] = cookie.get("roles") || [];

    // $FlowFixMe type Permission in Acl is incompatible with strict type Permission in user service of ui-framework. Both contains array of string
    this._userPermissions = roles.reduce((result: Permission[], role: Role): Permission[] => ([
      ...result,
      ...Acl.roleToPermissions(role),
    ]), []);
  }

  setUserRoles(roles: Role[], expires: Date) {
    const cookie: Cookies = new Cookies();
    cookie.set("roles", roles, { expires });
  }

  removeUserRoles() {
    const cookie: Cookies = new Cookies();
    cookie.remove("roles");
  }

  setUsername(username: string, expires: Date) {
    const cookie: Cookies = new Cookies();
    cookie.set("username", username, { expires });
  }

  getUsername(): string {
    const cookie: Cookies = new Cookies();
    const username: string = cookie.get("username");

    return username || "";
  }

  removeUsername() {
    const cookie: Cookies = new Cookies();
    cookie.remove("username");
  }
}

// eslint-disable-next-line import/prefer-default-export
export const userService: UserService = new UserService();
