/* eslint-disable import/max-dependencies */
// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type {
  StoreWithLimitStatModals,
  StoreWithFilters,
  StoreWithGroupBy,
} from "./types/limits.types";
import type { State as ModalsState } from "../../reducers/modals";
import type { State as FiltersState, DefaultState as Filters, StatDate } from "../../reducers/limitStatFilters";
import type { State as GroupByState, DefaultState as GroupBy } from "../../reducers/limitStatGroupBy";
import type { LimitStatModals, State } from "../../pages/LimitStat/store";
import type { SegmentListItem } from "../../components/SegmentsList/types/SegmentsList.types";
import type { LimitStatItem } from "../../reducers/limitStatList";

export const getLimitStatFilters: OutputSelector<StoreWithFilters, *, Filters> = createSelector(
  (state: StoreWithFilters): FiltersState => state.filters,
  // $FlowFixMe
  (filters: FiltersState): Filters => filters.toJS()
);

export const getLimitStatGroupBy: OutputSelector<StoreWithGroupBy, *, GroupBy> = createSelector(
  (state: StoreWithGroupBy): GroupByState => state.groupBy,
  // $FlowFixMe
  (groupBy: GroupByState): GroupBy => groupBy.toJS()
);

export const getIsLimitStatFiltersModalOpen: OutputSelector<StoreWithLimitStatModals, *, boolean> = createSelector(
  (state: StoreWithLimitStatModals): ModalsState<LimitStatModals> => state.modals,
  (modals: ModalsState<LimitStatModals>): boolean => modals.get("isFiltersModalOpen", false)
);

export const getIsLimitStatGroupByModalOpen: OutputSelector<StoreWithLimitStatModals, *, boolean> = createSelector(
  (state: StoreWithLimitStatModals): ModalsState<LimitStatModals> => state.modals,
  (modals: ModalsState<LimitStatModals>): boolean => modals.get("isGroupByModalOpen", false)
);

export const getLimitStatDateFilter: OutputSelector<State, *, StatDate> = createSelector(
  (state: State): StatDate => state.filters.get("date").value,
  (date: StatDate): StatDate => date
);

export const getLimitStatSegmentFilter: OutputSelector<State, *, SegmentListItem[]> = createSelector(
  (state: State): SegmentListItem[] => state.filters.get("segment").value,
  (segments: SegmentListItem[]): SegmentListItem[] => segments
);

// $FlowFixMe
export const getCheckedGroupBy: OutputSelector<State, *, string[]> = createSelector(
  (state: State): GroupByState => state.groupBy,
  // $FlowFixMe
  (groupBy: GroupBy): string[] => Object.keys(groupBy.toJS()).filter((key: string): boolean => groupBy.get(key).value)
);

export const getLimitStatList: OutputSelector<State, *, LimitStatItem[]> = createSelector(
  (state: State): LimitStatItem[] => state.limitStatList.get("list"),
  (list: LimitStatItem[]): LimitStatItem[] => list
);

export const getLimitStateListLoading: OutputSelector<State, *, boolean> = createSelector(
  (state: State): boolean => state.limitStatList.get("loading"),
  (loading: boolean): boolean => loading
);
