// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Table } from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { MOTIVATION_TEMPLATES_LIST } from "../../helpers/constants";
import { getMotivationTemplatesListSaga, type GetMotivationTemplatesListSaga } from "../../actions/motivationTemplateList";
import type { MotivationTemplate, Classes } from "./MotivationTemplatesList.types";
import getColumns from "./getColumns";
import TableActions, { type TableActionsProps } from "../TablesActions";
import { changeIsActiveSaga, deleteSaga } from "../../actions/tableActions";
import ExternalRedirect from "../../components/App/ExternalRedirect";

const useStyles: () => Classes = makeStyles(() => ({
  smallTableCol: {
    width: "50px",
    minWidth: "50px",
  },
}));

const actionsProps: TableActionsProps<typeof MOTIVATION_TEMPLATES_LIST> = {
  tableKey: MOTIVATION_TEMPLATES_LIST,
  editPath: "/motivationTemplates/edit/:id",
  viewPath: "/motivationTemplates/view/:id",
  onDelete: deleteSaga,
  disableDelete: false,
  onChangeIsActive: changeIsActiveSaga,
};

const MotivationTemplatesList: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();
  const tableProps: TableProps = useTable(MOTIVATION_TEMPLATES_LIST);
  const dispatch: <A>(A) => A = useDispatch();

  const onGetMotivationTemplatesList: () => GetMotivationTemplatesListSaga = () => (
    dispatch(getMotivationTemplatesListSaga()));

  const onCreate: () => void = () => {
    ExternalRedirect({ to: "/motivationTemplates/create" });
  };

  const columns: Array<Column<MotivationTemplate>> = getColumns(classes);

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        title="Motivation Templates"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onCreate={onCreate}
        onLoad={onGetMotivationTemplatesList}
        Actions={<TableActions {...actionsProps} />}
      />
    </Box>
  );
};

export default MotivationTemplatesList;
