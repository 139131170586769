// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import environment from "environment";
import { userService } from "../../services/user";
import ExternalRedirect from "../App/ExternalRedirect";

type Props = {
  component: StatelessFunctionalComponent<{}>,
};

const PrivateRoute: StatelessFunctionalComponent<Props> = ({ component: Component }: Props): Node => (
  (userService.getAuthToken() ? (
    <Component />
  ) : (
    <ExternalRedirect
      to={`${environment.links.login}?r=${window.location.href}`}
    />
  ))
);
export default PrivateRoute;
