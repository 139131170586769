/* eslint-disable import/prefer-default-export */
// @flow
import type { ScheduleListItem, ScheduleShort } from "../containers/SchedulesList/types/SchedulesList.types";
import type { SegmentListItem } from "../components/SegmentsList/types/SegmentsList.types";
import type { MessageGroupListItem } from "../components/MessageGroupsList/types/MessageGroupsList.types";
import type { ScheduleFormData, ScheduleFormDataShort } from "../containers/CreateSchedule";

export const scheduleListItemToShort: (ScheduleListItem) => ScheduleShort = (schedule) => {
  const {
    name,
    isActive,
    queueType,
    customScheduleAtHours,
    customScheduleAtMinutes,
    WebPushSegments,
    WebPushMessageGroups,
  }: ScheduleListItem = schedule;

  return {
    name,
    isActive,
    queueType,
    customSchedule: {
      hh: customScheduleAtHours,
      mm: customScheduleAtMinutes,
    },
    WebPushSegments: WebPushSegments.map(({ id }: SegmentListItem): string => id),
    WebPushMessageGroups: WebPushMessageGroups.map(({ id }: MessageGroupListItem): string => id),
  };
};

export const convertToServer: (ScheduleFormDataShort) => ScheduleShort = (schedule) => {
  const {
    // eslint-disable-next-line no-unused-vars
    createdAt, updatedAt,
    customScheduleAtHours,
    customScheduleAtMinutes,
    webPushSegments,
    webPushMessageGroups,
    ...rest
  }: ScheduleFormDataShort = schedule;

  return {
    ...rest,
    customSchedule: {
      hh: Number(customScheduleAtHours),
      mm: Number(customScheduleAtMinutes),
    },
    WebPushSegments: webPushSegments.map(({ id }: SegmentListItem): string => id),
    WebPushMessageGroups: webPushMessageGroups.map(({ id }: MessageGroupListItem): string => id),
  };
};

export const convertToUi: (ScheduleListItem) => ScheduleFormData = (schedule) => {
  const {
    WebPushSegments,
    WebPushMessageGroups,
    customScheduleAtHours,
    customScheduleAtMinutes,
    ...body
  }: ScheduleListItem = schedule;

  return {
    ...body,
    customScheduleAtHours: String(customScheduleAtHours),
    customScheduleAtMinutes: String(customScheduleAtMinutes),
    webPushSegments: WebPushSegments.map(({ _id, ...rest }) => ({ id: _id, ...rest })),
    webPushMessageGroups: WebPushMessageGroups
      // $FlowFixMe
      .map(({ _id, BrowserLanguages, ...rest }) => ({ id: _id, browserLanguages: BrowserLanguages, ...rest })),
  };
};
