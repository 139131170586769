// @flow

import React, { useMemo, type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import Notification from "../../components/Notification";
import CreateCampaignContainer from "../../containers/CreateCampaign";

const CreateCampaign: StatelessFunctionalComponent<{}> = () => {
  const store = useMemo(configureStore, []);
  return (
    <DocumentTitle title="Create Campaign">
      <Provider store={store}>
        <CreateCampaignContainer />
        <Notification />
      </Provider>
    </DocumentTitle>
  );
};
export default CreateCampaign;
