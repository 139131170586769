// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchFetchPwaIconsList from "../../sagas/getPwaIconsList";
import watchDeletePwaIcons from "../../sagas/deletePwaIcons";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import { PWA_ICONS_LIST_TABLE } from "../../helpers/constants";
import { getPwaIconsListSaga } from "../../actions/pwaIconsList";
import PwaIconApi from "../../services/pwaIconApi";
import { pwaIconListItemToShort } from "../../helpers/pwaIcon";
import type { PwaIconListItem, PwaIconShort } from "../../components/PwaIconsList/types/PwaIconsList.types";

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<PwaIconListItem, PwaIconShort>({
  tableKey: PWA_ICONS_LIST_TABLE,
  updateMethod: PwaIconApi.updatePwaIcon,
  getListAction: getPwaIconsListSaga,
  convert: (icon: PwaIconListItem): PwaIconShort => ({ ...pwaIconListItemToShort(icon) }),
  dictionaryName: "PWA Icon",
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchFetchPwaIconsList),
    call(changeIsActiveSaga),
    call(watchDeletePwaIcons),
  ]);
}
