/* eslint-disable import/max-dependencies */
// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State as StepsState } from "@fas/ui-framework/lib/redux/reducers/steps";
import type {
  StoreWithSteps,
  Steps,
} from "./types/steps.types";

export const getCurrentStepId: OutputSelector<StoreWithSteps, *, string> = createSelector(
  (state: StoreWithSteps): StepsState => state.steps,
  (steps: StepsState): string => steps.get("current")
);

export const getSteps: OutputSelector<StoreWithSteps, *, Steps> = createSelector(
  (state: StoreWithSteps): StepsState => state.steps,
  (steps: StepsState): Steps => steps.get("byId")
);
