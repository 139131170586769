// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetDictionarySaga from "../../sagas/getDictionary";
import savePwa from "../../sagas/savePwaMotivationContent";
import getPwa from "../../sagas/getPwaMotivationContent";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(getPwa),
    call(savePwa),
    call(watchGetDictionarySaga),
  ]);
}
