// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import getPwaOffline from "../../sagas/getPwaOfflinePagesList";
import watchDeletePwaOfflinePageSaga from "../../sagas/deletePwaOfflinePages";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import { PWA_OFFLINE_LIST_TABLE } from "../../helpers/constants";
import PwaApi from "../../services/PwaApi";
import { getPwaOfflineListSaga } from "../../actions/pwaOfflinePagesList";
import type { PwaOfflinePagesType as PwaOffItem } from "../../components/PwaOfflinePagesList";
import type { PwaOfflineSaveBody } from "../../services/PwaApi/PwaApi";

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<PwaOffItem, PwaOfflineSaveBody>({
  tableKey: PWA_OFFLINE_LIST_TABLE,
  updateMethod: PwaApi.updatePwaOfflinePage,
  getListAction: getPwaOfflineListSaga,
  convert: (pwaItem: PwaOffItem) => ({
    ...pwaItem,
    BrowserLanguages: pwaItem.BrowserLanguages?.map(({ id: langId }) => langId),
  }),
  dictionaryName: "PWA Offline list",
});

export default function* mainSaga(): Saga<void> {
  yield all([call(getPwaOffline), call(changeIsActiveSaga), call(watchDeletePwaOfflinePageSaga)]);
}
