// @flow
// eslint-disable import/max-dependencies
import {
  call,
  takeEvery,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import {
  DELETE_MESSAGES_SAGA,
  MESSAGES_LIST_TABLE,
} from "../../helpers/constants";
import {
  getMessagesListSaga,
} from "../../actions/messagesList";
import MessageApi from "../../services/messageApi";
import { deleteFn } from "../deleteSaga/deleteSaga";
import { deleteSaga } from "../../actions/tableActions/actions";

export function* makeFetch(): Saga<void> {
  const ids = yield select(getTableSelections, MESSAGES_LIST_TABLE);
  yield call(deleteFn, {
    tableKey: MESSAGES_LIST_TABLE,
    deleteMethod: MessageApi.deleteMessagesByIds,
    getListAction: getMessagesListSaga,
    dictionaryName: "Message",
  },
  deleteSaga(ids.map((i) => i.id)));
}

export default function* watchDeleteMessagesSaga(): Saga<void> {
  yield takeEvery(DELETE_MESSAGES_SAGA, makeFetch);
}
