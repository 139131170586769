// @flow
import {
  SET_TARGETING,
  GET_TARGETING_SAGA,
} from "../../helpers/constants";
import type { Targeting } from "../../containers/TargetingsList";

export type SetTargeting = {|
  type: typeof SET_TARGETING,
  targeting: Targeting,
|};

export type GetTargetingSaga = {|
  type: typeof GET_TARGETING_SAGA,
  id: string,
|};

export type Actions = SetTargeting | GetTargetingSaga;

export const setTargeting: (Targeting) => SetTargeting = (targeting) => ({
  type: SET_TARGETING,
  targeting,
});

export const getTargetingSaga: (string) => GetTargetingSaga = (id) => ({ type: GET_TARGETING_SAGA, id });
