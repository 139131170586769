// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { requestService } from "../request";
import type {
  MessageGroupListItem,
  MessageGroupShort,
} from "../../components/MessageGroupsList/types/MessageGroupsList.types";
import type {
  GetMessageGroupServerData,
  SaveMessageGroupServerData,
  MessageGroupFormData,
  MessageGroupFormDataShort,
} from "../../containers/CreateMessageGroup";

export const convertToUi = ({
  // eslint-disable-next-line no-unused-vars
  createdAt, updatedAt,
  browserLanguages,
  sendStrategies,
  ...rest
}: GetMessageGroupServerData): MessageGroupFormData => ({
  ...rest,
  browserLanguages: browserLanguages.map(({ id }) => id),
  sendStrategies: sendStrategies.id,
  browserLanguagesRaw: browserLanguages,
});

export const convertToServer = ({
  // eslint-disable-next-line no-unused-vars
  usedInCampaigns, rotationType, browserLanguagesRaw,
  messages,
  sendStrategies,
  ...rest
}: MessageGroupFormDataShort): SaveMessageGroupServerData => ({
  ...rest,
  messages: messages.map(({ id: messageId }): string => messageId),
  strategies: [sendStrategies],
});

export const getMessageGroupList: (?number, ?number, Filters) => PromiseResponse<
  mixed,
  {
    data: MessageGroupListItem[],
    totalCount: number
  }
> = (page, pageSize, filterList) => {
  const params: Filters = { ...filterList };
  if (page) {
    params.page = page;
  }
  if (pageSize) {
    params.limit = pageSize;
  }

  return requestService.get(environment.endpoints.get.messageGroupsList, {
    // $FlowFixMe
    params: { ...params },
  });
};

export const getMessageGroupById: (string) => Promise<GetMessageGroupServerData> = (id) => requestService
  .get(environment.endpoints.get.messageGroupById.replace("{id}", id))
  .then(({ data: { data } }) => data[0]);

export const getMessageGroupFormData: (string) => Promise<MessageGroupFormData> = (id) => getMessageGroupById(id)
  .then(convertToUi);

export async function saveMessageGroupForm({
  id,
  ...rest
}: MessageGroupFormData) {
  const body: SaveMessageGroupServerData = convertToServer(rest);
  if (id) {
    await requestService.put(environment.endpoints.put.messageGroups.replace("{id}", id), body);
  }
  else {
    await requestService.post(environment.endpoints.post.messageGroups, body)
      .then(({ data }) => data.id);
  }
}

export const updateMessageGroup: (string, MessageGroupShort) => PromiseResponse<*, *> = (
  id,
  messageGroup
) => requestService.put(environment.endpoints.put.messageGroups.replace("{id}", id), { ...messageGroup });

export const deleteMessageGroupsByIds: (string[]) => PromiseResponse<mixed> = (
  idList
) => requestService.delete(environment.endpoints.delete.messageGroups, { data: { idList } });

export const saveTag: (string) => PromiseResponse<{ name: string }> = (name) => requestService
  .post(environment.endpoints.post.saveTag, { name });
