// @flow
import React, { type Node, useState } from "react";
import { type Theme, makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary, { type AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails, { type AccordionDetailsProps } from "@mui/material/AccordionDetails";
import clsx from "clsx";
import type { UseState } from "../../helpers/common.types";

type Classes = {
  root?: string,
  summary?: string,
  details?: string,
}

type Props = {
  label: string,
  children: Node,
  expandedDefault: boolean,
  classes: Classes,
  summaryProps: AccordionSummaryProps,
  detailsProps: AccordionDetailsProps,
};

const useStyles: () => Classes = makeStyles((theme: Theme): mixed => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  summary: {
    backgroundColor: "rgba(0, 0, 0, .2)",
    color: theme.palette.common.white,
    fontWeight: 700,
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      color: theme.palette.common.white,
      fontWeight: 700,
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  },
  details: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

function Accordion({
  label,
  children,
  expandedDefault,
  classes,
  summaryProps,
  detailsProps,
  ...props
}: Props): Node {
  const ownClasses: Classes = useStyles();
  const [expanded, setExpanded]: UseState<boolean> = useState(expandedDefault);

  return (
    <MuiAccordion
      {...props}
      data-testid={`accordion-${label}`}
      className={clsx(ownClasses.root, classes.root)}
      disableGutters
      elevation={0}
      square
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <MuiAccordionSummary
        {...summaryProps}
        className={clsx(ownClasses.summary, classes.summary)}
        expandIcon={<ArrowForwardIosSharpIcon size="small" />}
      >
        <Typography variant="h6">
          {label}
        </Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails
        {...detailsProps}
        className={clsx(ownClasses.details, classes.details)}
      >
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
}

Accordion.defaultProps = {
  children: <></>,
  label: "",
  expandedDefault: true,
  classes: {},
  summaryProps: {},
  detailsProps: {},
};

export default Accordion;
