// @flow
import React from "react";
import {
  Card,
  CardContent,
  Grid,
  InputLabel as Label,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import type { Action, ActionFields } from "./types/MessageAction.types";

type Props = {
  action: Action,
  index: number,
  handleChangeActionsFields: (options: ActionFields) => void,
  handleRemoveAction: (number) => void,
};

const MessageAction = ({
  action,
  index,
  handleChangeActionsFields,
  handleRemoveAction,
}: Props) => (
  <Card>
    <CardContent>
      <Grid container spacing={2}>
        <Grid container item justifyContent="space-between">
          <Grid item xs={3}>
            <Typography variant="h6">
              Action
              {" "}
              {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={() => handleRemoveAction(index)}
              data-testid="message-form-message-action-remove-btn"
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item container alignItems="center" xs={3}>
            <Label>
              Title
            </Label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={action.title}
              onChange={
                (e) => {
                  handleChangeActionsFields({
                    title: "title",
                    value: e.target.value,
                    index,
                  });
                }
              }
              placeholder="Example: Go to website"
              margin="dense"
              size="small"
              variant="outlined"
              fullWidth
              data-testid="message-form-message-action-title"
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item container alignItems="center" xs={3}>
            <Label>
              Actions
            </Label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={action.action}
              onChange={
                (e) => {
                  handleChangeActionsFields({
                    title: "action",
                    value: e.target.value,
                    index,
                  });
                }
              }
              placeholder="Example: Action 1"
              margin="dense"
              size="small"
              variant="outlined"
              fullWidth
              data-testid="message-form-message-action-action"
            />
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default MessageAction;
