// @flow
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import {
  fromUIFactory,
  toUIFactory,
} from "../services/targeting";
import type { Rule, ConditionGroup, ConditionRule } from "../selectors/segmentConditions/types";

export const toUiViewDateValueMapper = {
  "@1MinAgo": "1 minute ago",
  "@1DayAgo": "1 day ago",
  "@2DaysAgo": "2 days ago",
  "@30DaysAgo": "30 days ago",
  "@1HourAgo": "1 hour ago",
  "@2HoursAgo": "2 hours ago",
  "@3HoursAgo": "3 hours ago",
  "@4HoursAgo": "4 hours ago",
  "@5HoursAgo": "5 hours ago",
  "@6HoursAgo": "6 hours ago",
  "@7HoursAgo": "7 hours ago",
  "@8HoursAgo": "8 hours ago",
  "@9HoursAgo": "9 hours ago",
  "@10HoursAgo": "10 hours ago",
  "@11HoursAgo": "11 hours ago",
  "@12HoursAgo": "12 hours ago",
  "@13HoursAgo": "13 hours ago",
  "@14HoursAgo": "14 hours ago",
  "@15HoursAgo": "15 hours ago",
  "@16HoursAgo": "16 hours ago",
  "@17HoursAgo": "17 hours ago",
  "@18HoursAgo": "18 hours ago",
  "@19HoursAgo": "19 hours ago",
  "@20HoursAgo": "20 hours ago",
  "@21HoursAgo": "21 hours ago",
  "@22HoursAgo": "22 hours ago",
  "@23HoursAgo": "23 hours ago",
  "@3DaysAgo": "3 days ago",
  "@4DaysAgo": "4 days ago",
  "@5DaysAgo": "5 days ago",
  "@6DaysAgo": "6 days ago",
  "@7DaysAgo": "7 days ago",
  "@8DaysAgo": "8 days ago",
  "@9DaysAgo": "9 days ago",
  "@10DaysAgo": "10 days ago",
  "@15DaysAgo": "15 days ago",
  "@60DaysAgo": "60 days ago",
  "@100DaysAgo": "100 days ago",
  "@150DaysAgo": "150 days ago",
};

export type Operator = {
  key: string,
  value: "equal" | "in" | "notequal" | "notin" | "greaterthanorequal" | "lessthanorequal" | "exists" | "isnull",
  serverValue: "$in" | "$ne" | "$nin" | "$gte" | "$lte" | "$exists" | "null"
};

type OperatorsList = {
  equal: Operator,
  notEqual: Operator,
  in: Operator,
  notIn: Operator,
  greater: Operator,
  less: Operator,
  exists: Operator,
  isNull: Operator,
};

export const operators: OperatorsList = {
  equal: {
    key: "equal",
    value: "equal",
    serverValue: "$in",
  },
  notEqual: {
    key: "not equal",
    value: "notequal",
    serverValue: "$ne",
  },
  in: {
    key: "in",
    value: "in",
    serverValue: "$in",
  },
  notIn: {
    key: "not in",
    value: "notin",
    serverValue: "$nin",
  },
  greater: {
    key: "greater or equal",
    value: "greaterthanorequal",
    serverValue: "$gte",
  },
  less: {
    key: "less or equal",
    value: "lessthanorequal",
    serverValue: "$lte",
  },
  exists: {
    key: "exists",
    value: "exists",
    serverValue: "$exists",
  },
  isNull: {
    key: "is null",
    value: "isnull",
    serverValue: "null",
  },
};

export const rulesList: { [string]: Rule } = {
  state: {
    field: "state",
    label: "State",
    type: "boolean",
    operators: [{ ...operators.equal }],
    valueType: "select",
  },
  browser: {
    field: "browser",
    label: "Browser",
    type: "string",
    operators: [{ ...operators.in }, { ...operators.notIn }],
    valueType: "multiselectWithSuggest",
    dictionaryName: "browsers",
  },
  platform: {
    field: "platform",
    label: "Platform",
    type: "string",
    operators: [{ ...operators.in }, { ...operators.notIn }],
    valueType: "multiselect",
  },
  placement: {
    field: "placement",
    label: "Placement",
    type: "string",
    operators: [{ ...operators.in }, { ...operators.notIn }],
    valueType: "multiselect",
  },
  device: {
    field: "device",
    label: "Device",
    type: "string",
    operators: [{ ...operators.in }, { ...operators.notIn }],
    valueType: "multiselectWithSuggest",
    dictionaryName: "devices",
  },
  WebPushCampaign: {
    field: "WebPushCampaign",
    label: "WebPushCampaign",
    type: "string",
    operators: [{ ...operators.in }, { ...operators.notIn }],
    valueType: "multiselect",
  },
  data1: {
    field: "data1",
    label: "data1",
    type: "string",
    operators: [{ ...operators.equal }, { ...operators.notEqual }],
    valueType: "input",
  },
  data2: {
    field: "data2",
    label: "data2",
    type: "string",
    operators: [{ ...operators.equal }, { ...operators.notEqual }],
    valueType: "input",
  },
  data3: {
    field: "data3",
    label: "data3",
    type: "string",
    operators: [{ ...operators.equal }, { ...operators.notEqual }],
    valueType: "input",
  },
  data4: {
    field: "data4",
    label: "data4",
    type: "string",
    operators: [{ ...operators.equal }, { ...operators.notEqual }],
    valueType: "input",
  },
  token1: {
    field: "token1",
    label: "token1",
    type: "string",
    operators: [{ ...operators.equal }],
    valueType: "input",
  },
  token2: {
    field: "token2",
    label: "token2",
    type: "string",
    operators: [{ ...operators.equal }],
    valueType: "input",
  },
  createdAt: {
    field: "createdAt",
    label: "createdAt",
    type: "string",
    operators: [{ ...operators.less }, { ...operators.equal }, { ...operators.greater }],
    valueType: "select",
  },
  lastSeenAt: {
    field: "lastSeenAt",
    label: "lastSeenAt",
    type: "string",
    operators: [{ ...operators.less }, { ...operators.equal }, { ...operators.greater }],
    valueType: "select",
  },
  lastSendAt: {
    field: "lastSendAt",
    label: "lastSendAt",
    type: "string",
    operators: [{ ...operators.less }, { ...operators.equal }, { ...operators.greater }],
    valueType: "select",
  },
  lastClickAt: {
    field: "lastClickAt",
    label: "lastClickAt",
    type: "string",
    operators: [{ ...operators.less }, { ...operators.equal }, { ...operators.greater }],
    valueType: "select",
  },
  lastIncomingMessageAt: {
    field: "lastIncomingMessageAt",
    label: "lastIncomingMessageAt",
    type: "string",
    operators: [{ ...operators.less }, { ...operators.equal }, { ...operators.greater }, { ...operators.isNull }],
    valueType: "select",
  },
  hasRegistration: {
    field: "hasRegistration",
    label: "hasRegistration",
    type: "string",
    operators: [{ ...operators.exists }],
    valueType: "none",
  },
  hasPayment: {
    field: "hasPayment",
    label: "hasPayment",
    type: "string",
    operators: [{ ...operators.exists }],
    valueType: "none",
  },
};

export function getFirstKey(obj: Object): string {
  return Object.keys(obj)[0];
}

export function parseChildren(queryBuilderRules: Object, children: string[]): Object {
  const nodes = [];
  for (const key of children) {
    const node: ConditionGroup | ConditionRule = queryBuilderRules[key];
    if (node.type === "group") {
      const { groupOperator }: ConditionGroup = node;
      const childNodes: ConditionGroup[] | ConditionRule[] = parseChildren(queryBuilderRules, node.children);
      const operator: string = groupOperator === "AND" ? "$and" : "$or";
      nodes.push({ [operator]: childNodes });
    }
    else if (node.type === "rule") {
      const convertedRule = fromUIFactory({ ...node, field: node.name }).convert();
      nodes.push(convertedRule);
    }
  }

  return nodes;
}

export function toServerView(conditions: { [string]: ConditionGroup | ConditionRule }): Object {
  // $FlowFixMe
  const { children }: ConditionGroup = conditions[1];
  const operator: string = "$and";
  return { [operator]: parseChildren(conditions, children) };
}

function toUiViewAllRules({
  criteria,
  parentId,
  nextId: currentId,
}: {
  criteria: *,
  parentId: string | null,
  nextId: string,
}): { [string]: ConditionGroup | ConditionRule } {
  const key: string = getFirstKey(criteria);
  let result: { [string]: ConditionGroup | ConditionRule } = {
    [currentId]: {
      type: "group",
      groupOperator: key === "$and" ? "AND" : "OR",
      children: [],
      parentId,
    },
  };
  criteria[key]
    .filter((rule) => !("" in rule))
    .forEach((rule) => {
      if (!rule.$and && !rule.$or) {
        const { operator, field, value } = toUIFactory(rule).convert();

        const nextRuleId: string = getNextId(Object.keys(result));
        const newRule: ConditionRule = {
          operator,
          parentId: currentId,
          type: "rule",
          name: field,
          value,
        };
        if (result[currentId].type === "group") {
          result[currentId].children.push(nextRuleId);
        }
        result[nextRuleId] = newRule;
      }
      else {
        const nextId: string = getNextId(Object.keys(result));
        if (result[currentId].type === "group") {
          result[currentId].children.push(nextId);
        }
        result = {
          ...result,
          ...toUiViewAllRules({
            criteria: rule,
            parentId: currentId,
            nextId,
          }),
        };
      }
    });

  return result;
}

export function isConditionValid(criteria: Object): boolean {
  if (criteria) {
    return criteria.current.getRules().condition === "and";
  }

  return true;
}

export function toUiView(criteria: *): { [string]: ConditionGroup | ConditionRule } {
  return toUiViewAllRules({
    criteria,
    parentId: null,
    nextId: "1",
  });
}
