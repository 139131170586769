/* eslint-disable flowtype/require-parameter-type */
// @flow
import { configureScope } from "@sentry/browser";
import type { Scope } from "@sentry/browser";
import RequestService from "@fas/ui-framework/lib/services/request";
import environment from "environment";
import { userService } from "../user";
import externalRedirect from "../../components/App/ExternalRedirect";

const getAuthorizationHeaders = (): { [key: string]: mixed } => ({
  Authorization: `Bearer ${userService.getAuthToken() || ""}`,
});

// eslint-disable-next-line import/prefer-default-export
export const requestService: RequestService = new RequestService({
  timeout: 60000,
  withCredentials: false,
  customAttr: { getAuthorizationHeaders },
});

requestService
  .getInstance()
  .interceptors
  .response
  .use(
    (response) => {
      const username: string = userService.getUsername();

      configureScope((scope: Scope) => {
        scope.setUser({
          username,
        });
      });

      return response;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        externalRedirect({ to: `${environment.links.login}?r=${window.location.href}` });
      }
      return Promise.reject(error);
    }
  );
