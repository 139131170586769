/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type {
  Dispatch,
  CombinedReducer,
  Store,
} from "redux";
import type { SagaMiddleware } from "redux-saga";
import notifications, { initNotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";

import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import mainSaga from "./saga";

import type { PwaIconShort } from "../../components/PwaIconsList/types/PwaIconsList.types";

import pwaIcon, { initPwaIconState, type State as PwaIconState } from "../../reducers/pwaIcon";
import type { Actions as PwaIconActions } from "../../actions/pwaIcon";

import loading, { initLoadingState, type State as PwaIconLoadingState } from "../../reducers/loading";
import type { Actions as PwaIconLoadingActions } from "../../actions/loading";

import modals, { initModalsState, type State as PwaIconModalsState } from "../../reducers/modals";
import type { Actions as PwaIconModalsActions } from "../../actions/modals";

export type PwaIconLoading = "isSaved";
export type PwaIconModals = "isUploadImageOpen" | "isPreviewImageOpen";

export type State = $ReadOnly<{|
  notifications: NotificationsState,
  pwaIcon: PwaIconState,
  loading: PwaIconLoadingState<PwaIconLoading>,
  modals: PwaIconModalsState<PwaIconModals>,
|}>;

export type Actions = PwaIconActions | PwaIconLoadingActions<PwaIconLoading> | PwaIconModalsActions<PwaIconModals>;

function mapPwaIconToState(data?: PwaIconShort): State {
  return {
    notifications: initNotificationsState(),
    pwaIcon: initPwaIconState(data),
    loading: initLoadingState<PwaIconLoading>({ isSaved: false }),
    modals: initModalsState<PwaIconModals>({ isUploadImageOpen: false }),
  };
}

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  pwaIcon,
  loading,
  modals,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (data: PwaIconShort): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapPwaIconToState(data),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
