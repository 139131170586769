// @flow
import React from "react";
import { SelectedItem } from "../SelectedItem";
import ViewSegment from "./ViewSegment";

type Props = {
  items: Array<Object>,
  handleDelete: Function,
};

// $FlowFixMe
export default ({ items, handleDelete }: Props) => items.map<SelectedItem>((segment: Object) => (
  <SelectedItem
    isCollapsed
    key={segment.id}
    data={segment}
    SubComponent={ViewSegment}
    subComponentData={[{ ...segment }]}
    handleDelete={handleDelete}
  />
));
