// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetDictionarySaga from "../../sagas/getDictionary";
import saveIpp from "../../sagas/saveIpp";
import getIpp from "../../sagas/getIpp";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(saveIpp),
    call(getIpp),
    call(watchGetDictionarySaga),
  ]);
}
