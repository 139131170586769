/* eslint-disable import/max-dependencies */
// @flow
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { Box, Container, Button } from "@mui/material";
import { Loader } from "@fas/ui-core";
import PwaMotivForm from "../../components/PwaMotivationContentForm";

import {
  getLoading,
  getFormData,
} from "../../selectors/createPwaMotivationContent";
import {
  savePwaSaga,
  getPwaSaga,
  setPwaFormField,
} from "../../actions/createPwaMotivationContent";
import { setModifiers } from "../../actions/modifiers";
import type { SetModifiers } from "../../actions/modifiers/actions.types";
import type {
  SavePwaSaga,
  GetPwaSaga,
  Actions,
} from "../../actions/createPwaMotivationContent";
import type {
  PWAFormData,
} from "../PwaMotivationContentList";
import { PWA_MOTIV_LOADING } from "../../helpers/constants";
import type { MotivationTemplateType } from "../CreateMotivationTemplate";
import type { ModifiersStateData } from "../Modifiers";

type OwnProps = {||}

type StateProps = $ReadOnly<{|
  formData: PWAFormData,
  isLoading: boolean,
|}>;

type DispatchProps = $ReadOnly<{|
  getPwa: (id: string) => GetPwaSaga,
  onSavePwa: () => SavePwaSaga,
  onSetPwaFormField: (filed: $Shape<PWAFormData>) => mixed,
  onSetModifiers: (MotivationTemplateType, ModifiersStateData) => SetModifiers,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|}>;

const mapStateToProps = (state) => ({
  isLoading: getLoading(state, PWA_MOTIV_LOADING),
  formData: getFormData(state),
});

const mapDispatchToProps: (Dispatch<Actions | SetModifiers>) => DispatchProps = (dispatch) => bindActionCreators({
  getPwa: getPwaSaga,
  onSavePwa: savePwaSaga,
  onSetPwaFormField: setPwaFormField,
  onSetModifiers: setModifiers,
}, dispatch);

const PwaMotivFormContainer = (props: Props) => {
  const {
    onSavePwa,
    getPwa,
    isLoading,
  }: Props = props;

  const { id: pwaId } = useParams();

  useEffect(() => {
    if (pwaId) {
      getPwa(pwaId);
    }
  }, [pwaId]);

  return (
    <Container maxWidth="md">
      <Loader loading={isLoading} withoutWrapper>
        <PwaMotivForm {...props} />
        <Box display="flex" justifyContent="flex-end" mb={5}>
          <Button
            type="button"
            size="medium"
            variant="contained"
            color="primary"
            onClick={onSavePwa}
            data-testid="save-button"
            disabled={isLoading}
          >
            Save
          </Button>
        </Box>
      </Loader>
    </Container>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(PwaMotivFormContainer);
