// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { DELETE_SAGA } from "../../helpers/constants";
import { type DeleteSaga } from "../../actions/tableActions";
import { lowerCaseFirstLetter } from "../../services/utils";

export type DeleteSagaConfig = {
  tableKey: string,
  deleteMethod: (string[]) => mixed,
  getListAction: () => *,
  dictionaryName?: string,
};

export function* deleteFn(
  {
    tableKey,
    deleteMethod,
    getListAction,
    dictionaryName,
  }: DeleteSagaConfig,
  {
    ids,
  }: DeleteSaga
): Saga<void> {
  yield put(setLoading<typeof tableKey>(tableKey, true));

  try {
    yield call(deleteMethod, ids);
    yield put(setTableSelection(tableKey, []));
    yield put(getListAction());
    yield put(addNotification({ message: `${dictionaryName || "Item"}s deleted successfully`, severity: "success" }));
  }
  catch (err) {
    yield put(addNotification({
      message: err.errorMessage || `An error occured while deleting ${lowerCaseFirstLetter(dictionaryName || "Item")}s`,
      severity: "error",
    }));
    // eslint-disable-next-line
    console.error(err);
    yield put(setLoading<typeof tableKey>(tableKey, false));
  }
}

export default function getDeleteSaga(config: DeleteSagaConfig): () => Saga<void> {
  return function* watchDeleteSaga(): Saga<void> {
    yield takeEvery(DELETE_SAGA, deleteFn, config);
  };
}
