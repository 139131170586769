/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect } from "react";
import type { StatelessFunctionalComponent } from "react";
import { useSelector } from "react-redux";
import {
  Container, Grid, Typography, Switch, Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Loader,
  SetupCard,
  GridUc,
} from "@fas/ui-core";
import { useParams } from "react-router-dom";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import externalRedirect from "../../components/App/ExternalRedirect";
import NamedItem from "../../components/NamedItem";
import CampViewWebPush from "../../components/CampViewWebPush";
import CampViewPwaMotiv from "../../components/CampViewPwaMotiv";
import CampViewIppMotiv from "../../components/CampViewIppMotiv";
import CampViewPwaOfflinePages from "../../components/CampViewPwaOfflinePages";
import CampViewWPMessages from "../../components/CampViewWPMessages";
import CampViewWPTargetings from "../../components/CampViewWPTargetings";
import AdditionalInfo from "../../components/AdditionalInfo";
import Form from "../Form";
import type { Campaign } from "../CreateCampaign";
import { FORM_KEY_CAMPAIGN, GET_FORM_DATA_LOADING } from "../../helpers/constants";
import { useLoading, useActions } from "../../hooks";
import type { State } from "../../pages/CampaignView/store";

export type Classes = {
  root: string,
};

const getItemEditPath: (string) => string = (id) => `/campaigns/edit/${id}`;

const useStyles: () => Classes = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
}));

const CampaignView: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();
  const campaign: Campaign = useSelector((state: State) => getFormData(state, FORM_KEY_CAMPAIGN));
  const { getFormDataSaga: onGetFormDataSaga } = useActions({ getFormDataSaga });

  const { id }: { id: ?string } = useParams();
  const isLoading = useLoading<>(GET_FORM_DATA_LOADING);

  useEffect(() => {
    onGetFormDataSaga(FORM_KEY_CAMPAIGN, id);
  }, []);

  return (
    <Form id={FORM_KEY_CAMPAIGN}>
      {isLoading && (
        <Loader loading size={35} mt={37} />
      )}
      {!isLoading && (
        <Container maxWidth="md">
          <SetupCard
            title="Campaign Info"
            showSecondaryBtn
            secondaryBtnText="Edit"
            onSecondaryBtnClick={() => {
              externalRedirect({ to: getItemEditPath(campaign.id), target: "_blank" });
            }}
          >
            <Box pb={3} width="100%" className={classes.root}>
              <NamedItem name="Id" value={campaign.id} />
              <NamedItem name="Name" value={campaign.name} />
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">isActive</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Switch checked={campaign.isActive} disabled />
                </Grid>
              </GridUc>
            </Box>
          </SetupCard>
          <AdditionalInfo usedInSchedules={campaign.usedInSchedulesAndSegments} blockTitle="Schedules&Segments Info" />
          {!!campaign.webPushMotivationContents.length && (
            <CampViewWebPush campaignContent={campaign.webPushMotivationContents} classes={classes} />
          )}
          {!!campaign.ippMotivationContents.length && (
            <CampViewIppMotiv campaignContent={campaign.ippMotivationContents} classes={classes} />
          )}
          {!!campaign.pwaMotivationContents.length && (
            <CampViewPwaMotiv campaignContent={campaign.pwaMotivationContents} classes={classes} />
          )}
          {!!campaign.pwaOfflinePages.length && (
            <CampViewPwaOfflinePages campaignContent={campaign.pwaOfflinePages} classes={classes} />
          )}
          <CampViewWPMessages campaignContent={campaign.webPushMessageGroups} classes={classes} />
          <CampViewWPTargetings campaignContent={campaign.webPushTargetings} classes={classes} />
        </Container>
      )}
    </Form>
  );
};

export default CampaignView;
