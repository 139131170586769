// @flow
import type { RecordOf, RecordFactory } from "immutable";
import {
  Record,
  Map,
  fromJS,
  List,
} from "immutable";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import { SET_DICTIONARY } from "../../helpers/constants";

export type DropDownObjItemType = Option;

export type DropDownObjType = {
  [string]: Array<DropDownObjItemType>
}

export type DropDownType = Map<string, List<DropDownObjItemType>>;
export type DefaultStateType = {
  dropdownLists: DropDownType,
};

const defaultState: DefaultStateType = {
  dropdownLists: Map({}),
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState({});

export function makeDictionaries(dictionaries: DropDownObjType): DropDownType {
  return Object.keys(dictionaries)
    .reduce((acc, key) => acc.set(key, List(dictionaries[key])), Map());
}

export function initState(dictionaries?: DropDownObjType): State {
  if (dictionaries) {
    return makeState({ dropdownLists: makeDictionaries(dictionaries) });
  }
  return initialState;
}

type Action = any;

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_DICTIONARY: {
      // $FlowFixMe
      return state.set("dropdownLists", state.get("dropdownLists", Map()).merge(fromJS(action.payload)));
    }
    default:
      return state;
  }
};
