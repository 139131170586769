// @flow
/* eslint-disable import/max-dependencies */
import React, { useState, type Node } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import {
  Visibility as ViewIcon,
  FileCopy as FileCopyIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { SelectCustom } from "@fas/ui-core";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import ColorPicker from "../../components/ColorPicker";
import Accordion from "../../components/Accordion";
import InputUpload from "../../components/InputUpload";
import UploadFileModal from "../../components/UploadFileModal";
import { POSITIONS } from "./Modifier.types";
import type {
  Props,
  Simple,
  Images,
  Handlers,
} from "./Modifier.types";
import { TypeToLabelMap, getSufixIdx } from "./utils";
import { POPUP_TYPES } from "../CreateMotivationTemplate";
import ModifierButtons from "./ModifierButtons";
import { useActions } from "../../hooks/useActions";
import * as actions from "../../actions/modifiers";
import type { Actions } from "../../actions/modifiers/actions.types";
import {
  getSimpleModifiers,
  getImageModifiers,
} from "../../selectors/modifiers";
import type { UseState } from "../../helpers/common.types";
import PreviewImageModal from "../../components/PreviewImageModal";
import { copyToClipboard } from "../../helpers/utils";

function Modifiers({
  templateType,
  disabled,
  readOnly,
}: Props): Node {
  const dispatch: <A>(A) => A = useDispatch();
  const [currentImageIndex, setCurrentImageIndex]: UseState<string> = useState("0");
  const [isImageUploadModalOpen, setIsImageUploadModalOpen]: UseState<boolean> = useState(false);
  const [isImagePreviewModalOpen, setIsImagePreviewModalOpen]: UseState<boolean> = useState(false);
  const {
    bg_color,
    text_color,
    popup_position,
    text_body,
    text_title,
  }: Simple = useSelector((state) => getSimpleModifiers(state, templateType));
  const images: Images = useSelector((state) => getImageModifiers(state, templateType));
  const editable: boolean = !(disabled || readOnly);

  const {
    setModifierSimple: onSetModifierSimple,
    addModifierImage: onAddModifierImage,
    setModifierImage: onSetModifierImage,
    removeModifierImage: onRemoveModifierImage,
    // $FlowFixMe
  }: Handlers = useActions<Actions>(actions);

  const imagesCount: number = Object.entries(images).length;

  return (
    <>
      <Box my={1} width={1}>
        <Typography gutterBottom variant="h5" component="h2">
          Template Modifiers
        </Typography>
      </Box>

      <Box width={1} mb={1}>
        <Accordion label={TypeToLabelMap[templateType]}>
          <Box display="flex" mb={1} gap={1} justifyContent="space-between" alignItems="center">
            <Box flexGrow={1}>
              <ColorPicker
                name="bg_color"
                label="bg_color"
                size="small"
                value={bg_color || ""}
                onChange={(newValue: string) => {
                  onSetModifierSimple(templateType, { bg_color: newValue });
                }}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Box>
            <Box flexGrow={1}>
              <ColorPicker
                name="text_color"
                label="text_color"
                size="small"
                value={text_color || ""}
                onChange={(newValue: string) => {
                  onSetModifierSimple(templateType, { text_color: newValue });
                }}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Box>
            {POPUP_TYPES.includes(templateType) && (
              <Box flexGrow={1} width="30%">
                <SelectCustom
                  dataTestid="popup_position"
                  title="popup_position"
                  value={popup_position}
                  values={POSITIONS}
                  onTypeChange={({ target: { value } }: { target: { value: string } }) => {
                    onSetModifierSimple(templateType, { popup_position: value });
                  }}
                  disabled={disabled}
                  readOnly={readOnly}
                />
              </Box>
            )}
          </Box>
          <Box mb={1}>
            <TextField
              fullWidth
              multiline
              data-testid="text_title"
              label="text_title"
              size="small"
              value={text_title}
              onChange={({ target: { value: newValue } }: SyntheticInputEvent<HTMLInputElement>) => {
                onSetModifierSimple(templateType, { text_title: newValue });
              }}
              disabled={disabled}
              InputProps={{
                readOnly,
              }}
            />
          </Box>
          <Box mb={1}>
            <TextField
              fullWidth
              multiline
              data-testid="text_body"
              label="text_body"
              size="small"
              value={text_body}
              onChange={({ target: { value: newValue } }: SyntheticInputEvent<HTMLInputElement>) => {
                onSetModifierSimple(templateType, { text_body: newValue });
              }}
              disabled={disabled}
              InputProps={{
                readOnly,
              }}
            />
          </Box>
          {Object.keys(images).map((idx: string): Node => {
            const modifier: string = `image${getSufixIdx(idx)}`;

            return (
              <Box mb={1} key={modifier} display="flex" alignItems="center">
                <InputUpload
                  data-testid={modifier}
                  name={modifier}
                  label={modifier}
                  size="small"
                  value={images[idx]}
                  onUpload={() => {
                    setIsImageUploadModalOpen(true);
                    setCurrentImageIndex(idx);
                  }}
                  onChange={(value: string) => {
                    onSetModifierImage(templateType, { idx, value });
                  }}
                  disabled={disabled}
                  readOnly={readOnly}
                />
                <Box ml={1}>
                  <IconButton
                    color="primary"
                    aria-label="view image picture"
                    component="span"
                    onClick={(): mixed => {
                      setIsImagePreviewModalOpen(true);
                      setCurrentImageIndex(idx);
                    }}
                    data-testid="android-preview-button"
                    size="small"
                    disabled={!images[idx]}
                  >
                    <ViewIcon />
                  </IconButton>
                </Box>
                <Box ml={1}>
                  <IconButton
                    color="primary"
                    aria-label="copy image url"
                    component="span"
                    onClick={(): mixed => copyToClipboard(images[idx])}
                    data-testid="copy-button"
                    size="small"
                    disabled={!images[idx]}
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Box>
                {editable && (
                  <Box ml={1}>
                    <IconButton
                      color="primary"
                      data-testid={`delete-image-btn-${idx}`}
                      onClick={() => onRemoveModifierImage(templateType)}
                      disabled={idx !== String(imagesCount) || idx === "1" || disabled}
                      size="small"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            );
          })}
          {editable && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                data-testid="add-image-btn"
                variant="text"
                disabled={imagesCount >= 5 || disabled}
                onClick={() => onAddModifierImage(templateType)}
              >
                + Add Image
              </Button>
            </Box>
          )}
        </Accordion>
      </Box>

      {POPUP_TYPES.includes(templateType) && (
        <Box width={1} mb={1}>
          <Accordion label="Accept button">
            <ModifierButtons
              buttonType="confirm"
              templateType={templateType}
              maxCount={20}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Accordion>
        </Box>
      )}

      {POPUP_TYPES.includes(templateType) && (
        <Box width={1} mb={1}>
          <Accordion label="Deny button">
            <ModifierButtons
              buttonType="deny"
              templateType={templateType}
              maxCount={5}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Accordion>
        </Box>
      )}

      {["PWAInstallPage", "PWAInstallPageIos"].includes(templateType) && (
        <Box width={1} mb={1}>
          <Accordion label="Install button">
            <ModifierButtons
              buttonType="motiv_install"
              templateType={templateType}
              maxCount={5}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Accordion>
        </Box>
      )}

      <UploadFileModal
        open={isImageUploadModalOpen}
        onSave={(url: string) => {
          onSetModifierImage(templateType, { idx: currentImageIndex, value: url });
          setIsImageUploadModalOpen(false);
        }}
        onClose={(): void => setIsImageUploadModalOpen(false)}
        onUploadError={(error) => {
          dispatch(addNotification({ message: error.message, severity: "error" }));
        }}
      />

      <PreviewImageModal
        open={isImagePreviewModalOpen}
        url={images[currentImageIndex]}
        onClose={(): mixed => setIsImagePreviewModalOpen(false)}
      />
    </>
  );
}

export default Modifiers;
