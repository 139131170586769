import environment from "./base";

const API_URL = "https://uf.noclef.com";
const ADS_BOX_API_URL = "https://adsbox.insigit.com";
const ADS_BOX_API_KEY = "4UpvcPk2xyeFsVc5";

export default {
  ...environment(API_URL, ADS_BOX_API_URL, ADS_BOX_API_KEY),
  appLinks: {
    dmp: "https://admin-dmp.insigit.com/",
    jus: "https://jus.insigit.com/",
    cpa: "https://cpa-admin.insigit.com/",
    webPush: "https://webpush.noclef.com/",
    adsBox: "https://adsbox.insigit.com/",
    reports: "https://reports.insigit.com/managersReport",
    crm: "https://crm.insigit.com/",
  },
};
