// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable, { Map } from "immutable";
import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import conditions, {
  type State as QueryBuilderState,
  makeState as initQueryBuilderState,
} from "../../reducers/segmentConditions";
import mainSaga from "./saga";
import { FORM_KEY_SEGMENT, GET_FORM_DATA_LOADING } from "../../helpers/constants";
import type { SegmentFormData } from "../../containers/CreateSegment";
import { rulesList } from "../../helpers/QueryBuilder";

export const defaultFormData: SegmentFormData = {
  id: "",
  name: "",
  isActive: true,
  isLimit: false,
  minimum: 0,
  maximum: 0,
  webPushMessageGroups: [],
  criteriaRaw: "",
};

export type SegmentViewLoadingKeys = typeof GET_FORM_DATA_LOADING;

export type State = $ReadOnly<{|
  loading: LoadingState<SegmentViewLoadingKeys>,
  notifications: NotificationsState,
  form: FormState,
  conditions: QueryBuilderState,
|}>

export function mapSegmentsToState(): State {
  return {
    form: initFormState({
      [FORM_KEY_SEGMENT]: defaultFormData,
    }),
    loading: initLoadingState({
      [GET_FORM_DATA_LOADING]: true,
    }),
    notifications: initNotificationsState(),
    conditions: initQueryBuilderState({
      rulesList: Map(rulesList),
    }),
  };
}

export type Actions = LoadingActions<SegmentViewLoadingKeys>
  | NotificationsActions
  | TableActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  loading,
  notifications,
  form,
  conditions,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapSegmentsToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
