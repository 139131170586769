// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import motivationTemplate, {
  type State as MotivationTemplateState,
  initCreateMotivationTemplateState,
} from "../../reducers/createMotivationTemplate";
import modifiers, {
  type State as ModifiersState,
  initModifiersState,
} from "../../reducers/modifiers";
import type { Actions as MotivationTemplateActions } from "../../actions/createMotivationTemplate/actions.types";
import type { Actions as ModifiersActions } from "../../actions/modifiers/actions.types";
import mainSaga from "./saga";
import { MOTIVATION_TEMPLATE_FORM } from "../../helpers/constants";

export type State = $ReadOnly<{|
  loading: LoadingState<string>,
  notifications: NotificationsState,
  motivationTemplate: MotivationTemplateState,
  modifiers: ModifiersState,
|}>

function mapMotivationTemplateToState(): State {
  return {
    loading: initLoadingState({
      [MOTIVATION_TEMPLATE_FORM]: false,
    }),
    notifications: initNotificationsState(),
    motivationTemplate: initCreateMotivationTemplateState(),
    modifiers: initModifiersState({
      WPMotivationPopUp: {},
      WPSubscriptionPage: {},
      PWAMotivationPopUp: {},
      PWAInstallPage: {},
      IPPMotivationPopUp: {},
    }),
  };
}

export type Actions = LoadingActions<string>
  | NotificationsActions
  | MotivationTemplateActions
  | ModifiersActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  loading,
  notifications,
  motivationTemplate,
  modifiers,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapMotivationTemplateToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
