/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  debounce,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
} from "@fas/ui-framework/lib/redux/selectors/table";

import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";

import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import {
  GET_MESSAGE_GROUPS_LIST_SAGA,
  MESSAGE_GROUPS_LIST_TABLE,
} from "../../helpers/constants";

import MessageGroupApi from "../../services/messageGroupApi";
import type { MessageGroupListItem } from "../../components/MessageGroupsList/types/MessageGroupsList.types";
import type { MessageGroupsListLoadingKeys } from "../../pages/MessageGroupsList/store";

export function* makeFetch(): Saga<void> {
  yield put(setLoading<MessageGroupsListLoadingKeys>(MESSAGE_GROUPS_LIST_TABLE, true));

  const page: number = yield select(getTablePage, MESSAGE_GROUPS_LIST_TABLE);
  const limit: number = yield select(getTablePageSize, MESSAGE_GROUPS_LIST_TABLE);
  const filters: Filters = yield select(getTableFilters, MESSAGE_GROUPS_LIST_TABLE);

  // Start @TODO need fix on backend
  const clonedFilters: Filters = { ...filters };

  if (Object.keys(clonedFilters).includes("placements")) {
    clonedFilters.placement = clonedFilters.placements;
    delete clonedFilters.placements;
  }
  if (Object.keys(clonedFilters).includes("sendStrategies")) {
    clonedFilters.strategies = clonedFilters.sendStrategies;
    delete clonedFilters.sendStrategies;
  }
  // End @TODO

  try {
    const { data: response }: Response<{
      data: MessageGroupListItem[],
      totalCount: number
    // $FlowFixMe
    }> = yield call(
      MessageGroupApi.getMessageGroupList,
      page,
      limit,
      clonedFilters
    );

    yield put(setTableData(MESSAGE_GROUPS_LIST_TABLE, response.data));
    yield put(changeTableItemsTotalAmount(MESSAGE_GROUPS_LIST_TABLE, response.totalCount));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
    // eslint-disable-next-line
    console.error(err);
  }
  finally {
    yield put(setLoading<MessageGroupsListLoadingKeys>(MESSAGE_GROUPS_LIST_TABLE, false));
  }
}

export default function* watchFetchMessageGroupsListSaga(): Saga<void> {
  yield debounce(1000, GET_MESSAGE_GROUPS_LIST_SAGA, (makeFetch: Function));
}
