// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";
import type { State as StoreState } from "../../pages/CreatePwaMotivationContent/store";
import type { PWAFormData } from "../../containers/PwaMotivationContentList";
import type { State } from "../../reducers/createPwaMotivationContent";
import { PWA_MOTIV_LOADING } from "../../helpers/constants";

export const getPwa: OutputSelector<StoreState, *, PWAFormData> = createSelector(
  (state: StoreState): PWAFormData => state.pwa.toJSON(),
  (item: PWAFormData): PWAFormData => item
);

export const getLoading: OutputSelector<StoreState,
  typeof PWA_MOTIV_LOADING,
  boolean> = createLoadingSelector<typeof PWA_MOTIV_LOADING>();

export const getFormData: OutputSelector<StoreState, *, PWAFormData> = createSelector(
  (state: StoreState): State => state.pwa,
  (form: State): PWAFormData => form.toObject()
);
