/* eslint-disable import/prefer-default-export */
// @flow

export type Error = { message: string, severity: "error" | "warning" | "success" };

export const enrichQueryBuilder = (
  criteria: Object,
  columns: Object
): Error[] => {
  const errors: Error[] = [];

  if (criteria.$and || criteria.$or) {
    return enrichQueryBuilder((criteria.$and || criteria.$or), columns);
  }

  Array.from(criteria).forEach((item) => {
    const field: string = Object.keys(item)[0];
    if (field === "WebPushCampaign") {
      (item[field].$in || item[field].$nin).forEach((campaignId) => {
        const listCampaign = columns.WebPushCampaign.find((c) => c.id === campaignId);
        if (!listCampaign) {
          columns.WebPushCampaign.push({
            id: campaignId,
            name: `DELETED (${campaignId})`,
            isActive: false,
            isDeleted: true,
          });

          errors.push({
            message: `You have deleted campaign "DELETED (${campaignId})" in criteria, remove it`,
            severity: "error",
          });
        }

        if (listCampaign && !listCampaign.isActive && !listCampaign.isDeleted) {
          errors.push({
            message: `You have inactive campaign "${listCampaign.name}" in criteria`,
            severity: "warning",
          });
        }
      });
    }
  });

  return errors;
};
