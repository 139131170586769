// @flow
import Joi from "joi";
import joiResolver from "../../services/form/joiResolver";

const schema = Joi.object({
  name: Joi.string().required(),
  placement: Joi.string().required(),
  BrowserLanguages: Joi.array().min(1),
  platforms: Joi.array(),
  data1: Joi.array(),
}).messages({
  "number.base": "This field is required",
  "string.empty": "This field is required",
  "array.min": "This field is required",
  "object.base": "This field is required",
}).unknown();

export default joiResolver(schema);
