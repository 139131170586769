/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  debounce,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
} from "@fas/ui-framework/lib/redux/selectors/table";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  GET_SCHEDULES_SAGA,
  SCHEDULES_LIST_TABLE,
} from "../../helpers/constants";
import ScheduleApi from "../../services/scheduleApi";
import type { ScheduleListItem } from "../../containers/SchedulesList/types/SchedulesList.types";

export function* makeFetch(): Saga<void> {
  yield put(setLoading<typeof SCHEDULES_LIST_TABLE>(SCHEDULES_LIST_TABLE, true));

  const page: number = yield select(getTablePage, SCHEDULES_LIST_TABLE);
  const limit: number = yield select(getTablePageSize, SCHEDULES_LIST_TABLE);
  const filters: Filters = yield select(getTableFilters, SCHEDULES_LIST_TABLE);

  try {
    const { data: response }: Response<{
      data: ScheduleListItem[],
      totalCount: number
      // $FlowFixMe
    }> = yield call(
      ScheduleApi.getSchedulesList,
      page,
      limit,
      filters
    );

    yield put(setTableData(SCHEDULES_LIST_TABLE, response.data));
    yield put(changeTableItemsTotalAmount(SCHEDULES_LIST_TABLE, response.totalCount));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
    // eslint-disable-next-line
    console.error(err);
  }
  finally {
    yield put(setLoading<typeof SCHEDULES_LIST_TABLE>(SCHEDULES_LIST_TABLE, false));
  }
}

export default function* watchFetchSchedulesListSaga(): Saga<void> {
  yield debounce(1000, GET_SCHEDULES_SAGA, (makeFetch: Function));
}
