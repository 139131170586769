// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import getPWAList from "../../sagas/getPwaList";
import getDeleteSaga from "../../sagas/deleteSaga";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import { PWA_LIST_TABLE } from "../../helpers/constants";
import PwaApi from "../../services/PwaApi";
import { getPWAListSaga } from "../../actions/pwaList";
import type { PWAMotivationContent, BrowserLanguages as BrowserLanguagesType } from "../../containers/PwaMotivationContentList";
import type { MotivationTemplateType } from "../../containers/CreateMotivationTemplate";

type PwaShort = $Diff<{ ...PWAMotivationContent, BrowserLanguages: Array<string> }, { id: string }>

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<PWAMotivationContent, PwaShort>({
  tableKey: PWA_LIST_TABLE,
  updateMethod: PwaApi.updatePWA,
  getListAction: getPWAListSaga,
  convert: ({ BrowserLanguages, motivationTemplates, ...pwa }: PWAMotivationContent): PwaShort => ({
    ...pwa,
    BrowserLanguages: BrowserLanguages?.map(({ id }: BrowserLanguagesType): string => id),
    motivationTemplates: motivationTemplates
      ? Object.keys(motivationTemplates)
        // $FlowFixMe
        .reduce((acc, key: MotivationTemplateType) => ({ ...acc, [key]: motivationTemplates[key].id }), {})
      : {},
  }),
  dictionaryName: "PWA Motivation Content",
});

const deleteSaga: () => Saga<void> = getDeleteSaga({
  tableKey: PWA_LIST_TABLE,
  deleteMethod: PwaApi.deletePWA,
  getListAction: getPWAListSaga,
  dictionaryName: "PWA Motivation Content",
});

export default function* mainSaga(): Saga<void> {
  yield all([call(getPWAList), call(changeIsActiveSaga), call(deleteSaga)]);
}
