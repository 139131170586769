/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type {
  Dispatch,
  CombinedReducer,
  Store,
} from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import type { OutputSelector } from "reselect";
import notifications, { initNotificationsState, type State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import loading, { initLoadingState, type State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";
import type { Actions as StepsActions } from "@fas/ui-framework/lib/redux/actions/steps";
import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";
import type { Actions as SegmentsListActions } from "../../actions/segmentsList";
import type { Actions as MessageGroupsListsActions } from "../../actions/messageGroupsList";
import { SEGMENTS_LIST_TABLE, MESSAGE_GROUPS_LIST_TABLE, FORM_KEY_SCHEDULE } from "../../helpers/constants";
import mainSaga from "./saga";
import type { ScheduleFormData } from "../../containers/CreateSchedule";

export const defaultFromData: ScheduleFormData = {
  id: "",
  name: "",
  isActive: true,
  webPushMessageGroups: [],
  webPushSegments: [],
  queueType: "",
  customScheduleAtHours: "0",
  customScheduleAtMinutes: "0",
  createdAt: "",
  updatedAt: "",
};

export type State = $ReadOnly<{
  notifications: NotificationsState,
  loading: LoadingState<string>,
  form: FormState,
}>;

export const getLoading: OutputSelector<
  State,
  string,
  boolean
> = createLoadingSelector<string>();

export type Actions = NotificationsActions
  | TableActions
  | LoadingState<string>
  | MessageGroupsListsActions
  | SegmentsListActions
  | StepsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  loading,
  form,
});

export const mapState: () => State = () => ({
  notifications: initNotificationsState(),
  loading: initLoadingState({
    [SEGMENTS_LIST_TABLE]: false,
    [MESSAGE_GROUPS_LIST_TABLE]: false,
  }),
  form: initFormState({
    [FORM_KEY_SCHEDULE]: defaultFromData,
  }),
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
