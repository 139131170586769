// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { MotivationContent, MotivationContentShort } from "../../containers/MotivationContentList";

export const getMotivationContentsList: ({ page: number, pageSize: number, filters?: Object}) => PromiseResponse<
  mixed,
  { data: MotivationContent[], totalCount: number }
> = (params) => requestService.get(environment.endpoints.get.motivationContentList, {
  params: {
    page: params.page,
    limit: params.pageSize,
    ...params.filters,
  },
});

export const getMotivationContentById: (string) => PromiseResponse<
  mixed,
  { data: MotivationContent[] }
> = (id: string) => requestService.get(environment.endpoints.get.motivationContentById.replace("{id}", id));

export const saveMotivationContent: (MotivationContentShort) => PromiseResponse<
  MotivationContentShort
> = (motivationContent) => requestService
  .post(environment.endpoints.post.motivationContent, { ...motivationContent });

export const updateMotivationContent: (string, MotivationContentShort) => PromiseResponse<MotivationContentShort> = (
  id,
  motivationContent
) => requestService.put(environment.endpoints.put.motivationContent.replace("{id}", id), { ...motivationContent });

export const deleteMotivationContentsByIds: (string[]) => PromiseResponse<mixed> = (idList) => requestService
  .delete(environment.endpoints.delete.motivationContent, {
    data: {
      idList,
    },
  });
