// @flow
/* eslint-disable react/require-default-props */
import { GridUc, SetupCard } from "@fas/ui-core";
import {
  Box, Grid, Typography, Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { Fragment, useEffect, useState } from "react";
import type { CampaignsRow, UsedInCampaigns, UseState } from "./types/AdditionalInfo.types";
import Collapse from "../Collapse/Collapse";
import type { Classes } from "../TargetingView/types/TargetingView.types";
import type { UsedInSchedulesAndSegments } from "../../containers/WebPushCampaignsList/types/WebPushCampaignsList.types";
import type { UsedInMotivationContents } from "../../containers/CreateMotivationTemplate";
import CardDivider from "../CardDivider";

export type Props = {
  blockTitle: string,
  usedInCampaigns: UsedInCampaigns[],
  usedInSchedules: UsedInSchedulesAndSegments[],
  usedInMotivationContents: UsedInMotivationContents[],
}

const useStyles: () => Classes = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
}));

const AdditionalInfo = ({
  blockTitle,
  usedInCampaigns,
  usedInSchedules,
  usedInMotivationContents,
}: Props) => {
  const [isShown, setIsShown]: UseState<boolean> = useState(false);
  const classes: Classes = useStyles();

  useEffect(() => {
    setIsShown(!!usedInCampaigns.length || !!usedInSchedules.length || !!usedInMotivationContents.length);
  }, [usedInCampaigns, usedInSchedules, usedInMotivationContents]);

  return (
    <>
      {isShown && blockTitle && (
        <SetupCard
          title={blockTitle}
        >
          <>
            {!!usedInCampaigns.length && (
              <Box width="100%" className={classes.root}>
                <GridUc
                  mx={3}
                  my={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textSecondary">Used in campaigns:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Collapse
                      showMoreDisabled={false}
                      list={usedInCampaigns.map(({
                        id,
                        isActive,
                        name,
                      }: CampaignsRow): * => (
                        {
                          id,
                          link: `/campaigns/view/${id}`,
                          name,
                          isActive,
                        }
                      ))}
                    />
                  </Grid>
                </GridUc>
              </Box>
            )}
            {!!usedInSchedules.length && (
              <Box
                width="100%"
                className={classes.root}
              >
                {usedInSchedules.map((item, index) => (
                  <Fragment key={item.id}>
                    <ul>
                      <li>
                        <Box my={1}>
                          <Link
                            key={item.id}
                            target="_blank"
                            href={`/schedules/view/${item.id}`}
                            underline="hover"
                          >
                            {item.name}
                          </Link>
                        </Box>
                      </li>
                      <ul>
                        {item.WebPushSegments.map((segment) => (
                          <li key={segment.id}>
                            <Link
                              target="_blank"
                              href={`/segments/view/${segment.id}`}
                              underline="hover"
                            >
                              {segment.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </ul>
                    <Box mx={3}>
                      <CardDivider visible={index < usedInSchedules.length - 1} />
                    </Box>
                  </Fragment>
                ))}
              </Box>
            )}
            {!!usedInMotivationContents.length && (
              <Box width="100%" className={classes.root}>
                <GridUc
                  mx={3}
                  my={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textSecondary">Used in motivation contents:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Collapse
                      showMoreDisabled={false}
                      list={usedInMotivationContents.map(({
                        id,
                        name,
                        integrationType,
                      }: UsedInMotivationContents): * => (
                        {
                          id,
                          link: `/${integrationType === "wp" ? "webpush" : integrationType}/view/${id}`,
                          name,
                        }
                      ))}
                    />
                  </Grid>
                </GridUc>
              </Box>
            )}
          </>
        </SetupCard>
      )}
    </>
  );
};

export default AdditionalInfo;

AdditionalInfo.defaultProps = {
  blockTitle: "Additional Info",
  usedInCampaigns: [],
  usedInSchedules: [],
  usedInMotivationContents: [],
};
