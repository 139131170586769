// @flow
import {
  call,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import {
  DELETE_PWA_ICONS_SAGA, PWA_ICONS_LIST_TABLE,
} from "../../helpers/constants";
import {
  getPwaIconsListSaga,
} from "../../actions/pwaIconsList";
import PwaIconApi from "../../services/pwaIconApi";
import { deleteFn } from "../deleteSaga/deleteSaga";
import { deleteSaga } from "../../actions/tableActions/actions";

export function* makeFetch(): Saga<void> {
  const ids = yield select(getTableSelections, PWA_ICONS_LIST_TABLE);
  yield call(deleteFn, {
    tableKey: PWA_ICONS_LIST_TABLE,
    deleteMethod: PwaIconApi.deletePwaIconsByIds,
    getListAction: getPwaIconsListSaga,
    dictionaryName: "PWA Icon",
  },
  deleteSaga(ids.map((i) => i.id)));
}

export default function* watchFetchSegmentsListSaga(): Saga<void> {
  // $FlowFixMe
  yield takeEvery(DELETE_PWA_ICONS_SAGA, makeFetch);
}
