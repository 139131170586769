// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import DocumentTitle from "react-document-title";
import { Provider } from "react-redux";
import Notification from "../../components/Notification";
import configureStore from "./store";
import PWAListContainer from "../../containers/PwaMotivationContentList";
import { PWA_LIST_TABLE } from "../../helpers/constants";
import TableActions, { type TableActionsProps } from "../../containers/TablesActions";
import { deleteSaga, changeIsActiveSaga } from "../../actions/tableActions";

const actionsProps: TableActionsProps<typeof PWA_LIST_TABLE> = {
  tableKey: PWA_LIST_TABLE,
  editPath: "/pwa/edit/:id",
  viewPath: "/pwa/view/:id",
  onDelete: deleteSaga,
  onChangeIsActive: changeIsActiveSaga,
};

const store = configureStore();

const PwaMotivationContentList: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="PWA Motivation Content">
    <Provider store={store}>
      <PWAListContainer Actions={<TableActions {...actionsProps} />} />
      <Notification />
    </Provider>
  </DocumentTitle>
);

export default PwaMotivationContentList;
