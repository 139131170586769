// @flow
import React from "react";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import { Counter } from "@fas/ui-core";

type Props = {
  name: string,
  value: number,
  min: number,
  max: number,
  onChange: (number) => *,
}

function CounterForm({
  value,
  ...props
}: Props) {
  return <Counter {...props} counter={value} />;
}

CounterForm.defaultProps = {
  onChange: () => {},
  value: 0,
};

export default withFormField<*>(CounterForm);
