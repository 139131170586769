// @flow
import type { RecordFactory, RecordOf } from "immutable";
import {
  Record,
  Map,
  fromJS,
} from "immutable";
import type { Reducer } from "redux";
import type {
  SetModifiers,
  SetModifiersState,
  SetModifierSimple,
  AddModifierButton,
  SetModifierButton,
  RemoveModifierButton,
  SetModifierImage,
  Actions,
} from "../../actions/modifiers/actions.types";
import {
  SET_MODIFIERS,
  SET_MODIFIERS_STATE,
  SET_MODIFIER_SIMPLE,
  ADD_MODIFIER_BUTTON,
  SET_MODIFIER_BUTTON,
  REMOVE_MODIFIER_BUTTON,
  ADD_MODIFIER_IMAGE,
  SET_MODIFIER_IMAGE,
  REMOVE_MODIFIER_IMAGE,
  TOP_LEFT_CSS,
} from "../../helpers/constants";
import type {
  ButtonTypes,
  ButtonPropTypes,
  Simple,
  Button,
} from "../../containers/Modifiers";
import type { MotivationTemplateType } from "../../containers/CreateMotivationTemplate";

export type ButtonState = Map<
  ButtonTypes, Map<
    string, Map<
      ButtonPropTypes,
      string
    >
  >
>

export const simpleModifiers: Simple = {
  popup_position: TOP_LEFT_CSS,
  text_title: "",
  text_body: "",
  bg_color: "",
  text_color: "",
};

export type ModifierstState = {
  simple: RecordOf<Simple>,
  images: Map<string, string>,
  buttons: ButtonState
};

type InitStateType = {
  [MotivationTemplateType]: $Shape<ModifierstState>,
};

export const emptyButton: Button = {
  text: "",
  bg_color: "",
  text_color: "",
};

export const makeSimpleModifiers: RecordFactory<typeof simpleModifiers> = Record(simpleModifiers);

export const defaultValues: ModifierstState = {
  simple: makeSimpleModifiers(),
  images: Map({
    "1": "",
  }),
  buttons: Map({
    confirm: Map({
      "1": Map(emptyButton),
    }),
    deny: Map({
      "1": Map(emptyButton),
    }),
    motiv_install: Map({
      "1": Map(emptyButton),
    }),
  }),
};

export const makeModifiers: RecordFactory<ModifierstState> = Record(defaultValues);

export type State = Map<string, RecordOf<ModifierstState>>;
export const initialState: State = Map({});

export function initModifiersState(shape: InitStateType = {}): State {
  return Map({}).withMutations((map: State) => {
    Object.keys(shape).forEach((key: MotivationTemplateType) => {
      map.set(key, makeModifiers(shape[key]));
    });
  });
}

// eslint-disable-next-line complexity
const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODIFIERS: {
      const {
        payload: {
          key,
          modifiers,
        },
      }: SetModifiers = action;
      // $FlowFixMe
      return state.mergeDeepIn([key], fromJS(modifiers));
    }
    case SET_MODIFIERS_STATE: {
      const {
        payload: modifiersState,
      }: SetModifiersState = action;
      // $FlowFixMe
      return state.mergeDeep(fromJS(modifiersState));
    }
    case SET_MODIFIER_SIMPLE: {
      const {
        payload: {
          key,
          modifier,
        },
      }: SetModifierSimple = action;
      return state.mergeIn([key, "simple"], modifier);
    }
    case ADD_MODIFIER_BUTTON: {
      const {
        payload: {
          key,
          buttonType,
        },
      }: AddModifierButton = action;
      const nextIdx: string = String(state.getIn([key, "buttons", buttonType], Map()).size + 1);
      return state.mergeIn([key, "buttons", buttonType], { [nextIdx]: Map(emptyButton) });
    }
    case SET_MODIFIER_BUTTON: {
      const {
        payload: {
          key,
          idx,
          buttonType,
          buttonPropType,
          value,
        },
      }: SetModifierButton = action;
      return state.setIn([key, "buttons", buttonType, idx, buttonPropType], value);
    }
    case REMOVE_MODIFIER_BUTTON: {
      const {
        payload: {
          key,
          buttonType,
        },
      }: RemoveModifierButton = action;
      const removeIdx: string = String(state.getIn([key, "buttons", buttonType], Map()).size);
      return state.removeIn([key, "buttons", buttonType, removeIdx]);
    }
    case ADD_MODIFIER_IMAGE: {
      const { payload: key }: * = action;
      const nextIdx: string = String(state.getIn([key, "images"], Map()).size + 1);
      return state.mergeIn([key, "images"], { [nextIdx]: "" });
    }
    case SET_MODIFIER_IMAGE: {
      const {
        payload: {
          key,
          idx,
          value,
        },
      }: SetModifierImage = action;
      return state.setIn([key, "images", idx], value);
    }
    case REMOVE_MODIFIER_IMAGE: {
      const { payload: key }: * = action;
      const removeIdx: string = String(state.getIn([key, "images"], Map()).size);
      return state.removeIn([key, "images", removeIdx]);
    }
    default:
      return state;
  }
};

export default reducer;
