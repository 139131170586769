/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import type { StatelessFunctionalComponent, Element } from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import {
  Table,
  IsActiveLabel,
  ViewLink,
  SelectSearchComponent,
  ShowMoreItems,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import { WEBPUSH_CAMPAIGNS_TABLE } from "../../helpers/constants";
import type { WebPushCampaignListItem, Classes } from "./types/WebPushCampaignsList.types";
import { getWebpushCampaignsListSaga } from "../../actions/webpushCampaignsList";
import externalRedirect from "../../components/App/ExternalRedirect";
import { getFields, compareById } from "../../services/utils";
import TableActions, { type TableActionsProps } from "../TablesActions";
import { deleteSaga, changeIsActiveSaga } from "../../actions/tableActions";

const useStyles: () => Classes = makeStyles((): {[$Keys<Classes>]: *} => ({
  fixedSizeColumn: {
    width: "25%",
  },
}));

const actionsProps: TableActionsProps<typeof WEBPUSH_CAMPAIGNS_TABLE> = {
  tableKey: WEBPUSH_CAMPAIGNS_TABLE,
  editPath: "/campaigns/edit/:id",
  viewPath: "/campaigns/view/:id",
  onDelete: deleteSaga,
  onChangeIsActive: changeIsActiveSaga,
};

const WebPushCampaignsList: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();
  const tableProps: TableProps = useTable(WEBPUSH_CAMPAIGNS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const columns: Column<WebPushCampaignListItem>[] = [
    {
      label: "Status",
      field: "isActive",
      searchable: true,
      SearchComponent: (props: SearchComponentProps): Element<*> => (
        <SelectSearchComponent
          {...props}
          label="Select"
          values={[
            { value: "", title: "None" },
            { value: "true", title: "Active" },
            { value: "false", title: "Inactive" },
          ]}
        />
      ),
      render: IsActiveLabel,
    },
    {
      label: "Name",
      field: "name",
      searchable: true,
      render: (row: WebPushCampaignListItem): Element<*> => <ViewLink {...row} viewPath="/campaigns/view/:id" />,
    },
    {
      label: "Targetings",
      field: "targetings",
      searchable: true,
      className: classes.fixedSizeColumn,
      render: (row: WebPushCampaignListItem): Element<*> => <ShowMoreItems data={getFields(row.targetings, "name")} />,
    },
    {
      label: "Motivation Contents",
      field: "motivationContents",
      searchable: true,
      className: classes.fixedSizeColumn,
      render: (row: WebPushCampaignListItem): Element<*> => <ShowMoreItems data={getFields(row.motivationContents, "name")} />,
    },
    {
      label: "IPP Motivation Contents",
      field: "ippMotivationContents",
      searchable: true,
      className: classes.fixedSizeColumn,
      render: (row: WebPushCampaignListItem): Element<*> => (
        <ShowMoreItems data={getFields(row.ippMotivationContents, "name")} />
      ),
    },
  ];

  const onLoad: () => mixed = () => dispatch(getWebpushCampaignsListSaga());
  const onCreate: () => null = () => externalRedirect({ to: "/campaigns/create", target: "_self" });
  const rowSelectAvailable: () => boolean = () => true;
  const allSelectAvailable: () => boolean = () => true;

  return (
    <Box m={2}>
      <Table
        {...tableProps}
        columns={columns}
        title="Campaigns"
        onLoad={onLoad}
        onCreate={onCreate}
        rowSelectAvailable={rowSelectAvailable}
        allSelectAvailable={allSelectAvailable}
        compareRows={compareById}
        Actions={<TableActions {...actionsProps} />}
      />
    </Box>
  );
};

export default WebPushCampaignsList;
