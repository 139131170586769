// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../containers/NotificationsContainer";
import CreateSegmentContainer from "../../containers/CreateSegment";
import configureStore from "./store";

function CreateSegment() {
  const store = useMemo(configureStore, []);

  return (
    <DocumentTitle title="Segment">
      <Provider store={store}>
        <NotificationsContainer />
        <CreateSegmentContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default CreateSegment;
