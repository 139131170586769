// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../components/Notification";
import MotivationTemplatesList from "../../containers/MotivationTemplatesList";
import configureStore from "./store";

function MotivationTemplatesListPage() {
  const store = useMemo(configureStore, []);

  return (
    <DocumentTitle title="Motivation Templates">
      <Provider store={store}>
        <NotificationsContainer />
        <MotivationTemplatesList />
      </Provider>
    </DocumentTitle>
  );
}

export default MotivationTemplatesListPage;
