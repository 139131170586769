/* eslint-disable import/max-dependencies */
// @flow
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import PwaOfflinePageView from "../../components/PwaOfflinePageView";
import type { RawPwaOfflinePage } from "../../components/PwaOfflinePagesList/types/PwaOfflinePages.types";
import {
  getBrowserLanguages,
  getId,
  getIsActive,
  getName,
  getPwaOfflineHead,
  getPwaOfflineBody,
  getLoading,
} from "../../selectors/createPwaOfflinePage";
import { getPwaOfflinePageSaga } from "../../actions/createPwaOfflinePage";
import type {
  GetPwaOfflinePageSaga,
  Actions,
} from "../../actions/createPwaOfflinePage";
import { PWA_OFFLINE_FORM_LOADING } from "../../helpers/constants";

type OwnProps = {||}

type StateProps = $ReadOnly<{|
  ...RawPwaOfflinePage,
  isLoading: boolean,
|}>;

type DispatchProps = $ReadOnly<{|
  getPwaOffline: (id: string) => GetPwaOfflinePageSaga,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|}>;

const mapStateToProps = (state) => ({
  id: getId(state),
  BrowserLanguages: getBrowserLanguages(state),
  name: getName(state),
  body: getPwaOfflineBody(state),
  head: getPwaOfflineHead(state),
  isActive: getIsActive(state),
  isLoading: getLoading(state, PWA_OFFLINE_FORM_LOADING),
});

const mapDispatchToProps: (Dispatch<Actions>) => DispatchProps = (dispatch) => bindActionCreators({
  getPwaOffline: getPwaOfflinePageSaga,
},
dispatch);

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(PwaOfflinePageView);
