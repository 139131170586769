/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  takeEvery,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  SAVE_PWA_ICON_SAGA,
} from "../../helpers/constants";
import PwaIconApi from "../../services/pwaIconApi";
import { getPwaIconFormData } from "../../selectors/pwaIcon";
import { setLoading } from "../../actions/loading";
import { type SavePwaIconSaga } from "../../actions/pwaIcon";
import type { PwaIconShort } from "../../components/PwaIconsList/types/PwaIconsList.types";
import externalRedirect from "../../components/App/ExternalRedirect";
import type { PwaIconFormData } from "../../reducers/pwaIcon";

export function* makeFetch({ id }: SavePwaIconSaga): Saga<void> {
  yield put(setLoading("isSaved", true));

  const {
    androidIcons,
    ...rest
  }: PwaIconFormData = yield select(getPwaIconFormData);

  try {
    if (androidIcons.length === 0) {
      yield put(addNotification({ message: "Image Android/WEB is requires", severity: "error" }));
      return;
    }

    const pwaIcon: PwaIconShort = {
      path: androidIcons[0].src,
      ...rest,
    };

    if (!id) {
      yield call(PwaIconApi.savePwaIcon, pwaIcon);
      yield put(addNotification({ message: "Icon saved successfully", severity: "success" }));
    }
    else {
      yield call(PwaIconApi.updatePwaIcon, id, pwaIcon);
      yield put(addNotification({ message: "Icon updated successfully", severity: "success" }));
    }
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
    // eslint-disable-next-line
    console.error(err);
    return;
  }
  finally {
    yield put(setLoading("isSaved", false));
  }

  externalRedirect({ to: "/pwaIcons" });
}

export default function* watchFetchSegmentsListSaga(): Saga<void> {
  yield takeEvery(SAVE_PWA_ICON_SAGA, makeFetch);
}
