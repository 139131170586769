// @flow
import React, { type Node } from "react";
import { GridUc, SetupCard } from "@fas/ui-core";
import {
  Box, Grid, Link, Switch, Typography,
} from "@mui/material";
import NamedItem from "../NamedItem";
import Collapse from "../Collapse/Collapse";
import CardDivider from "../CardDivider";
import type { Classes } from "../../containers/CampaignView";
import type { BrowserLanguage } from "../../containers/CreateMessageGroup";
import type { Targeting } from "../../containers/TargetingsList";

type Props = {
  campaignContent: Targeting[],
  classes: Classes,
}

const CampViewWPTargetings = ({ campaignContent, classes }: Props) => (
  <SetupCard title="Targeting">
    {campaignContent.map((targeting: Targeting, index: number): Node => (
      <Box width="100%" key={targeting.id} className={classes.root}>
        <NamedItem name="Id" value={targeting.id} />
        <GridUc
          mt={3}
          px={3}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">Name</Typography>
          </Grid>
          <Grid item xs={9}>
            <Link
              href={`/targetings/view/${targeting.id}`}
              target="_blank"
              underline="hover"
            >
              {targeting.name}
            </Link>
          </Grid>
        </GridUc>
        <GridUc
          mt={3}
          px={3}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">isActive</Typography>
          </Grid>
          <Grid item xs={9}>
            <Switch checked={targeting.isActive} disabled />
          </Grid>
        </GridUc>
        <NamedItem name="Data1" value={targeting.data1.split(",")} />
        <NamedItem name="Placement" value={targeting.placement[0] || ""} />
        {/* TODO: remove check after MTU-72118 */}
        {!!targeting.platforms?.length && (
          <GridUc
            mt={3}
            px={3}
            container
            direction="row"
            alignItems="center"
          >
            <Grid item xs={3}>
              <Typography variant="body2" color="textSecondary">Platforms</Typography>
            </Grid>
            <Grid item xs={9}>
              <Collapse
                list={targeting.platforms.map((item):
                    {name: string} => ({ name: item }))}
                showMoreDisabled
              />
            </Grid>
          </GridUc>
        )}
        <GridUc
          mt={3}
          px={3}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">Browser Languages</Typography>
          </Grid>
          <Grid item xs={9}>
            <Collapse
              list={targeting.BrowserLanguages.map(
                (item: BrowserLanguage): {name: string} => ({ name: item.language })
              )}
              limit={2}
              showMoreDisabled={false}
            />
          </Grid>
        </GridUc>
        <Box mt={3}>
          <CardDivider visible={index < campaignContent.length - 1} />
        </Box>
      </Box>
    ))}
  </SetupCard>
);

export default CampViewWPTargetings;
