// @flow
import environment from "environment";
import { requestService } from "../request";
import type {
  MotivationTemplate,
  MotivationTemplateServerData,
} from "../../containers/MotivationTemplatesList";
import type { MotivationTemplateDropdown, MotivationTemplateRaw } from "../../containers/CreateMotivationTemplate";

const convertServerToUiData = (
  data: MotivationTemplateServerData[]
) => data.map(({ css, html, ...item }: MotivationTemplateServerData): MotivationTemplate => ({
  ...item,
  inlineCss: css,
  inlineHtml: html,
}));

const convertUiToServer = ({ inlineCss, inlineHtml, ...item }: MotivationTemplate): MotivationTemplateServerData => ({
  ...item,
  css: inlineCss,
  html: inlineHtml,
});

export const fetchMotivationTemplateList: ({
  page: number,
  limit: number,
  filters: { [string]: mixed },
}) => Promise<*> = ({
  page,
  limit,
  filters,
}) => requestService.get(environment.endpoints.get.getMotivationTemplatesList, {
  params: {
    ...filters,
    page,
    limit,
  },
}).then(({ data: { data, totalCount } }) => ({ data: convertServerToUiData(data), totalCount }));

export const updateMotivationTemplate = (
  id: string,
  body: *
): Promise<*> => requestService
  .put(environment.endpoints.put.updateMotivationTemplate.replace("{id}", id), convertUiToServer(body));

export const deleteMotivationTemplate = (
  idList: Array<string>
): Promise<*> => requestService
  .delete(environment.endpoints.delete.deleteMotivationTemplate, { data: { idList } });

export const fetchMotivationTemplate: (id: string) => Promise<*> = (id) => requestService
  .get(environment.endpoints.get.getMotivationTemplateById.replace("{id}", id))
  .then(({ data: { data } }) => data[0]);

export async function saveMotivationTemplate({
  id,
  isActive,
  ...body
}: *) {
  if (id) {
    await requestService.put(environment.endpoints.put.updateMotivationTemplate
      .replace("{id}", String(id)), { ...body, isActive });
  }
  else {
    await requestService.post(environment.endpoints.post.saveMotivationTemplate, body)
      .then(({ data }) => data.id);
  }
}

export const fetchMotivationTemplateDropdown: ({ name: string, type: string }) => Promise<*> = ({
  name, type,
}) => requestService.get(environment.endpoints.get.getMotivationTemplatesList, {
  params: { name, type },
}).then(({ data: { data: templateList } }): MotivationTemplateDropdown[] => templateList
  .map((data: MotivationTemplateRaw): MotivationTemplateDropdown => ({ label: data.name, value: data.id, ...data })));
