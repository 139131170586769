/* eslint-disable import/max-dependencies */
// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setMotivationTemplate } from "../../actions/createMotivationTemplate";
import type { GetMotivationTemplateSaga } from "../../actions/createMotivationTemplate/actions.types";
import { setModifiers } from "../../actions/modifiers";
import { MOTIVATION_TEMPLATE_FORM, GET_MOTIVATION_TEMPLATE_SAGA } from "../../helpers/constants";
import setLoading from "../../actions/loading/actions";
import { fetchMotivationTemplate } from "../../services/motivationTemplateApi";
import { convertModifiersToUi, type Modifier } from "../../containers/Modifiers";
import type { MotivationTemplateFormData } from "../../containers/CreateMotivationTemplate";
import externalRedirect from "../../components/App/ExternalRedirect";

export function* makeFetch({ payload: id }: GetMotivationTemplateSaga): Saga<void> {
  try {
    yield put(setLoading(MOTIVATION_TEMPLATE_FORM, true));

    const { modifiers, type, ...data }: {|
      ...MotivationTemplateFormData, modifiers: Modifier,
    |} = yield call(fetchMotivationTemplate, id);

    yield put(setMotivationTemplate({ ...data, type }));
    yield put(setModifiers(type, convertModifiersToUi({ modifiers, type })));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
    externalRedirect({ to: "/motivationTemplates" });
  }
  finally {
    yield put(setLoading(MOTIVATION_TEMPLATE_FORM, false));
  }
}

export default function* watchGetFormDataSaga(): Saga<void> {
  yield takeEvery(GET_MOTIVATION_TEMPLATE_SAGA, (makeFetch: Function));
}
