/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import {
  Loader,
  SetupCard,
  GridUc,
} from "@fas/ui-core";
import {
  Container, Grid, Typography, Switch, Box,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import { FORM_KEY_SEGMENT, GET_FORM_DATA_LOADING } from "../../helpers/constants";
import externalRedirect from "../../components/App/ExternalRedirect";
import NamedItem from "../../components/NamedItem";
import ViewSegment from "../../components/Segments/ViewSegment";
import Collapse from "../../components/Collapse";
import type { MessageGroupListItem } from "../../components/MessageGroupsList/types/MessageGroupsList.types";
import { getSegmentFormDataSaga } from "../../actions/segmentView";
import { useLoading } from "../../hooks";
import type { SegmentFormData } from "../CreateSegment";
import type { State } from "../../pages/SegmentView";

type Classes = {
  root: string,
}

const useStyles: () => Classes = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
}));

const getItemEditPath: (string) => string = (id) => `/segments/edit/${id}`;

const SegmentView: StatelessFunctionalComponent<{}> = () => {
  const dispatch = useDispatch();
  const {
    id,
    name,
    isActive,
    criteriaRaw,
    isLimit,
    webPushMessageGroups,
    minimum,
    maximum,
  }: SegmentFormData = useSelector((state: State) => getFormData(state, FORM_KEY_SEGMENT));
  const classes: Classes = useStyles();
  const { id: segmentId }: { id: ?string } = useParams();

  const isLoading: boolean = useLoading(GET_FORM_DATA_LOADING);

  useEffect(() => {
    dispatch(getSegmentFormDataSaga(segmentId));
  }, []);

  return (
    <>
      {isLoading && (
        <Loader loading size={35} mt={37} />
      )}
      {!isLoading && (
        <Container maxWidth="md">
          <SetupCard
            title="Segment Info"
            showSecondaryBtn
            secondaryBtnText="Edit"
            onSecondaryBtnClick={() => {
              externalRedirect({ to: getItemEditPath(id), target: "_blank" });
            }}
          >
            <Box pb={3} width="100%" className={classes.root}>
              <NamedItem name="Id" value={id} />
              <NamedItem name="Name" value={name} />
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">isActive</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Switch checked={isActive} disabled />
                </Grid>
              </GridUc>
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">Criteria</Typography>
                </Grid>
                <Grid item xs={9}>
                  <ViewSegment criteria={criteriaRaw} />
                </Grid>
              </GridUc>
            </Box>
          </SetupCard>
          {isLimit && (
            <SetupCard title="Limit">
              <Box pb={3} width="100%" className={classes.root}>
                <NamedItem name="Minimum messages" value={String(minimum)} />
                <NamedItem name="Maximum messages" value={String(maximum)} />
                <GridUc
                  mt={3}
                  px={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textSecondary">Message Groups</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Collapse
                      list={webPushMessageGroups.map((item: MessageGroupListItem): { name: string, link: string } => ({
                        name: item.name,
                        link: `/messageGroups/view/${item.id}`,
                      }))}
                      limit={2}
                      showMoreDisabled={false}
                    />
                  </Grid>
                </GridUc>
              </Box>
            </SetupCard>
          )}
        </Container>
      )}
    </>
  );
};

export default SegmentView;
