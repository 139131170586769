// @flow
import {
  MOTIVATION_TEMPLATES_LIST,
} from "../../helpers/constants";

export type GetMotivationTemplatesListSaga = {
  type: typeof MOTIVATION_TEMPLATES_LIST,
};

export type Actions = GetMotivationTemplatesListSaga

export const getMotivationTemplatesListSaga: () => GetMotivationTemplatesListSaga = () => ({
  type: MOTIVATION_TEMPLATES_LIST,
});
