// @flow
import React, {
  useState,
  type StatelessFunctionalComponent,
} from "react";
import { userApiService } from "../../services/userApi";
import {
  isFieldValid,
  validateAllFields,
  isFormValid,
} from "../../helpers/formsValidation";
import AuthForm from "../../components/AuthForm";
import ErrorBoundry from "../../components/ErrorBoundry";
import externalRedirect from "../../components/App/ExternalRedirect";

type UseState<T> = [T, ((T => T) | T) => void];

const Login: StatelessFunctionalComponent<{}> = () => {
  const [formField, setFormFields]: UseState<{ [key: string]: string }> = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors]: UseState<{ [key: string]: boolean }> = useState({
    username: false,
    password: false,
  });

  const [message, setMessage]: UseState<string> = useState("");
  const [isValid, setIsValid]: UseState<boolean> = useState(true);

  const handleSubmit: () => void = () => {
    const { username, password }: { [key: string]: string } = formField;
    const fieldErrors: { [key: string]: boolean } = validateAllFields({ username, password });
    const isFormFieldsValid: boolean = isFormValid(fieldErrors);
    if (!isFormFieldsValid) {
      setErrors(fieldErrors);
      return;
    }
    userApiService.appLogin({ username, password })
      .then(() => {
        const params: URLSearchParams = (new URL(window.location)).searchParams;
        const redirectUrl: string = params.get("r") || "/";
        externalRedirect({ to: redirectUrl });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Fetch error:", error);

        if (error.errorMessage) {
          setIsValid(false);
          setMessage(error.errorMessage);
        }
      });
  };

  const onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void = (e) => {
    const { name, value }: HTMLInputElement = e.target;

    setErrors({
      ...errors,
      [name]: !isFieldValid({ name, value }),
    });
    setFormFields({
      ...formField,
      [name]: value,
    });
  };

  return (
    <ErrorBoundry>
      <AuthForm
        formName="Login"
        handleChange={onChange}
        fields={[
          {
            label: "username",
            value: formField.username,
            type: "text",
            error: errors.username,
          },
          {
            label: "password",
            value: formField.password,
            type: "password",
            error: errors.password,
          },
        ]}
        handleSubmit={handleSubmit}
        formError={{
          message,
          isValid,
        }}
        redirectLink={{
          url: "/signup",
          label: "Switch to sign up form",
        }}
      />
    </ErrorBoundry>
  );
};

export default Login;
