// @flow
import {
  GET_MOTIVATION_CONTENTS_SAGA,
} from "../../helpers/constants";

export type GetMotivationContentsSaga = {|
  type: typeof GET_MOTIVATION_CONTENTS_SAGA,
|};

export type Actions =
  GetMotivationContentsSaga;

export const getMotivationContentsSaga: () => GetMotivationContentsSaga = () => ({
  type: GET_MOTIVATION_CONTENTS_SAGA,
});
