/* eslint-disable import/max-dependencies */
// @flow
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { Box, Container, Button } from "@mui/material";
import { Loader } from "@fas/ui-core";
import PwaOfflinePageForm from "../../components/PwaOfflinePagesForm";

import {
  getBrowserLanguages,
  getId,
  getIsActive,
  getName,
  getPwaOfflineHead,
  getPwaOfflineBody,
  getLoading,
} from "../../selectors/createPwaOfflinePage";
import {
  savePwaOfflinePageSaga,
  getPwaOfflinePageSaga,
  setPwaOfflineIsActive,
  setPwaOfflineBrowserLanguages,
  setPwaOfflineName,
  setPwaOfflineHead,
  setPwaOfflineBody,
} from "../../actions/createPwaOfflinePage";
import type {
  Actions,
  SavePwaOfflinePageSaga,
  GetPwaOfflinePageSaga,
  SetPwaOfflineIsActive,
  SetPwaOfflineBrowserLanguages,
  SetPwaOfflineName,
  SetPwaOfflineHead,
  SetPwaOfflineBody,
} from "../../actions/createPwaOfflinePage";
import type { RawBrowserLanguages } from "../PwaMotivationContentList";
import { PWA_OFFLINE_FORM_LOADING } from "../../helpers/constants";

type OwnProps = {||}

type StateProps = $ReadOnly<{|
  id: string,
  name: string,
  BrowserLanguages: Array<RawBrowserLanguages>,
  body: string,
  head: string,
  isActive: boolean,
  isLoading: boolean,
|}>;

const mapStateToProps = (state) => ({
  id: getId(state),
  BrowserLanguages: getBrowserLanguages(state),
  name: getName(state),
  body: getPwaOfflineBody(state),
  head: getPwaOfflineHead(state),
  isActive: getIsActive(state),
  isLoading: getLoading(state, PWA_OFFLINE_FORM_LOADING),
});

type DispatchProps = $ReadOnly<{|
  getPwaOffline: (id: string) => GetPwaOfflinePageSaga,
  onSavePwaOffline: () => SavePwaOfflinePageSaga,
  onBrowserLanguagesChange: (Array<RawBrowserLanguages>) => SetPwaOfflineBrowserLanguages,
  onHeadChange: (headValue: string) => SetPwaOfflineHead,
  onBodyChange: (bodyValue: string) => SetPwaOfflineBody,
  onIsActiveChange: (isActive: boolean) => SetPwaOfflineIsActive,
  onNameChange: (name: string) => SetPwaOfflineName,
|}>;

const mapDispatchToProps: (Dispatch<Actions>) => DispatchProps = (dispatch) => bindActionCreators({
  getPwaOffline: getPwaOfflinePageSaga,
  onSavePwaOffline: savePwaOfflinePageSaga,
  onBrowserLanguagesChange: setPwaOfflineBrowserLanguages,
  onHeadChange: setPwaOfflineHead,
  onBodyChange: setPwaOfflineBody,
  onIsActiveChange: setPwaOfflineIsActive,
  onNameChange: setPwaOfflineName,
},
dispatch);

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|}>;

const PwaOfflineFormContainer = (props: Props) => {
  const {
    onSavePwaOffline,
    getPwaOffline,
    isLoading,
  }: Props = props;

  const { id: pwaId } = useParams();

  useEffect(() => {
    if (pwaId) {
      getPwaOffline(pwaId);
    }
  }, [pwaId]);

  return (
    <Container maxWidth="md">
      <Loader loading={isLoading} withoutWrapper>
        <PwaOfflinePageForm {...props} />
        <Box display="flex" justifyContent="flex-end" mb={5}>
          <Button
            type="button"
            size="medium"
            variant="contained"
            color="primary"
            onClick={onSavePwaOffline}
            data-testid="save-button"
            disabled={isLoading}
          >
            Save
          </Button>
        </Box>
      </Loader>
    </Container>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(PwaOfflineFormContainer);
