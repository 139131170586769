// @flow
import {
  SAVE_PWA_ICON_SAGA,
  SET_PWA_ICON_FORM_FIELD,
  SET_PWA_ICON_IMAGES,
} from "../../helpers/constants";
import type { PwaIconFields, PwaIconImages } from "../../reducers/pwaIcon";
import type { Image } from "../../components/UploadResizerModal";

export type SavePwaIconSaga = {|
  type: typeof SAVE_PWA_ICON_SAGA,
  id: ?string,
|};

export type SetPwaIconFormField = {|
  type: typeof SET_PWA_ICON_FORM_FIELD,
  payload: $Shape<PwaIconFields>,
|};

export type SetPwaIconImages = {|
  type: typeof SET_PWA_ICON_IMAGES,
  payload: {
    field: $Keys<PwaIconImages>,
    value: Array<Image>,
  },
|};

export type Actions = SetPwaIconFormField
  | SavePwaIconSaga
  | SetPwaIconImages;

export const savePwaIconSaga: (?string) => SavePwaIconSaga = (id) => ({ type: SAVE_PWA_ICON_SAGA, id });

export const setPwaIconFormField: (field: $Shape<PwaIconFields>) => SetPwaIconFormField = (field) => ({
  type: SET_PWA_ICON_FORM_FIELD,
  payload: field,
});

export const setPwaIconImages: (
  field: $Keys<PwaIconImages>, value: Array<Image>
) => SetPwaIconImages = (field, value) => ({
  type: SET_PWA_ICON_IMAGES,
  payload: { field, value },
});
