// @flow
import { type Node } from "react";
import { userService } from "../../services/user";
import type { Permission } from "../../services/Acl";

type Props = {
  permission: Permission,
  children: Node,
};

export default ({ permission, children }: Props): Node => (
  userService.can([permission]) && children);
