/* eslint-disable camelcase */
// @flow
import {
  call, put, takeEvery, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { PWA_OFFLINE_FORM_LOADING, SAVE_PWA_OFFLINE_PAGE_SAGA } from "../../helpers/constants";
import { getPwaOfflinePage } from "../../selectors/createPwaOfflinePage";
import type { RawPwaOfflinePage } from "../../components/PwaOfflinePagesList";
import externalRedirect from "../../components/App/ExternalRedirect";
import PwaApi from "../../services/PwaApi";

export function* makeFetch(): Saga<void> {
  yield put(setLoading(PWA_OFFLINE_FORM_LOADING, true));

  const {
    id,
    BrowserLanguages,
    name,
    body,
    head,
    isActive,
  }: RawPwaOfflinePage = yield select(getPwaOfflinePage);

  const data = {
    id,
    BrowserLanguages: BrowserLanguages.map(({ value }) => value),
    name,
    body,
    head,
    isActive,
  };

  try {
    if (data.id) {
      yield call(PwaApi.updatePwaOfflinePage, data.id, data);
      yield put(addNotification({
        message: "PWA Offline page updated successfully",
        severity: "success",
      }));
    }
    else {
      yield call(PwaApi.savePwaOfflinePage, data);
      yield put(addNotification({
        message: "PWA Offline page saved successfully",
        severity: "success",
      }));
    }
    yield put(setLoading(PWA_OFFLINE_FORM_LOADING, false));
    externalRedirect({ to: "/pwaOfflinePages" });
  }
  catch (error) {
    yield put(setLoading(PWA_OFFLINE_FORM_LOADING, false));
    yield put(addNotification({
      message: error.errorMessage,
      severity: "error",
    }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchSavePwaOfflineSaga(): Saga<void> {
  yield takeEvery(SAVE_PWA_OFFLINE_PAGE_SAGA, (makeFetch: Function));
}
