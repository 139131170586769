// @flow
import {
  GET_TARGETINGS_LIST_SAGA,
} from "../../helpers/constants";

export type GetTargetingsListSaga = {|
  type: typeof GET_TARGETINGS_LIST_SAGA,
|};

export type Actions = GetTargetingsListSaga;

export const getTargetingsListSaga: () => GetTargetingsListSaga = () => ({
  type: GET_TARGETINGS_LIST_SAGA,
});
