// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { GroupContainer, Loader, TargetingRuleContainer } from "@fas/ui-core";
import { actions, type Actions } from "../../actions/segmentConditions";
import {
  getRuleList,
  getOperatorsList,
  getValuesList,
  getGroupByIdSelector,
  getRuleByIdSelector,
  getGroupErrorById,
} from "../../selectors/segmentConditions";
import RuleComponent from "../../components/Rule";
import { useLoading, useActions } from "../../hooks";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants";
import type { Handlers } from "./SegmentConditions.types";

type Props = {||};

const SegmentConditions: StatelessFunctionalComponent<Props> = () => {
  const loading: boolean = useLoading(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  const {
    changeConditionGroupOperator: onChangeGroupOperator,
    deleteConditionRule: onRemoveRule,
    deleteConditionGroup: onRemoveGroup,
    addCondition: onAddRule,
    addConditionGroup: onAddGroup,
    changeConditionValue: onChangeValue,
    changeConditionRule: onChangeRule,
    changeConditionOperator: onChangeRuleOperator,
  }: Handlers = useActions<Actions>(actions);

  return (
    <Loader loading={loading}>
      {/* $FlowFixMe */}
      <GroupContainer
        id="1"
        isRuleListLoading={false}
        RuleComponent={RuleComponent}
        RuleContainer={TargetingRuleContainer}
        groupProps={{
          getGroupByIdSelector,
          changeGroupOperator: onChangeGroupOperator,
          removeRule: onRemoveRule,
          getStatusRule: (): boolean => false,
          addGroup: onAddGroup,
          removeGroup: onRemoveGroup,
          addRule: onAddRule,
          disabledDelete: true,
          getErrorMessage: getGroupErrorById,
        }}
        ruleProps={{
          getRuleByIdSelector,
          handleChangeValue: onChangeValue,
          handleChangeRule: onChangeRule,
          handleChangeOperator: onChangeRuleOperator,
          isRemoveDisabled: false,
          checkLoadingForValues: (): boolean => false,
          getRuleList,
          getOperatorsList,
          getValuesList,
        }}
      />
    </Loader>
  );
};

export default SegmentConditions;
