// @flow
import environment from "environment";
import UserApi from "@fas/ui-framework/lib/services/userApi";
import type {
  PromiseResponse,
  Response,
} from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import { userService } from "../user";
import type {
  SignInRequestType,
  SignInResponseDataType,
  SignOutResponseDataType,
  SignUpRequestType,
  SignUpResponseDataType,
} from "./types/userApi.types";

class UserApiService extends UserApi {
  logout<R>(url: string): PromiseResponse<mixed, R> {
    return this._request.get<R>(url);
  }

  appRegister(options: SignUpRequestType): PromiseResponse<SignUpRequestType, SignUpResponseDataType> {
    return this._request.post(environment.endpoints.post.signUp, options)
      .then((
        body: Response<SignUpRequestType, SignUpResponseDataType>
      ): Response<SignUpRequestType, SignUpResponseDataType> => {
        const { data }: Response<SignUpRequestType, SignUpResponseDataType> = body;
        if (data.result === "error") {
          throw new Error(data.message);
        }

        return body;
      });
  }

  appLogin({ username, password }: SignInRequestType): PromiseResponse<SignInRequestType, SignInResponseDataType> {
    return this.login<SignInRequestType, SignInResponseDataType>(`${environment.endpoints.post.token}`, { username, password })
      .then((
        body: Response<SignInRequestType, SignInResponseDataType>
      ): Response<SignInRequestType, SignInResponseDataType> => {
        const { data }: Response<SignInRequestType, SignInResponseDataType> = body;
        const {
          token,
          roles,
          expiresIn,
        }: SignInResponseDataType = data;
        const expires: Date = new Date(Date.now() + expiresIn * 1000);

        userService.setAuthToken(token, expires);
        userService.setUserRoles(roles, expires);
        userService.setUsername(username, expires);
        userService.setUserPermissions();

        return body;
      });
  }

  appLogout(): PromiseResponse<mixed, SignOutResponseDataType> {
    return this.logout<SignOutResponseDataType>(environment.endpoints.get.signOut)
      .then((
        body: Response<mixed, SignOutResponseDataType>
      ): Response<mixed, SignOutResponseDataType> => {
        const { data }: Response<mixed, SignOutResponseDataType> = body;
        if (!data.signouted) {
          throw new Error(data.msg);
        }

        userService.removeAuthToken();
        userService.removeUserRoles();
        userService.removeUsername();

        return body;
      });
  }
}

// eslint-disable-next-line import/prefer-default-export
export const userApiService: UserApiService = new UserApiService(userService, requestService);
