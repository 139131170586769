// @flow
import type { RecordFactory, RecordOf, List as ListType } from "immutable";
import { Record, List } from "immutable";
import type { Reducer } from "redux";
import type { Actions, SetPwaIconFormField, SetPwaIconImages } from "../../actions/pwaIcon";
import { SET_PWA_ICON_FORM_FIELD, SET_PWA_ICON_IMAGES } from "../../helpers/constants";
import type { PwaIconShort } from "../../components/PwaIconsList/types/PwaIconsList.types";
import type { Image } from "../../components/UploadResizerModal";

export type PwaIconFields = {|
  name: string,
  shortname: string,
  isActive: boolean,
|}
export type PwaIconImages = {|
  androidIcons: Array<Image>,
  iosIcons: Array<Image>,
  iosSplashScreens: Array<Image>,
  ipadSplashScreens: Array<Image>,
|}

export type PwaIconFormData = {|
  ...PwaIconFields,
  ...PwaIconImages,
|}

export type PwaIconImagesState = {|
  androidIcons: ListType<Image>,
  iosIcons: ListType<Image>,
  iosSplashScreens: ListType<Image>,
  ipadSplashScreens: ListType<Image>,
|}

export type DefaultState = {|
  ...PwaIconFormData,
  ...PwaIconImagesState,
|};

const defaultValues: DefaultState = {
  name: "",
  shortname: "",
  androidIcons: List([]),
  iosIcons: List([]),
  iosSplashScreens: List([]),
  ipadSplashScreens: List([]),
  isActive: true,
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export const initPwaIconState: (pwaIcon?: PwaIconShort) => State = ({ path, ...rest } = {}) => makeState({
  ...rest,
  androidIcons: path ? List([{ sizes: "192x192", src: path }]) : List([]),
});

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_PWA_ICON_FORM_FIELD: {
      const { payload }: SetPwaIconFormField = action;
      return state.merge(payload);
    }
    case SET_PWA_ICON_IMAGES: {
      const { payload: { field, value } }: SetPwaIconImages = action;
      return state.set(field, List(value));
    }
    default:
      return state;
  }
};

export default reducer;
