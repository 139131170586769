/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect, type StatelessFunctionalComponent } from "react";
import {
  Loader,
  SetupCard,
  GridUc,
} from "@fas/ui-core";
import {
  Container, Grid, Typography, Switch, Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import type { Targeting } from "../../containers/TargetingsList";
import type { Classes } from "./types/TargetingView.types";
import externalRedirect from "../App/ExternalRedirect";
import NamedItem from "../NamedItem";
import Collapse from "../Collapse";
import type { BrowserLanguage } from "../../containers/CreateMessageGroup";
import AdditionalInfo from "../AdditionalInfo";

type Props = {
  targeting: Targeting,
  isLoading: boolean,
  onLoad: (id: string) => mixed,
};

const useStyles: () => Classes = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
}));

const getItemEditPath: (string) => string = (id) => `/targetings/edit/${id}`;

const TargetingView: StatelessFunctionalComponent<Props> = ({
  targeting,
  isLoading,
  onLoad,
}) => {
  const classes: Classes = useStyles();
  const { id }: { id: ?string } = useParams();

  useEffect(() => {
    onLoad(id || "");
  }, []);

  return (
    <>
      {isLoading && (
        <Loader loading size={35} mt={37} />
      )}
      {!isLoading && (
        <Container maxWidth="md">
          <SetupCard
            title="Targeting Info"
            showSecondaryBtn
            secondaryBtnText="Edit"
            onSecondaryBtnClick={() => {
              externalRedirect({ to: getItemEditPath(targeting.id), target: "_blank" });
            }}
          >
            <Box pb={3} width="100%" className={classes.root}>
              <NamedItem name="Id" value={targeting.id} />
              <NamedItem name="Name" value={targeting.name} />
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">isActive</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Switch checked={targeting.isActive} disabled />
                </Grid>
              </GridUc>
            </Box>
          </SetupCard>
          <SetupCard title="Targeting">
            <Box pb={3} width="100%" className={classes.root}>
              <NamedItem name="Data1" value={targeting.data1.split(",")} />
              <NamedItem name="Placement" value={targeting.placement} />
              {/* TODO: remove check after MTU-72118 */}
              {!!targeting.platforms?.length && (
                <GridUc
                  mt={3}
                  px={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textSecondary">Platforms</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Collapse
                      list={targeting.platforms.map((item):
                          {name: string} => ({ name: item }))}
                      showMoreDisabled
                    />
                  </Grid>
                </GridUc>
              )}
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">Browser Languages</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Collapse
                    list={targeting.BrowserLanguages.map((item: BrowserLanguage):
                    {name: string} => ({ name: item.language }))}
                    limit={2}
                    showMoreDisabled={false}
                  />
                </Grid>
              </GridUc>
            </Box>
          </SetupCard>
          <AdditionalInfo usedInCampaigns={targeting.usedInCampaigns} />
        </Container>
      )}
    </>
  );
};

export default TargetingView;
