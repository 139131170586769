/* eslint-disable import/max-dependencies */
/* eslint-disable camelcase */
// @flow
import {
  call, put, takeEvery, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { SAVE_IPP_SAGA } from "../../helpers/constants";
import IppApi from "../../services/IPPApi";
import { getIpp } from "../../selectors/createIpp";
import { getModifiers } from "../../selectors/modifiers";
import { setLoading } from "../../actions/loading";
import externalRedirect from "../../components/App/ExternalRedirect";
import { convertModifiersToServer } from "../../containers/Modifiers";
import type { MotivationTemplateType } from "../../containers/CreateMotivationTemplate";
import type { Modifier } from "../../containers/Modifiers";
import type { IppSaveBody } from "../../components/IppList";

const motiveTypes: MotivationTemplateType[] = ["IPPMotivationPopUp"];

export function* makeFetch(): Saga<void> {
  yield put(setLoading("ippSaving", true));
  const {
    id,
    BrowserLanguages,
    motivationTemplates: motivationTemplatesState,
    // eslint-disable-next-line no-unused-vars
    usedInCampaigns,
    isTemplate,
    ...data
  } = yield select(getIpp);

  const motivationTemplates: { [MotivationTemplateType]: string } = {};
  const modifiers: { [MotivationTemplateType]: Modifier } = motiveTypes
    .reduce((acc: { [MotivationTemplateType]: Modifier }, type: MotivationTemplateType) => {
      if (isTemplate) {
        acc[type] = {};
      }
      return acc;
    }, {});

  for (const type of motiveTypes) {
    if (motivationTemplatesState[type].id) {
      motivationTemplates[type] = motivationTemplatesState[type].id || "";
      const modifiersState = yield select(
        (state) => getModifiers(state, type)
      );
      modifiers[type] = convertModifiersToServer({ modifiers: modifiersState, type });
    }
  }

  const body: IppSaveBody = {
    BrowserLanguages: BrowserLanguages.map(({ value }) => value),
    motivationTemplates,
    modifiers,
    isTemplate,
    ...data,
  };

  try {
    if (id) {
      yield call(IppApi.updateIpp, id, body);
      yield put(addNotification({ message: "Ipp updated successfully", severity: "success" }));
    }
    else {
      yield call(IppApi.saveIpp, body);
      yield put(addNotification({ message: "Ipp saved successfully", severity: "success" }));
    }
    yield put(setLoading("ippSaving", false));
    externalRedirect({ to: "/ipp" });
  }
  catch (error) {
    yield put(setLoading("ippSaving", false));
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchSaveIPPSaga(): Saga<void> {
  yield takeEvery(SAVE_IPP_SAGA, (makeFetch: Function));
}
