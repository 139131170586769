// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import Notification from "../../components/Notification";
import configureStore from "./store";
import PwaOfflineViewContainer from "../../containers/PwaOfflinePageView";

const PwaOfflinePageView: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="Pwa Offline page view">
    <Provider store={configureStore()}>
      <PwaOfflineViewContainer />
      <Notification />
    </Provider>
  </DocumentTitle>
);
export default PwaOfflinePageView;
