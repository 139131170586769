// @flow
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators, type ActionCreators } from "redux";

// eslint-disable-next-line import/prefer-default-export
export const useActions = <T>(actions: ActionCreators<*, T>) => {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators(actions, dispatch),
    [dispatch]
  );
};
