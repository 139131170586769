/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers, applyMiddleware, createStore, compose,
} from "redux";
import type { Dispatch, CombinedReducer, Store } from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";

import notifications, { initNotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import loading, { initLoadingState, type State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import type{ Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import mainSaga from "./saga";

import pwa, { initCreatePwaMotivState } from "../../reducers/createPwaMotivationContent";
import type { State as PwaState } from "../../reducers/createPwaMotivationContent";

import type { GetPwaSaga } from "../../actions/createPwaMotivationContent";
import { PWA_MOTIV_LOADING } from "../../helpers/constants";
import modifiers, {
  type State as ModifiersState,
  initModifiersState,
} from "../../reducers/modifiers";

export type State = $ReadOnly<{
  notifications: NotificationsState,
  pwa: PwaState,
  loading: LoadingState<typeof PWA_MOTIV_LOADING>,
  modifiers: ModifiersState,
}>;

export type Actions = GetPwaSaga | LoadingActions<typeof PWA_MOTIV_LOADING>;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  pwa,
  loading,
  modifiers,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export function mapState(): State {
  return {
    notifications: initNotificationsState(),
    pwa: initCreatePwaMotivState(),
    loading: initLoadingState({ [PWA_MOTIV_LOADING]: false }),
    modifiers: initModifiersState({
      PWAMotivationPopUp: {},
      PWAInstallPage: {},
      PWAMotivationPopUpIos: {},
      PWAInstallPageIos: {},
    }),
  };
}

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  })
    : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
