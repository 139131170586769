/* eslint-disable import/max-dependencies */
// @flow
import React, { useState } from "react";
import type { StatelessFunctionalComponent } from "react";
import {
  Avatar, Grid, Typography, Paper, CardMedia, IconButton, Box,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import FavoriteIcon from "@mui/icons-material/Favorite";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import type { UseState } from "./types/MessagePreview.types";
import type { MessageAction } from "../MessagesList/types/MessagesList.types";
import { withDomain } from "../../helpers/utils";

type Props = {
  title: string,
  body: string,
  image: string,
  icon: string,
  badge: string,
  actions: Array<MessageAction>
};

const useStyles = makeStyles({
  cardContent: {
    maxWidth: 900,
  },
  card: {
    minWidth: 425,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  browserIcon: {
    width: 15,
    height: 15,
    objectFit: "contain",
  },
  mediaContainer: {
    width: "100%",
    height: 200,
  },
  emptyMedia: {
    width: "100%",
    height: "100%",
    backgroundColor: "#E5E5E5",
  },
  settings: {
    fontWeight: 600,
    color: "#708090",
  },
});

const MessagePreview: StatelessFunctionalComponent<Props> = ({
  title,
  body,
  image,
  icon,
  badge,
  actions,
}: Props) => {
  const classes = useStyles();
  const [errorUrl, setErrorUrl]: UseState<boolean> = useState(false);
  const [isOpen, setIsOpen]: UseState<boolean> = useState(false);

  return (
    <Box
      mx="auto"
      display="flex"
      justifyContent="center"
      className={classes.cardContent}
    >
      <Grid
        container
        columnSpacing={{ xs: 2, sm: 2, md: 3 }}
        justifyContent="center"
      >
        <Grid item xs={3} md={5}>
          <Typography variant="body1">
            This is a preview of the push notification.
            There may be slight differences depending on the version of the system/browser.
          </Typography>
        </Grid>
        <Grid item xs={9} md={7}>
          <Paper
            elevation={3}
            className={classes.card}
          >
            <Box px={2}>
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <Box display="flex" justifyContent="space-between" alignItems="center" mr={2}>
                  <Box
                    className={classes.browserIcon}
                    mr={0.4}
                  >
                    <CardMedia
                      component="img"
                      src={badge ? withDomain(badge) : "/chrome-icon-default.svg"}
                    />
                  </Box>
                  <Typography>Chrome</Typography>
                </Box>
                <Typography>
                  some-site.com
                </Typography>
                <Box mx={2}>
                  <Typography variant="h6"> | </Typography>
                </Box>
                <Box display="flex" alignItems="baseline">
                  <Typography variant="body2">
                    14:24
                  </Typography>
                  <Box>
                    <IconButton
                      data-testid={isOpen ? "show-less-btn" : "show-more-btn"}
                      size="medium"
                      onClick={(): void => setIsOpen(!isOpen)}
                    >
                      {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box pb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Box mr={1} display="flex" flexDirection="column">
                  <Typography
                    variant="h6"
                    data-testid="preview-title"
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body1"
                    data-testid="preview-body"
                  >
                    {body}
                  </Typography>
                </Box>
                {(icon.length > 0 ? (
                  <Avatar
                    alt="message icon"
                    aria-label="preview-icon"
                    variant="circular"
                    src={withDomain(icon)}
                    data-testid="preview-icon"
                  >
                    <img className={classes.image} src="/default.png" />
                  </Avatar>
                ) : (
                  <Avatar
                    alt="message icon"
                    aria-label="preview-icon"
                    variant="circular"
                  >
                    <FavoriteIcon color="action" />
                  </Avatar>
                ))}
              </Box>
              {isOpen && (
                <>
                  <Box
                    data-testid="media-container"
                    className={classes.mediaContainer}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {image.length > 0 ? (
                      <img
                        alt={title}
                        className={classes.image}
                        onError={() => setErrorUrl(true)}
                        src={image.length !== 0 && !errorUrl ? withDomain(image) : "/default.png"}
                        data-testid="preview-image"
                      />
                    ) : (
                      <Box
                        className={classes.emptyMedia}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CameraAltIcon color="action" fontSize="large" />
                      </Box>
                    )}
                  </Box>
                  <Box
                    my={1}
                    pb={1}
                    display="flex"
                    flexDirection="row-reverse"
                    alignItems="center"
                  >
                    <Typography className={classes.settings} variant="h6">SETTINGS</Typography>
                    <Box mx={1.3} display="flex" flexDirection="row">
                      {actions.map((action, index) => {
                        if (index < 3) {
                          return <Box ml={1.3}><Typography key={action.title} variant="subtitle1" data-testid="preview-action-title">{action.title}</Typography></Box>;
                        }
                        return undefined;
                      })}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MessagePreview;
