// @flow
import Joi from "joi";
import type { JoiError, Errors } from "../types/campaign";
import type { BaseSimpleErrorsRule } from "./Base";

export default class Segments implements BaseSimpleErrorsRule {
  schema: Object;

  errorKey: string;

  errors: Errors;

  constructor() {
    this.errors = {};
    this.errorKey = "segments";

    this.schema = Joi.array().min(1)
      .error((errors) => this._onError(errors));
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach((item) => {
      switch (item.code) {
        case "array.min":
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: "At least 1 segment required" },
          };
          break;
        default:
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: item.message },
          };
          break;
      }
    });

    return errors;
  }

  getErrors(data: *): Errors {
    this.schema.validate(data);
    return this.errors;
  }
}
