/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers, applyMiddleware, createStore, compose,
} from "redux";
import type { Dispatch, CombinedReducer, Store } from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";

import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import mainSaga from "./saga";

import ipp, { initCreateIppState, type State as IPPState } from "../../reducers/createIPP";
import loading, { initLoadingState, type State as LoadingState } from "../../reducers/loading";
import type { Actions as LoadingActions } from "../../actions/loading";
import modifiers, {
  type State as ModifiersState,
  initModifiersState,
} from "../../reducers/modifiers";

export type State = $ReadOnly<{
  ipp: IPPState,
  loading: LoadingState<"ipp">,
  notifications: NotificationsState,
  modifiers: ModifiersState,
}>;

export type Actions = LoadingActions<"ipp">;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  ipp,
  loading,
  modifiers,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export function mapIppToState(): State {
  return {
    loading: initLoadingState<"ipp">({ ipp: false }),
    notifications: initNotificationsState(),
    ipp: initCreateIppState(),
    modifiers: initModifiersState({
      IPPMotivationPopUp: {},
    }),
  };
}

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  })
    : compose;

  const store: Store<State, Actions> = createStore<
    State,
    Actions,
    Dispatch<Actions>
  >(reducers, mapIppToState(), composeEnhancers(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(mainSaga);

  return store;
};
