// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import List from "../../containers/SchedulesList";
import Notification from "../../components/Notification";

const SchedulesList: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="Schedules">
    <Provider store={configureStore()}>
      <List />
      <Notification />
    </Provider>
  </DocumentTitle>
);

export default SchedulesList;
