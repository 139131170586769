// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Fullscreen";
import { codeCdnReplacer } from "../../helpers/utils";

export type Props = {
  title: string,
  "data-testid": string,
  htmlCode: string,
  cssCode: string,
};

const HtmlPreviewBlock: StatelessFunctionalComponent<Props> = (props) => {
  const {
    title,
    htmlCode,
    cssCode,
  }: Props = props;

  const openPreviewWindow: () => void = () => {
    const previewWindow = window.open("/motivationContent/preview", "_blank");
    previewWindow.onload = () => {
      previewWindow.document.body.innerHTML = codeCdnReplacer(`<style>${cssCode}</style>${htmlCode}`);
    };
  };

  return (
    <>
      <Box mb={2} width={1}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
          <Grid item xs={1}>
            <Tooltip
              arrow
              placement="left"
              title={title}
            >
              <IconButton
                color="default"
                component="span"
                onClick={openPreviewWindow}
                // eslint-disable-next-line react/destructuring-assignment
                data-testid={props["data-testid"]}
                size="large"
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HtmlPreviewBlock;
