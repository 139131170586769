/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  debounce,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
} from "@fas/ui-framework/lib/redux/selectors/table";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  GET_MESSAGES_LIST_SAGA,
  MESSAGES_LIST_TABLE,
} from "../../helpers/constants";
import MessageApi from "../../services/messageApi";
import type { MessageListItem } from "../../components/MessagesList/types/MessagesList.types";
import type { MessagesListLoadingKeys } from "../../pages/MessagesList/store";

export function* makeFetch(): Saga<void> {
  yield put(setLoading<MessagesListLoadingKeys>(MESSAGES_LIST_TABLE, true));

  const page: number = yield select(getTablePage, MESSAGES_LIST_TABLE);
  const limit: number = yield select(getTablePageSize, MESSAGES_LIST_TABLE);
  const filters: Filters = yield select(getTableFilters, MESSAGES_LIST_TABLE);

  try {
    const { data: response }: Response<{
      data: MessageListItem[],
      totalCount: number
      // $FlowFixMe
    }> = yield call(
      MessageApi.getMessagesList,
      page,
      limit,
      filters
    );

    yield put(setTableData(MESSAGES_LIST_TABLE, response.data));
    yield put(changeTableItemsTotalAmount(MESSAGES_LIST_TABLE, response.totalCount));
  }
  catch (err) {
    // eslint-disable-next-line
    console.error(err);
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
  }

  yield put(setLoading<MessagesListLoadingKeys>(MESSAGES_LIST_TABLE, false));
}

export default function* watchFetchMessagesListSaga(): Saga<void> {
  yield debounce(1000, GET_MESSAGES_LIST_SAGA, (makeFetch: Function));
}
