// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import fetchListSaga from "../../sagas/getMotivationContents";
import getDeleteSaga from "../../sagas/deleteSaga";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import { MOTIVATION_CONTENTS_TABLE } from "../../helpers/constants";
import MotivationContentApi from "../../services/motivationContentApi";
import { getMotivationContentsSaga } from "../../actions/motivationContentsList";
import type { MotivationContent, MotivationContentShort } from "../../containers/MotivationContentList";
import { motivationContentToShort } from "../../helpers/motivationContent";

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<MotivationContent, MotivationContentShort>({
  tableKey: MOTIVATION_CONTENTS_TABLE,
  updateMethod: MotivationContentApi.updateMotivationContent,
  getListAction: getMotivationContentsSaga,
  convert: motivationContentToShort,
  dictionaryName: "Motivation Content",
});

const deleteSaga: () => Saga<void> = getDeleteSaga({
  tableKey: MOTIVATION_CONTENTS_TABLE,
  deleteMethod: MotivationContentApi.deleteMotivationContentsByIds,
  getListAction: getMotivationContentsSaga,
  dictionaryName: "Motivation Content",
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(fetchListSaga),
    call(changeIsActiveSaga),
    call(deleteSaga),
  ]);
}
