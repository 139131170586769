// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import { SlideModal } from "@fas/ui-core";
import { Grid } from "@mui/material";
import { withDomain } from "../../helpers/utils";

type Props = {
  open: boolean,
  url: string,
  onClose: () => mixed,
};

const PreviewImageModal: StatelessFunctionalComponent<Props> = ({
  open,
  url,
  onClose,
}) => (
  <SlideModal
    open={open}
    title="Preview"
    onClose={onClose}
    content={(
      <Grid container>
        <Grid item xs>
          <div>
            <img src={withDomain(url)} alt="Link is broken" />
          </div>
        </Grid>
      </Grid>
    )}
    actions={<></>}
  />
);

export default PreviewImageModal;
