/* eslint-disable import/max-dependencies */
// @flow
import React, { type Node } from "react";
import { AutocompleteWithFetch } from "@fas/ui-core";
import {
  Grid,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import CodeEditor from "../CodeEditor";
import { POPUP_TYPES, type MotivationTemplateType, type MotivationTemplateDropdown } from "../../containers/CreateMotivationTemplate";
import Modifiers, { HtmlPreviewWithModifiers } from "../../containers/Modifiers";
import { fetchMotivationTemplateDropdown } from "../../services/motivationTemplateApi";

const nameRowSpacing: number = 3;

function TemplateBlock({
  template,
  type,
  fetchType = type,
  handleChangeTemplate = () => {},
  error,
  readOnly,
}: {
  template: MotivationTemplateDropdown,
  type: MotivationTemplateType,
  fetchType?: MotivationTemplateType,
  error?: string,
  readOnly?: boolean,
  handleChangeTemplate?: (MotivationTemplateType, MotivationTemplateDropdown) => void,
}): Node {
  const tabs = POPUP_TYPES.includes(type)
    ? [{
      name: "html-editor",
      label: "html",
      value: template.html,
      mode: "html",
      readOnly: true,
    },
    {
      name: "css-editor",
      label: "css",
      value: template.css,
      mode: "css",
      readOnly: true,
    }]
    : [{
      name: "css-editor",
      label: "head",
      value: template.css,
      mode: "html",
      readOnly: true,
    },
    {
      name: "html-editor",
      label: "body",
      value: template.html,
      mode: "html",
      readOnly: true,
    }];

  return (
    <>
      <Grid container item justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={nameRowSpacing}>
          <InputLabel>
            Motivation Content Template
          </InputLabel>
        </Grid>
        <Grid item xs={9}>
          <Box
            display="flex"
            alignItems="center"
          >
            <FormControl variant="outlined" size="small" fullWidth>
              <AutocompleteWithFetch
                fetchMethod={(name) => fetchMotivationTemplateDropdown({ name, type: fetchType })}
                selectedValue={template}
                disableClearable
                // $FlowFixMe
                onChange={(option: MotivationTemplateDropdown) => {
                  handleChangeTemplate(type, option);
                }}
                data-testid={`${type}-select`}
                inputProps={{
                  error: Boolean(error),
                  helperText: error,
                }}
                readOnly={readOnly}
              />
            </FormControl>
            <Box ml={2}>
              <HtmlPreviewWithModifiers
                templateType={type}
                title="Motivation Content preview"
                data-testid={`${type}-preview-btn`}
                html={template.html || ""}
                css={template.css || ""}
                disabled={!template.id}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {template.id && (
        <>
          <Box mb={4} width={1}>
            <CodeEditor
              height="400px"
              tabs={tabs}
            />
          </Box>
          <Box width={1} data-testid={`${type}-block`}>
            <Modifiers templateType={type} readOnly={readOnly} />
          </Box>
        </>
      )}
    </>
  );
}

export default TemplateBlock;
