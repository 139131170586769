// @flow
import {
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import type { StepClickAction } from "@fas/ui-framework/lib/redux/actions/steps";
import { STEP_CLICK } from "@fas/ui-framework/lib/redux/constants";
import {
  stepChange,
  setStepError,
} from "@fas/ui-framework/lib/redux/actions/steps";
import { getCurrentStepId } from "../../selectors/steps";
import validator from "../../services/validators";

export function* stepsClick(validatorName: string, action: StepClickAction): Saga<void> {
  const currentStepId: string = yield select(getCurrentStepId);
  if (currentStepId === action.id) {
    return;
  }

  if (currentStepId > action.id) {
    yield put(stepChange(action.id));
    return;
  }

  const fullState = yield select((state) => state);

  const Validator = validator(validatorName);
  const validationErrors = new Validator().validateCurrentStep(fullState, action);

  yield put(setErrors(validationErrors));

  if (!Object.keys(validationErrors).length) {
    yield put(stepChange(action.id));
    return;
  }

  yield put(setStepError());
}

export default function* watchStepsClick(validatorName: string): Saga<void> {
  yield takeEvery(STEP_CLICK, stepsClick, validatorName);
}
