// @flow
import {
  GET_SEGMENT_FORM_DATA_SAGA,
  SAVE_SEGMENT_SAGA,
} from "../../helpers/constants";

export type GetSegmentFormDataSaga = {|
  type: typeof GET_SEGMENT_FORM_DATA_SAGA,
  id: string | null | typeof undefined,
|};

export type SaveSegmentSaga = {|
  type: typeof SAVE_SEGMENT_SAGA,
|};

export type Actions = GetSegmentFormDataSaga | SaveSegmentSaga;

export const getSegmentFormDataSaga: (id: string | null | typeof undefined) => GetSegmentFormDataSaga = (
  id
) => ({ type: GET_SEGMENT_FORM_DATA_SAGA, id });

export const saveSegmentSaga: () => SaveSegmentSaga = () => ({ type: SAVE_SEGMENT_SAGA });
