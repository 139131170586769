// @flow
import {
  call,
  put,
  takeEvery,
  select,
  all,
} from "redux-saga/effects";
import type { Saga, CallEffect } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { getTableSelectedItemById } from "@fas/ui-framework/lib/redux/selectors/table";
import { removeTableSelections } from "@fas/ui-framework/lib/redux/actions/table";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { type ChangeIsActiveSaga } from "../../actions/tableActions";
import { CHANGE_IS_ACTIVE_SAGA } from "../../helpers/constants";

type DefaultItemType = { [key: "name" | string]: *, ..., };

export type IsActiveSagaConfig<T, A = T> = {
  tableKey: string,
  updateMethod: (id: string, value: A) => mixed,
  getListAction: () => *,
  convert: (T) => A,
  dictionaryName?: string,
};

export function* changeItemStatus<T: DefaultItemType, A>(
  config: IsActiveSagaConfig<T, A>,
  id: string,
  isActive: boolean
): Saga<void> {
  const {
    tableKey,
    updateMethod,
    convert,
    dictionaryName,
  }: IsActiveSagaConfig<T, A> = config;

  const item: T = yield select(getTableSelectedItemById, tableKey, id);
  try {
    const converted: A = convert(item);
    if ((converted || {}).isActive !== isActive) {
      yield call(updateMethod, id, { ...converted, isActive });
      yield put(addNotification({
        message: `${dictionaryName || "Item"} "${item.name || ""}" ${isActive ? "activated" : "deactivated"} successfully`,
        severity: "success",
      }));
    }
    yield put(removeTableSelections(tableKey, item));
  }
  catch (err) {
    yield put(addNotification({
      message: parseResponse(err) || `An error occured while updating ${item.name ? `"${item.name}"` : "item"}`,
      severity: "error",
    }));
    // eslint-disable-next-line
    console.error(err);
  }
}

export function* changeIsActive<T: DefaultItemType, A>(
  config: IsActiveSagaConfig<T, A>,
  action: ChangeIsActiveSaga
): Saga<void> {
  const { ids, isActive }: ChangeIsActiveSaga = action;

  yield put(setLoading<typeof config.tableKey>(config.tableKey, true));

  yield all(ids.map((id: string): CallEffect<*, *, *> => call(changeItemStatus, config, id, isActive)));
  yield put(config.getListAction());
}

export default function getIsActiveSaga<T: DefaultItemType, A>(config: IsActiveSagaConfig<T, A>): () => Saga<void> {
  return function* watchChangeIsActiveSaga(): Saga<void> {
    yield takeEvery(CHANGE_IS_ACTIVE_SAGA, changeIsActive, config);
  };
}
