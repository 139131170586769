// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchFetchSegmentsListSaga from "../../sagas/getSegmentsList";
import watchFetchLimitStatListSaga from "../../sagas/getLimitStatList";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchFetchSegmentsListSaga),
    call(watchFetchLimitStatListSaga),
  ]);
}
