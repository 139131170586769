// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record } from "immutable";
import type { Reducer } from "redux";
import type { Actions, ChangeLimitStatFilter } from "../../actions/limitStatFilters";
import {
  CHANGE_LIMIT_STAT_FILTER,
} from "../../helpers/constants";
import type { SegmentListItem } from "../../components/SegmentsList/types/SegmentsList.types";

export type StatDate = {
  startDate: string,
  endDate: string,
};

export type LimitStatFilter<T> = {|
  value: T,
  disabled: boolean,
|};

export type DefaultState = {|
  date: LimitStatFilter<StatDate>,
  name: LimitStatFilter<string>,
  webPushCampaign: LimitStatFilter<string>,
  segment: LimitStatFilter<SegmentListItem[]>,
  data1: LimitStatFilter<string>,
  placement: LimitStatFilter<string>,
  platform: LimitStatFilter<string>,
  browser: LimitStatFilter<string>,
  browserLanguage: LimitStatFilter<string>,
|};

const defaultValues: DefaultState = {
  date: {
    value: {
      startDate: "",
      endDate: "",
    },
    disabled: false,
  },
  name: {
    value: "",
    disabled: true,
  },
  webPushCampaign: {
    value: "",
    disabled: true,
  },
  segment: {
    value: [],
    disabled: false,
  },
  data1: {
    value: "",
    disabled: true,
  },
  placement: {
    value: "",
    disabled: true,
  },
  platform: {
    value: "",
    disabled: true,
  },
  browser: {
    value: "",
    disabled: true,
  },
  browserLanguage: {
    value: "",
    disabled: true,
  },
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initLimitStatFiltersState(): State {
  return initialState;
}

const reducer: Reducer<State, Actions> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case CHANGE_LIMIT_STAT_FILTER: {
      const { key, value }: ChangeLimitStatFilter = action;
      // $FlowFixMe
      return state.setIn([key, "value"], value);
    }
    default:
      return state;
  }
};

export default reducer;
