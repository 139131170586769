/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put, select,
  takeEvery,
  all,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { getDictionarySaga } from "../../actions/dictionary";
import { FORM_KEY_TARGETING, SAVE_DATA1_SAGA } from "../../helpers/constants";
import { getOptionList } from "../../selectors/dictionaries";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import TargetingApi from "../../services/targetingsApi";
import type { SaveData1Saga } from "../../actions/createTargeting";
import type { State } from "../../pages/CreateTargeting";

type HandleParams = {
  value: string,
  data1Prev: string[],
  data1Next: string[],
  data1List: DropDownObjItemType[],
  savedValues: string[],
}

export function* makeFetch(action: SaveData1Saga): Saga<void> {
  const { payload: newValue }: SaveData1Saga = action;
  try {
    const data1Prev: string[] = yield select((state: State) => getFormField(state, FORM_KEY_TARGETING, "data1"));
    const data1List: DropDownObjItemType[] = yield select((state: State) => getOptionList(state, "data1List"));

    const values: string[] = newValue.split(",");
    const savedValues: string[] = [];
    const data1Next: string[] = [];

    const arr = values.map((value: string) => call(
      handleNewValue,
      {
        value,
        data1Prev,
        data1Next,
        data1List,
        savedValues,
      }
    ));
    yield all(arr);

    if (data1Next.length > 0) {
      yield put(setFormData(FORM_KEY_TARGETING, { data1: [...data1Prev, ...data1Next] }));
    }
    if (savedValues.length > 0) {
      yield put(addNotification({ message: `New data1 (${savedValues.join(", ")}) saved successfully`, severity: "success" }));
      yield put(getDictionarySaga("data1List"));
    }
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage || "Failed to save data1", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function* handleNewValue({
  value,
  data1Prev,
  data1Next,
  data1List,
  savedValues,
}: HandleParams): Saga<void> {
  try {
    if (data1List.every(({ value: data1 }: DropDownObjItemType): boolean => data1 !== value)) {
      yield call(TargetingApi.saveData1, value);
      savedValues.push(value);
      data1Next.push(value);
    }
    else if ([...data1Prev, ...data1Next].includes(value)) {
      yield put(addNotification({ message: `data1 "${value}" already exists`, severity: "error" }));
    }
    else {
      data1Next.push(value);
    }
  }
  catch (e) {
    yield put(addNotification({ message: `"${value}" ${e.errorMessage}` || `Failed to save data1 "${value}"`, severity: "error" }));
  }
}

export default function* watchAddData1(): Saga<void> {
  yield takeEvery(SAVE_DATA1_SAGA, (makeFetch: Function));
}
