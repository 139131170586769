// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { PwaIconListItem, PwaIconShort } from "../../components/PwaIconsList/types/PwaIconsList.types";

export const getPwaIconsList: (?number, ?number, ?{ [string]: mixed }) => PromiseResponse<mixed, {
  data: PwaIconListItem[],
  totalCount: number
}> = (page, limit, filters) => {
  let params: {[string]: mixed} = {};
  if (page) {
    params.page = page;
  }
  if (limit) {
    params.limit = limit;
  }
  if (filters) {
    params = { ...params, ...filters };
  }

  return requestService.get(environment.endpoints.get.pwaIconsList, { params });
};

export const updatePwaIcon: (string, PwaIconShort) => PromiseResponse<PwaIconShort, *> = (id, pwaIcon) => requestService
  .put(environment.endpoints.put.pwaIcons.replace("{id}", id), { ...pwaIcon });

export const deletePwaIconsByIds: (string[]) => PromiseResponse<mixed> = (idList) => requestService
  .delete(environment.endpoints.delete.pwaIcons, { data: { idList } });

export const savePwaIcon: (PwaIconShort) => PromiseResponse<mixed> = (pwaIcon) => requestService
  .post(environment.endpoints.post.pwaIcons, { ...pwaIcon });

export const getPwaIconById: (string) => PromiseResponse<mixed, {
  data: PwaIconShort[],
  totalCount: number
}> = (id) => requestService.get(environment.endpoints.get.pwaIconById.replace("{id}", id));
