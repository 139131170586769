// @flow
import {
  GET_WEBPUSH_CAMPAIGNS_LIST_SAGA,
} from "../../helpers/constants";

export type GetWebpushCampaignsListSaga = {|
  type: typeof GET_WEBPUSH_CAMPAIGNS_LIST_SAGA,
|};

export type Actions = GetWebpushCampaignsListSaga;

export const getWebpushCampaignsListSaga: () => GetWebpushCampaignsListSaga = () => ({
  type: GET_WEBPUSH_CAMPAIGNS_LIST_SAGA,
});
