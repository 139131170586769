// @flow
import React, { type StatelessFunctionalComponent, useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import Notification from "../../components/Notification";
import CreateMotivationTemplate from "../../containers/CreateMotivationTemplate";

const MotivatioTemplateForm: StatelessFunctionalComponent<{}> = () => {
  const store = useMemo(() => configureStore());

  return (
    <DocumentTitle title="Motivation Template Form">
      <Provider store={store}>
        <CreateMotivationTemplate />
        <Notification />
      </Provider>
    </DocumentTitle>
  );
};

export default MotivatioTemplateForm;
