// @flow
import * as React from "react";
import withNotifications from "@fas/ui-framework/lib/services/notifications";
import Notifications from "@fas/ui-core/lib/Notifications";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

export type NotificationRaw = {
  message: string,
  isOpen: boolean,
  severity: string,
}

export type Props = {
  ...NotificationRaw,
  handleOnHide: () => Function
}

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

export const TempNotification = ({
  message, isOpen, severity, handleOnHide,
}: Props) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    handleOnHide();
  };

  return (
    <Snackbar
      key="asdd"
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      open={isOpen}
      onClose={(event?: SyntheticEvent<HTMLButtonElement>, reason: string): mixed => (
        handleClose(event, reason)
      )}
      autoHideDuration={8000}
      TransitionComponent={TransitionRight}
      TransitionProps={{
        onExited: () => handleOnHide(),
      }}
    >
      <Alert
        elevation={5}
        onClose={(event: SyntheticEvent<HTMLButtonElement>, reason: string): mixed => (
          handleClose(event, reason)
        )}
        severity={severity || "success"}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default withNotifications(Notifications);
