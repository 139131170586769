/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  debounce,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Response } from "@fas/ui-framework/lib/services/request";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import {
  SEGMENTS_LIST_TABLE,
  GET_SEGMENTS_SAGA,
} from "../../helpers/constants";
import SegmentApi from "../../services/segmentApi";
import type { SegmentListItem } from "../../components/SegmentsList";

export function* makeFetch(): Saga<void> {
  yield put(setLoading(SEGMENTS_LIST_TABLE, true));

  const page: number = yield select(getTablePage, SEGMENTS_LIST_TABLE);
  const limit: number = yield select(getTablePageSize, SEGMENTS_LIST_TABLE);
  const filters: Filters = yield select(getTableFilters, SEGMENTS_LIST_TABLE);

  const params: { page: number, limit: number, filters?: Filters } = { page, limit, filters };

  try {
    const { data: response }: Response<{ data: SegmentListItem[], totalCount: number }> = yield call(
      SegmentApi.getSegmentsList,
      params
    );

    yield put(setTableData(SEGMENTS_LIST_TABLE, response.data));
    yield put(changeTableItemsTotalAmount(SEGMENTS_LIST_TABLE, response.totalCount));
  }
  catch (err) {
    yield put(addNotification({ message: parseResponse(err), severity: "error" }));
    // eslint-disable-next-line
    console.error(err);
  }
  finally {
    yield put(setLoading(SEGMENTS_LIST_TABLE, false));
  }
}

export default function* watchFetchSegmentsListSaga(): Saga<void> {
  // $FlowFixMe
  yield debounce(1000, GET_SEGMENTS_SAGA, makeFetch);
}
