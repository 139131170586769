/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect } from "react";
import type { StatelessFunctionalComponent } from "react";
import {
  GridUc, Loader, SetupCard,
} from "@fas/ui-core";
import {
  Grid,
  Divider,
  Box,
  Switch,
  Typography,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import type { PWAFormData } from "../../containers/PwaMotivationContentList";
import HtmlPreviewButton from "../HtmlPreviewButton";
import externalRedirect from "../App/ExternalRedirect";
import NamedItem from "../NamedItem";
import Collapse from "../Collapse/Collapse";
import type { ViewBrowserLanguage } from "../IppView/types/ViewIpp.types";
import CodeEditor from "../CodeEditor";
import TemplateBlock from "../TemplateBlock";

type Props = {
  ...PWAFormData,
  isLoading: boolean,
  getPwa: (pwaId: string) => mixed
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
  codeRoot: {
    boxSizing: "border-box",
  },
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
}));

const PwaMotivationComponentView: StatelessFunctionalComponent<Props> = ({
  id: pwaId,
  name: pwaName,
  iconId,
  visualisationType,
  BrowserLanguages: pwaBrowserLanguages,
  head,
  body,
  initTimeout,
  toTimeout,
  behaviorToAction,
  isActive,
  inline_css: inlineCss,
  inline_html: inlineHtml,
  isSo,
  soId,
  isBo,
  isTo,
  isExit,
  isIos,
  ios_inline_css: iosInlineCss,
  ios_inline_html: iosInlineHtml,
  ios_head: iosHead,
  ios_body: iosBody,
  isTemplate,
  motivationTemplates: {
    PWAMotivationPopUp,
    PWAInstallPage,
    PWAMotivationPopUpIos,
    PWAInstallPageIos,
  },
  isLoading,
  getPwa,
}: Props) => {
  const classes = useStyles();
  const { id: urlId } = useParams();

  const getItemEditPath: (string) => void = () => {
    if (urlId) {
      externalRedirect({ to: `/pwa/edit/${urlId}`, target: "_blank" });
    }
  };

  useEffect(() => {
    if (urlId) {
      getPwa(urlId);
    }
  }, [urlId]);

  return (
    <Container maxWidth="md">
      <Loader loading={isLoading} withoutWrapper>
        <SetupCard
          title="PWA Motivation Content info"
          showSecondaryBtn
          secondaryBtnText="Edit"
          onSecondaryBtnClick={() => getItemEditPath(pwaId)}
        >
          <Box width="100%" pb={3} className={classes.root}>
            <NamedItem name="Name" value={pwaName} />
            <NamedItem name="Id" value={pwaId} />

            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">isActive</Typography>
              </Grid>
              <Grid item xs={9}>
                <Switch checked={isActive} disabled />
              </Grid>
            </GridUc>

            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">Browser languages:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Collapse
                  showMoreDisabled={false}
                  list={pwaBrowserLanguages.map(
                    ({ value: id, label: name }:{value: string, label: string}): ViewBrowserLanguage => ({ id, name })
                  )}
                />
              </Grid>
            </GridUc>

            <NamedItem name="Init timeout" value={initTimeout.toString()} />
            <NamedItem name="Behavior to action" value={behaviorToAction} />
            <NamedItem name="Visualisation Type" value={visualisationType} />
            <NamedItem name="PWA Icon" value={iconId} />

            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">Back Offer</Typography>
              </Grid>
              <Grid item xs={9}>
                <Switch checked={isBo} disabled />
              </Grid>
            </GridUc>

            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">Secondary Offer</Typography>
              </Grid>
              <Grid item xs={1}>
                <Switch checked={isSo} disabled />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="textSecondary">{soId}</Typography>
              </Grid>
            </GridUc>

            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">Timeout Offer</Typography>
              </Grid>
              <Grid item xs={1}>
                <Switch checked={isTo} disabled />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="textSecondary">{toTimeout}</Typography>
              </Grid>
            </GridUc>

            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">Exit Offer</Typography>
              </Grid>
              <Grid item xs={9}>
                <Switch checked={isExit} disabled />
              </Grid>
            </GridUc>

            <Box mb={4} mt={3} px={3}>
              <Divider />
            </Box>

            <Box mb={2} ml={3}>
              <Typography gutterBottom variant="h5" component="h2">
                Android/WEB PWA Motivation Content
              </Typography>
            </Box>

            {!isTemplate && (
              <Box className={classes.codeRoot}>
                <Box mb={2} width={1}>
                  <Grid container justifyContent="center">
                    <Grid item xs={11}>
                      <CodeEditor
                        height="400px"
                        tabs={[
                          {
                            name: "html-editor",
                            label: "html",
                            value: inlineHtml,
                            mode: "html",
                            readOnly: true,
                          },
                          {
                            name: "css-editor",
                            label: "css",
                            value: inlineCss,
                            mode: "css",
                            readOnly: true,
                          },
                        ]}
                      />
                      <HtmlPreviewButton
                        title="Preview HTML + CSS code"
                        data-testid="html-css-preview-btn"
                        htmlCode={inlineHtml}
                        cssCode={inlineCss}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}

            {isTemplate && (
              <Box px={3}>
                <TemplateBlock
                  template={PWAMotivationPopUp}
                  type="PWAMotivationPopUp"
                  readOnly
                />
              </Box>
            )}

            {visualisationType === "inline_without_manifest" && (
              <>
                <Box mb={4} mt={3} px={3}>
                  <Divider />
                </Box>

                <Box mb={2} ml={3}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Android/WEB PWA Install Page
                  </Typography>
                </Box>

                {!isTemplate && (
                  <Box className={classes.codeRoot}>
                    <Box mb={2} width={1}>
                      <Grid container justifyContent="center">
                        <Grid item xs={11}>
                          <CodeEditor
                            height="400px"
                            tabs={[
                              {
                                name: "head",
                                label: "head html",
                                value: head,
                                mode: "html",
                                readOnly: true,
                              },
                              {
                                name: "body",
                                label: "body html",
                                value: body,
                                mode: "html",
                                readOnly: true,
                              },
                            ]}
                          />
                          <HtmlPreviewButton
                            title="Preview HTML + CSS code"
                            data-testid="html-css-preview-btn"
                            htmlCode={head + body}
                            cssCode=""
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}

                {isTemplate && (
                  <Box px={3}>
                    <TemplateBlock
                      template={PWAInstallPage}
                      type="PWAInstallPage"
                      readOnly
                    />
                  </Box>
                )}
              </>
            )}
            {isIos && (
              <>
                <Box mb={4} mt={3} px={3}>
                  <Divider />
                </Box>

                <Box mb={2} ml={3}>
                  <Typography gutterBottom variant="h5" component="h2">
                    IOS PWA Motivation Content
                  </Typography>
                </Box>

                {!isTemplate && (
                  <Box className={classes.codeRoot}>
                    <Box mb={2} width={1}>
                      <Grid container justifyContent="center">
                        <Grid item xs={11}>
                          <CodeEditor
                            height="400px"
                            tabs={[
                              {
                                name: "ios-html-editor",
                                label: "html",
                                value: iosInlineHtml,
                                mode: "html",
                                readOnly: true,
                              },
                              {
                                name: "ios-css-editor",
                                label: "css",
                                value: iosInlineCss,
                                mode: "css",
                                readOnly: true,
                              },
                            ]}
                          />
                          <HtmlPreviewButton
                            title="Preview HTML + CSS code"
                            data-testid="html-css-preview-btn"
                            htmlCode={iosInlineHtml}
                            cssCode={iosInlineCss}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}

                {isTemplate && (
                  <Box px={3}>
                    <TemplateBlock
                      template={PWAMotivationPopUpIos}
                      type="PWAMotivationPopUpIos"
                      readOnly
                    />
                  </Box>
                )}

                {visualisationType === "inline_without_manifest" && (
                  <>
                    <Box mb={4} mt={3} px={3}>
                      <Divider />
                    </Box>

                    <Box mb={2} ml={4}>
                      <Typography gutterBottom variant="h5" component="h2">
                        IOS PWA Install Page
                      </Typography>
                    </Box>

                    {!isTemplate && (
                      <Box className={classes.codeRoot}>
                        <Box mb={2} width={1}>
                          <Grid container justifyContent="center">
                            <Grid item xs={11}>
                              <CodeEditor
                                height="400px"
                                tabs={[
                                  {
                                    name: "ios-head",
                                    label: "head html",
                                    value: iosHead,
                                    mode: "html",
                                    readOnly: true,
                                  },
                                  {
                                    name: "ios-body",
                                    label: "body html",
                                    value: iosBody,
                                    mode: "css",
                                    readOnly: true,
                                  },
                                ]}
                              />
                              <HtmlPreviewButton
                                title="Preview HTML + CSS code"
                                data-testid="html-css-preview-btn"
                                htmlCode={iosHead + iosBody}
                                cssCode=""
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )}

                    {isTemplate && (
                      <Box px={3}>
                        <TemplateBlock
                          template={PWAInstallPageIos}
                          type="PWAInstallPageIos"
                          readOnly
                        />
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </SetupCard>
      </Loader>
    </Container>
  );
};

export default PwaMotivationComponentView;
