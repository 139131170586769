// @flow
import Joi from "joi";
import type { JoiError, Errors } from "../types/campaign";
import type { BaseSimpleErrorsRule } from "./Base";

export default class MotivationContents implements BaseSimpleErrorsRule {
  schema: Object;

  errorKey: string;

  errors: Errors;

  constructor() {
    this.errors = {};
    this.errorKey = "motivationContents";

    this.schema = Joi.object({
      webPushMotivationContents: Joi.array(),
      ippMotivationContents: Joi.array(),
      pwaMotivationContents: Joi.array(),
      pwaOfflinePages: Joi.array(),
    })
      .when(".pwaMotivationContents", {
        is: Joi.array().min(1),
        then: Joi.object({ pwaOfflinePages: Joi.array().min(1) }),
        otherwise: Joi.any()
          .when(".ippMotivationContents", {
            is: Joi.array().min(1),
            then: Joi.any(),
            otherwise: Joi.any()
              .when(".webPushMotivationContents", {
                is: Joi.array().min(1),
                then: Joi.any(),
                otherwise: false,
              }),
          }),
      })
      .error((errors) => this._onError(errors));
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach((item) => {
      switch (item.code) {
        case "any.only":
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: "At least 1 motive content required" },
          };
          break;
        case "array.min":
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: "At least 1 pwa offline page required" },
          };
          break;
        default:
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: item.message },
          };
          break;
      }
    });

    return errors;
  }

  getErrors(data: *): Errors {
    this.schema.validate(data);
    return this.errors;
  }
}
