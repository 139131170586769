// @flow
import { fromUIOperatorMapper } from "../..";
import type { FromUI } from "../..";
import type { UIRule, ServerRule, ValueMapper } from "../types";

export default class DateBased implements FromUI<UIRule, ServerRule> {
  rule: UIRule;

  constructor(rule: UIRule) {
    this.rule = rule;
  }

  convert(): ServerRule {
    const { field, operator, value } = this.rule;

    if (operator === "isnull") {
      return {
        [field]: null,
      };
    }

    if (!["greaterthanorequal", "lessthanorequal"].includes(operator)) {
      return {
        [field]: this.valueMapper[value],
      };
    }

    const serverOperator: string = fromUIOperatorMapper[operator];

    return {
      [field]: {
        [serverOperator]: this.valueMapper[value],
      },
    };
  }

  get valueMapper(): ValueMapper {
    return {
      "1 minute ago": "@1MinAgo",
      "1 day ago": "@1DayAgo",
      "2 days ago": "@2DaysAgo",
      "30 days ago": "@30DaysAgo",
      "1 hour ago": "@1HourAgo",
      "2 hours ago": "@2HoursAgo",
      "3 hours ago": "@3HoursAgo",
      "4 hours ago": "@4HoursAgo",
      "5 hours ago": "@5HoursAgo",
      "6 hours ago": "@6HoursAgo",
      "7 hours ago": "@7HoursAgo",
      "8 hours ago": "@8HoursAgo",
      "9 hours ago": "@9HoursAgo",
      "10 hours ago": "@10HoursAgo",
      "11 hours ago": "@11HoursAgo",
      "12 hours ago": "@12HoursAgo",
      "13 hours ago": "@13HoursAgo",
      "14 hours ago": "@14HoursAgo",
      "15 hours ago": "@15HoursAgo",
      "16 hours ago": "@16HoursAgo",
      "17 hours ago": "@17HoursAgo",
      "18 hours ago": "@18HoursAgo",
      "19 hours ago": "@19HoursAgo",
      "20 hours ago": "@20HoursAgo",
      "21 hours ago": "@21HoursAgo",
      "22 hours ago": "@22HoursAgo",
      "23 hours ago": "@23HoursAgo",
      "3 days ago": "@3DaysAgo",
      "4 days ago": "@4DaysAgo",
      "5 days ago": "@5DaysAgo",
      "6 days ago": "@6DaysAgo",
      "7 days ago": "@7DaysAgo",
      "8 days ago": "@8DaysAgo",
      "9 days ago": "@9DaysAgo",
      "10 days agо": "@10DaysAgo",
      "15 days ago": "@15DaysAgo",
      "60 days ago": "@60DaysAgo",
      "100 days ago": "@100DaysAgo",
      "150 days ago": "@150DaysAgo",
    };
  }
}
