// @flow
import React, { useState } from "react";
import type { StatelessFunctionalComponent } from "react";
import {
  useNavigate,
} from "react-router-dom";
import { userApiService } from "../../services/userApi";
import {
  isFieldValid,
  validateAllFields,
  isFormValid,
} from "../../helpers/formsValidation";
import AuthForm from "../../components/AuthForm";
import ErrorBoundry from "../../components/ErrorBoundry";

type UseState<T> = [T, ((T => T) | T) => void];

const SignUp: StatelessFunctionalComponent<{}> = () => {
  const navigate = useNavigate();
  const [formField, setFormFields]: UseState<{ [key: string]: string }> = useState({
    username: "",
    password: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors]: UseState<{ [key: string]: boolean }> = useState({
    username: false,
    password: false,
    email: false,
    phone: false,
  });

  const [message, setMessage]: UseState<string> = useState("");
  const [isValid, setIsValid]: UseState<boolean> = useState(true);

  const handleSubmit: () => void = () => {
    const {
      username,
      password,
      email,
      phone,
    }: { [key: string]: string } = formField;
    const fieldErrors: { [key: string]: boolean } = validateAllFields({
      username,
      password,
      email,
      phone,
    });
    const isFormFieldsValid: boolean = isFormValid(fieldErrors);
    if (!isFormFieldsValid) {
      setErrors(fieldErrors);
      return;
    }
    userApiService.appRegister({
      username,
      email,
      password,
      phone,
    })
      .then(() => {
        navigate("/login");
      })
      .catch((error: Error) => {
        // eslint-disable-next-line no-console
        console.error("Fetch error:", error);

        if (error.message) {
          setIsValid(false);
          setMessage(error.message);
        }
      });
  };

  const onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void = (e) => {
    const { name, value }: HTMLInputElement = e.target;

    setErrors({
      ...errors,
      [name]: !isFieldValid({ name, value }),
    });
    setFormFields({
      ...formField,
      [name]: value,
    });
  };

  return (
    <ErrorBoundry>
      <AuthForm
        formName="Sign up"
        handleChange={onChange}
        fields={[
          {
            label: "username",
            value: formField.username,
            type: "text",
            error: errors.username,
          },
          {
            label: "password",
            value: formField.password,
            type: "password",
            error: errors.password,
          },
          {
            label: "email",
            value: formField.email,
            type: "email",
            error: errors.email,
          },
          {
            label: "phone",
            value: formField.phone,
            type: "text",
            error: errors.phone,
          },
        ]}
        handleSubmit={handleSubmit}
        formError={{
          message,
          isValid,
        }}
        redirectLink={{
          url: "/login",
          label: "Switch to login form",
        }}
      />
    </ErrorBoundry>
  );
};

export default SignUp;
