// @flow
import {
  SAVE_IPP_SAGA,
  GET_IPP_SAGA,
  SET_IPP,
  SET_IPP_FORM_FIELD,
} from "../../helpers/constants";
import type { IPP } from "../../components/IppList/types/IppList.types";

export type SaveIppSaga = {|
  type: typeof SAVE_IPP_SAGA,
|};

export type GetIppSaga = {|
  type: typeof GET_IPP_SAGA,
  id: string,
|};

export type SetIpp = {|
  type: typeof SET_IPP,
  ipp: IPP,
|};

export type SetIppFormField = {|
  type: typeof SET_IPP_FORM_FIELD,
  payload: $Shape<IPP>,
|};

export type Actions =
  | GetIppSaga
  | SaveIppSaga
  | SetIpp
  | SetIppFormField;

export const saveIppSaga: () => SaveIppSaga = () => ({ type: SAVE_IPP_SAGA });

export const getIppSaga: (id: string) => GetIppSaga = (id: string) => ({
  type: GET_IPP_SAGA,
  id,
});

export const setIpp: (ipp: IPP) => SetIpp = (ipp: IPP) => ({
  type: SET_IPP,
  ipp,
});

export const setIppFormField: ($Shape<IPP>) => SetIppFormField = (field) => ({
  type: SET_IPP_FORM_FIELD,
  payload: field,
});
