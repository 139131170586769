/* eslint-disable import/max-dependencies */
// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";
import {
  Grid,
  Button,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterList";
import CheckMarkIcon from "@mui/icons-material/DoneOutlineOutlined";
import makeStyles from "@mui/styles/makeStyles";
import {
  SlideModal,
  DateRangePickerV2 as DateRangePicker,
  ComboBox,
} from "@fas/ui-core";
import dayjs from "dayjs";
import {
  getTableData,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { State, LimitStatModals } from "../../pages/LimitStat/store";
import type { DefaultState as Filters, StatDate } from "../../reducers/limitStatFilters";
import type { DefaultState as GroupBy } from "../../reducers/limitStatGroupBy";
import changeModalOpen from "../../actions/modals";
import type { ChangeModalOpen } from "../../actions/modals";
import { changeLimitStatFilter } from "../../actions/limitStatFilters";
import type { ChangeLimitStatFilter } from "../../actions/limitStatFilters";
import { changeLimitStatGroupBy } from "../../actions/limitStatGroupBy";
import type { ChangeLimitStatGroupBy } from "../../actions/limitStatGroupBy";
import type { Classes, Mapper } from "./types/LimitStatConfigure.types";
import { getLoading } from "../../pages/SegmentsList/store";

import {
  getLimitStatFilters,
  getLimitStatGroupBy,
  getIsLimitStatFiltersModalOpen,
  getIsLimitStatGroupByModalOpen,
} from "../../selectors/limits";
import type { SegmentListItem } from "../../components/SegmentsList";
import { getSegmentsListSaga } from "../../actions/segmentsList";
import type { GetSegmentsListSaga } from "../../actions/segmentsList";
import type { GetLimitStatSaga } from "../../actions/limitStatList";
import { getLimitStatSaga } from "../../actions/limitStatList";
import { SEGMENTS_LIST_TABLE } from "../../helpers/constants";

const filtersNamesMapper: Mapper = {
  "date": "Date",
  "name": "Limit Name",
  "webPushCampaign": "WebPushCampaign",
  "segment": "Segment",
  "data1": "Data1",
  "placement": "Placement",
  "platform": "Platform",
  "browser": "Browser",
  "browserLanguage": "Browser Language",
};

type OwnProps = $ReadOnly<{||}>;

type StateProps = $ReadOnly<{|
  filters: Filters,
  groupBy: GroupBy,
  isFiltersModalOpen: boolean,
  isGroupByModalOpen: boolean,
  isSegemntsListLoading: boolean,
  segmentsList: SegmentListItem[],
|}>;

type DispatchProps = $ReadOnly<{|
  handleModalOpenChange: (LimitStatModals, boolean) => ChangeModalOpen<LimitStatModals>,
  handleChangeLimitStatFilter: <T>($Keys<Filters>, T) => ChangeLimitStatFilter,
  handleChangeLimitStatGroupBy: ($Keys<GroupBy>, boolean) => ChangeLimitStatGroupBy,
  handleGetSegmentsList: (string) => GetSegmentsListSaga,
  getLimitStat: () => GetLimitStatSaga,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|}>;

const mapStateToProps: (State) => StateProps = (state) => ({
  segmentsList: getTableData(state, SEGMENTS_LIST_TABLE),
  filters: getLimitStatFilters(state),
  groupBy: getLimitStatGroupBy(state),
  isFiltersModalOpen: getIsLimitStatFiltersModalOpen(state),
  isGroupByModalOpen: getIsLimitStatGroupByModalOpen(state),
  isSegemntsListLoading: getLoading(state, "isSegmentsListLoading"),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchProps = (dispatch) => bindActionCreators({
  getLimitStat: getLimitStatSaga,
  handleModalOpenChange: changeModalOpen,
  handleChangeLimitStatFilter: changeLimitStatFilter,
  handleChangeLimitStatGroupBy: changeLimitStatGroupBy,
  handleGetSegmentsList: getSegmentsListSaga,
}, dispatch);

const useStyles: () => Classes = makeStyles((): Classes => ({
  whiteBtn: {
    background: "white",
  },
  dateWrapper: {
    position: "relative",
    zIndex: 2,
  },
  "@global": {
    ".MuiCoreUi-wrapperDatePicker > div": {
      maxWidth: "100%",
    },
  },

}));

const LimitStatConfigure: StatelessFunctionalComponent<Props> = ({
  filters,
  groupBy,
  isFiltersModalOpen,
  isGroupByModalOpen,
  handleModalOpenChange,
  handleChangeLimitStatFilter,
  handleChangeLimitStatGroupBy,
  isSegemntsListLoading,
  segmentsList,
  handleGetSegmentsList,
  getLimitStat,
}) => {
  const classes: Classes = useStyles();

  const componetsMapper: { [key: $Keys<Filters> | "default"]: (key: $Keys<Filters>) => Node } = {
    date: (): Node => (
      <Box className={classes.dateWrapper}>
        <DateRangePicker
          onChangeDate={(e: StatDate) => {
            handleChangeLimitStatFilter<StatDate>("date", { ...e, preset: "custom" });
          }}
          startDate={filters.date.value.startDate}
          endDate={filters.date.value.endDate}
          presets={[
            {
              label: "Today",
              value: "today",
              start: dayjs().startOf("d").format("YYYY-MM-DD"),
              end: dayjs().endOf("d").format("YYYY-MM-DD"),
            },
            {
              label: "Yesterday",
              value: "yesterday",
              start: dayjs().startOf("d").subtract(1, "d").format("YYYY-MM-DD"),
              end: dayjs().endOf("d").subtract(1, "d").format("YYYY-MM-DD"),
            },
            {
              label: "Last week",
              value: "lastweek",
              start: dayjs().subtract(1, "w").startOf("w").format("YYYY-MM-DD"),
              end: dayjs().subtract(1, "w").endOf("w").format("YYYY-MM-DD"),
            },
            {
              label: "Last month",
              value: "lastmonth",
              start: dayjs().subtract(1, "M").startOf("M").format("YYYY-MM-DD"),
              end: dayjs().subtract(1, "M").endOf("M").format("YYYY-MM-DD"),
            },
          ]}
        />
      </Box>
    ),
    segment: (key: $Keys<Filters>): Node => (
      <ComboBox
        id="mui-autocomplete-segment"
        className={classes.whiteBtn}
        label="Segment"
        options={segmentsList}
        multiple
        value={filters[key].value}
        isOptionEqualToValue={(option: SegmentListItem, value: SegmentListItem): boolean => option.id === value.id}
        getOptionLabel={(option: SegmentListItem): string => option.name || ""}
        onChange={(e: mixed, value: SegmentListItem[]) => {
          handleChangeLimitStatFilter<SegmentListItem[]>(key, value);
        }}
        loading={isSegemntsListLoading}
        lengthToInputEmit={1}
        onInputChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
          handleGetSegmentsList(e.target.value);
        }}
        disableClearable
        data-testid="id-segment-comboBox"
      />
    ),
    default: (key: $Keys<Filters>): Node => (
      <TextField
        id={`${key}-filter`}
        fullWidth
        className={classes.whiteBtn}
        disabled={filters[key].disabled}
        variant="outlined"
        size="small"
        value={filters[key].value}
        placeholder={filtersNamesMapper[key]}
        onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
          handleChangeLimitStatFilter<string>(key, e.target.value);
        }}
        data-testid={`${key}-filter-text`}
      />
    ),
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={1}>
        <Button
          className={classes.whiteBtn}
          variant="outlined"
          onClick={() => {
            handleModalOpenChange("isFiltersModalOpen", true);
          }}
          data-testid="limit-stat-filters-Button"
          size="large"
          startIcon={<FilterIcon />}
        >
          Filters
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Button
          className={classes.whiteBtn}
          variant="outlined"
          onClick={() => {
            handleModalOpenChange("isGroupByModalOpen", true);
          }}
          data-testid="limit-stat-group-by-button"
          size="large"
          startIcon={<CheckMarkIcon />}
        >
          Group by
        </Button>
      </Grid>
      <Grid item xs>
        <Grid item container spacing={0} justifyContent="flex-end">
          <Box display="flex" flexDirection="row-reverse">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                getLimitStat();
              }}
              data-testid="limit-stat-apply-filters-button"
              size="large"
            >
              Apply filters
            </Button>
          </Box>
        </Grid>
      </Grid>
      <SlideModal
        width="60%"
        open={isFiltersModalOpen}
        onClose={() => {
          handleModalOpenChange("isFiltersModalOpen", false);
        }}
        title="Filters"
        dataTestid="limit-stat-filters-modal"
        actions={(<></>)}
        content={(
          <Grid container>
            {Object.keys(filters).map((key: $Keys<Filters>): Node => {
              const viewComponent: (key: $Keys<Filters>) => Node = componetsMapper[key] || componetsMapper.default;

              return (
                <Grid container key={key}>
                  <Grid item xs>
                    <Box mt={2} data-testid={`${key}-filters-field`}>
                      {viewComponent(key)}
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        )}
        onSave={() => {
          handleModalOpenChange("isFiltersModalOpen", false);
        }}
      />
      <SlideModal
        width="25%"
        open={isGroupByModalOpen}
        onClose={() => {
          handleModalOpenChange("isGroupByModalOpen", false);
        }}
        title="Group by"
        dataTestid="limit-stat-group-by-modal"
        actions={(<></>)}
        content={Object.keys(groupBy).map((key: $Keys<GroupBy>): Node => (
          <Box mt={2} key={key}>
            <FormControlLabel
              control={(
                <Checkbox
                  disabled={groupBy[key].disabled}
                  checked={groupBy[key].value}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChangeLimitStatGroupBy(key, e.target.checked);
                  }}
                  data-testid={`${key}-group-by-checkbox`}
                />
              )}
              label={filtersNamesMapper[key]}
            />
          </Box>
        ))}
        onSave={() => {
          handleModalOpenChange("isGroupByModalOpen", false);
        }}
      />
    </Grid>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(LimitStatConfigure);
