// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record } from "immutable";
import type { Reducer } from "redux";
import type { Actions } from "../../actions/createPwaMotivationContent";
import {
  SET_PWA_MOTIV,
  SET_PWA_FORM_FIELD,
} from "../../helpers/constants";
import type { PWAFormData } from "../../containers/PwaMotivationContentList";

export type DefaultState = {
  ...PWAFormData,
};

const defaultValues: DefaultState = {
  id: "",
  iconId: "",
  name: "",
  BrowserLanguages: [],
  createdAt: "",
  updatedAt: "",
  body: "",
  head: "",
  initTimeout: 0,
  toTimeout: 0,
  inline_css: "",
  inline_html: "",
  isActive: true,
  isBo: false,
  isExit: false,
  isSo: false,
  soId: "",
  isTo: false,
  behaviorToAction: "action_automatic",
  visualisationType: "inline_with_manifest",
  isIos: false,
  ios_inline_html: "",
  ios_inline_css: "",
  ios_head: "",
  ios_body: "",
  isTemplate: false,
  motivationTemplates: {
    PWAMotivationPopUp: {},
    PWAInstallPage: {},
    PWAMotivationPopUpIos: {},
    PWAInstallPageIos: {},
  },
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initCreatePwaMotivState(): State {
  return initialState;
}

// eslint-disable-next-line complexity
const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_PWA_MOTIV: {
      const { pwa } = action;
      return state.mergeDeep(pwa);
    }
    case SET_PWA_FORM_FIELD: {
      const { payload } = action;
      return state.merge(payload);
    }
    default:
      return state;
  }
};

export default reducer;
