// @flow
import {
  CHANGE_IS_ACTIVE_SAGA,
  DELETE_SAGA,
} from "../../helpers/constants";

export type ChangeIsActiveSaga = {|
  type: typeof CHANGE_IS_ACTIVE_SAGA,
  ids: string[],
  isActive: boolean,
|};

export type DeleteSaga = {|
  type: typeof DELETE_SAGA,
  ids: string[],
|};

export type Actions = ChangeIsActiveSaga | DeleteSaga;

export const changeIsActiveSaga: (string[], boolean) => ChangeIsActiveSaga = (ids, isActive) => ({
  type: CHANGE_IS_ACTIVE_SAGA,
  ids,
  isActive,
});

export const deleteSaga: (string[]) => DeleteSaga = (ids) => ({
  type: DELETE_SAGA,
  ids,
});
