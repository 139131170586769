/* eslint-disable import/prefer-default-export */
// @flow
import type {
  StoreWithLoadingType,
  // eslint-disable-next-line no-unused-vars
  LoadingTypes,
} from "./types";

/**
 * Get loading state
 * @param {StoreWithLoadingType} state redux state
 * @param {string} key loading key to get from loading state
 * @returns {boolean} loading
 */
export function getLoading<T: string = LoadingTypes>(state: StoreWithLoadingType<T>, key: T): boolean {
  return state.loading.get(key, false);
}
