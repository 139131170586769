// @flow
import Joi from "joi";
import type { JoiError, Errors } from "../types/campaign";
import type { BaseSimpleErrorsRule } from "./Base";

export default class ScheduleInfo implements BaseSimpleErrorsRule {
  schema: Object;

  errorKey: string;

  errors: Errors;

  constructor() {
    this.errors = {};

    this.schema = Joi.object({
      name: Joi
        .string()
        .pattern(/^[A-Za-z0-9\s_-]{1,64}$/),
      queueType: Joi
        .string()
        .required(),
      customScheduleAtHours: Joi.when("queueType", {
        is: "client",
        then: Joi.string().required(),
        otherwise: Joi.any(),
      }),
      customScheduleAtMinutes: Joi.when("queueType", {
        is: "client",
        then: Joi.string().required(),
        otherwise: Joi.any(),
      }),
    })
      .error((errors) => this._onError(errors));
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach((item) => {
      const [key] = item.path;
      switch (item.code) {
        case "string.empty":
          this.errors = {
            ...this.errors,
            [key]: { message: "Field required" },
          };
          break;
        case "string.pattern.base":
          this.errors = {
            ...this.errors,
            [key]: { message: " Invalid name" },
          };
          break;
        default:
          this.errors = {
            ...this.errors,
            [key]: { message: item.message },
          };
          break;
      }
    });

    return errors;
  }

  getErrors(data: *): Errors {
    this.schema.validate(data, { abortEarly: false });
    return this.errors;
  }
}
