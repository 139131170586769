// @flow
import React, { type StatelessFunctionalComponent, useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import Notification from "../../components/Notification";
import CreatePwaContainer from "../../containers/CreatePwaMotivationContent";

const PwaMotivForm: StatelessFunctionalComponent<{}> = () => {
  const store = useMemo(configureStore, []);
  return (
    <DocumentTitle title="Create PWA Motivation Content">
      <Provider store={store}>
        <CreatePwaContainer />
        <Notification />
      </Provider>
    </DocumentTitle>
  );
};
export default PwaMotivForm;
