// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import getSegmentForm from "../../sagas/getSegmentForm";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(getSegmentForm),
  ]);
}
