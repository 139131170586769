// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { MOTIVATION_TEMPLATES_LIST } from "../../helpers/constants";
import { getMotivationTemplatesListSaga } from "../../actions/motivationTemplateList";
import {
  fetchMotivationTemplateList,
  deleteMotivationTemplate,
  updateMotivationTemplate,
} from "../../services/motivationTemplateApi";
import getTableSaga from "../../sagas/getTableSaga";
import getDeleteSaga from "../../sagas/deleteSaga";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import type { MotivationTemplate } from "../../containers/MotivationTemplatesList";

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<MotivationTemplate, MotivationTemplate>({
  tableKey: MOTIVATION_TEMPLATES_LIST,
  updateMethod: updateMotivationTemplate,
  getListAction: getMotivationTemplatesListSaga,
  convert: (item: MotivationTemplate) => ({ ...item }),
  dictionaryName: "Motivation Template",
});

const deleteSaga: () => Saga<void> = getDeleteSaga({
  tableKey: MOTIVATION_TEMPLATES_LIST,
  deleteMethod: deleteMotivationTemplate,
  getListAction: getMotivationTemplatesListSaga,
  dictionaryName: "Motivation Template",
});

const getListSaga = getTableSaga({
  tableName: MOTIVATION_TEMPLATES_LIST,
  fetchMethod: fetchMotivationTemplateList,
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(getListSaga),
    call(deleteSaga),
    call(changeIsActiveSaga),
  ]);
}
