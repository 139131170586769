// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import MessagesContainer from "../../containers/MessagesView";
import Notification from "../../components/Notification";
import configureStore from "./store";

const MessagesView: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="Message View">
    <Provider store={configureStore()}>
      <MessagesContainer />
      <Notification />
    </Provider>
  </DocumentTitle>
);

export default MessagesView;
