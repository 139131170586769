// @flow
import {
  GET_SEGMENTS_LIST_SAGA,
  GET_SEGMENTS_SAGA,
  CHANGE_SEGMENT_IS_ACTIVE_SAGA,
  DELETE_SEGMENTS_SAGA,
} from "../../helpers/constants";

export type GetSegmentsListSaga = {|
  type: typeof GET_SEGMENTS_LIST_SAGA,
  search: string,
|};

export type GetSegmentsSaga = {|
  type: typeof GET_SEGMENTS_SAGA,
|};

export type ChangeSegmentIsActiveSaga = {|
  type: typeof CHANGE_SEGMENT_IS_ACTIVE_SAGA,
  isActive: boolean,
|};

export type DeleteSegmentsSaga = {|
  type: typeof DELETE_SEGMENTS_SAGA,
|};

export type Actions =
  | GetSegmentsListSaga
  | ChangeSegmentIsActiveSaga
  | DeleteSegmentsSaga;

export const getSegmentsListSaga: (string) => GetSegmentsListSaga = (search) => ({
  type: GET_SEGMENTS_LIST_SAGA,
  search,
});

export const getSegmentsSaga: () => GetSegmentsSaga = () => ({
  type: GET_SEGMENTS_SAGA,
});

export const changeSegmentIsActiveSaga: (boolean) => ChangeSegmentIsActiveSaga = (isActive) => ({
  type: CHANGE_SEGMENT_IS_ACTIVE_SAGA,
  isActive,
});

export const deleteSegmentsSaga: () => DeleteSegmentsSaga = () => ({
  type: DELETE_SEGMENTS_SAGA,
});
