// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import getFormDataSaga from "../../sagas/getFormDataSaga";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(getFormDataSaga),
  ]);
}
