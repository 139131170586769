// @flow
import type { RecordOf } from "immutable";
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";
import { getErrorMessage } from "@fas/ui-framework/lib/redux/selectors/errors";
import type { State as StoreState } from "../../pages/CreateWpMotivationContent/store";
import type { WPFormData, Errors } from "../../containers/CreateWPMotivationContent";
import { WP_MOTIV_LOADING } from "../../helpers/constants";

export const getWpFormData: OutputSelector<StoreState, *, WPFormData> = createSelector(
  (state: StoreState): RecordOf<WPFormData> => state.wp,
  (item: RecordOf<WPFormData>): WPFormData => item.toObject()
);

export const getLoading: OutputSelector<StoreState,
  typeof WP_MOTIV_LOADING,
  boolean> = createLoadingSelector<typeof WP_MOTIV_LOADING>();

export const getErrors: OutputSelector<StoreState, *, Errors> = createSelector(
  [
    (state: StoreState): string => getErrorMessage(state, "name"),
    (state: StoreState): string => getErrorMessage(state, "BrowserLanguages"),
    (state: StoreState): string => getErrorMessage(state, "WPMotivationPopUp"),
    (state: StoreState): string => getErrorMessage(state, "WPSubscriptionPage"),
  ],
  (name: string, BrowserLanguages: string, WPMotivationPopUp: string, WPSubscriptionPage: string): Errors => (
    {
      name,
      BrowserLanguages,
      WPMotivationPopUp,
      WPSubscriptionPage,
    })
);
