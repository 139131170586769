// @flow
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { getErrorMessage } from "@fas/ui-framework/lib/redux/selectors/errors";

// eslint-disable-next-line import/prefer-default-export
export const useNotificationError = (errorName: string) => {
  const dispatch = useDispatch();
  const message = useSelector((state) => getErrorMessage(state, errorName));

  useEffect(() => {
    if (message) {
      dispatch(addNotification({ message, severity: "error" }));
    }
  }, [message]);
};
