// @flow
import {
  GET_PWA_OFFLINE_LIST_SAGA,
  CHANGE_PWA_OFFLINE_IS_ACTIVE_SAGA,
  DELETE_PWA_OFFLINE_SAGA,
} from "../../helpers/constants";

export type GetPwaOfflineListSaga = {
  type: typeof GET_PWA_OFFLINE_LIST_SAGA,
};

export type ChangePwaOfflineIsActiveSaga = {
  type: typeof CHANGE_PWA_OFFLINE_IS_ACTIVE_SAGA,
  isActive: boolean,
};

export type DeletePwaOfflineSaga = {
  type: typeof DELETE_PWA_OFFLINE_SAGA
};

export type Actions = GetPwaOfflineListSaga | ChangePwaOfflineIsActiveSaga | DeletePwaOfflineSaga;

export const getPwaOfflineListSaga: () => GetPwaOfflineListSaga = () => ({
  type: GET_PWA_OFFLINE_LIST_SAGA,
});

export const changePwaOfflineIsActiveSaga: (boolean) => ChangePwaOfflineIsActiveSaga = (isActive) => ({
  type: CHANGE_PWA_OFFLINE_IS_ACTIVE_SAGA,
  isActive,
});

export const deletePwaOfflineSaga: () => DeletePwaOfflineSaga = () => ({
  type: DELETE_PWA_OFFLINE_SAGA,
});
