// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import savePwaIconSaga from "../../sagas/savePwaIcon";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(savePwaIconSaga),
  ]);
}
