// @flow
import React, { type Node } from "react";
import { GridUc, SetupCard } from "@fas/ui-core";
import {
  Box, Grid, Link, Switch, Typography,
} from "@mui/material";
import NamedItem from "../NamedItem";
import Collapse from "../Collapse/Collapse";
import CardDivider from "../CardDivider";
import type { Classes } from "../../containers/CampaignView";
import type { PwaOfflinePagesType as PwaOfflineItem } from "../PwaOfflinePagesList";
import type { BrowserLanguages as PWABrowserLanguage } from "../../containers/PwaMotivationContentList";

type Props = {
  campaignContent: PwaOfflineItem[],
  classes: Classes,
}

const CampViewPwaOfflinePages = ({ campaignContent, classes }: Props) => (
  <SetupCard title="PWA Offline pages">
    {campaignContent.map((pwaOfflinePage: PwaOfflineItem, index: number): Node => (
      <Box width="100%" key={pwaOfflinePage.id} className={classes.root}>
        <NamedItem name="Id" value={pwaOfflinePage.id} />
        <GridUc
          mt={3}
          px={3}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">Name</Typography>
          </Grid>
          <Grid item xs={9}>
            <Link
              href={`/pwaOfflinePages/view/${pwaOfflinePage.id}`}
              target="_blank"
              underline="hover"
            >
              {pwaOfflinePage.name}
            </Link>
          </Grid>
        </GridUc>
        <GridUc
          mt={3}
          px={3}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">isActive</Typography>
          </Grid>
          <Grid item xs={9}>
            <Switch checked={pwaOfflinePage.isActive} disabled />
          </Grid>
        </GridUc>
        <GridUc
          mt={3}
          px={3}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">Browser Languages</Typography>
          </Grid>
          <Grid item xs={9}>
            <Collapse
              list={pwaOfflinePage.BrowserLanguages.map(
                (item: PWABrowserLanguage): {name: string} => ({ name: item.language || "" })
              )}
              limit={2}
              showMoreDisabled={false}
            />
          </Grid>
        </GridUc>
        <Box mt={3}>
          <CardDivider visible={index < campaignContent.length - 1} />
        </Box>
      </Box>
    ))}
  </SetupCard>
);

export default CampViewPwaOfflinePages;
