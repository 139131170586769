// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record } from "immutable";
import type { Reducer } from "redux";
import type {
  SetMotivationTemplate,
  SetMotivationTemplateField,
  Actions,
} from "../../actions/createMotivationTemplate/actions.types";
import {
  SET_MOTIVATION_TEMPLATE,
  SET_MOTIVATION_TEMPLATE_FIELD,
} from "../../helpers/constants";
import type { MotivationTemplateFormData } from "../../containers/CreateMotivationTemplate";

export type DefaultState = MotivationTemplateFormData;

const defaultValues: MotivationTemplateFormData = {
  id: "",
  name: "",
  type: "WPMotivationPopUp",
  isActive: false,
  html: "",
  css: "",
  motivationContents: [],
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initCreateMotivationTemplateState(): State {
  return initialState;
}

// eslint-disable-next-line complexity
const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOTIVATION_TEMPLATE: {
      const { payload: motivationTemplate }: SetMotivationTemplate = action;
      return state.mergeDeep(motivationTemplate);
    }
    case SET_MOTIVATION_TEMPLATE_FIELD: {
      const { payload: field }: SetMotivationTemplateField = action;
      return state.merge(field);
    }
    default:
      return state;
  }
};

export default reducer;
