// @flow
import React, {
  type Element,
} from "react";
import {
  SelectSearchComponent,
  ViewLink,
  IsActiveLabel,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import type { MotivationTemplate, Classes } from "./MotivationTemplatesList.types";

export default function getColumns(
  classes: Classes
): Array<Column<MotivationTemplate>> {
  return [
    {
      field: "isActive",
      label: "Status",
      sortable: false,
      searchable: true,
      className: classes.smallTableCol,
      SearchComponent: (props: SearchComponentProps): Element<*> => (
        <SelectSearchComponent
          {...props}
          label="Select"
          values={[
            { value: "", title: "All" },
            { value: "true", title: "Active" },
            { value: "false", title: "Inactive" },
          ]}
        />
      ),
      render: IsActiveLabel,
    },
    {
      field: "id",
      label: "ID",
      searchable: false,
      sortable: false,
    },
    {
      field: "name",
      label: "Name",
      sortable: false,
      searchable: true,
      render: ({ id, name }: MotivationTemplate): Element<typeof ViewLink> => (
        <ViewLink id={String(id)} name={name} viewPath="/motivationTemplates/view/:id" />
      ),
    },
    {
      field: "type",
      label: "Type",
      sortable: false,
      searchable: true,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { value: "", label: "All" },
            { value: "WPMotivationPopUp", label: "WPMotivationPopUp" },
            { value: "WPSubscriptionPage", label: "WPSubscriptionPage" },
            { value: "PWAMotivationPopUp", label: "PWAMotivationPopUp" },
            { value: "PWAInstallPage", label: "PWAInstallPage" },
            { value: "IPPMotivationPopUp", label: "IPPMotivationPopUp" },
          ]}
        />
      ),
    },
  ];
}
