// @flow
import {
  CHANGE_LIMIT_STAT_GROUP_BY,
} from "../../helpers/constants";
import type { DefaultState } from "../../reducers/limitStatFilters";

export type ChangeLimitStatGroupBy = {
  type: typeof CHANGE_LIMIT_STAT_GROUP_BY,
  key: $Keys<DefaultState>,
  value: boolean,
};

export type Actions = ChangeLimitStatGroupBy;

// eslint-disable-next-line import/prefer-default-export
export const changeLimitStatGroupBy: ($Keys<DefaultState>, boolean) => ChangeLimitStatGroupBy = (key, value) => ({
  type: CHANGE_LIMIT_STAT_GROUP_BY,
  key,
  value,
});
