/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers, applyMiddleware, createStore, compose,
} from "redux";
import type { Dispatch, CombinedReducer, Store } from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import notifications, { initNotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import {
  reducer as errors,
  initState as initErrorState,
  type State as ErrorState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import mainSaga from "./saga";
import wp, { initCreateWpState } from "../../reducers/createWp";
import type { State as WpState } from "../../reducers/createWp";
import loading, { initLoadingState } from "../../reducers/loading";
import type { State as LoadingState } from "../../reducers/loading";
import type { Actions as CreateWpActions } from "../../actions/createWp";
import type { Actions as LoadingActions } from "../../actions/loading";
import { WP_MOTIV_LOADING } from "../../helpers/constants";
import modifiers, {
  type State as ModifiersState,
  initModifiersState,
} from "../../reducers/modifiers";
import dictionaries, {
  type State as DictionariesState,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";
import { convertToDropdownList } from "../../helpers/generators";

export type State = $ReadOnly<{
  notifications: NotificationsState,
  wp: WpState,
  modifiers: ModifiersState,
  loading: LoadingState<typeof WP_MOTIV_LOADING>,
  errors: ErrorState,
  dictionaries: DictionariesState,
}>;

export type Actions = CreateWpActions | LoadingActions<typeof WP_MOTIV_LOADING>;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  wp,
  modifiers,
  loading,
  errors,
  dictionaries,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export function mapIppToState(): State {
  return {
    loading: initLoadingState<typeof WP_MOTIV_LOADING>({ WP_MOTIV_LOADING: false }),
    notifications: initNotificationsState(),
    errors: initErrorState(),
    wp: initCreateWpState(),
    dictionaries: initDictionariesState({
      visualizationTypes: [
        {
          label: "system",
          value: "system",
        },
        {
          label: "inline_without_manifest",
          value: "inline_without_manifest",
        },
        {
          label: "inline_with_manifest",
          value: "inline_with_manifest",
        },
      ],
      behaviorToActions: convertToDropdownList([
        "action_automatic",
        "action_on_any_click",
        "motivation_content_deny",
        "install",
      ]),
    }),
    modifiers: initModifiersState({
      WPMotivationPopUp: {},
      WPSubscriptionPage: {},
    }),
  };
}

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  })
    : compose;

  const store: Store<State, Actions> = createStore<
    State,
    Actions,
    Dispatch<Actions>
  >(reducers, mapIppToState(), composeEnhancers(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(mainSaga);

  return store;
};
