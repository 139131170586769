// @flow

import Campaign from "./types/campaign";
import Schedule from "./types/schedule";

const mapper = {
  "campaign": Campaign,
  "schedule": Schedule,
};

const validator = (type: string): Object => mapper[type];

export default validator;
