/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";
import { Box } from "@mui/material";
import { LoadingButton } from "@fas/ui-core";
import { useParams } from "react-router-dom";
import type { State, PwaIconModals } from "../../pages/CreatePwaIcon/store";
import {
  savePwaIconSaga,
  type SavePwaIconSaga,
  setPwaIconFormField,
  setPwaIconImages,
} from "../../actions/pwaIcon";
import changeModalOpen, { type ChangeModalOpen } from "../../actions/modals";
import {
  getIsSaved,
  getIsUploadImageOpen,
  getIsPreviewImageOpen,
  getPwaIconFormData,
} from "../../selectors/pwaIcon";
import PwaIconCard from "../../components/PwaIcon";
import type { PwaIconFields, PwaIconImages, PwaIconFormData } from "../../reducers/pwaIcon";
import type { Image } from "../../components/UploadResizerModal";

type OwnProps = $ReadOnly<{||}>;

type StateProps = $ReadOnly<{|
  isSaved: boolean,
  isUploadImageOpen: boolean,
  isPreviewImageOpen: boolean,
  formData: PwaIconFormData,
|}>;

type DispatchProps = $ReadOnly<{|
  onSave: (?string) => SavePwaIconSaga,
  onUploadImageModalOpenChange: (boolean) => ChangeModalOpen<PwaIconModals>,
  onPreviewImageOpenChange: (boolean) => ChangeModalOpen<PwaIconModals>,
  onSetPwaIconFormField: (field: $Shape<PwaIconFields>) => mixed,
  onSetPwaIconImage: (field: $Keys<PwaIconImages>, value: Array<Image>) => mixed,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|}>;

const mapStateToProps: (State) => StateProps = (state) => ({
  isSaved: getIsSaved(state),
  isUploadImageOpen: getIsUploadImageOpen(state),
  isPreviewImageOpen: getIsPreviewImageOpen(state),
  formData: getPwaIconFormData(state),
});

const mapDispatchToProps: (Dispatch<any>) => DispatchProps = (dispatch) => bindActionCreators({
  onSave: savePwaIconSaga,
  onUploadImageModalOpenChange: (isOpen: boolean): ChangeModalOpen<PwaIconModals> => changeModalOpen("isUploadImageOpen", isOpen),
  onPreviewImageOpenChange: (isOpen: boolean): ChangeModalOpen<PwaIconModals> => changeModalOpen("isPreviewImageOpen", isOpen),
  onSetPwaIconFormField: setPwaIconFormField,
  onSetPwaIconImage: setPwaIconImages,
}, dispatch);

const PwaIcon: StatelessFunctionalComponent<Props> = ({
  isSaved,
  onSave,
  ...props
}) => {
  const { id }: { id: ?string } = useParams();

  return (
    <>
      <PwaIconCard {...props} />
      <Box my={3} display="flex" justifyContent="flex-end">
        <LoadingButton
          loading={isSaved}
          type="button"
          size="medium"
          variant="contained"
          color="primary"
          onClick={() => onSave(id)}
          data-testid="save-button"
        >
          Save
        </LoadingButton>
      </Box>
    </>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(PwaIcon);
