// @flow
import {
  SET_CONDITIONS,
  SET_VALUES_LISTS,
  ADD_CONDITION,
  ADD_CONDITION_GROUP,
  CHANGE_CONDITION_GROUP_OPERATOR,
  DELETE_CONDITION_RULE,
  DELETE_CONDITION_GROUP,
  CHANGE_CONDITION_RULE,
  CHANGE_CONDITION_OPERATOR,
  CHANGE_CONDITION_VALUE,
} from "../../helpers/constants";
import type { ConditionRule, ConditionGroup, Value } from "../../selectors/segmentConditions/types";

export type SetConditions = {|
  type: typeof SET_CONDITIONS,
  conditions: { [string]: ConditionRule | ConditionGroup },
|}

export type SetValuesLists = {|
  type: typeof SET_VALUES_LISTS,
  valuesLists: { [string]: Value[] },
|}

export type AddCondition = {|
  type: typeof ADD_CONDITION,
  groupId: string,
|}

export type AddConditionGroup = {|
  type: typeof ADD_CONDITION_GROUP,
  groupId: string,
|}

export type ChangeConditionGroupOperator = {|
  type: typeof CHANGE_CONDITION_GROUP_OPERATOR,
  id: string,
  value: "AND" | "OR",
|}

export type DeleteConditionRule = {|
  type: typeof DELETE_CONDITION_RULE,
  id: string,
|}

export type DeleteConditionGroup = {|
  type: typeof DELETE_CONDITION_GROUP,
  id: string,
|}

export type ChangeConditionRule = {|
  type: typeof CHANGE_CONDITION_RULE,
  id: string,
  rule: ConditionRule,
|}

export type ChangeConditionOperator = {|
  type: typeof CHANGE_CONDITION_OPERATOR,
  id: string,
  operator: string,
|}

export type ChangeConditionValue = {|
  type: typeof CHANGE_CONDITION_VALUE,
  id: string,
  value: string,
|}

export type Actions = SetConditions
  | SetValuesLists
  | AddCondition
  | AddConditionGroup
  | ChangeConditionGroupOperator
  | DeleteConditionRule
  | DeleteConditionGroup
  | ChangeConditionRule
  | ChangeConditionOperator
  | ChangeConditionValue;

export const setConditions: (
  conditions: { [string]: ConditionRule | ConditionGroup }
) => SetConditions = (conditions) => ({
  type: SET_CONDITIONS,
  conditions,
});

export const setValuesLists: (valuesLists: { [string]: Value[] }) => SetValuesLists = (valuesLists) => ({
  type: SET_VALUES_LISTS,
  valuesLists,
});

export const addCondition: (groupId: string) => AddCondition = (groupId) => ({
  type: ADD_CONDITION,
  groupId,
});

export const addConditionGroup: (groupId: string) => AddConditionGroup = (groupId) => ({
  type: ADD_CONDITION_GROUP,
  groupId,
});

export const changeConditionGroupOperator: (id: string, value: "AND" | "OR") => ChangeConditionGroupOperator = (id, value) => ({
  type: CHANGE_CONDITION_GROUP_OPERATOR,
  id,
  value,
});

export const deleteConditionRule: (id: string) => DeleteConditionRule = (id) => ({
  type: DELETE_CONDITION_RULE,
  id,
});

export const deleteConditionGroup: (id: string) => DeleteConditionGroup = (id) => ({
  type: DELETE_CONDITION_GROUP,
  id,
});

export const changeConditionRule: (
  id: string,
  rule: ConditionRule,
) => ChangeConditionRule = (id, rule) => ({
  type: CHANGE_CONDITION_RULE,
  id,
  rule,
});

export const changeConditionOperator: (
  id: string,
  operator: string,
) => ChangeConditionOperator = (id, operator) => ({
  type: CHANGE_CONDITION_OPERATOR,
  id,
  operator,
});

export const changeConditionValue: (
  id: string,
  value: string,
) => ChangeConditionValue = (id, value) => ({
  type: CHANGE_CONDITION_VALUE,
  id,
  value,
});

export const actions = {
  setConditions,
  setValuesLists,
  addCondition,
  addConditionGroup,
  changeConditionGroupOperator,
  deleteConditionRule,
  deleteConditionGroup,
  changeConditionRule,
  changeConditionOperator,
  changeConditionValue,
};
