// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import {
  reducer as errors,
  type State as ErrorsState,
  initState as initErrorsState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import mainSaga from "./saga";
import dictionaries, {
  type State as DictionariesState,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";
import { FORM_KEY_TARGETING } from "../../helpers/constants";
import type { TargetingFormData } from "../../containers/CreateTargeting";

export const defaultFormData: TargetingFormData = {
  id: "",
  name: "",
  isActive: true,
  BrowserLanguages: [],
  placement: "",
  platforms: [],
  data1: [],
  usedInCampaigns: [],
};

export type State = $ReadOnly<{|
  loading: LoadingState<string>,
  notifications: NotificationsState,
  errors: ErrorsState,
  form: FormState,
  dictionaries: DictionariesState,
|}>

export function mapMailsToState(): State {
  return {
    form: initFormState({
      [FORM_KEY_TARGETING]: defaultFormData,
    }),
    loading: initLoadingState({
      [FORM_KEY_TARGETING]: false,
    }),
    notifications: initNotificationsState(),
    errors: initErrorsState(),
    dictionaries: initDictionariesState({
      platforms: [
        { label: "web", value: "web" },
        { label: "mobile", value: "mobile" },
      ],
    }),
  };
}

export type Actions = LoadingActions<string>
  | NotificationsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  loading,
  notifications,
  errors,
  form,
  dictionaries,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapMailsToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
