// @flow
import React, { type Node } from "react";
import { GridUc, SetupCard } from "@fas/ui-core";
import {
  Box, Grid, Link, Switch, Typography,
} from "@mui/material";
import NamedItem from "../NamedItem";
import Collapse from "../Collapse/Collapse";
import CardDivider from "../CardDivider";
import type { Classes } from "../../containers/CampaignView";
import type {
  BrowserLanguages as PWABrowserLanguage,
  PWAMotivationContent as PWA,
} from "../../containers/PwaMotivationContentList";

type Props = {
  campaignContent: PWA[],
  classes: Classes,
}

const CampViewPwaMotiv = ({ campaignContent, classes }: Props) => (
  <SetupCard title="PWA Motivation Content">
    {campaignContent.map((motivationContent: PWA, index: number): Node => (
      <Box width="100%" key={motivationContent.id} className={classes.root}>
        <NamedItem name="Id" value={motivationContent.id} />
        <GridUc
          mt={3}
          px={3}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">Name</Typography>
          </Grid>
          <Grid item xs={9}>
            <Link
              href={`/pwa/view/${motivationContent.id}`}
              target="_blank"
              underline="hover"
            >
              {motivationContent.name}
            </Link>
          </Grid>
        </GridUc>
        <GridUc
          mt={3}
          px={3}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">isActive</Typography>
          </Grid>
          <Grid item xs={9}>
            <Switch checked={motivationContent.isActive} disabled />
          </Grid>
        </GridUc>
        <GridUc
          mt={3}
          px={3}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">Browser Languages</Typography>
          </Grid>
          <Grid item xs={9}>
            <Collapse
              list={motivationContent.BrowserLanguages.map(
                (item: PWABrowserLanguage): {name: string} => ({ name: item.language || "" })
              )}
              limit={2}
              showMoreDisabled={false}
            />
          </Grid>
        </GridUc>
        <Box mt={3}>
          <CardDivider visible={index < campaignContent.length - 1} />
        </Box>
      </Box>
    ))}
  </SetupCard>
);

export default CampViewPwaMotiv;
