// @flow
import { Map } from "immutable";
import type { Actions, ChangeModalOpen } from "../../actions/modals";
import { CHANGE_MODAL_OPEN } from "../../helpers/constants";

export type DefaultState<T> = {
  [key: T]: boolean,
};

export const initialState: <T: string>() => Map<T, boolean> = <T: string>(): Map<T, boolean> => Map({});
export type State<T: string> = Map<T, boolean>;

export const initModalsState: <T: string>(modals?: DefaultState<T>) => Map<T, boolean> = <T: string>(
  modals?: DefaultState<T>
): Map<T, boolean> => (
    modals
      ? Map(modals)
      : initialState()
  );

export default <T: string>(state: State<T> = initialState(), action: Actions<T>): State<T> => {
  switch (action.type) {
    case CHANGE_MODAL_OPEN: {
      const { key, value }: ChangeModalOpen<T> = action;
      return state.set(key, value);
    }
    default:
      return state;
  }
};
