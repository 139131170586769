// @flow
import type { DropDownObjItemType } from "../../reducers/dictionaries";

export function getDictionaryLoadingKey(dictionayName: string): string {
  return `${dictionayName}-static`;
}

export function convertToDropdownList(dictionary: string[]): DropDownObjItemType[] {
  return dictionary.map((value: string): DropDownObjItemType => ({ label: value, value }));
}
