/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";
import type { State } from "../../pages/CreateIPP/store";
import {
  getFormData,
  getIsLoading,
} from "../../selectors/createIpp";
import IppViewComponent from "../../components/IppView";
import { getIppSaga } from "../../actions/createIpp";
import type {
  Actions,
  GetIppSaga,
} from "../../actions/createIpp";
import type { IPP } from "../../components/IppList/types/IppList.types";

type OwnProps = $ReadOnly<{||}>;

type StateProps = $ReadOnly<{|
  formData: IPP,
  isLoading: boolean,
|}>;

type DispatchProps = $ReadOnly<{|
  getIpp: (id: string) => GetIppSaga,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|}>;

const mapStateToProps: (State) => StateProps = (state) => ({
  formData: getFormData(state),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps: (
  Dispatch<Actions | GetIppSaga>
) => DispatchProps = (dispatch) => bindActionCreators(
  {
    getIpp: getIppSaga,
  },
  dispatch
);

const ViewIpp: StatelessFunctionalComponent<Props> = (props: Props) => <IppViewComponent {...props} />;

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(ViewIpp);
