// @flow
import type { PwaIconListItem, PwaIconShort } from "../components/PwaIconsList/types/PwaIconsList.types";

// eslint-disable-next-line import/prefer-default-export
export const pwaIconListItemToShort: (PwaIconListItem) => PwaIconShort = (pwaIcon) => {
  const {
    name,
    shortname,
    path,
    isActive,
  }: PwaIconListItem = pwaIcon;

  return {
    name,
    shortname,
    path,
    isActive,
  };
};
