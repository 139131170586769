// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, type Node } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Box,
} from "@mui/material";
import SetupCard from "@fas/ui-core/lib/SetupCard";
import { useParams } from "react-router-dom";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import {
  SelectForm,
  TextFieldForm,
} from "@fas/ui-core/lib/Form";
import { FormHeader, Loader } from "@fas/ui-core";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { useLoading, useDictionary, useList } from "../../hooks";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { FORM_KEY_TARGETING, GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants";
import Form from "../Form";
import validate from "./validate";
import MultiSelectForm from "../Form/MultiSelectForm";
import type { State } from "../../pages/CreateTargeting";
import type { UsedInCampaigns } from "../../components/AdditionalInfo/types/AdditionalInfo.types";
import { saveData1Saga } from "../../actions/createTargeting";
import AdditionalInfo from "../../components/AdditionalInfo";

const CreateTargeting = (): Node => {
  const dispatch: <A>(A) => A = useDispatch();
  const { id: objectId } = useParams();

  useEffect(() => {
    if (objectId) {
      dispatch(getFormDataSaga(FORM_KEY_TARGETING, objectId));
    }
  }, [dispatch, objectId]);

  const id: string = useSelector((state: State) => getFormField(state, FORM_KEY_TARGETING, "id"));
  const usedInCampaigns: UsedInCampaigns[] = useSelector((state: State) => getFormField(state, FORM_KEY_TARGETING, "usedInCampaigns"));
  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  const { list: languages }: { list: DropDownObjItemType[] } = useDictionary("browserLanguages");
  const { list: placements }: { list: DropDownObjItemType[] } = useDictionary("placements");
  const { list: data1List }: { list: DropDownObjItemType[] } = useDictionary("data1List");
  const platforms: DropDownObjItemType[] = useList("platforms");

  const handleData1KeyPress: (SyntheticKeyboardEvent<HTMLInputElement>) => void = (event) => {
    const { target }: SyntheticKeyboardEvent<HTMLInputElement> = event;
    if (event.key === "Enter" && target instanceof HTMLInputElement) {
      event.preventDefault();
      dispatch(saveData1Saga(target.value));
    }
  };

  return (
    <Form id={FORM_KEY_TARGETING} redirectOnSave="/targetings" validate={validate}>
      <FormHeader
        title={id ? `Edit targeting: ${String(id)}` : "Create targeting"}
        loading={loading}
        isActionVisible={!loading}
      />
      <Container maxWidth="md">
        <SetupCard title="General Info">
          <Box width={1} mt={1}>
            <Loader loading={loading}>
              <TextFieldForm
                name="name"
                label="Name"
              />
              <SelectForm
                name="placement"
                label="Placement"
                options={placements}
              />
              <MultiSelectForm
                name="BrowserLanguages"
                label="Browser Languages"
                options={languages}
                enableAddAllBtn
                enableAddMatchesBtn
              />
              <MultiSelectForm
                name="platforms"
                label="Platforms"
                options={platforms}
                enableAddAllBtn
              />
            </Loader>
          </Box>
        </SetupCard>

        <SetupCard title="Data1">
          <Box width={1} mt={1}>
            <Loader loading={loading}>
              <MultiSelectForm
                name="data1"
                label="Data1"
                options={data1List}
                InputProps={{
                  onKeyPress: handleData1KeyPress,
                }}
              />
            </Loader>
          </Box>
        </SetupCard>

        <AdditionalInfo usedInCampaigns={usedInCampaigns} />

      </Container>
    </Form>
  );
};

export default CreateTargeting;
