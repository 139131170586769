// @flow

import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import Notification from "../../components/Notification";
import WpMotivationContentView from "../../containers/WpMotivationContentView";

const CreateWp: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="View WP">
    <Provider store={configureStore()}>
      <WpMotivationContentView />
      <Notification />
    </Provider>
  </DocumentTitle>
);
export default CreateWp;
