// @flow
import type { MotivationContent, MotivationContentShort } from "../containers/MotivationContentList";
import type { BrowserLanguage } from "../containers/CreateMessageGroup";
import type { MotivationTemplateType } from "../containers/CreateMotivationTemplate";

// eslint-disable-next-line import/prefer-default-export
export const motivationContentToShort: (MotivationContent) => MotivationContentShort = (motivationContent) => {
  const {
    // eslint-disable-next-line no-unused-vars
    id,
    // eslint-disable-next-line no-unused-vars
    createdAt,
    // eslint-disable-next-line no-unused-vars
    updatedAt,
    BrowserLanguages,
    // eslint-disable-next-line no-unused-vars
    usedInCampaigns,
    motivationTemplates,
    ...rest
  }: MotivationContent = motivationContent;

  return {
    ...rest,
    BrowserLanguages: BrowserLanguages.map((item: BrowserLanguage): string => item.id),
    motivationTemplates: motivationTemplates
      ? Object.keys(motivationTemplates)
        // $FlowFixMe
        .reduce((acc, key: MotivationTemplateType) => ({ ...acc, [key]: motivationTemplates[key].id }), {})
      : null,
  };
};
