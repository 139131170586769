// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StepControl as UCStepControl } from "@fas/ui-core";
import { stepClick } from "@fas/ui-framework/lib/redux/actions/steps";
import { Box } from "@mui/material";
import { getCurrentStepId } from "../../selectors/steps";

type Props = $ReadOnly<{|
  nextButtonText?: string,
  onBack?: () => *,
  onNext?: () => *,
  isLoading?: boolean,
|}>;

const StepControl: StatelessFunctionalComponent<Props> = ({
  nextButtonText = "Next",
  onNext: onNextDefault,
  isLoading = false,
}) => {
  const dispatch: <A>(A) => A = useDispatch();

  const currentStep: string = useSelector(getCurrentStepId);
  const changeStep: (string) => mixed = (stepId) => dispatch(stepClick(stepId));
  const onBack = (): mixed => changeStep(String(Number(currentStep) - 1));
  const onNext = (): mixed => changeStep(String(Number(currentStep) + 1));

  return (
    <Box mb={3} display="flex" justifyContent="space-between">
      <UCStepControl
        nextButtonText={nextButtonText}
        showBackButton={currentStep !== "1"}
        onBack={onBack}
        onNext={onNextDefault || onNext}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default StepControl;
