// @flow
import React, { type Node } from "react";
import { useSelector } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Fullscreen";
import { convertModifiersToServer, applyModifiers, openPreviewWindow } from "./utils";
import type {
  Modifier,
  ModifiersStateData,
} from "./Modifier.types";
import { getModifiers } from "../../selectors/modifiers";
import type { MotivationTemplateType } from "../CreateMotivationTemplate";

export type Props = {
  title: string,
  templateType: MotivationTemplateType,
  "data-testid": string,
  html: string,
  css: string,
  customModifiers?: Modifier,
  disabled?: boolean,
};

export default function HtmlPreviewWithModifiers({
  title,
  templateType,
  html,
  css,
  customModifiers,
  disabled = false,
  ...props
}: Props): Node {
  const modifiersState: ModifiersStateData = useSelector((state) => getModifiers(state, templateType));

  const onOpenPreviewWindow: () => void = () => {
    const modifiers: Modifier = customModifiers
      || convertModifiersToServer({ modifiers: modifiersState, type: templateType });

    const htmlCode: string = applyModifiers(html, modifiers);
    const cssCode: string = applyModifiers(css, modifiers);

    openPreviewWindow({ htmlCode, cssCode });
  };

  return (
    <Tooltip
      arrow
      placement="left"
      title={title}
    >
      <IconButton
        color="default"
        component="span"
        onClick={onOpenPreviewWindow}
        data-testid={props["data-testid"]}
        size="small"
        disabled={disabled}
      >
        <PreviewIcon />
      </IconButton>
    </Tooltip>
  );
}
