/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import { Grid } from "@mui/material";
import { GridUc } from "@fas/ui-core";
import StepControl from "../StepControl";
import AddMessageGroups from "../AddMessageGroups";
import { FORM_KEY_SCHEDULE } from "../../helpers/constants";

const MessageGroupsStep: StatelessFunctionalComponent<{}> = () => (
  <Grid container>
    <Grid item container justifyContent="center" alignItems="center">
      <GridUc item xs={8} mt={3}>
        <AddMessageGroups defaultOpen formKey={FORM_KEY_SCHEDULE} />
        <StepControl />
      </GridUc>
    </Grid>
  </Grid>
);

export default MessageGroupsStep;
