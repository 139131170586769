/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { GET_TARGETING_SAGA } from "../../helpers/constants";
import {
  setTargeting,
  type GetTargetingSaga,
} from "../../actions/targetingView";
import TargetingApi from "../../services/targetingsApi";
import type { Targeting } from "../../containers/TargetingsList";
import externalRedirect from "../../components/App/ExternalRedirect";

export function* makeFetch({ id }: GetTargetingSaga): Saga<void> {
  yield put(setLoading("isTargetingLoading", true));

  try {
    const { data: response }: Response<{ data: Targeting[] }> = yield call(TargetingApi.getTargetingById, id);

    yield put(setTargeting(response.data[0]));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
    // eslint-disable-next-line
    console.error(err);
    externalRedirect({ to: "/targetings" });
  }
  finally {
    yield put(setLoading("isTargetingLoading", false));
  }
}

export default function* watchFetchScheduleSaga(): Saga<void> {
  yield takeEvery(GET_TARGETING_SAGA, (makeFetch: Function));
}
