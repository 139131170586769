// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import getSegmentForm from "../../sagas/getSegmentForm";
import saveSegment from "../../sagas/saveSegment";
import getMessageGroupsList from "../../sagas/getMessageGroupsList";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(getSegmentForm),
    call(saveSegment),
    call(getMessageGroupsList),
  ]);
}
