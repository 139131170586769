/* eslint-disable import/max-dependencies */
/* eslint-disable camelcase */
// @flow
import {
  call, put, takeEvery, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { PWA_MOTIV_LOADING, SAVE_PWA_SAGA } from "../../helpers/constants";
import { getPwa } from "../../selectors/createPwaMotivationContent";
import { getModifiers } from "../../selectors/modifiers";
import externalRedirect from "../../components/App/ExternalRedirect";
import PwaApi from "../../services/PwaApi";
import { convertModifiersToServer } from "../../containers/Modifiers";
import type { MotivationTemplateType } from "../../containers/CreateMotivationTemplate";
import type { Modifier } from "../../containers/Modifiers";

const motiveTypes: MotivationTemplateType[] = ["PWAMotivationPopUp", "PWAMotivationPopUpIos", "PWAInstallPage", "PWAInstallPageIos"];

export function* makeFetch(): Saga<void> {
  yield put(setLoading(PWA_MOTIV_LOADING, true));

  const {
    BrowserLanguages,
    visualisationType,
    motivationTemplates: motivationTemplatesState,
    isTemplate,
    ...rest
  } = yield select(getPwa);

  const motivationTemplates: { [MotivationTemplateType]: string } = {};
  const modifiers: { [MotivationTemplateType]: Modifier } = motiveTypes
    .reduce((acc: { [MotivationTemplateType]: Modifier }, type: MotivationTemplateType) => {
      if (isTemplate) {
        acc[type] = {};
      }
      return acc;
    }, {});

  for (const type of motiveTypes) {
    if (motivationTemplatesState[type].id) {
      motivationTemplates[type] = motivationTemplatesState[type].id || "";
      const modifiersState = yield select(
        (state) => getModifiers(state, type)
      );
      modifiers[type] = convertModifiersToServer({ modifiers: modifiersState, type });
    }
  }

  const data = {
    ...rest,
    isTemplate,
    visualisationType,
    motivationTemplates,
    modifiers,
    BrowserLanguages: BrowserLanguages.map(({ value }) => value),
  };

  try {
    if (data.id) {
      yield call(PwaApi.updatePWA, data.id, data);
      yield put(addNotification({
        message: "PWA updated successfully",
        severity: "success",
      }));
    }
    else {
      yield call(PwaApi.savePWA, data);
      yield put(addNotification({
        message: "PWA saved successfully",
        severity: "success",
      }));
    }
    yield put(setLoading(PWA_MOTIV_LOADING, false));
    externalRedirect({ to: "/pwa" });
  }
  catch (error) {
    yield put(setLoading(PWA_MOTIV_LOADING, false));
    yield put(addNotification({
      message: error.errorMessage,
      severity: "error",
    }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchSavePwaSaga(): Saga<void> {
  yield takeEvery(SAVE_PWA_SAGA, (makeFetch: Function));
}
