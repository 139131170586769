/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import {
  Table,
  IsActiveLabel,
  ViewLink,
  SelectSearchComponent,
  ShowMoreItems,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import type { ScheduleListItem, Classes } from "./types/SchedulesList.types";
import { SCHEDULES_LIST_TABLE } from "../../helpers/constants";
import { getSchedulesSaga } from "../../actions/schedulesList";
import externalRedirect from "../../components/App/ExternalRedirect";
import { getFields, compareById } from "../../services/utils";
import TableActions, { type TableActionsProps } from "../TablesActions";
import { changeIsActiveSaga, deleteSaga } from "../../actions/tableActions";

const useStyles: () => Classes = makeStyles((): {[$Keys<Classes>]: *} => ({
  fixedSizeColumn: {
    width: "30%",
  },
}));

const actionsProps: TableActionsProps<typeof SCHEDULES_LIST_TABLE> = {
  tableKey: SCHEDULES_LIST_TABLE,
  editPath: "/schedules/edit/:id",
  viewPath: "/schedules/view/:id",
  onDelete: deleteSaga,
  onChangeIsActive: changeIsActiveSaga,
};

const SchedulesList: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();
  const tableProps: TableProps = useTable(SCHEDULES_LIST_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const columns: Column<ScheduleListItem>[] = [
    {
      label: "Status",
      field: "isActive",
      searchable: true,
      SearchComponent: (props: SearchComponentProps): Element<*> => (
        <SelectSearchComponent
          {...props}
          label="Select"
          values={[
            { value: "", title: "None" },
            { value: "true", title: "Active" },
            { value: "false", title: "Inactive" },
          ]}
        />
      ),
      render: IsActiveLabel,
    },
    {
      label: "Name",
      field: "name",
      searchable: true,
      render: (row: ScheduleListItem): Element<*> => <ViewLink {...row} viewPath="/schedules/view/:id" />,
    },
    {
      label: "Message Groups",
      field: "WebPushMessageGroups",
      searchable: true,
      className: classes.fixedSizeColumn,
      render: (row: ScheduleListItem): Element<*> => <ShowMoreItems data={getFields(row.WebPushMessageGroups, "name")} />,
    },
    {
      label: "Segments",
      field: "WebPushSegments",
      searchable: true,
      className: classes.fixedSizeColumn,
      render: (row: ScheduleListItem): Element<*> => <ShowMoreItems data={getFields(row.WebPushSegments, "name")} />,
    },
  ];

  const onLoad: () => mixed = () => dispatch(getSchedulesSaga());
  const onCreate: () => null = () => externalRedirect({ to: "/schedules/create", target: "_self" });
  const rowSelectAvailable: () => boolean = () => true;
  const allSelectAvailable: () => boolean = () => true;

  return (
    <Box m={2}>
      <Table
        {...tableProps}
        title="Schedules"
        columns={columns}
        onLoad={onLoad}
        onCreate={onCreate}
        rowSelectAvailable={rowSelectAvailable}
        allSelectAvailable={allSelectAvailable}
        compareRows={compareById}
        Actions={<TableActions {...actionsProps} />}
      />
    </Box>
  );
};

export default SchedulesList;
