// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import List from "../../containers/MotivationContentList";
import Notification from "../../components/Notification";
import TableActions, { type TableActionsProps } from "../../containers/TablesActions";
import { deleteSaga, changeIsActiveSaga } from "../../actions/tableActions";
import { MOTIVATION_CONTENTS_TABLE } from "../../helpers/constants";

const actionsProps: TableActionsProps<typeof MOTIVATION_CONTENTS_TABLE> = {
  tableKey: MOTIVATION_CONTENTS_TABLE,
  editPath: "/webpush/edit/:id",
  viewPath: "/webpush/view/:id",
  onDelete: deleteSaga,
  onChangeIsActive: changeIsActiveSaga,
  disableDelete: false,
};

const store = configureStore();

const MotivationContentList: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="WebPush Motivation Content">
    <Provider store={store}>
      <List Actions={<TableActions {...actionsProps} />} />
      <Notification />
    </Provider>
  </DocumentTitle>
);

export default MotivationContentList;
