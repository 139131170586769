/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import {
  Box,
  Link,
} from "@mui/material";
import type { StatelessFunctionalComponent, Element } from "react";
import {
  Table,
  ShowMoreItems,
  ViewLink,
  IsActiveLabel,
} from "@fas/ui-core";
import { SelectSearchComponent } from "@fas/ui-core/lib/SearchComponents";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import { useDispatch } from "react-redux";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import externalRedirect from "../App/ExternalRedirect";
import Collapse from "../Collapse";
import { PWA_OFFLINE_LIST_TABLE } from "../../helpers/constants";
import {
  getPwaOfflineListSaga,
} from "../../actions/pwaOfflinePagesList";
import type { PwaOfflinePagesType } from "./types/PwaOfflinePages.types";
import { getFields, compareById } from "../../services/utils";

type Props = {
  Actions?: Element<*>,
  choicedData?: Array<PwaOfflinePagesType>,
  onSelect?: (PwaOfflinePagesType) => mixed,
  onDeselect?: (PwaOfflinePagesType) => mixed,
  onCreate?: () => mixed,
  allSelectAvailable?: () => boolean,
  rowSelectAvailable?: () => boolean,
};

const handleCreate: () => null = () => externalRedirect({ to: "/pwaOfflinePages/create", target: "_self" });

const PwaOfflinePagesList: StatelessFunctionalComponent<Props> = ({
  Actions,
  choicedData,
  onSelect,
  onDeselect,
  onCreate = handleCreate,
  allSelectAvailable = (): boolean => true,
  rowSelectAvailable = (): boolean => true,
}) => {
  const dispatch: <A>(A) => A = useDispatch();

  const columns: Array<Column<PwaOfflinePagesType>> = [
    {
      field: "isActive",
      label: "Status",
      sortable: false,
      searchable: true,
      SearchComponent: (props: SearchComponentProps): Element<*> => (
        <SelectSearchComponent
          {...props}
          label="Select"
          values={[
            { value: "", title: "None" },
            { value: "true", title: "Active" },
            { value: "false", title: "Inactive" },
          ]}
        />
      ),
      render: IsActiveLabel,
    },
    {
      field: "id",
      label: "Id",
      sortable: false,
      searchable: false,
    },
    {
      field: "name",
      label: "Name",
      searchable: true,
      render: (row: PwaOfflinePagesType): Element<typeof Link> => (
        <ViewLink id={row.id} name={row.name} viewPath="/pwaOfflinePages/view/:id" />
      ),
    },
    {
      field: "BrowserLanguages",
      label: "Browser Languages",
      searchable: true,
      render: (row: PwaOfflinePagesType): Element<typeof Collapse> => (
        <ShowMoreItems data={getFields(row.BrowserLanguages, "language")} />
      ),
    },
  ];

  const tableProps: TableProps = useTable(PWA_OFFLINE_LIST_TABLE);

  const handleLoad: () => mixed = () => dispatch(getPwaOfflineListSaga());

  return (
    <Box m={2}>
      <Table
        {...tableProps}
        title="PWA Offline pages"
        columns={columns}
        onLoad={handleLoad}
        onCreate={onCreate}
        selected={choicedData || tableProps.selected}
        onSelect={onSelect || tableProps.onSelect}
        onDeselect={onDeselect || tableProps.onDeselect}
        rowSelectAvailable={rowSelectAvailable}
        allSelectAvailable={allSelectAvailable}
        compareRows={compareById}
        Actions={Actions}
      />
    </Box>
  );
};

export default PwaOfflinePagesList;
