/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  debounce,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
} from "@fas/ui-framework/lib/redux/selectors/table";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  GET_WEBPUSH_CAMPAIGNS_LIST_SAGA,
  WEBPUSH_CAMPAIGNS_TABLE,
} from "../../helpers/constants";
import WebpushCampaignApi from "../../services/campaignApi";
import type { WebPushCampaignListItem } from "../../containers/WebPushCampaignsList/types/WebPushCampaignsList.types";

export function* makeFetch(): Saga<void> {
  yield put(setLoading(WEBPUSH_CAMPAIGNS_TABLE, true));

  const page: number = yield select(getTablePage, WEBPUSH_CAMPAIGNS_TABLE);
  const limit: number = yield select(getTablePageSize, WEBPUSH_CAMPAIGNS_TABLE);
  const filters: Filters = yield select(getTableFilters, WEBPUSH_CAMPAIGNS_TABLE);

  try {
    const { data: response }: Response<{
      data: WebPushCampaignListItem[],
      totalCount: number
    }> = yield call(
      WebpushCampaignApi.getCampaignsList,
      page,
      limit,
      filters
    );

    yield put(setTableData(WEBPUSH_CAMPAIGNS_TABLE, response.data));
    yield put(changeTableItemsTotalAmount(WEBPUSH_CAMPAIGNS_TABLE, response.totalCount));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
    // eslint-disable-next-line
    console.error(err);
  }
  finally {
    yield put(setLoading(WEBPUSH_CAMPAIGNS_TABLE, false));
  }
}

export default function* watchFetchWeppushCmpaignsListSaga(): Saga<void> {
  yield debounce(1000, GET_WEBPUSH_CAMPAIGNS_LIST_SAGA, (makeFetch: Function));
}
