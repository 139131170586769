/* eslint-disable import/max-dependencies */
// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import fetchSchedulesListSaga from "../../sagas/getSchedulesList";
import getDeleteSaga from "../../sagas/deleteSaga";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import { SCHEDULES_LIST_TABLE } from "../../helpers/constants";
import ScheduleApi from "../../services/scheduleApi";
import { getSchedulesSaga } from "../../actions/schedulesList";
import { scheduleListItemToShort } from "../../helpers/schedule";
import type { ScheduleListItem, ScheduleShort } from "../../containers/SchedulesList/types/SchedulesList.types";

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<ScheduleListItem, ScheduleShort>({
  tableKey: SCHEDULES_LIST_TABLE,
  updateMethod: ScheduleApi.updateSchedule,
  getListAction: getSchedulesSaga,
  convert: scheduleListItemToShort,
  dictionaryName: "Schedule",
});

const deleteSaga: () => Saga<void> = getDeleteSaga({
  tableKey: SCHEDULES_LIST_TABLE,
  deleteMethod: ScheduleApi.deleteSchedulesByIds,
  getListAction: getSchedulesSaga,
  dictionaryName: "Schedule",
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(fetchSchedulesListSaga),
    call(deleteSaga),
    call(changeIsActiveSaga),
  ]);
}
