// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import {
  Grid, Typography, Box, Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GridUc } from "@fas/ui-core";

import type { Props } from "./types/NamedItem.types";

const useStyles = makeStyles(() => ({
  root: {
    wordBreak: "break-all",
  },
}));

const NamedItem: StatelessFunctionalComponent<Props> = ({
  name,
  value,
  listDirection = "row",
  alignGridItems = "center",
}: Props) => {
  const classes = useStyles();

  return (
    <GridUc
      mt={3}
      px={3}
      container
      direction="row"
      alignItems={alignGridItems}
    >
      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {Array.isArray(value) ? (
          <Box display="flex" flexDirection={listDirection} flexWrap="wrap">
            {value.map((item: string): Element<Chip> => (
              <Box mx={0.5} my={0.5} key={item}>
                <Chip key={item} label={item} />
              </Box>
            ))}
          </Box>
        ) : (
          <Typography classes={{ root: classes.root }}>{value}</Typography>
        )}
      </Grid>
    </GridUc>
  );
};

export default NamedItem;
