/* eslint-disable import/max-dependencies */
// @flow
import {
  call, put, takeEvery, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { type ValidationResult } from "joi";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import parseJoiErrors from "@fas/ui-framework/lib/services/parseJoiErrors";
import { SAVE_WP_SAGA, WP_MOTIV_LOADING } from "../../helpers/constants";
import motivationContentApi from "../../services/motivationContentApi";
import { getWpFormData } from "../../selectors/createWp";
import { getModifiers } from "../../selectors/modifiers";
import { setLoading } from "../../actions/loading";
import externalRedirect from "../../components/App/ExternalRedirect";
import { convertModifiersToServer } from "../../containers/Modifiers";
import type { MotivationTemplateType } from "../../containers/CreateMotivationTemplate";
import type { Modifier } from "../../containers/Modifiers";
import schema from "./schema";

// Remove after API update
const defaultPwaSettings = {
  wpOrder: 0,
  pwaOrder: 1,
  isPwa: false,
  isWp: true,
  initTimeoutPwa: 0,
  inlineContentClickBehaviorPwa: "tag",
  pwaIconId: null,
  visualisationTypePwa: "inline_with_manifest",
  behaviorToActionPwa: "action_automatic",
  isPwaSo: false,
  isPwaBo: false,
  isPwaTo: false,
  isPwaExit: false,
  pwa_inline_html: "",
  pwa_inline_css: "",
  pwa_head: "",
  pwa_body: "",
  soId: "",
  toTimeout: 0,
};

const motiveTypes: MotivationTemplateType[] = ["WPMotivationPopUp", "WPSubscriptionPage"];

export function* makeFetch(): Saga<void> {
  yield put(setLoading(WP_MOTIV_LOADING, true));

  try {
    const {
      id,
      // eslint-disable-next-line no-unused-vars
      usedInCampaigns,
      BrowserLanguages,
      isTemplate,
      isActive,
      motivationTemplates: motivationTemplatesState,
      ...data
    } = yield select(getWpFormData);

    const motivationTemplates: { [MotivationTemplateType]: string } = {};
    const modifiers: { [MotivationTemplateType]: Modifier } = motiveTypes
      .reduce((acc: { [MotivationTemplateType]: Modifier }, type: MotivationTemplateType) => {
        if (isTemplate) {
          acc[type] = {};
        }
        return acc;
      }, {});

    for (const type of motiveTypes) {
      if (motivationTemplatesState[type].id) {
        motivationTemplates[type] = motivationTemplatesState[type].id;
        const modifiersState = yield select(
          (state) => getModifiers(state, type)
        );
        modifiers[type] = convertModifiersToServer({ modifiers: modifiersState, type });
      }
    }

    const validationResult: ValidationResult = schema.validate({
      ...data,
      BrowserLanguages,
      motivationTemplates,
      isTemplate,
    });
    const validationErrors: ErrorsMapper = parseJoiErrors(validationResult);
    yield put(setErrors(validationErrors));
    if (Object.keys(validationErrors).length) return;

    const body = {
      modifiers,
      motivationTemplates,
      BrowserLanguages: BrowserLanguages.map(({ value }) => value),
      isTemplate,
      isActive: id ? isActive : true,
      ...data,
      ...defaultPwaSettings,
    };

    if (id) {
      yield call(motivationContentApi.updateMotivationContent, id, body);
      yield put(addNotification({ message: "WebPush updated successfully", severity: "success" }));
    }
    else {
      yield call(motivationContentApi.saveMotivationContent, body);
      yield put(addNotification({ message: "WebPush saved successfully", severity: "success" }));
    }
    externalRedirect({ to: "/webpush" });
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(WP_MOTIV_LOADING, false));
  }
}

export default function* watchSaveIPPSaga(): Saga<void> {
  yield takeEvery(SAVE_WP_SAGA, (makeFetch: Function));
}
