// @flow
import React, { useMemo, type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import Notification from "../../components/Notification";
import PwaOfflineFormContainer from "../../containers/CreatePwaOfflinePage";

const PwaOfflinePageForm: StatelessFunctionalComponent<{}> = () => {
  const store = useMemo(configureStore, []);
  return (
    <DocumentTitle title="Create PWA Offline page">
      <Provider store={store}>
        <PwaOfflineFormContainer />
        <Notification />
      </Provider>
    </DocumentTitle>
  );
};
export default PwaOfflinePageForm;
