// @flow
import {
  SAVE_DATA1_SAGA,
} from "../../helpers/constants";

export type SaveData1Saga = {|
  type: typeof SAVE_DATA1_SAGA,
  payload: string,
|};

export type Actions = SaveData1Saga

export const saveData1Saga: (string) => SaveData1Saga = (value) => ({
  type: SAVE_DATA1_SAGA,
  payload: value,
});
