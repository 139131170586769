// @flow
import { GET_DICTIONARY_SAGA, SET_DICTIONARY } from "../../helpers/constants";
import type { DropDownObjType } from "../../reducers/dictionaries";

export type SetDictionary = {|
  type: typeof SET_DICTIONARY,
  payload: DropDownObjType,
|}

export type GetDictionarySaga = {|
  type: typeof GET_DICTIONARY_SAGA,
  dictionaryName: string,
|}

export const setDictionary = (payload: DropDownObjType): SetDictionary => ({
  type: SET_DICTIONARY,
  payload,
});

export function getDictionarySaga(dictionaryName: string): GetDictionarySaga {
  return {
    type: GET_DICTIONARY_SAGA,
    dictionaryName,
  };
}
