/* eslint-disable import/max-dependencies */
// @flow
import React, { type Element, type StatelessFunctionalComponent } from "react";
import { Multiselect } from "@fas/ui-core";
import {
  Grid,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Divider,
  Box,
  Switch,
  IconButton,
  Tooltip,
  Paper,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close, Help } from "@mui/icons-material";
import type { IPP } from "../IppList/types/IppList.types";
import HtmlPreviewButton from "../HtmlPreviewButton";
import AdditionalInfo from "../AdditionalInfo";
import CodeEditor from "../CodeEditor";
import TemplateBlock from "../TemplateBlock";
import { useDictionary, useList } from "../../hooks";
import {
  convertModifiersToUi,
  type ModifiersStateData,
} from "../../containers/Modifiers";
import type { MotivationTemplateType, MotivationTemplateDropdown } from "../../containers/CreateMotivationTemplate";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

const nameRowSpacing = 3;
const fieldRowSpacing = 8;
const changeDomainTooltip = "Domain on main funnel will be changed* during redirect if user accept IPP with follow cases: We didn't get \"subscription\" or \"subscription_decline\" or \"notification_denied\" webpush events.\n\n*Logic for WebPush re-subscription.\n*Logic only for Visualisation Type - \"system\" of WebPush Motivation Content.";

type Props = {
  formData: IPP,
  onSetIppFormField: ($Shape<IPP>) => *,
  onSetModifiers: (MotivationTemplateType, ModifiersStateData) => *,
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
}));

const CreateIpp: StatelessFunctionalComponent<Props> = ({
  formData: {
    name: ippName,
    BrowserLanguages,
    initTimeout,
    behaviorToAction,
    isActive,
    inline_css: inlineCss,
    inline_html: inlineHtml,
    clickAction,
    shouldChangeDomain,
    usedInCampaigns,
    isTemplate,
    motivationTemplates: {
      IPPMotivationPopUp,
    },
  },
  onSetIppFormField,
  onSetModifiers,
}: Props) => {
  const classes = useStyles();

  const { list: languages }: { list: DropDownObjItemType[] } = useDictionary("browserLanguages");
  const behaviorToActions: DropDownObjItemType[] = useList("behaviorToActions");
  const funnelActions: DropDownObjItemType[] = useList("funnelActions");

  const handleChangeTemplate: (MotivationTemplateType, MotivationTemplateDropdown) => void = (
    type, template
  ) => {
    const modifiers: ModifiersStateData = convertModifiersToUi({ modifiers: template.modifiers, type });
    onSetModifiers(type, modifiers);
    onSetIppFormField({
      motivationTemplates: {
        IPPMotivationPopUp: template,
      },
    });
  };

  return (
    <>
      <Paper className={classes.container}>
        <Box my={3} ml={4}>
          <Typography gutterBottom variant="h5" component="h2">
            IPP General info
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid container item justifyContent="center" alignItems="center">
            <Grid item xs={nameRowSpacing}>
              <InputLabel htmlFor="name">Name</InputLabel>
            </Grid>
            <Grid item xs={fieldRowSpacing}>
              <TextField
                size="small"
                name="name"
                variant="outlined"
                placeholder="Example: external_motiv_content_ipp_tag_en"
                fullWidth
                value={ippName}
                onChange={(e) => onSetIppFormField({ name: e.target.value })}
                data-testid="name"
              />
            </Grid>
          </Grid>

          <Grid container item justifyContent="center" alignItems="center">
            <Grid item xs={nameRowSpacing}>
              <InputLabel htmlFor="BrowserLanguages">
                Browser Languages
              </InputLabel>
            </Grid>
            <Grid item xs={fieldRowSpacing}>
              <Multiselect
                type="simpleMultiple"
                // $FlowFixMe
                selectedOptions={BrowserLanguages}
                options={languages}
                // $FlowFixMe
                onChange={(selectedBL) => onSetIppFormField({ BrowserLanguages: selectedBL })}
                placeholder="Type languages or select from list"
                enableAddAllBtn
                clearIcon={<Close fontSize="small" />}
                enableAddMatchesBtn
                data-testid="browserLanguages"
              />
            </Grid>
          </Grid>

          <Grid container item justifyContent="center" alignItems="center">
            <Grid item xs={nameRowSpacing}>
              <InputLabel htmlFor="initTimeout">Init Timeout</InputLabel>
            </Grid>
            <Grid item xs={fieldRowSpacing}>
              <TextField
                size="small"
                name="initTimeout"
                variant="outlined"
                placeholder="Example: 100"
                fullWidth
                type="number"
                value={initTimeout}
                onChange={(e) => onSetIppFormField({ initTimeout: e.target.valueAsNumber })}
                inputProps={{
                  min: "0",
                  step: "1",
                }}
                data-testid="initTimeout"
              />
            </Grid>
          </Grid>

          <Grid container item justifyContent="center" alignItems="center">
            <Grid item xs={nameRowSpacing}>
              <InputLabel htmlFor="behaviorToAction">
                Behavior To Action
              </InputLabel>
            </Grid>
            <Grid item xs={fieldRowSpacing}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select
                  name="behaviorToAction"
                  displayEmpty
                  value={behaviorToAction}
                  onChange={(e) => onSetIppFormField({ behaviorToAction: e.target.value })}
                  data-testid="behaviorToAction"
                >
                  <MenuItem value="" disabled>
                    Example: automatic
                  </MenuItem>
                  {behaviorToActions.map(({ label, value }: DropDownObjItemType): mixed => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container item justifyContent="center" alignItems="center">
            <Grid item xs={nameRowSpacing}>
              <InputLabel htmlFor="clickAction">
                Funnel Type
              </InputLabel>
            </Grid>
            <Grid item xs={fieldRowSpacing}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select
                  name="clickAction"
                  displayEmpty
                  value={clickAction}
                  onChange={(e) => onSetIppFormField({ clickAction: e.target.value })}
                  data-testid="clickAction"
                >
                  {funnelActions.map(({ label, value }): Element<MenuItem> => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container item justifyContent="center" alignItems="center">
            <Grid item xs={nameRowSpacing}>
              <InputLabel htmlFor="shouldChangeDomain">
                Domain Changing
                <Tooltip placement="top" title={<Box whiteSpace="pre-line">{changeDomainTooltip}</Box>}>
                  <IconButton size="small" tooltip={changeDomainTooltip}>
                    <Help />
                  </IconButton>
                </Tooltip>
              </InputLabel>
            </Grid>
            <Grid item xs={fieldRowSpacing}>
              <Switch
                name="shouldChangeDomain"
                color="primary"
                checked={shouldChangeDomain}
                onChange={(e) => onSetIppFormField({ shouldChangeDomain: e.target.checked })}
                data-testid="shouldChangeDomain"
              />
            </Grid>
          </Grid>

          <Grid container item justifyContent="center" alignItems="center">
            <Grid container item justifyContent="center" alignItems="center">
              <Grid item xs={nameRowSpacing}>
                <InputLabel htmlFor="back-offer">Is Template</InputLabel>
              </Grid>
              <Grid item xs={fieldRowSpacing}>
                <Switch
                  id="back-offer"
                  data-testid="is-template"
                  color="primary"
                  checked={isTemplate}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetIppFormField({
                    isTemplate: e.target.checked,
                  })}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Grid>
            </Grid>
          </Grid>

          {!isTemplate && (
            <Grid container item justifyContent="center" alignItems="center">
              <Grid item xs={11}>
                <CodeEditor
                  height="400px"
                  tabs={[
                    {
                      name: "html-editor",
                      label: "html",
                      value: inlineHtml,
                      mode: "html",
                      onChange: (value) => onSetIppFormField({ inline_html: value }),
                    },
                    {
                      name: "css-editor",
                      label: "css",
                      value: inlineCss,
                      mode: "css",
                      onChange: (value) => onSetIppFormField({ inline_css: value }),
                    },
                  ]}
                />
                <HtmlPreviewButton
                  title="Preview HTML + CSS code"
                  data-testid="html-css-preview-btn"
                  htmlCode={inlineHtml}
                  cssCode={inlineCss}
                />
              </Grid>
            </Grid>
          )}

          {isTemplate && (
            <Box pr={4} pl={6} mt={2} width={1}>
              <TemplateBlock
                handleChangeTemplate={handleChangeTemplate}
                template={IPPMotivationPopUp}
                type="IPPMotivationPopUp"
              />
            </Box>
          )}
        </Grid>
        <Box width={1} mb={2} mt={3}>
          <Divider />
        </Box>
        <Grid container item justifyContent="center" alignItems="center">
          <Grid item xs={nameRowSpacing}>
            <InputLabel htmlFor="isActive">Is Active</InputLabel>
          </Grid>
          <Grid item xs={fieldRowSpacing}>
            <Switch
              name="isActive"
              color="primary"
              checked={isActive}
              onChange={(e) => onSetIppFormField({ isActive: e.target.checked })}
              data-testid="isActive"
            />
          </Grid>
        </Grid>
      </Paper>
      <AdditionalInfo usedInCampaigns={usedInCampaigns} />
    </>
  );
};

export default CreateIpp;
