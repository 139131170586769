// @flow
import React, { type Node } from "react";

type Props = {
  children: Node,
};

type State = {
  hasError: boolean,
};

class ErrorBoundry extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Object, info: Object) {
    this.setState({ hasError: true });
    // eslint-disable-next-line no-console
    console.error(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <div className="error"> component has error on rendering </div>;
    }
    return children;
  }
}

export default ErrorBoundry;
