// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type {
  PWAMotivationContent as PwaItem,
  RawPwaItem,
} from "../../containers/PwaMotivationContentList";
import type {
  PwaOfflinePagesType as PwaOfflineItem,
  RawPwaOfflinePage,
} from "../../components/PwaOfflinePagesList/types/PwaOfflinePages.types";

type PwaSaveBody = $Diff<{ ...RawPwaItem, BrowserLanguages: Array<string> }, { id: string }>
export type PwaOfflineSaveBody = $Diff<{ ...RawPwaOfflinePage, BrowserLanguages: Array<string> }, { id: string }>

export const getPWAList: (number, number, { [key: string]: mixed }) => PromiseResponse<mixed, {
  data: Array<PwaItem>,
  totalCount: number
}> = (
  page,
  limit,
  filterList
) => requestService.get(environment.endpoints.get.pwaList, {
  params: {
    ...filterList,
    page,
    limit,
  },
});

export const getPwaOfflinePagesList : (number, number, { [key: string]: mixed }) => PromiseResponse<mixed, {
  data: Array<PwaOfflineItem>,
  totalCount: number
}> = (
  page,
  limit,
  filterList
) => requestService.get(environment.endpoints.get.pwaOfflinePagesList, {
  params: {
    ...filterList,
    page,
    limit,
  },
});

export const updatePWA = (
  id: string,
  body: PwaSaveBody
): PromiseResponse<mixed, { data: boolean }> => requestService.put(environment.endpoints.put.pwa.replace("{id}", id), body);

export const updatePwaOfflinePage: (string, PwaOfflineSaveBody) => PromiseResponse<mixed, { data: boolean }> = (
  id: string,
  body: PwaOfflineSaveBody
): PromiseResponse<mixed, { data: boolean }> => requestService.put(environment.endpoints.put.pwaOfflinePages.replace("{id}", id), body);

export const savePWA = (
  body: PwaSaveBody
): PromiseResponse<mixed, { data: boolean }> => requestService.post(environment.endpoints.post.pwa, body);

export const savePwaOfflinePage = (
  body: PwaOfflineSaveBody
): PromiseResponse<mixed, { data: boolean }> => requestService.post(environment.endpoints.post.pwaOfflinePages, body);

export const getPWA = (id: string): PromiseResponse<mixed, { data: PwaItem }> => requestService.get(environment.endpoints.get.pwaById.replace("{id}", id));

export const getPwaOfflinePage = (id: string): PromiseResponse<mixed, { data: PwaOfflineItem }> => requestService.get(environment.endpoints.get.pwaOfflinePageById.replace("{id}", id));

export const deletePWA = (
  idList: Array<string>
): PromiseResponse<mixed, { data: 1 }> => requestService.delete(environment.endpoints.delete.pwa, { data: { idList } });

export const deletePwaOfflinePages = (
  idList: Array<string>
): PromiseResponse<mixed, { data: 1 }> => requestService.delete(
  environment.endpoints.delete.pwaOfflinePages, { data: { idList } }
);
