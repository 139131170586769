// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import {
  Grid,
} from "@mui/material";

type Props = {
  visible: boolean,
};

const CardDivider: StatelessFunctionalComponent<Props> = ({ visible }) => (
  visible && (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  )
);

export default CardDivider;
