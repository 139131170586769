// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record } from "immutable";
import type { Reducer } from "redux";
import type { Actions, ChangeLimitStatGroupBy } from "../../actions/limitStatGroupBy";
import {
  CHANGE_LIMIT_STAT_GROUP_BY,
} from "../../helpers/constants";

export type LimitStatGroupBy = {|
  value: boolean,
  disabled: boolean,
|};

export type DefaultState = {|
  date: LimitStatGroupBy,
  name: LimitStatGroupBy,
  webPushCampaign: LimitStatGroupBy,
  segment: LimitStatGroupBy,
  data1: LimitStatGroupBy,
  placement: LimitStatGroupBy,
  platform: LimitStatGroupBy,
  browser: LimitStatGroupBy,
  browserLanguage: LimitStatGroupBy,
|};

const defaultValues: DefaultState = {
  date: {
    value: false,
    disabled: false,
  },
  name: {
    value: false,
    disabled: true,
  },
  webPushCampaign: {
    value: false,
    disabled: true,
  },
  segment: {
    value: false,
    disabled: false,
  },
  data1: {
    value: false,
    disabled: true,
  },
  placement: {
    value: false,
    disabled: true,
  },
  platform: {
    value: false,
    disabled: true,
  },
  browser: {
    value: false,
    disabled: true,
  },
  browserLanguage: {
    value: false,
    disabled: true,
  },
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initLimitStatGroupByState(): State {
  return initialState;
}

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LIMIT_STAT_GROUP_BY: {
      const { key, value }: ChangeLimitStatGroupBy = action;
      return state.setIn([key, "value"], value);
    }
    default:
      return state;
  }
};

export default reducer;
