// @flow
import { runSaga, type Saga } from "redux-saga";

export const getFields: (*[], string) => *[] = (arr, field) => arr.map((item: *): * => item[field]);

export const compareById: (*, *) => boolean = (current, selected) => current.id === selected.id;

export const lowerCaseFirstLetter: (string) => string = (value) => value.charAt(0).toLowerCase() + value.slice(1);

export const recordSaga: ((...rest: *[]) => Saga<void>, *[], mixed) => Promise<*[]> = async (
  saga,
  args,
  state? = {}
) => {
  const dispatched: *[] = [];

  // $FlowFixMe
  await runSaga(
    {
      dispatch: (action: *): number => dispatched.push(action),
      getState: (): mixed => state,
    },
    saga,
    ...args
  ).toPromise();

  return dispatched;
};
