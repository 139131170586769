// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node } from "react";
import { isEqual } from "lodash";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  MenuItem,
} from "@mui/material";
import {
  DeleteOutlineTwoTone as DeleteOutlineTwoToneIcon,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import type { DropdownItem } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import getOptionLabel from "@fas/ui-core/lib/SearchComponents/helpers/getOptionLabel";
import {
  GridUc,
  ComboBox,
  MultiSelectWithSuggest,
} from "@fas/ui-core";
import type {
  SelectedRule,
  Rule,
  ConditionRule,
} from "../../selectors/segmentConditions/types";
import { getSuggestDropdown } from "../../services/dictionaries";

export type Classes = {
  removeBtn: string,
};

const staticOperators: Array<string> = ["exists", "absent", "isnull"];

export type Props = {
  id: string,
  parentGroupId: string,
  selectedRule: SelectedRule,
  isDisabledRule?: boolean,
  isRemoveDisabled?: boolean,

  operatorList: Array<DropdownItem>,
  valuesList: Array<DropdownItem>,
  ruleList: Array<DropdownItem>,

  handleChangeValue: (string, string | string[]) => void,
  handleChangeRule: (string, ConditionRule) => void,
  handleChangeOperator: (string, string) => void,
  handleRemoveRule: (string, string) => void,
};

const useStyles: () => Classes = makeStyles(() => ({
  removeBtn: {
    whiteSpace: "nowrap",
    textDecoration: "underline",
    color: "#148EFF",
    fontSize: "12px",
  },
}));

function RuleComponent(props: Props): Node {
  const {
    id,
    parentGroupId,
    selectedRule,
    operatorList,
    ruleList,
    valuesList,

    handleChangeValue,
    handleChangeRule,
    handleChangeOperator,
    handleRemoveRule,
  }: Props = props;

  const isValueVisible: boolean = !staticOperators.includes(selectedRule.operator);

  const classes: Classes = useStyles();
  return (
    <GridUc
      container
      alignItems="flex-start"
      data-testid="conditions-qb-row"
      mb={2}
      spacing={2}
    >
      <Grid item xs={3} data-testid={`rule-${id}`}>
        <Autocomplete
          data-testid={`rule-input-${id}`}
          size="small"
          options={ruleList}
          getOptionLabel={(option: Rule): string => option.label || ""}
          renderInput={(params): Node => (
            <TextField
              {...params}
              label="Name"
              fullWidth
              variant="outlined"
            />
          )}
          value={selectedRule}
          onChange={(_, value: ConditionRule) => {
            handleChangeRule(id, value);
          }}
          isOptionEqualToValue={(option, value): boolean => option.value === value.name}
          disableClearable
        />
      </Grid>
      <Grid item xs={2} data-testid={`operator-${id}`}>
        <TextField
          data-testid={`operator-input-${id}`}
          fullWidth
          select
          disabled={!selectedRule.name}
          label="Operator"
          variant="outlined"
          type="string"
          size="small"
          value={selectedRule.operator}
          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
            handleChangeOperator(id, e.target.value);
          }}
        >
          {operatorList.map(({ value, label }: DropdownItem): Node => (
            <MenuItem key={value} value={value}>{label}</MenuItem>
          ))}
        </TextField>
      </Grid>
      {isValueVisible && (
        <Grid item xs={5} data-testid={`value-${id}`}>
          {selectedRule.valueType === "select" && (
            <ComboBox
              options={valuesList}
              value={valuesList.find(({ value }: DropdownItem) => value === selectedRule.value) || ""}
              isOptionEqualToValue={(option, value): boolean => option.value === value}
              getOptionLabel={getOptionLabel}
              onChange={(e, { value }: DropdownItem) => {
                handleChangeValue(id, value);
              }}
              disableClearable
              data-testid={`value-input-${id}`}
            />
          )}
          {selectedRule.valueType === "multiselect" && (
            <ComboBox
              multiple
              options={valuesList}
              value={valuesList.filter(({ value }: DropdownItem) => selectedRule.value.includes(value))}
              isOptionEqualToValue={(option, value): boolean => option.value === value}
              getOptionLabel={getOptionLabel}
              onChange={(e, options: DropdownItem[]) => {
                handleChangeValue(id, options.map(({ value }) => value));
              }}
              renderOption={(params, option) => !selectedRule.value.includes(option.value) && (
                <li {...params} key={option.value}>
                  {getOptionLabel(option)}
                </li>
              )}
              disableClearable
              data-testid={`value-input-${id}`}
            />
          )}
          {selectedRule.valueType === "multiselectWithSuggest" && (
            <MultiSelectWithSuggest
              name={selectedRule.dictionaryName || ""}
              searchLimit={0}
              // $FlowFixMe
              fetchMethod={(name, filter) => getSuggestDropdown({ name, limit: 100, filter })}
              selectedList={Array.isArray(selectedRule.value)
                ? selectedRule.value.map((value) => ({ value, label: value }))
                : []
              }
              disableClearable
              onClose={(options: DropdownItem[]) => {
                handleChangeValue(id, options.map(({ value }) => value));
              }}
              data-testid={`value-input-${id}`}
            />
          )}
          {selectedRule.valueType === "input" && (
            <TextField
              data-testid={`value-input-${id}`}
              fullWidth
              disabled={!selectedRule.name}
              variant="outlined"
              type="string"
              size="small"
              value={selectedRule.value}
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                handleChangeValue(id, e.target.value);
              }}
            />
          )}
        </Grid>
      )}
      <Grid item xs={1} data-testid="remove-rule">
        <IconButton
          data-testid="remove-rule-icon"
          onClick={() => {
            handleRemoveRule(id, parentGroupId);
          }}
          color="primary"
          className={classes.removeBtn}
        >
          <DeleteOutlineTwoToneIcon />
        </IconButton>
      </Grid>
    </GridUc>
  );
}

RuleComponent.defaultProps = {
  isDisabledRule: false,
  isRemoveDisabled: false,
  errorRule: null,
  errorValue: null,
  errorOperator: null,
};

export default React.memo<Props>(RuleComponent, isEqual);
