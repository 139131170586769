// @flow
import environment from "environment";
import { DYNAMIC_CDN_REF } from "./constants";

export const copyToClipboard: (string) => void = (copyStr) => {
  const input: HTMLInputElement = document.createElement("input");
  input.value = copyStr;
  // $FlowFixMe
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  input.remove();
};

export const codeCdnReplacer: (string) => string = (
  code
) => code.replace(new RegExp(DYNAMIC_CDN_REF, "g"), environment.links.cdnDomain);

export const withCdnDynamicRef: (string) => string = (
  path
) => `${DYNAMIC_CDN_REF}${path}`;

export const withDomain: (string) => string = (path) => `${environment.links.cdnDomain}${path}`;
