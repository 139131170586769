// @flow
import { SAVE_CAMPAIGN_SAGA } from "../../helpers/constants";

export type SaveCampaignSaga = {|
  type: typeof SAVE_CAMPAIGN_SAGA,
|};

export type Actions = SaveCampaignSaga

export const saveCampaignSaga: () => SaveCampaignSaga = () => ({ type: SAVE_CAMPAIGN_SAGA });
