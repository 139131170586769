// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record } from "immutable";
import type { Reducer } from "redux";
import type { Actions } from "../../actions/createIpp";
import {
  SET_IPP,
  SET_IPP_FORM_FIELD,
} from "../../helpers/constants";
import type { IPP } from "../../components/IppList/types/IppList.types";

export type DefaultState = {
  ...IPP,
};

const defaultValues: DefaultState = {
  id: "",
  BrowserLanguages: [],
  initTimeout: 0,
  behaviorToAction: "automatic",
  isActive: true,
  name: "",
  inline_css: "",
  inline_html: "",
  clickAction: "default",
  shouldChangeDomain: false,
  usedInCampaigns: [],
  isTemplate: false,
  motivationTemplates: {
    IPPMotivationPopUp: {},
  },
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initCreateIppState(): State {
  return initialState;
}

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_IPP_FORM_FIELD: {
      const { payload: field } = action;
      return state.merge(field);
    }
    case SET_IPP: {
      const { ipp } = action;
      return state.mergeDeep(ipp);
    }
    default:
      return state;
  }
};

export default reducer;
