// @flow
import React, { type StatelessFunctionalComponent } from "react";
import type { Url, CollapseItem, Breadcrumb } from "@fas/ui-core/lib/AppNavbar";
import { AppNavbar } from "@fas/ui-core";
import environment from "environment";
import { userApiService } from "../../services/userApi";
import { getUiCoreMenuItems } from "../../helpers/Menu";
import getBreadcrumbs from "../../helpers/breadcrumbs";
import appLinks from "./appLinks";
import externalRedirect from "../App/ExternalRedirect";

const AppHeader: StatelessFunctionalComponent<{}> = () => {
  const breadcrumbs: Breadcrumb[] = getBreadcrumbs(window.location);

  const uiCoreMenuItems: Array<Url | CollapseItem> = getUiCoreMenuItems();

  const handleLogout: () => void = () => {
    userApiService.appLogout()
      .then(() => {
        externalRedirect({ to: environment.links.login });
      })
      .catch((error: Error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  return (
    <AppNavbar
      appName="WEB PUSH"
      menuLinks={uiCoreMenuItems}
      appLinks={appLinks}
      breadcrumbs={breadcrumbs}
      onLogout={handleLogout}
    />
  );
};

export default AppHeader;
