// @flow
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";
import { getLoading, type State } from "../../pages/TargetingView/store";
import { getTargeting } from "../../selectors/targetingView";
import { getTargetingSaga, type GetTargetingSaga } from "../../actions/targetingView";
import type { Targeting } from "../TargetingsList";
import View from "../../components/TargetingView";

type OwnProps = $ReadOnly<{||}>;

type StateProps = $ReadOnly<{|
  targeting: Targeting,
  isLoading: boolean,
|}>;

type DispatchProps = $ReadOnly<{|
  onLoad: (string) => GetTargetingSaga,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|}>;

const mapStateToProps: (State) => StateProps = (state) => ({
  targeting: getTargeting(state),
  isLoading: getLoading(state, "isTargetingLoading"),
});

const mapDispatchToProps: (Dispatch<GetTargetingSaga>) => DispatchProps = (dispatch) => bindActionCreators({
  onLoad: getTargetingSaga,
}, dispatch);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(View);
