/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type {
  Dispatch,
  CombinedReducer,
  Store,
} from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import type { OutputSelector } from "reselect";
import notifications, { initNotificationsState, type State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import { tableReducer as tables, initTableState, type TableState } from "@fas/ui-framework/lib/redux/reducers/table";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import loading, { initLoadingState, type State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";
import {
  reducer as errors,
  type State as ErrorsState,
  initState as initErrorsState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";
import type { State as StepsState } from "@fas/ui-framework/lib/redux/reducers/steps";
import type { Actions as StepsActions } from "@fas/ui-framework/lib/redux/actions/steps";
import type { Actions as IPPListActions } from "../../actions/ippList";
import type { Actions as MotivationContentsActions } from "../../actions/motivationContentsList";
import type { Actions as PwaListActions } from "../../actions/pwaList";
import type { Actions as PwaOfflinePagesListActions } from "../../actions/pwaOfflinePagesList";
import type { Actions as MessageGroupsListActions } from "../../actions/messageGroupsList";
import type { Actions as TargetingsListActions } from "../../actions/targetingsList";
import type { Actions as CampaignActions } from "../../actions/createCampaign";
import steps, { initialState } from "../../reducers/campaignSteps";
import {
  IPP_LIST_TABLE,
  MOTIVATION_CONTENTS_TABLE,
  PWA_LIST_TABLE,
  MESSAGE_GROUPS_LIST_TABLE,
  TARGETINGS_LIST_TABLE,
  PWA_OFFLINE_LIST_TABLE,
  FORM_KEY_CAMPAIGN,
} from "../../helpers/constants";
import type { Campaign } from "../../containers/CreateCampaign";

import mainSaga from "./saga";

export const defaultFromData: Campaign = {
  id: "",
  name: "",
  ratio: 1,
  isActive: true,
  webPushMotivationContents: [],
  webPushMessageGroups: [],
  webPushTargetings: [],
  ippMotivationContents: [],
  pwaMotivationContents: [],
  pwaOfflinePages: [],
  usedInSchedulesAndSegments: [],
  createdAt: "",
  updatedAt: "",
};

export type LoadingTypes = typeof IPP_LIST_TABLE
  | typeof MOTIVATION_CONTENTS_TABLE
  | typeof PWA_LIST_TABLE
  | typeof MESSAGE_GROUPS_LIST_TABLE
  | typeof TARGETINGS_LIST_TABLE
  | typeof PWA_OFFLINE_LIST_TABLE
  | typeof FORM_KEY_CAMPAIGN;

export type State = $ReadOnly<{
  notifications: NotificationsState,
  tables: TableState,
  loading: LoadingState<LoadingTypes>,
  steps: StepsState,
  form: FormState,
  errors: ErrorsState,
}>;

export const getLoading: OutputSelector<
  State,
  LoadingTypes,
  boolean
> = createLoadingSelector<LoadingTypes>();

export type Actions = NotificationsActions
  | TableActions
  | LoadingState<LoadingTypes>
  | IPPListActions
  | MotivationContentsActions
  | PwaListActions
  | PwaOfflinePagesListActions
  | MessageGroupsListActions
  | TargetingsListActions
  | StepsActions
  | CampaignActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  tables,
  loading,
  steps,
  form,
  errors,
});

export const mapState: () => State = () => ({
  form: initFormState({
    [FORM_KEY_CAMPAIGN]: defaultFromData,
  }),
  notifications: initNotificationsState(),
  tables: initTableState({
    [IPP_LIST_TABLE]: { pageSize: 20 },
    [MOTIVATION_CONTENTS_TABLE]: { pageSize: 20 },
    [PWA_LIST_TABLE]: { pageSize: 20 },
    [MESSAGE_GROUPS_LIST_TABLE]: { pageSize: 20 },
    [TARGETINGS_LIST_TABLE]: { pageSize: 20 },
    [PWA_OFFLINE_LIST_TABLE]: { pageSize: 20 },
  }),
  loading: initLoadingState({
    IPP_LIST_TABLE: false,
    MOTIVATION_CONTENTS_TABLE: false,
    PWA_LIST_TABLE: false,
    MESSAGE_GROUPS_LIST_TABLE: false,
    TARGETINGS_LIST_TABLE: false,
    PWA_OFFLINE_LIST_TABLE: false,
    FORM_KEY_CAMPAIGN: false,
  }),
  steps: initialState,
  errors: initErrorsState(),
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
