// @flow
import {
  GET_PWA_LIST_SAGA,
} from "../../helpers/constants";

export type GetPWAListSaga = {
  type: typeof GET_PWA_LIST_SAGA,
};

export type Actions = GetPWAListSaga;

export const getPWAListSaga: () => GetPWAListSaga = () => ({
  type: GET_PWA_LIST_SAGA,
});
