// @flow
import { Map } from "immutable";
import type { RecordOf } from "immutable";
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type {
  ModifiersStateData,
  Simple,
  Images,
  Buttons,
  ButtonTypes,
  ButtonsByType,
} from "../../containers/Modifiers";
import type { MotivationTemplateType } from "../../containers/CreateMotivationTemplate";
import { makeModifiers, makeSimpleModifiers } from "../../reducers/modifiers";
import type { ModifierstState, State, ButtonState } from "../../reducers/modifiers";

export type StoreWithModifiersState = $ReadOnly<{ modifiers: State, ... }>;

export const getModifiers: OutputSelector<StoreWithModifiersState, *, ModifiersStateData> = createSelector(
  (state: StoreWithModifiersState, key: MotivationTemplateType): RecordOf<ModifierstState> => state.modifiers
    .get(key, makeModifiers({})),
  // $FlowFixMe
  (form: RecordOf<ModifierstState>): ModifiersStateData => form.toJS()
);

export const getSimpleModifiers: OutputSelector<StoreWithModifiersState, *, Simple> = createSelector(
  (state: StoreWithModifiersState, key: MotivationTemplateType): RecordOf<Simple> => state.modifiers
    .getIn([key, "simple"], makeSimpleModifiers({})),
  (value: RecordOf<Simple>): Simple => value.toObject()
);

export const getImageModifiers: OutputSelector<StoreWithModifiersState, *, Images> = createSelector(
  (state: StoreWithModifiersState, key: MotivationTemplateType): Map<string, string> => state.modifiers
    .getIn([key, "images"], Map()),
  (value: Map<string, string>): Images => value.toObject()
);

export const getButtonModifiers: OutputSelector<StoreWithModifiersState, *, Buttons> = createSelector(
  (
    state: StoreWithModifiersState,
    key: MotivationTemplateType,
    buttonType: ButtonTypes
  ): ButtonState => state.modifiers.getIn([key, "buttons", buttonType], Map()),
  // $FlowFixMe
  (value: ButtonState): ButtonsByType => value.toJS()
);
