/* eslint-disable import/max-dependencies */
// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { useSelector } from "react-redux";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { saveFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import { SetupCard, GridUc } from "@fas/ui-core";
import {
  Container, Grid, Typography, Switch, Box, Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import weakKey from "weak-key";
import NamedItem from "../../components/NamedItem";
import CardDivider from "../../components/CardDivider";
import ViewSegment from "../../components/Segments/ViewSegment";
import type { MessageGroupListItem } from "../../components/MessageGroupsList/types/MessageGroupsList.types";
import type { SegmentListItem } from "../../components/SegmentsList/types/SegmentsList.types";
import Collapse from "../../components/Collapse";
import type { BrowserLanguage } from "../CreateMessageGroup";
import StepControl from "../StepControl";
import { useActions } from "../../hooks";
import type { ScheduleFormData } from "../CreateSchedule";
import { FORM_KEY_SCHEDULE } from "../../helpers/constants";
import type { State } from "../../pages/CreateSchedule";

export type Classes = {
  root: string,
};

const useStyles: () => Classes = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
}));

const SchedulePreview: StatelessFunctionalComponent<{}> = () => {
  const classes = useStyles();
  const {
    id,
    name,
    isActive,
    webPushMessageGroups,
    webPushSegments,
    queueType,
  }: ScheduleFormData = useSelector((state: State) => getFormData(state, FORM_KEY_SCHEDULE));

  const { saveFormDataSaga: onSaveFormDataSaga } = useActions({ saveFormDataSaga });

  return (
    <Container maxWidth="md">
      <SetupCard
        title="Schedule Info"
      >
        <Box pb={3} width="100%" className={classes.root}>
          {id && <NamedItem name="Id" value={id} />}
          <NamedItem name="Name" value={name} />
          <GridUc
            mt={3}
            px={3}
            container
            direction="row"
            alignItems="center"
          >
            <Grid item xs={3}>
              <Typography variant="body2" color="textSecondary">isActive</Typography>
            </Grid>
            <Grid item xs={9}>
              <Switch checked={isActive} disabled />
            </Grid>
          </GridUc>
          <NamedItem name="Queue Type" value={queueType} />
        </Box>
      </SetupCard>
      <SetupCard title="Segments">
        {webPushSegments.map((segment: SegmentListItem, index: number): Node => (
          <Box width="100%" key={weakKey(segment)} className={classes.root}>
            <NamedItem name="Id" value={segment.id || ""} />
            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">Name</Typography>
              </Grid>
              <Grid item xs={9}>
                <Link
                  href={`/segments/view/${segment.id || ""}`}
                  target="_blank"
                  underline="hover"
                >
                  {segment.name}
                </Link>
              </Grid>
            </GridUc>
            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">isActive</Typography>
              </Grid>
              <Grid item xs={9}>
                <Switch checked={segment.isActive} disabled />
              </Grid>
            </GridUc>
            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">Criteria</Typography>
              </Grid>
              <Grid item xs={9}>
                <ViewSegment {...segment} />
              </Grid>
            </GridUc>
            <Box mt={3}>
              <CardDivider visible={index < webPushSegments.length - 1} />
            </Box>
          </Box>
        ))}
      </SetupCard>
      <SetupCard title="Message Groups">
        {webPushMessageGroups.map((messageGroup: MessageGroupListItem, index: number): Node => (
          <Box width="100%" key={weakKey(messageGroup)} className={classes.root}>
            <NamedItem name="Id" value={messageGroup.id || ""} />
            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">Name</Typography>
              </Grid>
              <Grid item xs={9}>
                <Link
                  href={`/messageGroups/view/${messageGroup.id || ""}`}
                  target="_blank"
                  underline="hover"
                >
                  {messageGroup.name}
                </Link>
              </Grid>
            </GridUc>
            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">isActive</Typography>
              </Grid>
              <Grid item xs={9}>
                <Switch checked={messageGroup.isActive} disabled />
              </Grid>
            </GridUc>
            <NamedItem name="Placements" value={messageGroup.placements} />
            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">Browser Languages</Typography>
              </Grid>
              <Grid item xs={9}>
                <Collapse
                  list={messageGroup.browserLanguages.map(
                    (item: BrowserLanguage): {name: string} => ({ name: item.language })
                  )}
                  limit={2}
                  showMoreDisabled={false}
                />
              </Grid>
            </GridUc>
            <Box mt={3}>
              <CardDivider visible={index < webPushMessageGroups.length - 1} />
            </Box>
          </Box>
        ))}
      </SetupCard>

      <Box mt={3}>
        <StepControl
          nextButtonText="Save"
          onNext={() => {
            onSaveFormDataSaga(FORM_KEY_SCHEDULE, {}, "/schedules");
          }}
        />
      </Box>
    </Container>
  );
};

export default SchedulePreview;
