// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import Notifications from "../../components/Notification";
import View from "../../containers/TargetingView";

const TargetingView: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="Targeting View">
    <Provider store={configureStore()}>
      <View />
      <Notifications />
    </Provider>
  </DocumentTitle>
);

export default TargetingView;
