// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";
import type { State as StoreState } from "../../pages/CreatePwaOfflinePage/store";
import type { RawBrowserLanguages } from "../../containers/PwaMotivationContentList";
import type { RawPwaOfflinePage } from "../../components/PwaOfflinePagesList/types/PwaOfflinePages.types";
import type { State as PwaOffState } from "../../reducers/createPwaOfflinePage";
import { PWA_OFFLINE_FORM_LOADING } from "../../helpers/constants";

export const getId: OutputSelector<StoreState, *, string> = createSelector(
  (state: StoreState): string => state.pwaOffline.get("id"),
  (id: string): string => id
);

export const getIsActive: OutputSelector<StoreState,
  *,
  boolean> = createSelector(
    (state: StoreState): boolean => state.pwaOffline.get("isActive"),
    (isActive: boolean): boolean => isActive
  );

export const getName: OutputSelector<StoreState, *, string> = createSelector(
  (state: StoreState): string => state.pwaOffline.get("name"),
  (name: string): string => name
);

export const getBrowserLanguages: OutputSelector<StoreState,
  *,
  Array<RawBrowserLanguages>> = createSelector(
    (state: StoreState): Array<RawBrowserLanguages> => state.pwaOffline.get("BrowserLanguages"),
    (browserLanguages: Array<RawBrowserLanguages>): Array<RawBrowserLanguages> => browserLanguages
  );

export const getPwaOfflineHead: OutputSelector<StoreState, *, string> = createSelector(
  (state: StoreState): string => state.pwaOffline.get("head"),
  (head: string): string => head
);

export const getPwaOfflineBody: OutputSelector<StoreState, *, string> = createSelector(
  (state: StoreState): string => state.pwaOffline.get("body"),
  (body: string): string => body
);

export const getPwaOfflinePage: OutputSelector<StoreState, *, RawPwaOfflinePage> = createSelector(
  (state: StoreState): PwaOffState => state.pwaOffline,
  (item: PwaOffState): RawPwaOfflinePage => item.toJSON()
);

export const getLoading: OutputSelector<StoreState,
  typeof PWA_OFFLINE_FORM_LOADING,
  boolean> = createLoadingSelector<typeof PWA_OFFLINE_FORM_LOADING>();
