/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Grid } from "@mui/material";
import TargetingsList from "../TargetingsList";
import ListModalSetupForm from "../Form/ListModalSetupForm";
import StepControl from "../StepControl";
import { useNotificationError } from "../../hooks";

const TargetingsStep: StatelessFunctionalComponent<{}> = () => {
  useNotificationError("targetings");

  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item container justifyContent="center" alignItems="center">
        <ListModalSetupForm
          name="webPushTargetings"
          ListComponent={TargetingsList}
          title="ADD TARGETINGS"
          listName="targetings"
          openOnEmpty
        />
        <Grid item xs={8}>
          <StepControl />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TargetingsStep;
