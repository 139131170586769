/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type {
  Dispatch,
  CombinedReducer,
  Store,
} from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import type { OutputSelector } from "reselect";
import notifications, { initNotificationsState, type State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import { tableReducer as tables, initTableState, type TableState } from "@fas/ui-framework/lib/redux/reducers/table";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import loading, { initLoadingState, type State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";
import type { State as StepsState } from "@fas/ui-framework/lib/redux/reducers/steps";
import type { Actions as StepsActions } from "@fas/ui-framework/lib/redux/actions/steps";
import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";
import {
  reducer as errors,
  type State as ErrorsState,
  initState as initErrorsState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import dictionaries, {
  type State as DictionariesState,
  type DropDownObjItemType,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";
import steps, { initialState as initialStepsState } from "../../reducers/scheduleSteps";
import type { Actions as SegmentsListActions } from "../../actions/segmentsList";
import type { Actions as MessageGroupsListsActions } from "../../actions/messageGroupsList";
import { SEGMENTS_LIST_TABLE, MESSAGE_GROUPS_LIST_TABLE, FORM_KEY_SCHEDULE } from "../../helpers/constants";
import mainSaga from "./saga";
import type { ScheduleFormData } from "../../containers/CreateSchedule";

export type TableTypes = typeof SEGMENTS_LIST_TABLE | typeof MESSAGE_GROUPS_LIST_TABLE;

export const defaultFromData: ScheduleFormData = {
  id: "",
  name: "",
  isActive: true,
  webPushMessageGroups: [],
  webPushSegments: [],
  queueType: "",
  customScheduleAtHours: "0",
  customScheduleAtMinutes: "0",
  createdAt: "",
  updatedAt: "",
};

const mmValues: Array<DropDownObjItemType> = [...Array(60).keys()].map((v) => String(v)).map((value) => ({
  label: value,
  value,
}));
const hhValues: Array<DropDownObjItemType> = [...Array(24).keys()].map((v) => String(v)).map((value) => ({
  label: value,
  value,
}));

export type State = $ReadOnly<{
  notifications: NotificationsState,
  tables: TableState,
  loading: LoadingState<TableTypes>,
  form: FormState,
  errors: ErrorsState,
  steps: StepsState,
  dictionaries: DictionariesState,
}>;

export const getLoading: OutputSelector<
  State,
  TableTypes,
  boolean
> = createLoadingSelector<TableTypes>();

export type Actions = NotificationsActions
  | TableActions
  | LoadingState<TableTypes>
  | MessageGroupsListsActions
  | SegmentsListActions
  | StepsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  tables,
  loading,
  form,
  errors,
  steps,
  dictionaries,
});

export const mapState: () => State = () => ({
  notifications: initNotificationsState(),
  tables: initTableState({
    [SEGMENTS_LIST_TABLE]: { pageSize: 20 },
    [MESSAGE_GROUPS_LIST_TABLE]: { pageSize: 20 },
  }),
  loading: initLoadingState({
    [SEGMENTS_LIST_TABLE]: false,
    [MESSAGE_GROUPS_LIST_TABLE]: false,
  }),
  form: initFormState({
    [FORM_KEY_SCHEDULE]: defaultFromData,
  }),
  errors: initErrorsState(),
  steps: initialStepsState,
  dictionaries: initDictionariesState({
    queueTypes: [
      { label: "based on subscription", value: "subscription" },
      { label: "based on client time", value: "client" },
      { value: "immediately", label: "immediately" },
    ],
    mmValues,
    hhValues,
  }),
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
