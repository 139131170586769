/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect, type Node } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "@fas/ui-core/lib";
import { stepChange } from "@fas/ui-framework/lib/redux/actions/steps";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import Form from "../Form";
import {
  FORM_KEY_SCHEDULE,
  GET_FORM_DATA_LOADING,
  SAVE_FORM_DATA_LOADING,
} from "../../helpers/constants";
import {
  ScheduleInfoStep as ScheduleInfo,
  MessageGroupsScheduleStep as MessageGroups,
  SegmentsStep as Segments,
  SchedulePreviewStep as SchedulePreview,
} from "..";
import { useLoading, useActions } from "../../hooks";
import Steps from "../Steps";

const stepsMap = {
  "1": ScheduleInfo,
  "2": MessageGroups,
  "3": Segments,
  "4": SchedulePreview,
};

function CreateSchedule(): Node {
  const { id: objectId } = useParams();

  useEffect(() => {
    if (objectId) {
      onGetFormDataSaga(FORM_KEY_SCHEDULE, objectId);
    }
  }, [objectId]);

  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  const {
    getFormDataSaga: onGetFormDataSaga,
    stepChange: onStepChange,
  } = useActions({
    getFormDataSaga,
    stepChange,
  });

  return (
    <Form id={FORM_KEY_SCHEDULE}>
      <Loader loading={loading}>
        <Steps
          onClick={onStepChange}
          getStepContent={(stepKey) => stepsMap[stepKey]}
        />
      </Loader>
    </Form>
  );
}

export default CreateSchedule;
