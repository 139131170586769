// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record } from "immutable";
import type { Reducer } from "redux";
import type { Actions } from "../../actions/createPwaOfflinePage";
import {
  SET_PWA_OFFLINE_NAME,
  SET_PWA_OFFLINE_BROWSER_LANGUAGES,
  SET_PWA_OFFLINE_IS_ACTIVE,
  SET_PWA_OFFLINE_PAGE,
  SET_PWA_OFFLINE_HEAD,
  SET_PWA_OFFLINE_BODY,
} from "../../helpers/constants";
import type { RawPwaOfflinePage } from "../../components/PwaOfflinePagesList/types/PwaOfflinePages.types";

export type DefaultState = {
    ...RawPwaOfflinePage,
};

const defaultValues: DefaultState = {
  id: "",
  isActive: true,
  name: "",
  BrowserLanguages: [],
  body: "",
  head: "",
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initCreatePwaOfflinePageState(): State {
  return initialState;
}

// eslint-disable-next-line complexity
const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_PWA_OFFLINE_PAGE: {
      const { pwaOffline } = action;
      return makeState(pwaOffline);
    }
    case SET_PWA_OFFLINE_NAME: {
      const { name } = action;
      return state.set("name", name);
    }
    case SET_PWA_OFFLINE_BROWSER_LANGUAGES: {
      const { browserLanguages } = action;
      return state.set("BrowserLanguages", browserLanguages);
    }
    case SET_PWA_OFFLINE_IS_ACTIVE: {
      const { isActive } = action;
      return state.set("isActive", isActive);
    }
    case SET_PWA_OFFLINE_HEAD: {
      const { head } = action;
      return state.set("head", head);
    }
    case SET_PWA_OFFLINE_BODY: {
      const { body } = action;
      return state.set("body", body);
    }
    default:
      return state;
  }
};

export default reducer;
