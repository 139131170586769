// @flow
import React from "react";
import { TreeView, TreeItem } from "@mui/lab";
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import keyGenerator from "weak-key";
import ViewCriteria from "./ViewCriteria";
import { getFirstKey } from "../../helpers/QueryBuilder";

type Props = {
  criteria: Object,
};

const keyMapper = {
  $and: "AND",
  $or: "OR",
};

function parseCriteria(criteria: Object, key: string) {
  return criteria[key].map((rule) => {
    if (!rule.$and && !rule.$or) {
      return (<ViewCriteria rule={rule} key={keyGenerator(rule)} />);
    }

    const ruleKey = "$and" in rule ? "$and" : "$or";

    return (
      <TreeItem
        key={keyGenerator(rule)}
        nodeId={keyGenerator(rule)}
        label={keyMapper[ruleKey]}
        data-testid="segments-or-btn"
      >
        {parseCriteria(rule, ruleKey)}
      </TreeItem>
    );
  });
}

export default ({ criteria }: Props) => {
  const parsedCriteria = typeof criteria === "string" ? JSON.parse(criteria) : criteria;
  const key: string = getFirstKey(parsedCriteria);

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem
        key={keyGenerator(parsedCriteria)}
        nodeId={keyGenerator(parsedCriteria)}
        label={keyMapper[key]}
        data-testid="segments-and-btn"
      >
        {parseCriteria(parsedCriteria, key)}
      </TreeItem>
    </TreeView>
  );
};
