/* eslint-disable import/max-dependencies */
// @flow
import React, { useState, type Node, type StatelessFunctionalComponent } from "react";
import { SetupCard } from "@fas/ui-core";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  TextField,
  Divider,
  FormControlLabel,
  Switch,
  IconButton,
  Link,
  Tooltip,
} from "@mui/material";
import {
  Visibility as ViewIcon,
  CloudUpload as UploadIcon,
  FileCopy as FileCopyIcon,
  DeleteOutline as DeleteOutlineIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import UploadResizerModal, { type Image } from "../UploadResizerModal";
import PreviewImageModal from "../PreviewImageModal";
import type { UseState } from "./types/PwaIcon.types";
import type { PwaIconImages, PwaIconFields, PwaIconFormData } from "../../reducers/pwaIcon";
import { copyToClipboard, withDomain } from "../../helpers/utils";

const sizeTypes: { [$Keys<PwaIconImages>]: Array<string> } = {
  androidIcons: ["192x192"],
  iosIcons: ["120x120", "152x152", "167x167", "180x180"],
  ipadSplashScreens: [
    "2048x2732",
    "1668x2388",
    "1668x2224",
    "1640x2360",
    "1620x2160",
    "1536x2048",
    "1488x2266",
    "768x1024",
  ],
  iosSplashScreens: [
    "1284x2778",
    "1242x2688",
    "1170x2532",
    "1125x2436",
    "1080x2340",
    "1080x1920",
    "828x1792",
    "750x1334",
    "640x1136",
  ],
};

const tooltips: { [$Keys<PwaIconImages>]: string } = {
  androidIcons: "You must upload a file 500x500px. Can use only files with png format. The Max. file size is 1mb.",
  iosIcons: "You must upload a file 500x500px. Can use only files with png format. The Max. file size is 1mb.",
  ipadSplashScreens: "You must upload a file 2048x2732px. Can use only files with png format. The Max. file size is 1mb.",
  iosSplashScreens: "You must upload a file 1284х2778px. Can use only files with png format. The Max. file size is 1mb.",
};

const renderImageUrl = ({ sizes, src }: Image): Node => (
  <Box key={src} display="flex" alignItems="center" mb={1} ml={1} maxWidth="380px" justifyContent="space-between">
    <Typography>
      {`${sizes}: `}
      <Link href={withDomain(src)} target="_blank">
        {src}
      </Link>
    </Typography>
    <IconButton
      color="primary"
      aria-label="copy image url"
      component="span"
      onClick={(): mixed => copyToClipboard(src)}
      data-testid="copy-button"
      size="small"
    >
      <FileCopyIcon />
    </IconButton>
  </Box>
);

type Props = {
  formData: PwaIconFormData,
  isUploadImageOpen: boolean,
  isPreviewImageOpen: boolean,
  onUploadImageModalOpenChange: (boolean) => mixed,
  onPreviewImageOpenChange: (boolean) => mixed,
  onSetPwaIconFormField: (field: $Shape<PwaIconFields>) => mixed,
  onSetPwaIconImage: (field: $Keys<PwaIconImages>, value: Array<Image>) => mixed,
};

const PwaIcon: StatelessFunctionalComponent<Props> = ({
  formData,
  isUploadImageOpen,
  isPreviewImageOpen,
  onUploadImageModalOpenChange,
  onPreviewImageOpenChange,
  onSetPwaIconFormField,
  onSetPwaIconImage,
}) => {
  const [currentType, setCurrentType]: UseState<$Keys<PwaIconImages>> = useState("androidIcons");

  const {
    name,
    shortname,
    isActive,
    androidIcons,
    iosIcons,
    iosSplashScreens,
    ipadSplashScreens,
  }: PwaIconFormData = formData;

  const currentImage: string = formData[currentType] && formData[currentType][0] ? formData[currentType][0].src : "";

  return (
    <SetupCard title="PWA Icon">
      <>
        <Box mb={2} width={1}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3}>
              <Typography>Name</Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="pwa-icon-name"
                  variant="outlined"
                  size="small"
                  value={name}
                  placeholder="Example: pwa_icon_test"
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    onSetPwaIconFormField({ name: e.target.value });
                  }}
                  inputProps={{ maxLength: "60" }}
                  data-testid="pwa-icon-name"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2} width={1}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3}>
              <Typography>Short Name</Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="pwa-icon-short-name"
                  variant="outlined"
                  size="small"
                  value={shortname}
                  placeholder="Example: pwa_icon_shortname"
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    onSetPwaIconFormField({ shortname: e.target.value });
                  }}
                  inputProps={{ maxLength: "60" }}
                  data-testid="pwa-icon-short-name"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2} width={1}>
          <Grid container direction="row" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3} mt={1}>
              <Box display="flex" alignItems="center">
                <Typography>Image Android/WEB</Typography>
                <Tooltip placement="top" title={tooltips.androidIcons}>
                  <IconButton size="small">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display="flex">
                <Box mr={1}>
                  <IconButton
                    disabled={androidIcons.length === 0}
                    color="primary"
                    aria-label="view image picture"
                    component="span"
                    onClick={(): mixed => {
                      setCurrentType("androidIcons");
                      onPreviewImageOpenChange(true);
                    }}
                    size="large"
                    data-testid="android-preview-button"
                  >
                    <ViewIcon />
                  </IconButton>
                </Box>
                <Box mr={1}>
                  <IconButton
                    color="primary"
                    aria-label="upload image picture"
                    component="span"
                    onClick={(): mixed => {
                      setCurrentType("androidIcons");
                      onUploadImageModalOpenChange(true);
                    }}
                    data-testid="android-upload-button"
                    size="large"
                  >
                    <UploadIcon />
                  </IconButton>
                </Box>
                <Box mr={1}>
                  <IconButton
                    color="primary"
                    disabled={androidIcons.length === 0}
                    aria-label="delete image picture"
                    component="span"
                    onClick={(): mixed => {
                      onSetPwaIconImage("androidIcons", []);
                    }}
                    data-testid="android-delete-button"
                    size="large"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              </Box>
              {androidIcons.length > 0 && (
                <>
                  <Box width={1} mb={2}>
                    <Divider />
                  </Box>
                  {androidIcons.map(renderImageUrl)}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box mb={2} width={1}>
          <Grid container direction="row" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3} mt={1}>
              <Box display="flex" alignItems="center">
                <Typography>Image IOS</Typography>
                <Tooltip placement="top" title={tooltips.iosIcons}>
                  <IconButton size="small">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display="flex">
                <Box mr={1}>
                  <IconButton
                    disabled={iosIcons.length === 0}
                    color="primary"
                    aria-label="view image picture"
                    component="span"
                    onClick={(): mixed => {
                      setCurrentType("iosIcons");
                      onPreviewImageOpenChange(true);
                    }}
                    size="large"
                    data-testid="ios-preview-button"
                  >
                    <ViewIcon />
                  </IconButton>
                </Box>
                <Box mr={1}>
                  <IconButton
                    color="primary"
                    aria-label="upload image picture"
                    component="span"
                    onClick={(): mixed => {
                      setCurrentType("iosIcons");
                      onUploadImageModalOpenChange(true);
                    }}
                    data-testid="ios-upload-button"
                    size="large"
                  >
                    <UploadIcon />
                  </IconButton>
                </Box>
                <Box mr={1}>
                  <IconButton
                    color="primary"
                    disabled={iosIcons.length === 0}
                    aria-label="delete image picture"
                    component="span"
                    onClick={(): mixed => {
                      onSetPwaIconImage("iosIcons", []);
                    }}
                    data-testid="ios-delete-button"
                    size="large"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              </Box>
              {iosIcons.length > 0 && (
                <>
                  <Box width={1} mb={2}>
                    <Divider />
                  </Box>
                  {iosIcons.map(renderImageUrl)}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box width={1} mb={2}>
          <Divider />
        </Box>
        <Box width={1} mb={1}>
          <Typography variant="h5">IOS PWA Splash Screen</Typography>
        </Box>
        <Box mb={2} width={1}>
          <Grid container direction="row" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3} mt={1}>
              <Box display="flex" alignItems="center">
                <Typography>Splash Image IPad</Typography>
                <Tooltip placement="top" title={tooltips.ipadSplashScreens}>
                  <IconButton size="small">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display="flex">
                <Box mr={1}>
                  <IconButton
                    disabled={ipadSplashScreens.length === 0}
                    color="primary"
                    aria-label="view image picture"
                    component="span"
                    onClick={(): mixed => {
                      setCurrentType("ipadSplashScreens");
                      onPreviewImageOpenChange(true);
                    }}
                    size="large"
                    data-testid="ipad-preview-button"
                  >
                    <ViewIcon />
                  </IconButton>
                </Box>
                <Box mr={1}>
                  <IconButton
                    color="primary"
                    aria-label="upload image picture"
                    component="span"
                    onClick={(): mixed => {
                      setCurrentType("ipadSplashScreens");
                      onUploadImageModalOpenChange(true);
                    }}
                    data-testid="ipad-upload-button"
                    size="large"
                  >
                    <UploadIcon />
                  </IconButton>
                </Box>
                <Box mr={1}>
                  <IconButton
                    color="primary"
                    disabled={ipadSplashScreens.length === 0}
                    aria-label="delete image picture"
                    component="span"
                    onClick={(): mixed => {
                      onSetPwaIconImage("ipadSplashScreens", []);
                    }}
                    data-testid="ipad-delete-button"
                    size="large"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              </Box>
              {ipadSplashScreens.length > 0 && (
                <>
                  <Box width={1} mb={2}>
                    <Divider />
                  </Box>
                  {ipadSplashScreens.map(renderImageUrl)}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box mb={2} width={1}>
          <Grid container direction="row" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3} mt={1}>
              <Box display="flex" alignItems="center">
                <Typography>Splash Image IPhone</Typography>
                <Tooltip placement="top" title={tooltips.iosSplashScreens}>
                  <IconButton size="small">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display="flex">
                <Box mr={1}>
                  <IconButton
                    disabled={iosSplashScreens.length === 0}
                    color="primary"
                    aria-label="view image picture"
                    component="span"
                    onClick={(): mixed => {
                      setCurrentType("iosSplashScreens");
                      onPreviewImageOpenChange(true);
                    }}
                    size="large"
                    data-testid="iphone-preview-button"
                  >
                    <ViewIcon />
                  </IconButton>
                </Box>
                <Box mr={1}>
                  <IconButton
                    color="primary"
                    aria-label="upload image picture"
                    component="span"
                    onClick={(): mixed => {
                      setCurrentType("iosSplashScreens");
                      onUploadImageModalOpenChange(true);
                    }}
                    data-testid="iphone-upload-button"
                    size="large"
                  >
                    <UploadIcon />
                  </IconButton>
                </Box>
                <Box mr={1}>
                  <IconButton
                    color="primary"
                    disabled={iosSplashScreens.length === 0}
                    aria-label="delete image picture"
                    component="span"
                    onClick={(): mixed => {
                      onSetPwaIconImage("iosSplashScreens", []);
                    }}
                    data-testid="iphone-delete-button"
                    size="large"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              </Box>
              {iosSplashScreens.length > 0 && (
                <>
                  <Box width={1} mb={2}>
                    <Divider />
                  </Box>
                  {iosSplashScreens.map(renderImageUrl)}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box width={1} mb={2}>
          <Divider />
        </Box>
        <Box width={1}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
            <Grid item xs={4}>
              <FormControlLabel
                control={(
                  <Switch
                    data-testid="is-active-switch"
                    color="primary"
                    checked={isActive}
                    onClick={(): mixed => onSetPwaIconFormField({ isActive: !isActive })}
                  />
                )}
                label="Is Active"
              />
            </Grid>
          </Grid>
        </Box>
        <UploadResizerModal
          open={isUploadImageOpen}
          onSave={(images: Array<Image>) => {
            onSetPwaIconImage(currentType, images);
          }}
          onClose={(): mixed => onUploadImageModalOpenChange(false)}
          sizes={sizeTypes[currentType]}
        />
        <PreviewImageModal
          open={isPreviewImageOpen}
          url={currentImage}
          onClose={(): mixed => onPreviewImageOpenChange(false)}
        />
      </>
    </SetupCard>
  );
};

export default PwaIcon;
