/* eslint-disable import/max-dependencies */
// @flow
import React from "react";

import {
  ViewLink,
  Table,
  IsActiveLabel,
} from "@fas/ui-core";

import type { StatelessFunctionalComponent, Element } from "react";
import { useDispatch } from "react-redux";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import {
  Box, Chip, Typography, Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getMessagesListSaga } from "../../actions/messagesList";
import externalRedirect from "../App/ExternalRedirect";
import { MESSAGES_LIST_TABLE } from "../../helpers/constants";
import Image from "../MessageView/Image";
import type {
  MessageListItem,
  Classes,
} from "./types/MessagesList.types";
import { compareById } from "../../services/utils";

type Props = {|
  TableActions?: Element<*>,
  choicedData?: Array<MessageListItem>,
  onSelect?: (MessageListItem) => mixed,
  onDeselect?: (MessageListItem) => mixed,
  onCreate?: () => mixed,
  rowSelectAvailable?: () => boolean,
  allSelectAvailable?: () => boolean,
|};

const useStyles: () => Classes = makeStyles({
  smallColumn: {
    width: "40px",
  },
  imgColumn: {
    width: "50px",
    "& img": {
      width: "100%",
    },
  },
  fixedSizeColumn: {
    width: "15%",
  },
});

const handleCreate: () => null = () => externalRedirect({ to: "/messages/create", target: "_self" });

const MessagesList: StatelessFunctionalComponent<Props> = ({
  TableActions,
  choicedData,
  onSelect,
  onDeselect,
  onCreate = handleCreate,
  rowSelectAvailable = (): boolean => true,
  allSelectAvailable = (): boolean => true,
}) => {
  const dispatch: <A>(A) => A = useDispatch();

  const tableProps: TableProps = useTable(MESSAGES_LIST_TABLE);

  const handleLoad = () => dispatch(getMessagesListSaga());

  const { imgColumn, smallColumn, fixedSizeColumn }: Classes = useStyles();

  const columns: Array<Column<MessageListItem>> = [
    {
      label: "Status",
      field: "isActive",
      className: smallColumn,
      render: IsActiveLabel,
    },
    {
      label: "Name",
      field: "name",
      searchable: true,
      className: fixedSizeColumn,
      render: (row: MessageListItem): Element<Link> => (
        <ViewLink id={row.id} name={row.name} viewPath="/messages/view/:id" />
      ),
    },
    {
      label: "Badge",
      field: "badge",
      // eslint-disable-next-line no-undef
      className: imgColumn,
      render: (row: MessageListItem): Element<Link> => (
        <Image
          src={row.badge}
          fallbackSrc="/chrome-icon-default.svg"
          alt="message badge"
        />
      ),
    },
    {
      label: "Icon",
      field: "icon",
      className: imgColumn,
      render: (row: MessageListItem): Element<Link> => (
        <Image
          src={row.icon}
          fallbackSrc="/happy-face.svg"
          alt={row.name}
        />
      ),
    },
    {
      label: "Image",
      field: "image",
      className: imgColumn,
      render: (row: MessageListItem): Element<Link> => (
        <Image
          src={row.image}
          fallbackSrc="/default.png"
          alt={row.name}
        />
      ),
    },
    {
      label: "Title",
      field: "title",
      searchable: true,
      className: fixedSizeColumn,
    },
    {
      label: "Body",
      field: "body",
      className: fixedSizeColumn,
    },
    {
      label: "Actions / Description",
      field: "actions",
      className: fixedSizeColumn,
      render: (row: MessageListItem): Array<Element<Link>> => (
        row.actions.map((item): Element<Box> => (
          <Box
            key={item.id}
            display="flex"
            alignItems="baseline"
          >
            <Typography variant="body2" color="textSecondary">{item.title}</Typography>
            {Array.isArray(item.action) ? (
              <Box display="flex" flexWrap="wrap">
                {item.action.map((actionItem: string): Element<Chip> => (
                  <Box ml={0.5} my={0.5} key={item.title}>
                    <Chip key={actionItem} label={actionItem} />
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                ml={0.5}
              >
                <Typography>{item.action}</Typography>
              </Box>
            )}
          </Box>
        ))
      ),
    },
    {
      label: "Time To Live",
      field: "timeToLive",
      className: fixedSizeColumn,
    },
    {
      label: "Default click URL",
      field: "clickUrl",
      className: fixedSizeColumn,
    },
  ];

  return (
    <Box m={2}>
      <Table
        {...tableProps}
        title="Messages"
        columns={columns}
        onLoad={handleLoad}
        onCreate={onCreate}
        compareRows={compareById}
        selected={choicedData || tableProps.selected}
        onSelect={onSelect || tableProps.onSelect}
        onDeselect={onDeselect || tableProps.onDeselect}
        rowSelectAvailable={rowSelectAvailable}
        allSelectAvailable={allSelectAvailable}
        Actions={TableActions}
      />
    </Box>
  );
};

export default MessagesList;
