// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record } from "immutable";
import type { Reducer } from "redux";
import type { Actions } from "../../actions/targetingView";
import {
  SET_TARGETING,
} from "../../helpers/constants";
import type { Targeting } from "../../containers/TargetingsList";

const defaultValues: Targeting = {
  id: "",
  name: "",
  isActive: true,
  data1: "",
  BrowserLanguages: [],
  placement: [],
  platforms: [],
  createdAt: "",
  updatedAt: "",
  usedInCampaigns: [],
  isData1TargetingIgnore: false,
};

export const makeState: RecordFactory<Targeting> = Record(defaultValues);

export type State = RecordOf<Targeting>;

export const initialState: State = makeState({});

export function initTargetingViewState(): State {
  return initialState;
}

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_TARGETING: {
      return state.merge(action.targeting);
    }
    default:
      return state;
  }
};

export default reducer;
