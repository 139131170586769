/* eslint-disable import/max-dependencies */
// @flow
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import PwaMotivationComponentView from "../../components/PwaMotivationView";
import type { RawPwaItem } from "../PwaMotivationContentList";
import {
  getFormData,
  getLoading,
} from "../../selectors/createPwaMotivationContent";
import {
  getPwaSaga,
} from "../../actions/createPwaMotivationContent";
import type {
  GetPwaSaga,
  Actions,
} from "../../actions/createPwaMotivationContent";
import { PWA_MOTIV_LOADING } from "../../helpers/constants";

type OwnProps = {||}

type StateProps = $ReadOnly<{|
  ...RawPwaItem,
  isLoading: boolean,
|}>;

type DispatchProps = $ReadOnly<{|
  getPwa: (id: string) => GetPwaSaga,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|}>;

const mapStateToProps = (state) => ({
  ...getFormData(state),
  isLoading: getLoading(state, PWA_MOTIV_LOADING),
});

const mapDispatchToProps: (Dispatch<Actions>) => DispatchProps = (dispatch) => bindActionCreators({
  getPwa: getPwaSaga,
},
dispatch);

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(PwaMotivationComponentView);
