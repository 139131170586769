// @flow
import {
  call, put, takeEvery, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import { SAVE_CAMPAIGN_SAGA, FORM_KEY_CAMPAIGN } from "../../helpers/constants";
import CampaignApi from "../../services/campaignApi";
import { setLoading } from "../../actions/loading";
import externalRedirect from "../../components/App/ExternalRedirect";
import type { Campaign, CampaignSaveBody } from "../../containers/CreateCampaign";

export function* makeFetch(): Saga<void> {
  yield put(setLoading(SAVE_CAMPAIGN_SAGA, true));
  const {
    id: campaignId,
    name,
    ratio,
    webPushMotivationContents,
    webPushMessageGroups,
    webPushTargetings,
    ippMotivationContents,
    pwaMotivationContents,
    pwaOfflinePages,
  }: Campaign = yield select(getFormData, FORM_KEY_CAMPAIGN);

  const body: CampaignSaveBody = {
    name,
    ratio,
    motivationContent: webPushMotivationContents.map(({ id }): string => id),
    messageGroups: webPushMessageGroups.map(({ id }): string => id),
    targetings: webPushTargetings.map(({ id }): string => id),
    ippMotivationContents: ippMotivationContents.map(({ id }): string => id),
    pwaMotivationContents: pwaMotivationContents.map(({ id }): string => id),
    pwaOfflinePages: pwaOfflinePages.map(({ id }): string => id),
  };

  try {
    if (campaignId) {
      yield call(CampaignApi.updateCampaign, campaignId, body);
      yield put(addNotification({ message: "Campaign updated successfully", severity: "success" }));
    }
    else {
      yield call(CampaignApi.saveCampaign, body);
      yield put(addNotification({ message: "Campaign saved successfully", severity: "success" }));
    }
    yield put(setLoading(SAVE_CAMPAIGN_SAGA, false));
    externalRedirect({ to: "/campaigns" });
  }
  catch (error) {
    yield put(setLoading(SAVE_CAMPAIGN_SAGA, false));
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchSaveIPPSaga(): Saga<void> {
  yield takeEvery(SAVE_CAMPAIGN_SAGA, (makeFetch: Function));
}
