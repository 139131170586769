/* eslint-disable import/max-dependencies */
// @flow
import {
  call, put, takeEvery, all,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { get } from "lodash";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { GET_PWA_SAGA, PWA_MOTIV_LOADING } from "../../helpers/constants";
import type { GetPwaSaga } from "../../actions/createPwaMotivationContent";
import { setPwa } from "../../actions/createPwaMotivationContent";
import { setModifiersState } from "../../actions/modifiers";
import { convertModifiersToUi, type ModifiersStateData } from "../../containers/Modifiers";
import type { MotivationTemplateType, MotivationTemplateRaw, MotivationTemplateDropdown } from "../../containers/CreateMotivationTemplate";
import type {
  BrowserLanguages,
  PWAMotivationContent as PwaItem,
  PWAFormData,
} from "../../containers/PwaMotivationContentList";
import PwaApi from "../../services/PwaApi";
import externalRedirect from "../../components/App/ExternalRedirect";
import { fetchMotivationTemplate } from "../../services/motivationTemplateApi";

export function* makeFetch(action: GetPwaSaga): Saga<void> {
  const { id } = action;
  yield put(setLoading(PWA_MOTIV_LOADING, true));

  try {
    const response = yield call(PwaApi.getPWA, id);

    const {
      motivationTemplates = {},
      modifiers,
      ...pwaItem
    }: PwaItem = get(response, "data.data.0");
    const browserLanguages: BrowserLanguages[] = get(pwaItem, "BrowserLanguages", []);

    const motivationTemplatesState: { [MotivationTemplateType]: MotivationTemplateDropdown } = {};
    const modifiersState: { [MotivationTemplateType]: ModifiersStateData } = {};

    if (motivationTemplates) {
      const templates: MotivationTemplateRaw[] = yield all(Object.keys(motivationTemplates)
        .map((type: MotivationTemplateType) => call(fetchMotivationTemplate, motivationTemplates[type])));

      Object.keys(motivationTemplates).forEach((type, index) => {
        const template: MotivationTemplateRaw = templates[index];
        motivationTemplatesState[type] = { label: template.name, value: template.id, ...template };
        modifiersState[type] = convertModifiersToUi({ modifiers: modifiers[type], type });
      });

      yield put(setModifiersState(modifiersState));
    }

    const item: PWAFormData = {
      ...pwaItem,
      motivationTemplates: motivationTemplatesState,
      BrowserLanguages: browserLanguages.map(({ id: value, language: label }) => ({ value, label })),
    };
    yield put(setPwa(item));
  }
  catch (error) {
    yield put(addNotification({
      message: error.errorMessage,
      severity: "error",
    }));
    // eslint-disable-next-line no-console
    console.error(error);
    externalRedirect({ to: "/pwa" });
  }
  finally {
    yield put(setLoading(PWA_MOTIV_LOADING, false));
  }
}

export default function* watchFetchPwaSaga(): Saga<void> {
  yield takeEvery(GET_PWA_SAGA, (makeFetch: Function));
}
