// @flow
import React from "react";
import {
  Button,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Link, Switch,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Delete as DeleteIcon, ArrowForwardIos } from "@mui/icons-material";
import Image from "./Image";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    backgroundColor: "rgb(250, 250, 255)",
  },
  arrow: {
    width: "10px",
    height: "10px",
  },
  image: {
    maxHeight: "50px",
    maxWidth: "50px",
  },
  messageTitle: {
    fontWeight: "700",
  },
  messageText: {
    fontWeight: "400",
  },
  controlDelete: {
    display: "block",
  },
  controlGroup: {
    display: "none",
  },
});

type Props = {
  id: string,
  name: string,
  title: string,
  body: string,
  image: string,
  icon: string,
  badge: string,
  isActive: boolean,
  removeMessage?: Function,
  isDeleteDisable?: boolean,
};

const defaultImage = "/default.png";
const defaultBadge = "/chrome-icon-default.svg";
const defaultIcon = "/happy-face.svg";

const MessageView = (props: Props) => {
  const {
    id,
    name,
    title,
    body,
    image,
    icon,
    badge,
    isActive,
    removeMessage,
    isDeleteDisable,
  } = props;
  const classes = useStyles();

  return (
    <Box mt={1}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item container justifyContent="space-around" xs={3}>
              <Image
                className={classes.image}
                src={badge}
                fallbackSrc={defaultBadge}
              />
              <Image
                className={classes.image}
                src={icon}
                fallbackSrc={defaultIcon}
              />
              <Image
                className={classes.image}
                src={image}
                fallbackSrc={defaultImage}
              />
            </Grid>
            <Grid item xs={3} zeroMinWidth>
              <Typography noWrap className={classes.messageTitle}>{title}</Typography>
              <Typography noWrap className={classes.messageText}>{body}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Link href={`/messages/view/${id}`} target="_blank" underline="hover">
                <Typography noWrap>{name}</Typography>
              </Link>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" xs={2}>
              <Typography noWrap>is active:</Typography>
              <Switch checked={isActive} disabled />
            </Grid>
            <Grid item xs={1} className={classes.controlGroup}>
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={() => {}}
              >
                Preview
              </Button>
            </Grid>
            <Grid item xs={1} className={classes.controlDelete}>
              { !isDeleteDisable && (
                <IconButton
                  type="button"
                  size="medium"
                  variant="contained"
                  onClick={removeMessage}
                  data-testid="message-view-delete-btn"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={1} className={classes.controlGroup}>
              <IconButton
                type="button"
                size="medium"
                variant="contained"
                onClick={() => {}}
              >
                <ArrowForwardIos />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

MessageView.defaultProps = {
  isDeleteDisable: false,
  removeMessage: () => {},
};

export default MessageView;
