/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import { Grid, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { mtuDefaultTheme } from "@fas/ui-themes";
import configureStore from "./store";
import LimitStatConfigure from "../../containers/LimitStatConfigure";
import LimitStatList from "../../containers/LimitStatList";
import type { Classes } from "./types/LimitState.types";
import Notification from "../../components/Notification";

type Props = {};

const useStyles: () => Classes = makeStyles((theme: typeof mtuDefaultTheme): Classes => ({
  mainBackground: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}));

const LimitStat: StatelessFunctionalComponent<Props> = () => {
  const classes: Classes = useStyles();

  return (
    <DocumentTitle title="Limit Stat">
      <Provider store={configureStore()}>
        <Grid container spacing={0} className={classes.mainBackground}>
          <Box flex={1} m={3}>
            <LimitStatConfigure />
            <LimitStatList />
          </Box>
        </Grid>
        <Notification />
      </Provider>
    </DocumentTitle>
  );
};

export default LimitStat;
