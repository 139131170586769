// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { Element, StatelessFunctionalComponent } from "react";
import {
  ViewLink,
  Table,
  IsActiveLabel,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { useDispatch } from "react-redux";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import { SEGMENTS_LIST_TABLE } from "../../helpers/constants";
import { getSegmentsSaga } from "../../actions/segmentsList";
import externalRedirect from "../App/ExternalRedirect";
import type { SegmentListItem, Classes } from "./types/SegmentsList.types";
import { compareById } from "../../services/utils";

const useStyles: () => Classes = makeStyles((): {[$Keys<Classes>]: *} => ({
  smallColumn: {
    width: "10%",
  },
  mediumColumn: {
    width: "90%",
  },
}));

type Props = {|
  TableActions?: Element<*>,
  choicedData?: Array<SegmentListItem>,
  onSelect?: (SegmentListItem) => mixed,
  onDeselect?: (SegmentListItem) => mixed,
  onCreate?: () => mixed,
  allSelectAvailable?: () => boolean,
  rowSelectAvailable?: () => boolean,
|};

const handleCreate: () => void = () => {
  externalRedirect({ to: "/segments/create", target: "_self" });
};

const SegmentsTable: StatelessFunctionalComponent<Props> = ({
  TableActions,
  choicedData,
  onSelect,
  onDeselect,
  onCreate = handleCreate,
  allSelectAvailable = (): boolean => true,
  rowSelectAvailable = (): boolean => true,
}) => {
  const { mediumColumn, smallColumn } = useStyles();
  const dispatch: <A>(A) => A = useDispatch();
  const tableProps: TableProps = useTable(SEGMENTS_LIST_TABLE);
  const handleLoad: () => mixed = () => dispatch(getSegmentsSaga());

  const columns: Array<Column<SegmentListItem>> = [
    {
      label: "Is active",
      field: "isActive",
      searchable: false,
      sortable: false,
      className: smallColumn,
      render: IsActiveLabel,
    },
    {
      label: "Name",
      field: "name",
      searchable: true,
      sortable: false,
      className: mediumColumn,
      render: (row: SegmentListItem) => (
        <ViewLink
          id={row.id}
          name={row.name}
          viewPath="/segments/view/:id"
        />
      ),
    },
  ];

  return (
    <Box m={2}>
      <Table
        {...tableProps}
        title="Segments"
        columns={columns}
        onLoad={handleLoad}
        onCreate={onCreate}
        selected={choicedData || tableProps.selected}
        onSelect={onSelect || tableProps.onSelect}
        onDeselect={onDeselect || tableProps.onDeselect}
        rowSelectAvailable={rowSelectAvailable}
        allSelectAvailable={allSelectAvailable}
        compareRows={compareById}
        Actions={TableActions}
      />
    </Box>
  );
};

export default SegmentsTable;
