// @flow
import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowForwardIos, Delete } from "@mui/icons-material";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  arrow: {
    width: "10px",
    height: "10px",
  },
  root: {
    padding: "5px 16px",
    "&:last-child": {
      paddingBottom: "5px",
    },
  },
});

// $FlowFixMe
export default function (props) {
  const {
    data,
    handleCollapse,
    handleDelete,
    SubComponent,
    subComponentData,
    isCollapsed,
  } = props;
  const classes = useStyles();

  return (
    <Box mt={1}>
      <Card className={classes.card}>
        <CardContent className={classes.root}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>{data.name}</Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => handleDelete(data.id)}
                data-testid="deleteButton"
                size="large"
              >
                <Delete fontSize="small" />
              </IconButton>
            </Grid>
            {handleCollapse && (
              <Grid item xs={1}>
                <IconButton
                  onClick={() => handleCollapse(data.id)}
                  data-testid="collapseButton"
                  size="large"
                >
                  <ArrowForwardIos className={classes.arrow} />
                </IconButton>
              </Grid>
            )}
            {
              isCollapsed
              && SubComponent
              && subComponentData.length
              && (
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                    {subComponentData.map((message) => <SubComponent key={message.id} {...message} />)}
                  </Grid>
                </Grid>
              )
            }
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
