// @flow
import React from "react";
import { Typography } from "@mui/material";
import type { Breadcrumb } from "@fas/ui-core/lib/AppNavbar";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const defaultCreateRegex = "/(?<name>.+)/create$";
const defaultCloneRegex = "/(?<name>.+)/clone/(?<id>.+)$";
const defaultEditRegex = "/(?<name>.+)/edit/(?<id>.+)$";
const defaultViewRegex = "/(?<name>.+)/view/(?<id>.+)$";
const defaultListRegex = "/(?<name>.+)$";
const splitByUpperCaseRegex = /[A-Z]*[^A-Z]+/g;

export default function getBreadcrumbs(location: Location): Breadcrumb[] {
  if (location.pathname.match(defaultViewRegex)) {
    const {
      // $FlowFixMe
      groups: { name, id },
    } = location.pathname.match(defaultViewRegex);

    const title = capitalizeFirstLetter(
      // $FlowFixMe
      name.match(splitByUpperCaseRegex).join(" ")
    );
    return [
      { path: `/${name}`, title },
      {
        title: id,
        path: name,
        component: <Typography>{`View: ${id}`}</Typography>,
      },
    ];
  }

  if (location.pathname.match(defaultEditRegex)) {
    const {
      // $FlowFixMe
      groups: { name, id },
    } = location.pathname.match(defaultEditRegex);

    const title = capitalizeFirstLetter(
      // $FlowFixMe
      name.match(splitByUpperCaseRegex).join(" ")
    );
    return [
      { path: `/${name}`, title },
      {
        title: id,
        path: name,
        component: <Typography>{`Edit: ${id}`}</Typography>,
      },
    ];
  }

  if (location.pathname.match(defaultCreateRegex)) {
    const {
      // $FlowFixMe
      groups: { name },
    } = location.pathname.match(defaultCreateRegex);

    const title = capitalizeFirstLetter(
      // $FlowFixMe
      name.match(splitByUpperCaseRegex).join(" ")
    );
    return [
      { path: `/${name}`, title },
      {
        title: "New",
        path: name,
        component: <Typography>New</Typography>,
      },
    ];
  }

  if (location.pathname.match(defaultCloneRegex)) {
    const {
      // $FlowFixMe
      groups: { name, id },
    } = location.pathname.match(defaultCloneRegex);

    const title = capitalizeFirstLetter(
      // $FlowFixMe
      name.match(splitByUpperCaseRegex).join(" ")
    );
    return [
      { path: `/${name}`, title },
      {
        title: id,
        path: name,
        component: <Typography>{`Clone: ${id}`}</Typography>,
      },
    ];
  }

  if (location.pathname.match(defaultListRegex)) {
    const {
      // $FlowFixMe
      groups: { name },
    } = location.pathname.match(defaultListRegex);

    const br = capitalizeFirstLetter(
      // $FlowFixMe
      name.match(splitByUpperCaseRegex).join(" ")
    );
    return [
      {
        title: br,
        path: name,
        component: <Typography>{br}</Typography>,
      },
    ];
  }
  return [];
}
