// @flow
import React, { useState, type Node } from "react";
import { SelectForm } from "@fas/ui-core/lib/Form";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import { ConfirmDialog } from "@fas/ui-core";

type Props = {
  name: string,
  label: string,
  options: *[],
  onSubmit: (string) => mixed,
  onChange: (string) => mixed,
  confirmTitle: string,
  confirmMessage: string,
}

function SelectWithConfirmForm({
  onSubmit,
  onChange,
  options,
  confirmTitle,
  confirmMessage,
  ...props
}: Props): Node {
  const [open, setOpen] = useState(false);
  const [newValue, setNewValue] = useState("");

  const handleKeyUp: (SyntheticKeyboardEvent<HTMLInputElement>) => void = (event) => {
    const { target }: SyntheticKeyboardEvent<HTMLInputElement> = event;
    if (event.key === "Enter" && target instanceof HTMLInputElement && target.value) {
      if (options.some(({ value }) => value === target.value)) {
        onChange(target.value);
        target.blur();
      }
      else {
        setOpen(true);
        setNewValue(target.value);
      }
    }
  };

  const handleKeyDown: (SyntheticKeyboardEvent<HTMLInputElement>) => void = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleDisagreeClick: () => void = () => setOpen(false);

  const handleAgreeClick: () => void = () => {
    setOpen(false);
    onSubmit(newValue);
  };

  return (
    <>
      {/* $FlowFixMe */}
      <SelectForm
        {...props}
        options={options}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
      />
      <ConfirmDialog
        title={confirmTitle}
        open={open}
        message={confirmMessage}
        onMainBtnClick={handleAgreeClick}
        onSecondaryBtnClick={handleDisagreeClick}
      />
    </>
  );
}

SelectWithConfirmForm.defaultProps = {
  onChange: () => {},
};

export default withFormField<*>(SelectWithConfirmForm);
