// @flow
import React, { type Node } from "react";
import { useSelector } from "react-redux";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import MessagePreview from "../../components/MessagePreview";

type Props = {
  formKey: string
}

function MessagePreviewForm({ formKey }: Props): Node {
  const {
    title,
    body,
    image,
    icon,
    badge,
    actions,
  } = useSelector((state) => getFormData(state, formKey));

  return (
    <MessagePreview
      title={title}
      body={body}
      image={image}
      icon={icon}
      badge={badge}
      actions={actions}
    />
  );
}

MessagePreviewForm.defaultProps = {
  onChange: () => {},
  value: 0,
};

export default MessagePreviewForm;
