/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect, type Node } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GridUc, Loader, SetupCard } from "@fas/ui-core";
import {
  Grid,
  Divider,
  Box,
  Container,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { WPFormData } from "../CreateWPMotivationContent";
import HtmlPreviewButton from "../../components/HtmlPreviewButton";
import { getWpFormData } from "../../selectors/createWp";
import CodeEditor from "../../components/CodeEditor";
import { getWpSaga } from "../../actions/createWp";
import { useActions } from "../../hooks/useActions";
import { useLoading } from "../../hooks/useLoading";
import AdditionalInfo from "../../components/AdditionalInfo";
import { WP_MOTIV_LOADING } from "../../helpers/constants";
import externalRedirect from "../../components/App/ExternalRedirect";
import NamedItem from "../../components/NamedItem";
import Collapse from "../../components/Collapse";
import TemplateBlock from "../../components/TemplateBlock";

type Classes = {
  root: string,
  codeRoot: string,
}

const useStyles: () => Classes = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
  codeRoot: {
    boxSizing: "border-box",
  },
}));

function WpMotivForm(): Node {
  const classes: Classes = useStyles();

  const {
    getWpSaga: onGetWp,
  } = useActions({ getWpSaga });

  const {
    id,
    name,
    BrowserLanguages,
    initTimeout,
    visualisationType,
    behaviorToAction,
    inline_html,
    inline_css,
    head,
    body,
    usedInCampaigns,
    isTemplate,
    motivationTemplates: {
      WPMotivationPopUp,
      WPSubscriptionPage,
    },
  }: WPFormData = useSelector(getWpFormData);

  const { id: wpId } = useParams();
  const isLoading: boolean = useLoading(WP_MOTIV_LOADING);

  const getItemEditPath: () => void = () => {
    if (wpId) {
      externalRedirect({ to: `/webpush/edit/${wpId}`, target: "_blank" });
    }
  };

  useEffect(() => {
    onGetWp(wpId);
  }, [wpId]);

  const isSystem: boolean = visualisationType === "system";

  return (
    <Container maxWidth="md">
      <Loader loading={isLoading} withoutWrapper>
        <SetupCard
          title="WebPush Motivation Content info"
          showSecondaryBtn
          secondaryBtnText="Edit"
          onSecondaryBtnClick={getItemEditPath}
        >
          <Box width="100%" pb={3} className={classes.root}>
            <NamedItem name="Name" value={name} />
            <NamedItem name="Id" value={id} />
            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">Browser languages:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Collapse
                  showMoreDisabled={false}
                  list={BrowserLanguages.map(
                    ({
                      value,
                      label,
                    }: { value: string, label: string }): { id: string, name: string } => ({
                      id: value,
                      name: label,
                    })
                  )}
                />
              </Grid>
            </GridUc>
            <NamedItem name="Visualisation Type" value={visualisationType} />
            <NamedItem name="Init timeout" value={initTimeout.toString()} />
            <NamedItem name="Behavior to action" value={behaviorToAction} />

            {!isSystem && (
              <Box px={3} className={classes.codeRoot}>
                <Box mb={4} mt={3}>
                  <Divider />
                </Box>

                <Box my={1}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Motivation Content
                  </Typography>
                </Box>

                {!isTemplate && (
                  <Box width={1}>
                    <CodeEditor
                      height="400px"
                      tabs={[
                        {
                          name: "html-editor",
                          label: "html",
                          value: inline_html,
                          mode: "html",
                          readOnly: true,
                        },
                        {
                          name: "css-editor",
                          label: "css",
                          value: inline_css,
                          mode: "css",
                          readOnly: true,
                        },
                      ]}
                    />
                    <HtmlPreviewButton
                      title="Preview HTML + CSS code"
                      data-testid="html-css-preview-btn"
                      htmlCode={inline_html}
                      cssCode={inline_css}
                    />
                  </Box>
                )}

                {isTemplate && (
                  <TemplateBlock
                    template={WPMotivationPopUp}
                    type="WPMotivationPopUp"
                    readOnly
                  />
                )}

                {visualisationType === "inline_without_manifest" && (
                  <>
                    <Box mb={4} mt={3}>
                      <Divider />
                    </Box>

                    <Box my={1}>
                      <Typography gutterBottom variant="h5" component="h2">
                        Subscription Page
                      </Typography>
                    </Box>

                    {!isTemplate && (
                      <Box width={1}>
                        <CodeEditor
                          height="400px"
                          tabs={[
                            {
                              name: "head",
                              label: "head html",
                              value: head,
                              mode: "html",
                              readOnly: true,
                            },
                            {
                              name: "body",
                              label: "body html",
                              value: body,
                              mode: "html",
                              readOnly: true,
                            },
                          ]}
                        />
                        <HtmlPreviewButton
                          title="Preview HEAD + BODY code"
                          data-testid="html-css-preview-btn"
                          htmlCode={head + body}
                          cssCode=""
                        />
                      </Box>
                    )}

                    {isTemplate && (
                      <TemplateBlock
                        template={WPSubscriptionPage}
                        type="WPSubscriptionPage"
                        readOnly
                      />
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
        </SetupCard>

        <AdditionalInfo usedInCampaigns={usedInCampaigns} />

      </Loader>
    </Container>
  );
}

export default WpMotivForm;
