// @flow
import React from "react";
import { SelectedItem } from "../SelectedItem";
import MessageView from "../MessageView";

export type MessageType = {
  id: number,
  name: string,
  messageTitle: string,
  timeToLive: string,
  messageBody: string,
  defaultClickUrl: string,
  customAttributes: string,
  messageImage: string,
  isActive: boolean,
};

type Props = {
  handleCollapse: Function,
  messageGroups: Array<{
    id: number,
    name: string,
    BrowserLanguages: Array<string>,
    placements: Array<string>,
    strategies: Array<string>,
    WebPushMessages: Array<MessageType>,
    isActive: boolean,
  }>,
  collapseStatuses: Object,
  handleDelete: Function,
};

export default ({
  messageGroups, handleCollapse, collapseStatuses, handleDelete,
// $FlowFixMe
}: Props) => messageGroups
  .map((item) => (
    <SelectedItem
      handleCollapse={handleCollapse}
      key={item.id}
      data={item}
      SubComponent={MessageView}
      // @TODO remove when new api routes for messageGroups will be ready
      subComponentData={(item.WebPushMessages || item.messages || [])
        .map((message) => ({ ...message, isDeleteDisable: true, id: message.id }))}
      isCollapsed={collapseStatuses[item.id]}
      handleDelete={handleDelete}
    />
  ));
