/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import { Box, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import {
  ShowMoreItems,
  ViewLink,
  Table,
  IsActiveLabel,
} from "@fas/ui-core";
import { useDispatch } from "react-redux";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import type { MessageGroupListItem, Classes } from "./types/MessageGroupsList.types";
import Collapse from "../Collapse";
import { MESSAGE_GROUPS_LIST_TABLE } from "../../helpers/constants";
import externalRedirect from "../App/ExternalRedirect";
import { getMessageGroupsListSaga } from "../../actions/messageGroupsList";
import { compareById, getFields } from "../../services/utils";

type Props = {|
  TableActions?: Element<*>,
  choicedData?: Array<MessageGroupListItem>,
  onSelect?: (MessageGroupListItem) => mixed,
  onDeselect?: (MessageGroupListItem) => mixed,
  onCreate?: () => mixed,
  rowSelectAvailable?: () => boolean,
  allSelectAvailable?: () => boolean,
|};

const useStyles: () => Classes = makeStyles({
  fixedColumn: {
    width: "25%",
  },
  statusColumn: {
    width: "40px",
  },
});

const handleCreate: () => null = () => externalRedirect({ to: "/messageGroups/create", target: "_self" });

const MessageGroupsList: StatelessFunctionalComponent<Props> = ({
  TableActions,
  choicedData,
  onSelect,
  onDeselect,
  onCreate = handleCreate,
  rowSelectAvailable = (): boolean => true,
  allSelectAvailable = (): boolean => true,
}) => {
  const { statusColumn, fixedColumn }: Classes = useStyles();
  const dispatch: <A>(A) => A = useDispatch();

  const tableProps: TableProps = useTable(MESSAGE_GROUPS_LIST_TABLE);
  const handleLoad = () => dispatch(getMessageGroupsListSaga());

  const columns: Array<Column<MessageGroupListItem>> = [
    {
      label: "Status",
      field: "isActive",
      render: IsActiveLabel,
      className: statusColumn,
    },
    {
      label: "Name",
      field: "name",
      searchable: true,
      render: (row: MessageGroupListItem): Element<Link> => (
        <ViewLink id={row.id} name={row.name} viewPath="/messageGroups/view/:id" />
      ),
    },
    {
      label: "Browser Languages",
      field: "browserLanguages",
      searchable: true,
      className: fixedColumn,
      render: (row: MessageGroupListItem): Element<typeof Collapse> => (
        <ShowMoreItems data={getFields(row.browserLanguages, "language")} />
      ),
    },
    {
      label: "Sending strategy",
      field: "sendStrategies",
      searchable: true,
      className: fixedColumn,
    },
    {
      label: "Placements",
      field: "placements",
      searchable: true,
      className: fixedColumn,
      render: (row: MessageGroupListItem): Element<typeof Collapse> => (
        <ShowMoreItems data={row.placements.map((i: string): string => i)} />
      ),
    },
  ];

  return (
    <Box m={2}>
      <Table
        {...tableProps}
        title="Message groups"
        columns={columns}
        onLoad={handleLoad}
        onCreate={onCreate}
        selected={choicedData || tableProps.selected}
        onSelect={onSelect || tableProps.onSelect}
        onDeselect={onDeselect || tableProps.onDeselect}
        rowSelectAvailable={rowSelectAvailable}
        allSelectAvailable={allSelectAvailable}
        compareRows={compareById}
        Actions={TableActions}
      />
    </Box>
  );
};

export default MessageGroupsList;
