// @flow
import Joi from "joi";
import joiResolver from "../../services/form/joiResolver";

const schema = Joi.object({
  name: Joi.string().required(),
  isActive: Joi.boolean(),
  minimum: Joi.when("isLimit", {
    is: true,
    then: Joi.number().min(0),
    otherwise: Joi.any(),
  }),
  maximum: Joi.when("isLimit", {
    is: true,
    then: Joi.number().min(0),
    otherwise: Joi.any(),
  }),
}).messages({
  "string.empty": "This field is required",
}).unknown();

export default joiResolver(schema);
