// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State as StoreState } from "../../pages/CreateMotivationTemplate/store";
import type { MotivationTemplateFormData } from "../../containers/CreateMotivationTemplate";
import type { State } from "../../reducers/createMotivationTemplate";

// eslint-disable-next-line import/prefer-default-export
export const getFormData: OutputSelector<StoreState, *, MotivationTemplateFormData> = createSelector(
  (state: StoreState): State => state.motivationTemplate,
  (form: State): MotivationTemplateFormData => form.toObject()
);
