// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import TableActions, { type TableActionsProps } from "../../containers/TablesActions";
import List from "../../components/PwaIconsList";
import Notification from "../../components/Notification";
import { PWA_ICONS_LIST_TABLE } from "../../helpers/constants";
import { changeIsActiveSaga, deleteSaga } from "../../actions/tableActions";

const actionsProps: TableActionsProps<typeof PWA_ICONS_LIST_TABLE> = {
  tableKey: PWA_ICONS_LIST_TABLE,
  editPath: "/pwaIcons/edit/:id",
  onDelete: deleteSaga,
  onChangeIsActive: changeIsActiveSaga,
};

const PwaIconsList: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="PwaIcons">
    <Provider store={configureStore()}>
      <List TableActions={<TableActions {...actionsProps} />} />
      <Notification />
    </Provider>
  </DocumentTitle>
);

export default PwaIconsList;
