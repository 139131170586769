/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put, select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { MOTIVATION_TEMPLATE_FORM, SAVE_MOTIVATION_TEMPLATE_SAGA } from "../../helpers/constants";
import setLoading from "../../actions/loading/actions";
import { saveMotivationTemplate } from "../../services/motivationTemplateApi";
import { convertModifiersToServer } from "../../containers/Modifiers";
import { getFormData } from "../../selectors/motivationTemplates";
import { getModifiers } from "../../selectors/modifiers";
import type { MotivationTemplateFormData } from "../../containers/CreateMotivationTemplate";
import type { Modifier, ModifiersStateData } from "../../containers/Modifiers";
import externalRedirect from "../../components/App/ExternalRedirect";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(MOTIVATION_TEMPLATE_FORM, true));

    // eslint-disable-next-line no-unused-vars
    const { type, motivationContents, ...data }: MotivationTemplateFormData = yield select(getFormData);
    const modifiersState: ModifiersStateData = yield select(
      (state) => getModifiers(state, type)
    );

    const modifiers : Modifier = convertModifiersToServer({ modifiers: modifiersState, type });

    yield call(saveMotivationTemplate, { ...data, type, modifiers });

    externalRedirect({ to: "/motivationTemplates" });
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to save", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(MOTIVATION_TEMPLATE_FORM, false));
  }
}

export default function* watchSaveFormDataSaga(): Saga<void> {
  yield takeEvery(SAVE_MOTIVATION_TEMPLATE_SAGA, (makeFetch: Function));
}
