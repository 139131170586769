/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, type Node, useEffect } from "react";
import {
  Loader,
  SetupCard,
  GridUc,
} from "@fas/ui-core";
import {
  Container, Grid, Typography, Switch, Box, Link,
} from "@mui/material";
import weakKey from "weak-key";
import { useSelector, useDispatch } from "react-redux";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import CardDivider from "../../components/CardDivider";
import type { MessageGroupListItem } from "../../components/MessageGroupsList/types/MessageGroupsList.types";
import type { SegmentListItem } from "../../components/SegmentsList/types/SegmentsList.types";
import { FORM_KEY_SCHEDULE, GET_FORM_DATA_LOADING } from "../../helpers/constants";
import externalRedirect from "../../components/App/ExternalRedirect";
import NamedItem from "../../components/NamedItem";
import ViewSegment from "../../components/Segments/ViewSegment";
import Collapse from "../../components/Collapse";
import { useLoading } from "../../hooks";
import type { ScheduleFormData } from "../CreateSchedule";
import type { State } from "../../pages/SegmentView";
import { strategiesList, type Strategy } from "../../helpers/messageGroup";
import type { BrowserLanguage } from "../CreateMessageGroup";

type Classes = {
  root: string,
}

export const useStyles: () => Classes = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
}));

const getItemEditPath: (string) => string = (id) => `/schedules/edit/${id}`;

const SegmentView: StatelessFunctionalComponent<{}> = () => {
  const dispatch = useDispatch();
  const {
    id,
    name,
    isActive,
    webPushMessageGroups,
    webPushSegments,
    queueType,
  }: ScheduleFormData = useSelector((state: State) => getFormData(state, FORM_KEY_SCHEDULE));
  const classes: Classes = useStyles();
  const { id: scheduleId }: { id: ?string } = useParams();

  const isLoading: boolean = useLoading(GET_FORM_DATA_LOADING);

  useEffect(() => {
    if (scheduleId) {
      dispatch(getFormDataSaga(FORM_KEY_SCHEDULE, scheduleId));
    }
  }, []);

  return (
    <>
      {isLoading && (
        <Loader loading size={35} mt={37} />
      )}
      {!isLoading && (
        <Container maxWidth="md">
          <SetupCard
            title="Schedule Info"
            showSecondaryBtn
            secondaryBtnText="Edit"
            onSecondaryBtnClick={() => {
              externalRedirect({ to: getItemEditPath(id), target: "_blank" });
            }}
          >
            <Box pb={3} width="100%" className={classes.root}>
              <NamedItem name="Id" value={id} />
              <NamedItem name="Name" value={name} />
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">isActive</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Switch checked={isActive} disabled />
                </Grid>
              </GridUc>
              <NamedItem name="Queue Type" value={queueType} />
            </Box>
          </SetupCard>
          <SetupCard title="Segments">
            {webPushSegments.map((segment: SegmentListItem, index: number): Node => (
              <Box width="100%" key={weakKey(segment)} className={classes.root}>
                <NamedItem name="Id" value={segment.id || ""} />
                <GridUc
                  mt={3}
                  px={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textSecondary">Name</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Link
                      href={`/segments/view/${segment.id || ""}`}
                      target="_blank"
                      underline="hover"
                    >
                      {segment.name}
                    </Link>
                  </Grid>
                </GridUc>
                <GridUc
                  mt={3}
                  px={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textSecondary">isActive</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Switch checked={segment.isActive} disabled />
                  </Grid>
                </GridUc>
                <GridUc
                  mt={3}
                  px={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textSecondary">Criteria</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <ViewSegment {...segment} />
                  </Grid>
                </GridUc>
                <Box mt={3}>
                  <CardDivider visible={index < webPushSegments.length - 1} />
                </Box>
              </Box>
            ))}
          </SetupCard>
          <SetupCard title="Message Groups">
            {webPushMessageGroups.map((messageGroup: MessageGroupListItem, index: number): Node => (
              <Box width="100%" key={weakKey(messageGroup)} className={classes.root}>
                <NamedItem name="Id" value={messageGroup.id || ""} />
                <GridUc
                  mt={3}
                  px={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textSecondary">Name</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Link
                      href={`/messageGroups/view/${messageGroup.id || ""}`}
                      target="_blank"
                      underline="hover"
                    >
                      {messageGroup.name}
                    </Link>
                  </Grid>
                </GridUc>
                <GridUc
                  mt={3}
                  px={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textSecondary">isActive</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Switch checked={messageGroup.isActive} disabled />
                  </Grid>
                </GridUc>
                <NamedItem name="Placements" value={messageGroup.placements} />
                <NamedItem
                  name="Sending strategy"
                  value={(strategiesList
                  // $FlowFixMe
                    .find((item: Strategy): boolean => item.value === messageGroup.WPSendStrategy) || {}).label || ""}
                />
                <GridUc
                  mt={3}
                  px={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textSecondary">Browser Languages</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Collapse
                    // $FlowFixMe
                      list={messageGroup.browserLanguages.map(
                        (item: BrowserLanguage): {name: string} => ({ name: item.language })
                      )}
                      limit={2}
                      showMoreDisabled={false}
                    />
                  </Grid>
                </GridUc>
                <Box mt={3}>
                  <CardDivider visible={index < webPushMessageGroups.length - 1} />
                </Box>
              </Box>
            ))}
          </SetupCard>
        </Container>
      )}
    </>
  );
};

export default SegmentView;
