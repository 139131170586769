// @flow
import type { ToUI } from "../..";
import type {
  UIRule,
  ServerRule,
  UIOperatorList,
} from "../types";

export default class ExistsBased implements ToUI<UIRule, ServerRule> {
  rule: ServerRule;

  constructor(rule: ServerRule) {
    this.rule = rule;
  }

  convert(): UIRule {
    const field = Object.keys(this.rule)[0];
    const label = field;

    const serverRule = this.rule[field];
    const serverValue = serverRule[Object.keys(serverRule)[0]];

    const operator: UIOperatorList = "exists";
    const value: string = serverValue ? "yes" : "no";
    const type: string = "string";

    return {
      field,
      label,
      type,
      operator,
      value,
    };
  }
}
