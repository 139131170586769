// @flow
import {
  GET_PWA_OFFLINE_PAGE_SAGA,
  SAVE_PWA_OFFLINE_PAGE_SAGA,
  SET_PWA_OFFLINE_PAGE,
  SET_PWA_OFFLINE_IS_ACTIVE,
  SET_PWA_OFFLINE_NAME,
  SET_PWA_OFFLINE_BROWSER_LANGUAGES,
  SET_PWA_OFFLINE_HEAD, SET_PWA_OFFLINE_BODY,
} from "../../helpers/constants";
import type {
  RawPwaOfflinePage,
} from "../../components/PwaOfflinePagesList";
import type { RawBrowserLanguages } from "../../containers/PwaMotivationContentList";

export type GetPwaOfflinePageSaga = {|
  type: typeof GET_PWA_OFFLINE_PAGE_SAGA,
  id: string,
|};

export type SetPwaOfflinePage = {|
  type: typeof SET_PWA_OFFLINE_PAGE,
  pwaOffline: RawPwaOfflinePage,
|};

export type SavePwaOfflinePageSaga = {|
  type: typeof SAVE_PWA_OFFLINE_PAGE_SAGA,
|};

export type SetPwaOfflineIsActive = {|
  type: typeof SET_PWA_OFFLINE_IS_ACTIVE,
  isActive: boolean,
|}

export type SetPwaOfflineName = {| type: typeof SET_PWA_OFFLINE_NAME, name: string |};

export type SetPwaOfflineBrowserLanguages = {|
  type: typeof SET_PWA_OFFLINE_BROWSER_LANGUAGES,
  browserLanguages: Array<RawBrowserLanguages>,
|};

export type SetPwaOfflineHead = {|
  type: typeof SET_PWA_OFFLINE_HEAD,
  head: string,
|};

export type SetPwaOfflineBody = {|
  type: typeof SET_PWA_OFFLINE_BODY,
  body: string,
|};

export type Actions =
  | GetPwaOfflinePageSaga
  | SavePwaOfflinePageSaga
  | SetPwaOfflinePage
  | SetPwaOfflineIsActive
  | SetPwaOfflineName
  | SetPwaOfflineBrowserLanguages
  | SetPwaOfflineHead
  | SetPwaOfflineBody

export const getPwaOfflinePageSaga: (id: string) => GetPwaOfflinePageSaga = (id: string) => ({
  type: GET_PWA_OFFLINE_PAGE_SAGA,
  id,
});

export const savePwaOfflinePageSaga: () => SavePwaOfflinePageSaga = () => ({ type: SAVE_PWA_OFFLINE_PAGE_SAGA });

export const setPwaOfflinePage: (pwaOffline: RawPwaOfflinePage) => SetPwaOfflinePage = (pwaOffline) => ({
  type: SET_PWA_OFFLINE_PAGE,
  pwaOffline,
});

export const setPwaOfflineIsActive: (isActive: boolean) => SetPwaOfflineIsActive = (
  isActive: boolean
) => ({
  type: SET_PWA_OFFLINE_IS_ACTIVE,
  isActive,
});

export const setPwaOfflineName: (name: string) => SetPwaOfflineName = (name: string) => ({
  type: SET_PWA_OFFLINE_NAME,
  name,
});

export const setPwaOfflineBrowserLanguages: (
  browserLanguages: Array<RawBrowserLanguages>
) => SetPwaOfflineBrowserLanguages = (browserLanguages: Array<RawBrowserLanguages>) => ({
  type: SET_PWA_OFFLINE_BROWSER_LANGUAGES,
  browserLanguages,
});

export const setPwaOfflineHead: (head: string) => SetPwaOfflineHead = (head: string) => ({
  type: SET_PWA_OFFLINE_HEAD,
  head,
});

export const setPwaOfflineBody: (body: string) => SetPwaOfflineBody = (body: string) => ({
  type: SET_PWA_OFFLINE_BODY,
  body,
});
