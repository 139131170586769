// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { IPP, IppSaveBody } from "../../components/IppList/types/IppList.types";

export type IppRequestBody = $Diff<
  {
    ...IppSaveBody,
  },
  { id: string }
>;

export const getIPPList: (number, number, { [key: string]: mixed }) => PromiseResponse<mixed, {
  data: IPP[],
  totalCount: number
}> = (
  page,
  limit,
  filterList
) => requestService.get(environment.endpoints.get.ippList, {
  params: {
    ...filterList,
    page,
    limit,
  },
});

export const updateIpp = (
  id: string,
  body: IppRequestBody
): PromiseResponse<mixed, { data: boolean }> => requestService.put(environment.endpoints.put.ipp.replace("{id}", id), body);

export const saveIpp = (
  item: IppRequestBody
): PromiseResponse<mixed, { data: boolean }> => requestService.post(environment.endpoints.post.ipp, item);

export const getIpp = (id: string): PromiseResponse<mixed, { data: IPP }> => requestService.get(environment.endpoints.get.ippById.replace("{id}", id));

export const deleteIpp = (
  idList: Array<string>
): PromiseResponse<mixed, { data: 1 }> => requestService.delete(environment.endpoints.delete.ipp, { data: { idList } });
