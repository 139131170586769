// @flow
const getPipePosition: (inputName: string) => number | null = (inputName) => {
  const input: HTMLElement | null = document.getElementById(inputName);
  if (input !== null && input instanceof HTMLInputElement) {
    return input.selectionStart;
  }

  return null;
};

export default getPipePosition;
