// @flow
/* eslint-disable import/max-dependencies */
import {
  FORM_KEY_TARGETING,
  FORM_KEY_MESSAGE,
  FORM_KEY_CAMPAIGN,
  FORM_KEY_SCHEDULE,
  FORM_KEY_MESSAGE_GROUP,
} from "../../helpers/constants";
import targeting from "../targetingsApi";
import messagesApi from "../messageApi";
import campaignApi from "../campaignApi";
import scheduleApi from "../scheduleApi";
import messageGroupApi from "../messageGroupApi";

export const apiMapSaveFormData = {
  [FORM_KEY_TARGETING]: targeting.saveTargetingForm,
  [FORM_KEY_MESSAGE]: messagesApi.saveMessageForm,
  [FORM_KEY_SCHEDULE]: scheduleApi.saveScheduleForm,
  [FORM_KEY_MESSAGE_GROUP]: messageGroupApi.saveMessageGroupForm,
};

export const apiMapGetFormData = {
  [FORM_KEY_TARGETING]: targeting.getTargetingFormData,
  [FORM_KEY_MESSAGE]: messagesApi.getMessageById,
  [FORM_KEY_CAMPAIGN]: campaignApi.getCampaignById,
  [FORM_KEY_SCHEDULE]: scheduleApi.getScheduleById,
  [FORM_KEY_MESSAGE_GROUP]: messageGroupApi.getMessageGroupFormData,
};
