// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import List from "../../containers/TargetingsList";
import Notification from "../../components/Notification";
import TableActions, { type TableActionsProps } from "../../containers/TablesActions";
import { TARGETINGS_LIST_TABLE } from "../../helpers/constants";
import { deleteSaga, changeIsActiveSaga } from "../../actions/tableActions";

const actionsProps: TableActionsProps<typeof TARGETINGS_LIST_TABLE> = {
  tableKey: TARGETINGS_LIST_TABLE,
  editPath: "/targetings/edit/:id",
  viewPath: "/targetings/view/:id",
  onDelete: deleteSaga,
  onChangeIsActive: changeIsActiveSaga,
  disableDelete: false,
};

const store = configureStore();

const TargetingsList: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="Targetings">
    <Provider store={store}>
      <List Actions={<TableActions {...actionsProps} />} />
      <Notification />
    </Provider>
  </DocumentTitle>
);

export default TargetingsList;
