// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record } from "immutable";
import type { Reducer } from "redux";
import type { Actions, SetLimitStatList, ChangeLimitStatLoading } from "../../actions/limitStatList";
import {
  SET_LIMIT_STAT_LIST,
  CHANGE_LIMIT_STAT_LOADING,
} from "../../helpers/constants";

export type LimitStatItem = {
  id: string | null,
  date: string,
  WebPushSegment: string,
  minimum: number,
  maximum: number,
};

export type DefaultState = {|
  list: LimitStatItem[],
  loading: boolean,
|};

const defaultValues: DefaultState = {
  list: [],
  loading: false,
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initLimitStatListState(): State {
  return initialState;
}

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIMIT_STAT_LIST: {
      const { list }: SetLimitStatList = action;
      return state.set("list", list);
    }
    case CHANGE_LIMIT_STAT_LOADING: {
      const { loading }: ChangeLimitStatLoading = action;
      return state.set("loading", loading);
    }
    default:
      return state;
  }
};

export default reducer;
