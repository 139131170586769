/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { StatelessFunctionalComponent, Element } from "react";
import {
  Table,
  IsActiveLabel,
} from "@fas/ui-core";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import type { PwaIconListItem, Classes } from "./types/PwaIconsList.types";
import Image from "../MessageView/Image";
import { PWA_ICONS_LIST_TABLE } from "../../helpers/constants";
import { getPwaIconsListSaga } from "../../actions/pwaIconsList";
import externalRedirect from "../App/ExternalRedirect";
import { compareById } from "../../services/utils";

type Props = {
  TableActions?: Element<*>,
};

const useStyles: () => Classes = makeStyles({
  statusColumn: {
    width: "50px",
  },
  fixedColumn: {
    width: "25%",
  },
  image: {
    maxHeight: "50px",
    maxWidth: "50px",
  },
});

const onCreate: () => null = () => externalRedirect({ to: "/pwaIcons/create", target: "_self" });

const PWAIcons: StatelessFunctionalComponent<Props> = ({ TableActions }) => {
  const { statusColumn, image, fixedColumn }: Classes = useStyles();
  const dispatch: <A>(A) => A = useDispatch();
  const tableProps: TableProps = useTable(PWA_ICONS_LIST_TABLE);
  const handleLoad = () => dispatch(getPwaIconsListSaga());

  const columns = [
    {
      label: "Status",
      field: "isActive",
      className: statusColumn,
      render: IsActiveLabel,
    },
    {
      label: "ID",
      field: "id",
      className: fixedColumn,
    },
    {
      label: "Icon Preview",
      field: "path",
      className: fixedColumn,
      render: (row: PwaIconListItem): Element<typeof Image> => (
        <Image
          className={image}
          src={row.path}
          fallbackSrc="/default.png"
          alt={row.name}
        />
      ),
    },
    {
      label: "Name",
      field: "name",
      searchable: true,
      className: fixedColumn,
    },
    {
      label: "Short Name",
      field: "shortname",
      searchable: true,
      className: fixedColumn,
    },
  ];

  return (
    <Box m={2}>
      <Table
        {...tableProps}
        title="Pwa icon"
        columns={columns}
        onLoad={handleLoad}
        onCreate={onCreate}
        compareRows={compareById}
        rowSelectAvailable={() => true}
        allSelectAvailable={() => true}
        Actions={TableActions}
      />
    </Box>
  );
};

export default PWAIcons;
