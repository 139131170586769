// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type {
  StoreWithPwaIcon,
  StoreWithPwaIconLoading,
  StoreWithPwaIconModals,
} from "./types/pwaIcon.types";
import type { State, PwaIconFormData } from "../../reducers/pwaIcon";

export const getIsSaved: OutputSelector<StoreWithPwaIconLoading, *, boolean> = createSelector(
  (state: StoreWithPwaIconLoading): boolean => state.loading.get("isSaved", false),
  (isSaved: boolean): boolean => isSaved
);

export const getIsUploadImageOpen: OutputSelector<StoreWithPwaIconModals, *, boolean> = createSelector(
  (state: StoreWithPwaIconModals): boolean => state.modals.get("isUploadImageOpen", false),
  (isUploadImageOpen: boolean): boolean => isUploadImageOpen
);

export const getIsPreviewImageOpen: OutputSelector<StoreWithPwaIconModals, *, boolean> = createSelector(
  (state: StoreWithPwaIconModals): boolean => state.modals.get("isPreviewImageOpen", false),
  (isPreviewImageOpen: boolean): boolean => isPreviewImageOpen
);

export const getPwaIconFormData: OutputSelector<StoreWithPwaIcon, *, PwaIconFormData> = createSelector(
  (state: StoreWithPwaIcon): State => state.pwaIcon,
  // $FlowFixMe
  (data: State): PwaIconFormData => data.toJS()
);
