/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type {
  Dispatch,
  CombinedReducer,
  Store,
} from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import notifications, { initNotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import { initTableState, tableReducer as tables } from "@fas/ui-framework/lib/redux/reducers/table";
import loading, { initLoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import type { TableState } from "@fas/ui-framework/lib/redux/reducers/table";
import type { State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading/reducer";
import mainSaga from "./saga";

import type { PwaIconListItem } from "../../components/PwaIconsList/types/PwaIconsList.types";

import type { Actions as PwaIconsListActions } from "../../actions/pwaIconsList";
import type { Actions as ChoicedPwaIconsActions } from "../../actions/choicedData";
import type { Actions as PwaIconsTableActions } from "../../actions/tableFilters";
import { PWA_ICONS_LIST_TABLE } from "../../helpers/constants";

export type PWAIconsLoadingKeys = typeof PWA_ICONS_LIST_TABLE;

export type State = $ReadOnly<{
  notifications: NotificationsState,
  tables: TableState,
  loading: LoadingState<PWAIconsLoadingKeys>,
}>;

export type Actions = PwaIconsListActions | ChoicedPwaIconsActions<PwaIconListItem> | PwaIconsTableActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  tables,
  loading,
});

export const mapState: () => State = () => ({
  notifications: initNotificationsState(),
  tables: initTableState({
    [PWA_ICONS_LIST_TABLE]: {
      pageSize: 20,
    },
  }),
  loading: initLoadingState({
    [PWA_ICONS_LIST_TABLE]: false,
  }),
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
