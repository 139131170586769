// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, type Node } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Loader,
  SetupCard,
  GridUc,
} from "@fas/ui-core";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Container,
  Switch,
} from "@mui/material";
import { type Theme, makeStyles } from "@mui/styles";
import CodeEditor from "../../components/CodeEditor";
import { MOTIVATION_TEMPLATE_FORM } from "../../helpers/constants";
import {
  POPUP_TYPES,
  type MotivationTemplateFormData,
} from "../CreateMotivationTemplate";
import { getFormData } from "../../selectors/motivationTemplates";
import { getMotivationTemplateSaga } from "../../actions/createMotivationTemplate";
import { useLoading } from "../../hooks/useLoading";
import Modifiers, { HtmlPreviewWithModifiers } from "../Modifiers";
import externalRedirect from "../../components/App/ExternalRedirect";
import NamedItem from "../../components/NamedItem";
import AdditionalInfo from "../../components/AdditionalInfo/AdditionalInfo";

type Classes = {
  root: string
}

const useStyles: () => Classes = makeStyles((theme: Theme): mixed => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
}));

function MotivationTemplateView(): Node {
  const { root }: Classes = useStyles();
  const dispatch = useDispatch();
  const {
    name,
    type,
    isActive,
    html,
    css,
    motivationContents,
  }: MotivationTemplateFormData = useSelector(getFormData);

  const { id: templateId } = useParams();
  const isLoading: boolean = useLoading(MOTIVATION_TEMPLATE_FORM);

  const onGetMotivationTemplate: (string) => void = (id) => {
    dispatch(getMotivationTemplateSaga(id));
  };

  const redirectToEdit: () => void = () => {
    if (templateId) {
      externalRedirect({ to: `/motivationTemplates/edit/${templateId}`, target: "_blank" });
    }
  };

  useEffect(() => {
    if (templateId) {
      onGetMotivationTemplate(templateId);
    }
  }, [templateId]);

  const tabs = POPUP_TYPES.includes(type)
    ? [{
      name: "html-editor",
      label: "html",
      value: html,
      mode: "html",
      readOnly: true,
    },
    {
      name: "css-editor",
      label: "css",
      value: css,
      mode: "css",
      readOnly: true,
    }]
    : [{
      name: "css-editor",
      label: "head",
      value: css,
      mode: "html",
      readOnly: true,
    },
    {
      name: "html-editor",
      label: "body",
      value: html,
      mode: "html",
      readOnly: true,
    }];

  return (
    <Container maxWidth="md">
      <Loader loading={isLoading} withoutWrapper>
        <SetupCard
          title=" Motivation Template info"
          showSecondaryBtn
          secondaryBtnText="Edit"
          onSecondaryBtnClick={redirectToEdit}
        >
          <Box
            width="100%"
            pb={3}
            className={root}
          >
            <NamedItem name="Name" value={name} />
            <NamedItem name="Type" value={type} />
            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">isActive</Typography>
              </Grid>
              <Grid item xs={9}>
                <Switch checked={isActive} disabled />
              </Grid>
            </GridUc>
            <Box px={3}>
              <Box width={1} mb={2} mt={3}>
                <Divider />
              </Box>
              <Box my={1} width={1}>
                <Typography gutterBottom variant="h5" component="h2">
                  Setup
                </Typography>
              </Box>
              <Grid container justifyContent="center">
                <Grid item xs={12} justifyContent="center">
                  <CodeEditor
                    height="400px"
                    tabs={tabs}
                  />
                </Grid>
                <Box mb={2} width={1} display="flex" justifyContent="flex-end">
                  <HtmlPreviewWithModifiers
                    templateType={type}
                    title="Motivation Content preview"
                    data-testid="motivation-content-preview-btn"
                    html={html}
                    css={css}
                  />
                </Box>
              </Grid>

              <Box width={1} mb={2} mt={3}>
                <Divider />
              </Box>

              <Modifiers templateType={type} readOnly />
            </Box>
          </Box>
        </SetupCard>

        <AdditionalInfo usedInMotivationContents={motivationContents} />
      </Loader>
    </Container>
  );
}

export default MotivationTemplateView;
