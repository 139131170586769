// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import withNotifications from "@fas/ui-framework/lib/services/notifications";
import { Notifications } from "@fas/ui-core";
import configureStore from "./store";
import List from "../../containers/WebPushCampaignsList";

const WebpushCampaignsNotifications: StatelessFunctionalComponent<*> = withNotifications(Notifications);

const store = configureStore();

const WebpushCampaignsList: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="WebPush Campaigns">
    <Provider store={store}>
      <List />
      <WebpushCampaignsNotifications />
    </Provider>
  </DocumentTitle>
);

export default WebpushCampaignsList;
