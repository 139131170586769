// @flow
import React, { type StatelessFunctionalComponent } from "react";
import NamedItem from "../NamedItem";
import { strategiesList } from "../../helpers/messageGroup";

type Props = {
  strategy: number,
};

const MessageGroupSendingStartegy: StatelessFunctionalComponent<Props> = ({
  strategy,
}: Props) => {
  const selected = strategiesList.find((item) => item.value === strategy);
  return (
    <React.Fragment key={strategy}>
      {selected && <NamedItem name="Strategy" value={selected.label} />}
    </React.Fragment>
  );
};

export default MessageGroupSendingStartegy;
