// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, type Node } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SetupCard, GridUc, Loader,
} from "@fas/ui-core";
import {
  Container, Grid, Box, Switch, Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import makeStyles from "@mui/styles/makeStyles";
import { useLoading } from "../../hooks";
import { FORM_KEY_MESSAGE, GET_FORM_DATA_LOADING } from "../../helpers/constants";
import CardDivider from "../../components/CardDivider";
import MessagePreview from "../../components/MessagePreview";
import NamedItem from "../../components/NamedItem";
import externalRedirect from "../../components/App/ExternalRedirect";
import type { MessageFormData } from "../CreateMessage";

type Classes = { root: string };

const useStyles: () => Classes = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
    boxSizing: "border-box",
  },
}));

const MessageView = (): Node => {
  const classes: Classes = useStyles();
  const dispatch: <A>(A) => A = useDispatch();
  const { id } = useParams();

  const {
    name,
    clickUrl,
    body,
    title,
    tag,
    image,
    icon,
    badge,
    timeToLive,
    customAttributes,
    isActive,
    actions,
    priority,
  }: MessageFormData = useSelector((state): MessageFormData => getFormData(state, FORM_KEY_MESSAGE));

  useEffect(() => {
    if (id) {
      dispatch(getFormDataSaga(FORM_KEY_MESSAGE, id));
    }
  }, [dispatch, id]);

  const getItemEditPath: () => void = () => {
    if (id) {
      externalRedirect({ to: `/messages/edit/${id}`, target: "_blank" });
    }
  };

  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING);

  return (
    <>
      {loading && (
        <Loader loading size={35} mt={37} />
      )}
      {!loading && (
        <Container maxWidth="md">
          <SetupCard
            title="Messages info"
            showSecondaryBtn
            secondaryBtnText="Edit"
            onSecondaryBtnClick={getItemEditPath}
          >
            <Box width="100%" pb={3} className={classes.root}>
              <NamedItem name="Name" value={name} />
              <NamedItem name="Id" value={String(id)} />
              <NamedItem name="Priority" value={priority} />
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">isActive</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Switch checked={isActive} disabled />
                </Grid>
              </GridUc>
            </Box>
          </SetupCard>

          <SetupCard title="Preview Android / Chrome">
            <Box width="100%" p={3} className={classes.root}>
              <MessagePreview
                title={title}
                body={body}
                image={image}
                icon={icon}
                badge={badge}
                actions={actions}
              />
            </Box>
          </SetupCard>
          <SetupCard title="Configuration">
            <Box width="100%" pb={3} className={classes.root}>
              <NamedItem name="Time to live" value={String(timeToLive)} />
              <NamedItem name="Default click url" value={clickUrl} />
              {customAttributes && (
                <NamedItem name="Custom attributes" value={customAttributes} />
              )}
              <NamedItem name="Message title" value={title} />
              <NamedItem name="Message body" value={body} />
              <NamedItem name="Message image" value={image} />
              <NamedItem name="Icon" value={icon} />
              <NamedItem name="Badge" value={badge} />
              {tag && <NamedItem name="Tags" value={tag} />}
            </Box>
          </SetupCard>

          {actions.length > 0 && (
            <SetupCard title="Additional Actions">
              <Box width="100%" className={classes.root}>
                {actions.map((item, index) => (
                  <Box key={`${item.title}${item.action}`}>
                    <Box ml={3} mt={2}>
                      <Typography variant="h6" color="textSecondary">{`Action ${index + 1}`}</Typography>
                    </Box>
                    <NamedItem name="Title" value={item.title} />
                    <NamedItem name="Actions" value={item.action} />
                    <Box mt={3}>
                      <CardDivider visible={index < actions.length - 1} />
                    </Box>
                  </Box>
                ))}
              </Box>
            </SetupCard>
          )}
        </Container>
      )}
    </>
  );
};

export default MessageView;
