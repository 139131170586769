// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import DocumentTitle from "react-document-title";
import { Provider } from "react-redux";
import Notification from "../../components/Notification";
import configureStore from "./store";
import PwaOfflinePagesList from "../../components/PwaOfflinePagesList";
import TableActions, { type TableActionsProps } from "../../containers/TablesActions";
import { PWA_OFFLINE_LIST_TABLE } from "../../helpers/constants";
import { changeIsActiveSaga, deleteSaga } from "../../actions/tableActions";

const actionsProps: TableActionsProps<typeof PWA_OFFLINE_LIST_TABLE> = {
  tableKey: PWA_OFFLINE_LIST_TABLE,
  editPath: "/pwaOfflinePages/edit/:id",
  viewPath: "/pwaOfflinePages/view/:id",
  onDelete: deleteSaga,
  onChangeIsActive: changeIsActiveSaga,
};

const PwaMotivationContentList: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="PWA Offline pages">
    <Provider store={configureStore()}>
      <PwaOfflinePagesList Actions={<TableActions {...actionsProps} />} />
      <Notification />
    </Provider>
  </DocumentTitle>
);

export default PwaMotivationContentList;
