/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  debounce,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
} from "@fas/ui-framework/lib/redux/selectors/table";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  GET_MOTIVATION_CONTENTS_SAGA,
  MOTIVATION_CONTENTS_TABLE,
} from "../../helpers/constants";
import MotivationContentApi from "../../services/motivationContentApi";
import type { MotivationContent } from "../../containers/MotivationContentList";

export function* makeFetch(): Saga<void> {
  yield put(setLoading<typeof MOTIVATION_CONTENTS_TABLE>(MOTIVATION_CONTENTS_TABLE, true));

  const page: number = yield select(getTablePage, MOTIVATION_CONTENTS_TABLE);
  const pageSize: number = yield select(getTablePageSize, MOTIVATION_CONTENTS_TABLE);
  const filters: Filters = yield select(getTableFilters, MOTIVATION_CONTENTS_TABLE);

  const params: { page: number, pageSize: number, filters?: Filters } = { page, pageSize, filters };

  try {
    const { data: response }: Response<{ data: MotivationContent[], totalCount: number }> = yield call(
      MotivationContentApi.getMotivationContentsList,
      params
    );

    yield put(setTableData(MOTIVATION_CONTENTS_TABLE, response.data));
    yield put(changeTableItemsTotalAmount(MOTIVATION_CONTENTS_TABLE, response.totalCount));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
    // eslint-disable-next-line
    console.error(err);
  }

  yield put(setLoading<typeof MOTIVATION_CONTENTS_TABLE>(MOTIVATION_CONTENTS_TABLE, false));
}

export default function* watchFetchMotivationContentsListSaga(): Saga<void> {
  yield debounce(1000, GET_MOTIVATION_CONTENTS_SAGA, (makeFetch: Function));
}
