// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { IdList } from "../generalApi/types/General.type";
import type { SegmentSaveDataShort, SegmentSaveData } from "../../containers/CreateSegment";
import type { Segment } from "../../components/SegmentsList/types/SegmentsList.types";

export const getSegmentsList: ({ page: number, limit: number, filters?: Object}) => PromiseResponse<
  mixed,
  { data: Segment[], totalCount: number }
> = (params) => requestService.get(environment.endpoints.get.segmentsList, {
  params: {
    page: params.page,
    limit: params.limit,
    ...params.filters,
  },
});

export const getLimitStatList = (params: {
  page: number,
  limit: number,
  WebPushSegments?: string,
  from: number,
  to: number,
  group: string
}): PromiseResponse<mixed> => requestService
  .get(environment.endpoints.get.limitIncidents, {
    params: { ...params },
  });

export const getQueryBuilderColumnsConfigs: () => PromiseResponse<
  mixed,
  { data: { [string]: mixed[] } }
> = () => requestService.get(environment.endpoints.get.queryBuilder);

export const getSegmentById = (id: string): PromiseResponse<mixed, { data: Segment }> => requestService
  .get(environment.endpoints.get.segmentById.replace("{id}", id))
  .then(({ data: { data } }) => data[0]);

export const saveSegment = (segment: SegmentSaveDataShort): PromiseResponse<mixed> => requestService
  .post(environment.endpoints.post.segments, { ...segment });

export const deleteSegmentsByIds = (idList: IdList): PromiseResponse<mixed> => requestService
  .delete(environment.endpoints.delete.segments, {
    data: {
      ids: idList,
    },
  });

export const updateSegment = (id: string, segment: SegmentSaveData): PromiseResponse<mixed> => requestService
  .put(environment.endpoints.put.segments.replace("{id}", id), {
    ...segment,
  });
