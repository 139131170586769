// @flow
import { toUIOperatorMapper } from "../..";
import type { ToUI } from "../..";
import type {
  UIRule,
  ServerRule,
  UIOperatorList,
} from "../types";

export default class ManualvalueBased implements ToUI<UIRule, ServerRule> {
  rule: ServerRule;

  constructor(rule: ServerRule) {
    this.rule = rule;
  }

  convert(): UIRule {
    const field = Object.keys(this.rule)[0];
    const label = field;

    const serverRule = this.rule[field];

    // $FlowFixMe
    const operator: UIOperatorList = typeof serverRule === "string" ? "equal" : toUIOperatorMapper[Object.keys(serverRule)[0]];
    const value = typeof serverRule === "string" ? serverRule : serverRule[Object.keys(serverRule)[0]];
    const type: string = "string";

    return {
      field,
      label,
      type,
      operator,
      value,
    };
  }
}
