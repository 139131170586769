/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect, type Node } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Multiselect, Loader } from "@fas/ui-core";
import {
  Grid,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Divider,
  Box,
  Container,
  Paper,
  Typography,
  Button,
  Switch,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";
import {
  type WPFormData,
  type Classes,
  type Errors,
} from "./CreateWPMotivationContent.types";
import HtmlPreviewButton from "../../components/HtmlPreviewButton";
import { getWpFormData, getErrors } from "../../selectors/createWp";
import type { DropdownObj } from "../../helpers/common.types";
import CodeEditor from "../../components/CodeEditor";
import { setWpFormField, saveWpSaga, getWpSaga } from "../../actions/createWp";
import { setModifiers } from "../../actions/modifiers";
import {
  useActions,
  useLoading,
  useDictionary,
  useList,
} from "../../hooks";
import AdditionalInfo from "../../components/AdditionalInfo";
import TemplateBlock from "../../components/TemplateBlock";
import { WP_MOTIV_LOADING } from "../../helpers/constants";
import { type MotivationTemplateType, type MotivationTemplateDropdown } from "../CreateMotivationTemplate";
import {
  convertModifiersToUi,
  type ModifiersStateData,
} from "../Modifiers";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

const nameRowSpacing: number = 3;
const fieldRowSpacing: number = 8;

const useStyles: () => Classes = makeStyles((theme) => ({
  textarea: {
    width: "100%",
    borderRadius: "4px",
    borderColor: "#ccc",
    boxSizing: "border-box",
  },
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
}));

function WpMotivForm(): Node {
  const {
    setWpFormField: onSetWpFormField,
    saveWpSaga: onSaveWp,
    getWpSaga: onGetWp,
    setModifiers: onSetModifiers,
  } = useActions({
    setWpFormField,
    getWpSaga,
    saveWpSaga,
    setModifiers,
  });

  const classes: Classes = useStyles();

  const {
    name,
    BrowserLanguages,
    initTimeout,
    visualisationType,
    behaviorToAction,
    inline_html,
    inline_css,
    head,
    body,
    usedInCampaigns,
    isTemplate,
    motivationTemplates,
  }: WPFormData = useSelector(getWpFormData);
  const errors: Errors = useSelector(getErrors);

  const { id } = useParams();
  const isLoading: boolean = useLoading(WP_MOTIV_LOADING);

  const { list: languages }: { list: DropDownObjItemType[] } = useDictionary("browserLanguages");
  const visualizationTypes: DropDownObjItemType[] = useList("visualizationTypes");
  const behaviorToActions: DropDownObjItemType[] = useList("behaviorToActions");
  const isSystem: boolean = visualisationType === "system";

  useEffect(() => {
    if (id) {
      onGetWp(id);
    }
  }, [id]);

  const handleChangeTemplate: (MotivationTemplateType, MotivationTemplateDropdown) => void = (
    type, template
  ) => {
    const modifiers: ModifiersStateData = convertModifiersToUi({ modifiers: template.modifiers, type });
    onSetModifiers(type, modifiers);
    onSetWpFormField({
      motivationTemplates: {
        ...motivationTemplates,
        // $FlowFixMe
        [type]: template,
      },
    });
  };

  return (
    <Container maxWidth="md">
      <Loader loading={isLoading} withoutWrapper>
        <Paper className={classes.container}>
          <Box my={3} ml={4}>
            <Typography gutterBottom variant="h5" component="h2">
              WebPush Motivation Content
            </Typography>
          </Box>

          <Grid container rowSpacing={2}>
            <Grid container item justifyContent="center" alignItems="center">
              <Grid item xs={nameRowSpacing}>
                <InputLabel htmlFor="name">Name</InputLabel>
              </Grid>
              <Grid item xs={fieldRowSpacing}>
                <TextField
                  size="small"
                  name="name"
                  variant="outlined"
                  placeholder="Example: external_motiv_content_wp_tag_en"
                  fullWidth
                  value={name}
                  onChange={(e) => onSetWpFormField({ name: e.target.value })}
                  data-testid="name"
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                />
              </Grid>
            </Grid>

            <Grid container item justifyContent="center" alignItems="center">
              <Grid item xs={nameRowSpacing}>
                <InputLabel htmlFor="BrowserLanguages">
                  Browser Languages
                </InputLabel>
              </Grid>
              <Grid item xs={fieldRowSpacing}>
                <Multiselect
                  type="simpleMultiple"
                  // $FlowFixMe
                  selectedOptions={BrowserLanguages}
                  options={languages}
                  onChange={(selectedBL) => {
                    onSetWpFormField({ BrowserLanguages: selectedBL });
                  }}
                  placeholder="Type languages or select from list"
                  enableAddAllBtn
                  clearIcon={<Close fontSize="small" />}
                  enableAddMatchesBtn
                  data-testid="browserLanguages"
                  error={{ isError: Boolean(errors.BrowserLanguages), helperText: errors.BrowserLanguages }}
                />
              </Grid>
            </Grid>

            <Grid container item justifyContent="center" alignItems="center">
              <Grid item xs={nameRowSpacing}>
                <InputLabel htmlFor="initTimeout">Init Timeout</InputLabel>
              </Grid>
              <Grid item xs={fieldRowSpacing}>
                <TextField
                  size="small"
                  name="initTimeout"
                  variant="outlined"
                  placeholder="Example: 100"
                  fullWidth
                  type="number"
                  value={initTimeout}
                  onChange={(e) => onSetWpFormField({ initTimeout: e.target.valueAsNumber })}
                  inputProps={{
                    min: "0",
                    step: "1",
                  }}
                  data-testid="initTimeout"
                />
              </Grid>
            </Grid>

            <Grid container item justifyContent="center" alignItems="center">
              <Grid item xs={nameRowSpacing}>
                <InputLabel htmlFor="behaviorToAction">
                  Behavior To Action
                </InputLabel>
              </Grid>
              <Grid item xs={fieldRowSpacing}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <Select
                    name="behaviorToAction"
                    displayEmpty
                    value={behaviorToAction}
                    onChange={(e) => onSetWpFormField({ behaviorToAction: e.target.value })}
                    inputProps={{
                      "data-testid": "behaviorToAction",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Example: automatic
                    </MenuItem>
                    {behaviorToActions.map(({ label, value }: DropdownObj): mixed => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item justifyContent="center" alignItems="center">
              <Grid item xs={nameRowSpacing}>
                <InputLabel htmlFor="visualisation-type">
                  Visualisation Type
                </InputLabel>
              </Grid>
              <Grid item xs={fieldRowSpacing}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <Select
                    data-testid="visualization-type"
                    id="visualization-type"
                    value={visualisationType}
                    onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetWpFormField({
                      visualisationType: e.target.value,
                    })}
                    placeholder="Select from list"
                  >
                    {visualizationTypes.map(({ label, value }: DropdownObj): mixed => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item justifyContent="center" alignItems="center">
              <Grid container item justifyContent="center" alignItems="center">
                <Grid item xs={nameRowSpacing}>
                  <InputLabel htmlFor="back-offer">Is Template</InputLabel>
                </Grid>
                <Grid item xs={fieldRowSpacing}>
                  <Switch
                    id="back-offer"
                    data-testid="is-template"
                    color="primary"
                    checked={isTemplate}
                    onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetWpFormField({
                      isTemplate: e.target.checked,
                    })}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {!isSystem && (
              <>
                <Box width={1} mb={2} mt={3}>
                  <Divider />
                </Box>

                <Box my={1} ml={4}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Motivation Content
                  </Typography>
                </Box>

                {!isTemplate && (
                  <Grid container justifyContent="center">
                    <Grid item xs={11} justifyContent="center">
                      <CodeEditor
                        height="400px"
                        tabs={[
                          {
                            name: "html-editor",
                            label: "html",
                            value: inline_html,
                            mode: "html",
                            onChange: (value: string) => {
                              onSetWpFormField({ inline_html: value });
                            },
                          },
                          {
                            name: "css-editor",
                            label: "css",
                            value: inline_css,
                            mode: "css",
                            onChange: (value: string) => {
                              onSetWpFormField({ inline_css: value });
                            },
                          },
                        ]}
                      />
                      <HtmlPreviewButton
                        title="Preview HTML + CSS code"
                        data-testid="html-css-preview-btn"
                        htmlCode={inline_html}
                        cssCode={inline_css}
                      />
                    </Grid>
                  </Grid>
                )}

                {isTemplate && (
                  <Box px={4} width={1}>
                    <TemplateBlock
                      handleChangeTemplate={handleChangeTemplate}
                      template={motivationTemplates.WPMotivationPopUp}
                      type="WPMotivationPopUp"
                      error={errors.WPMotivationPopUp}
                    />
                  </Box>
                )}

                {visualisationType === "inline_without_manifest" && (
                  <>
                    <Box my={1} ml={4}>
                      <Typography gutterBottom variant="h5" component="h2">
                        Subscription Page
                      </Typography>
                    </Box>

                    {!isTemplate && (
                      <Grid container justifyContent="center">
                        <Grid item xs={11} justifyContent="center">
                          <CodeEditor
                            height="400px"
                            tabs={[
                              {
                                name: "head",
                                label: "head html",
                                value: head,
                                mode: "html",
                                onChange: (value: string) => {
                                  onSetWpFormField({ head: value });
                                },
                              },
                              {
                                name: "body",
                                label: "body html",
                                value: body,
                                mode: "html",
                                onChange: (value: string) => {
                                  onSetWpFormField({ body: value });
                                },
                              },
                            ]}
                          />
                          <HtmlPreviewButton
                            title="Preview HEAD + BODY code"
                            data-testid="html-css-preview-btn"
                            htmlCode={head + body}
                            cssCode=""
                          />
                        </Grid>
                      </Grid>
                    )}

                    {isTemplate && (
                      <Box px={4} width={1}>
                        <TemplateBlock
                          handleChangeTemplate={handleChangeTemplate}
                          template={motivationTemplates.WPSubscriptionPage}
                          type="WPSubscriptionPage"
                          error={errors.WPSubscriptionPage}
                        />
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </Paper>

        <AdditionalInfo usedInCampaigns={usedInCampaigns} />

        <Box display="flex" justifyContent="flex-end" mb={5}>
          <Button
            type="button"
            size="medium"
            variant="contained"
            color="primary"
            onClick={onSaveWp}
            data-testid="save-button"
            disabled={isLoading}
          >
            Save
          </Button>
        </Box>
      </Loader>
    </Container>
  );
}

export default WpMotivForm;
