// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { TextFieldForm, SelectForm, ShowByValue } from "@fas/ui-core/lib/Form";
import { SetupCard } from "@fas/ui-core";
import StepControl from "../StepControl";
import { useList } from "../../hooks";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

const ScheduleInfoStep: StatelessFunctionalComponent<{}> = () => {
  const queueTypes: DropDownObjItemType[] = useList("queueTypes");
  const hhValues: DropDownObjItemType[] = useList("hhValues");
  const mmValues: DropDownObjItemType[] = useList("mmValues");

  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={8}>
          <SetupCard title="General Info">
            <Grid container>
              <Grid item container alignItems="center">
                <TextFieldForm name="name" label="Schedule name" />
              </Grid>
              <Grid item container alignItems="center">
                <SelectForm name="queueType" label="Queue Type" options={queueTypes} />
              </Grid>
              <ShowByValue name="queueType" compare={(type: string): boolean => type === "client"}>
                <Box display="flex" width={1} gap={1} alignItems="center">
                  <Box mr={4}>
                    <Typography>Custom schedule (hh:mm)</Typography>
                  </Box>
                  <Box display="flex" alignItems="flex-start" gap={1}>
                    <Box width={100}>
                      <SelectForm name="customScheduleAtHours" label="Hours" options={hhValues} />
                    </Box>
                    <Box width={100}>
                      <SelectForm name="customScheduleAtMinutes" label="Minutes" options={mmValues} />
                    </Box>
                  </Box>
                </Box>
              </ShowByValue>
            </Grid>
          </SetupCard>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={8}>
          <Box display="flex" justifyContent="flex-end">
            <StepControl />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScheduleInfoStep;
