/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  debounce,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { AxiosXHR } from "axios";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  setTableData,
} from "@fas/ui-framework/lib/redux/actions/table";
import {
  SEGMENTS_LIST_TABLE,
  GET_SEGMENTS_LIST_SAGA,
} from "../../helpers/constants";
import SegmentApi from "../../services/segmentApi";
import type { GetSegmentsListSaga } from "../../actions/segmentsList";
import type { SegmentListItem } from "../../components/SegmentsList/types/SegmentsList.types";
import type { SegmentsListLoadingKeys } from "../../pages/SegmentsList/store";

export function* makeFetch(action: GetSegmentsListSaga): Saga<void> {
  const { search }: GetSegmentsListSaga = action;

  yield put(setLoading<SegmentsListLoadingKeys>("isSegmentsListLoading", true));

  try {
    const { data: response }: AxiosXHR<{ data: SegmentListItem[] }> = yield call(SegmentApi.getSegmentsList, {
      page: 1,
      limit: 50,
      name: search,
    });

    yield put(setTableData(SEGMENTS_LIST_TABLE, response.data));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
    // eslint-disable-next-line
    console.error(err);
  }
  yield put(setLoading<SegmentsListLoadingKeys>("isSegmentsListLoading", false));
}

export default function* watchFetchSegmentsListSaga(): Saga<void> {
  // $FlowFixMe
  yield debounce(1000, GET_SEGMENTS_LIST_SAGA, makeFetch);
}
