// @flow
import _ from "lodash";
import { POPUP_TYPES, type MotivationTemplateType } from "../CreateMotivationTemplate";
import type {
  ButtonTypes,
  MotivTypes,
  Modifier,
  Buttons,
  Images,
  ModifiersStateData,
} from "./Modifier.types";
import { codeCdnReplacer } from "../../helpers/utils";

export const TypeToButtonTypesMap: { [MotivationTemplateType]: ButtonTypes[] } = {
  WPMotivationPopUp: ["confirm", "deny"],
  WPSubscriptionPage: [],
  PWAMotivationPopUp: ["confirm", "deny"],
  PWAInstallPage: ["motiv_install"],
  PWAMotivationPopUpIos: ["confirm", "deny"],
  PWAInstallPageIos: ["motiv_install"],
  IPPMotivationPopUp: ["confirm", "deny"],
};

export const TypeToMotiveTypeMap: { [MotivationTemplateType]: MotivTypes } = {
  WPMotivationPopUp: "wp",
  WPSubscriptionPage: "wp",
  PWAMotivationPopUp: "pwa",
  PWAInstallPage: "pwa",
  PWAMotivationPopUpIos: "pwa",
  PWAInstallPageIos: "pwa",
  IPPMotivationPopUp: "ipp",
};

export const TypeToLabelMap: { [MotivationTemplateType]: string } = {
  WPMotivationPopUp: "PopUp",
  PWAMotivationPopUp: "PopUp",
  PWAMotivationPopUpIos: "PopUp",
  IPPMotivationPopUp: "PopUp",
  WPSubscriptionPage: "Subscription Page",
  PWAInstallPage: "Install Page",
  PWAInstallPageIos: "Install Page",
};

export const getSufixIdx: (string) => string = (idx) => (idx === "1" ? "" : `_${idx}`);

export const convertModifiersToUi: ({ modifiers: Modifier, type: MotivationTemplateType }) => ModifiersStateData = ({
  modifiers, type,
}) => {
  const images: Images = Object.keys(modifiers).reduce((acc: Images, key: string): Images => {
    if (key.includes("image")) {
      const idx: string = key.replace(/^\D+/g, "") || "1";
      _.setWith(acc, [idx], modifiers[key], Object);
    }
    return acc;
  }, {});

  const buttons: Buttons = TypeToButtonTypesMap[type].reduce((acc: Buttons, buttonType: ButtonTypes): Buttons => {
    Object.keys(modifiers).forEach((key: string) => {
      if (key.includes(TypeToMotiveTypeMap[type]) && key.includes(buttonType)) {
        const idx: string = key.replace(/\D+/g, "") || "1";
        const propMatch: string[] | null = key.match(/(bg_color|text_color)/);
        const buttonProp: string = propMatch ? propMatch[1] : "text";
        _.setWith(acc, [buttonType, idx, buttonProp], modifiers[key], Object);
      }
    });
    return acc;
  }, {});

  return {
    simple: {
      popup_position: modifiers.popup_position,
      text_title: modifiers.text_title,
      text_body: modifiers.text_body,
      bg_color: modifiers.bg_color,
      text_color: modifiers.text_color,
    },
    images,
    buttons,
  };
};

export const convertModifiersToServer: ({ modifiers: *, type: MotivationTemplateType }) => Modifier = ({
  modifiers: {
    simple: {
      popup_position,
      ...simple
    },
    images,
    buttons,
  },
  type,
}) => {
  const motiveType: MotivTypes = TypeToMotiveTypeMap[type];
  const simpleModifiers: Modifier = POPUP_TYPES.includes(type) ? { ...simple, popup_position } : simple;

  const imageModifiers: Modifier = Object.keys(images)
    .reduce((acc: Modifier, idx: string): Modifier => ({ ...acc, [`image${getSufixIdx(idx)}`]: images[idx] }), {});

  const buttonModifiers: Modifier = TypeToButtonTypesMap[type]
    .reduce((acc: Modifier, buttonType: ButtonTypes): Modifier => {
      Object.keys(_.get(buttons, [buttonType])).forEach((idx: string) => {
        acc[`${motiveType}_${buttonType}${getSufixIdx(idx)}`] = _.get(buttons, [buttonType, idx, "text"], "");
        acc[`${motiveType}_${buttonType}${getSufixIdx(idx)}_bg_color`] = _.get(buttons, [buttonType, idx, "bg_color"], "");
        acc[`${motiveType}_${buttonType}${getSufixIdx(idx)}_text_color`] = _.get(buttons, [buttonType, idx, "text_color"], "");
      });
      return acc;
    }, {});
  return {
    ...simpleModifiers,
    ...imageModifiers,
    ...buttonModifiers,
  };
};

export const applyModifiers: (string, Modifier) => string = (
  code, modifiers
) => Object.keys(modifiers).reduce(
  (acc: string, key: string): string => acc.replace(new RegExp(`{%=${key}%}`, "g"), modifiers[key]),
  code
);

export const openPreviewWindow: ({ htmlCode: string, cssCode: string }) => void = ({
  htmlCode, cssCode,
}) => {
  const previewWindow = window.open("/motivationContent/preview", "_blank");
  previewWindow.onload = () => {
    previewWindow.document.body.innerHTML = codeCdnReplacer(`<style>${cssCode}</style>${htmlCode}`);
  };
};
