/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type {
  Dispatch,
  CombinedReducer,
  Store,
} from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";

import notifications, { initNotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import { tableReducer as tables, initTableState, type TableState } from "@fas/ui-framework/lib/redux/reducers/table";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import loading, { initLoadingState, type State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";

import mainSaga from "./saga";

import filters, { initLimitStatFiltersState } from "../../reducers/limitStatFilters";
import groupBy, { initLimitStatGroupByState } from "../../reducers/limitStatGroupBy";
import modals, { initModalsState } from "../../reducers/modals";
import limitStatList, { initLimitStatListState } from "../../reducers/limitStatList";

import type { State as FiltersState } from "../../reducers/limitStatFilters";
import type { Actions as FiltersActions } from "../../actions/limitStatFilters";
import type { State as GroupByState } from "../../reducers/limitStatGroupBy";
import type { Actions as GroupByActions } from "../../actions/limitStatGroupBy";
import type { State as ModalsState } from "../../reducers/modals";
import type { Actions as ModalsActions } from "../../actions/modals";
import type { State as LimitStatListState } from "../../reducers/limitStatList";
import type { Actions as LimitStatActions } from "../../actions/limitStatList";
import type { Actions as LimitStatTableActions } from "../../actions/tableFilters";
import { SEGMENTS_LIST_TABLE, LIMIT_STAT_TABLE } from "../../helpers/constants";
import type { SegmentsListLoadingKeys } from "../SegmentsList/store";

export type LimitStatModals = "isFiltersModalOpen" | "isGroupByModalOpen";

export type State = $ReadOnly<{|
notifications: NotificationsState,
  filters: FiltersState,
  groupBy: GroupByState,
  modals: ModalsState<LimitStatModals>,
  limitStatList: LimitStatListState,
  tables: TableState,
  loading: LoadingState<SegmentsListLoadingKeys>,
|}>;

export type Actions = FiltersActions
  | GroupByActions
  | ModalsActions<LimitStatModals>
  | LimitStatActions
  | TableActions
  | LimitStatTableActions
  | LoadingActions<SegmentsListLoadingKeys>;

function mapLimitStatToState(): State {
  return {
    notifications: initNotificationsState(),
    filters: initLimitStatFiltersState(),
    groupBy: initLimitStatGroupByState(),
    modals: initModalsState<LimitStatModals>({
      isFiltersModalOpen: false,
      isGroupByModalOpen: false,
    }),
    tables: initTableState({
      [SEGMENTS_LIST_TABLE]: {},
      [LIMIT_STAT_TABLE]: {},
    }),
    limitStatList: initLimitStatListState(),
    loading: initLoadingState({ isSegmentsListLoading: false }),
  };
}

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  filters,
  groupBy,
  modals,
  limitStatList,
  tables,
  loading,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapLimitStatToState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
