// @flow
import { CHANGE_MODAL_OPEN } from "../../helpers/constants";

export type ChangeModalOpen<T> = {|
  type: typeof CHANGE_MODAL_OPEN,
  key: T,
  value: boolean,
|};

export type Actions<T> = ChangeModalOpen<T>;

export default <T: string>(key: T, value: boolean): ChangeModalOpen<T> => ({
  type: CHANGE_MODAL_OPEN,
  key,
  value,
});
