// @flow
import Joi from "joi";
import joiResolver from "../../services/form/joiResolver";

const schema = Joi.object({
  name: Joi.string().required(),
  browserLanguages: Joi.array().min(1),
  placements: Joi.array().min(1),
  sendStrategies: Joi.number().min(1),
  messages: Joi.array().min(1).messages({
    "array.min": "At least one message required",
  }),
}).messages({
  "number.base": "This field is required",
  "string.empty": "This field is required",
  "array.min": "This field is required",
  "number.min": "This field is required",
  "object.base": "This field is required",
}).unknown();

export default joiResolver(schema);
