// @flow
import joi from "joi";

const schema = joi.object({
  name: joi
    .string()
    .min(1)
    .max(255)
    .required()
    .label("Name"),
  BrowserLanguages: joi
    .array()
    .min(1)
    .required()
    .label("BrowserLanguages"),
  initTimeout: joi
    .number()
    .min(0)
    .required()
    .label("initTimeout"),
  motivationTemplates: joi.any().when("visualisationType", {
    is: "system",
    then: joi.any(),
    otherwise: joi.any().when("isTemplate", {
      is: true,
      then: joi.any().when("visualisationType", {
        is: "inline_without_manifest",
        then: joi.object({
          WPMotivationPopUp: joi
            .string()
            .min(1)
            .required()
            .label("WPMotivationPopUp"),
          WPSubscriptionPage: joi
            .string()
            .min(1)
            .required()
            .label("WPSubscriptionPage"),
        }),
        otherwise: joi.object({
          WPMotivationPopUp: joi
            .string()
            .min(1)
            .required()
            .label("WPMotivationPopUp"),
        }),
      }),
      otherwise: joi.any(),
    }),
  }),
})
  .options({ abortEarly: false, allowUnknown: true });

export default schema;
