// @flow
import type { DropdownObj } from "../../helpers/common.types";

export const TEMPLATE_TYPES: DropdownObj[] = [
  { label: "WebPush Motivation PopUp", value: "WPMotivationPopUp" },
  { label: "WebPush Subscription Page", value: "WPSubscriptionPage" },
  { label: "PWA Motivation PopUp", value: "PWAMotivationPopUp" },
  { label: "PWA Install Page", value: "PWAInstallPage" },
  { label: "IPP Motivation PopUp", value: "IPPMotivationPopUp" },
];

export const POPUP_TYPES: string[] = [
  "WPMotivationPopUp",
  "PWAMotivationPopUp",
  "IPPMotivationPopUp",
  "PWAMotivationPopUpIos",
];

export type MotivationTemplateType = "WPMotivationPopUp"
  | "WPSubscriptionPage"
  | "PWAMotivationPopUp"
  | "PWAInstallPage"
  | "IPPMotivationPopUp"
  | "PWAMotivationPopUpIos"
  | "PWAInstallPageIos"

export type UsedInMotivationContents = {
  id: string,
  name: string,
  integrationType: string
}

export type MotivationTemplateFormData = {|
  id: string,
  name: string,
  type: MotivationTemplateType,
  isActive: boolean,
  html: string,
  css: string,
  motivationContents: UsedInMotivationContents[],
|}

export type MotivationTemplateRaw = {|
  ...MotivationTemplateFormData,
  modifiers: { [string]: string },
|}

export type MotivationTemplateDropdown = {
  ...MotivationTemplateRaw,
  value: string,
  label: string,
}

export type Handlers = {
  setMotivationTemplateField: ($Shape<MotivationTemplateFormData>) => *,
  getMotivationTemplateSaga: (id: string) => *,
  saveMotivationTemplateSaga: () => *,
}

export type MotivationTemplates = { [MotivationTemplateType]: { id: string, name: string } } | null;
