// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { ErrorBody } from "./types/General.type";
import type { Placement, BrowserLanguage } from "../../containers/CreateMessageGroup";

export const saveWebError = (body: ErrorBody): PromiseResponse<mixed> => requestService
  .post(`${environment.endpoints.post.saveWebError}`, {
    ...body,
  });

export const getBrowserLanguagesList = (): Promise<BrowserLanguage[]> => requestService
  .get(environment.endpoints.get.browserLanguages)
  .then(({ data: response }) => response.data);

export const getPlacementsList = (): Promise<Placement[]> => requestService
  .get(environment.endpoints.get.placements)
  .then(({ data: response }) => response.data);

export const getTagsList = (): Promise<string[]> => requestService
  .get(environment.endpoints.get.tags)
  .then(({ data: response }) => response.data);
