/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect, useState } from "react";
import type { StatelessFunctionalComponent } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { SlideModal, AddContent } from "@fas/ui-core";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import TableActions from "../StepTableActions";
import MessageGroupApi from "../../services/messageGroupApi";
import MessageGroupsList from "../../components/MessageGroupsList";
import type { MessageGroupListItem } from "../../components/MessageGroupsList";
import type { GetMessageGroupServerData } from "../CreateMessageGroup";
import type { UseState } from "../../helpers/common.types";
import MessageGroupSetupList from "../../components/MessageGroupSetupList";
import ExternalRedirect from "../../components/App/ExternalRedirect";
import { FORM_KEY_CAMPAIGN } from "../../helpers/constants";
import { useActions, useNotificationError } from "../../hooks";
import type { State } from "../../pages/CreateCampaign";

type Props = {
  formKey?: string,
  defaultOpen?: boolean,
}

// @TODO Update component after segments refactor

const MessageGroupsStep: StatelessFunctionalComponent<Props> = ({
  formKey = FORM_KEY_CAMPAIGN,
  defaultOpen = false,
}) => {
  // $FlowFixMe
  const webPushMessageGroups: MessageGroupListItem[] = useSelector(
    (state: State) => getFormField(state, formKey, "webPushMessageGroups")
  );
  const {
    setFormData: onSetFormData,
    addNotification: onAddNotification,
  } = useActions({ setFormData, addNotification });

  useNotificationError("messageGroups");

  const [isModalOpen, setIsModalOpen]: UseState<boolean> = useState(defaultOpen && !webPushMessageGroups.length);
  const [collapseStatuses, setCollapseStatuses]: UseState<{ [key: string]: boolean }> = useState({});
  const [choicedMessageGroups, setChoicedMessageGroups]: UseState<MessageGroupListItem[]> = useState([]);

  useEffect(() => {
    setChoicedMessageGroups(webPushMessageGroups);
  }, [webPushMessageGroups]);

  const handleCollapse: (string) => void = (id) => {
    const index: number = choicedMessageGroups
      .findIndex((item: MessageGroupListItem): boolean => item.id === id);

    MessageGroupApi
      .getMessageGroupById(id)
      .then((data: GetMessageGroupServerData) => {
        const newMessageGroups: MessageGroupListItem[] = [...choicedMessageGroups];
        newMessageGroups[index].messages = data.messages;
        setChoicedMessageGroups(newMessageGroups);

        setCollapseStatuses({ ...collapseStatuses, [id]: !collapseStatuses[id] });
      })
      .catch((err) => {
        onAddNotification({ message: err.errorMessage || "Failed to fetch messageGroup", severity: "error" });
      });
  };

  return (
    <Box mb={3}>
      <AddContent
        title="+ ADD MESSAGE GROUPS"
        onTitileClick={(): void => setIsModalOpen(true)}
        onArrowClick={(): void => setIsModalOpen(true)}
      />
      <SlideModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          onSetFormData(formKey, { webPushMessageGroups: choicedMessageGroups });
        }}
        onSave={() => {
          setIsModalOpen(false);
          onSetFormData(formKey, { webPushMessageGroups: choicedMessageGroups });
        }}
        title="ADD MESSAGE GROUPS"
        dataTestid="messageGroups-setup-modal"
        content={(
          <MessageGroupsList
            choicedData={choicedMessageGroups}
            TableActions={(
              <TableActions
                editPath="/messageGroups/edit/:id"
                viewPath="/messageGroups/view/:id"
                items={choicedMessageGroups}
              />
            )}
            allSelectAvailable={() => false}
            onCreate={() => {
              ExternalRedirect({ to: "/messageGroups/create", target: "_blank" });
              setIsModalOpen(false);
            }}
            onSelect={(messageGroup: MessageGroupListItem): void => setChoicedMessageGroups((prev) => ([
              ...prev, messageGroup,
            ]))}
            onDeselect={(messageGroup: MessageGroupListItem): void => setChoicedMessageGroups((prev) => (
              prev.filter((item: MessageGroupListItem): boolean => item.id !== messageGroup.id)
            ))}
          />
        )}
      />
      {choicedMessageGroups.length > 0 && (
        <MessageGroupSetupList
          // $FlowFixMe
          messageGroups={choicedMessageGroups}
          handleCollapse={handleCollapse}
          collapseStatuses={collapseStatuses}
          handleDelete={(id: string) => {
            const newChoiced: MessageGroupListItem[] = choicedMessageGroups
              .filter((item: MessageGroupListItem): boolean => item.id !== id);
            setChoicedMessageGroups(newChoiced);
            onSetFormData(formKey, { webPushMessageGroups: newChoiced });
          }}
          data-testid="messageGroups-step"
        />
      )}
    </Box>
  );
};

export default MessageGroupsStep;
