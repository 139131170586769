// @flow
export type CustomAttributes = {[string]: string}

const getCustomAttributes: (inputValue: string) => CustomAttributes = (inputValue) => {
  const attributesArr = inputValue.split("&");
  const attrObject = {};

  attributesArr.forEach((attr) => {
    if (attr.length > 0) {
      const [key, value] = attr.split("=");
      attrObject[key] = value;
    }
  });
  return attrObject;
};

export default getCustomAttributes;
