// @flow
import type { RecordOf } from "immutable";
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State as StoreState } from "../../pages/CreateIPP/store";
import type { IPP } from "../../components/IppList/types/IppList.types";

export const getId: OutputSelector<StoreState, *, string> = createSelector(
  (state: StoreState): string => state.ipp.get("id"),
  (id: string): string => id
);

export const getFormData: OutputSelector<StoreState, *, IPP> = createSelector(
  (state: StoreState): RecordOf<IPP> => state.ipp,
  (data: RecordOf<IPP>): IPP => data.toObject()
);

export const getIpp: OutputSelector<StoreState, *, IPP> = createSelector(
  (state: StoreState): IPP => state.ipp.toJSON(),
  (item: IPP): IPP => item
);

export const getIsLoading: OutputSelector<
  StoreState,
  *,
  boolean
> = createSelector(
  (state: StoreState): boolean => state.loading.get("ipp", false),
  (isActive: boolean): boolean => isActive
);

export const getIsSaving: OutputSelector<
  StoreState,
  *,
  boolean
> = createSelector(
  (state: StoreState): boolean => state.loading.get("ippSaving", false),
  (isActive: boolean): boolean => isActive
);
