// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../containers/NotificationsContainer";
import CreateMessageContainer from "../../containers/CreateMessage";
import configureStore from "./store";

function CreateTargeting() {
  const store = useMemo(configureStore, []);

  return (
    <DocumentTitle title="Message">
      <Provider store={store}>
        <NotificationsContainer />
        <CreateMessageContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default CreateTargeting;
