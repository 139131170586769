/* eslint-disable import/max-dependencies */
// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import { Steps } from "@fas/ui-core";
import { stepClick, type StepClickAction } from "@fas/ui-framework/lib/redux/actions/steps";
import type { Step } from "@fas/ui-framework/lib/redux/reducers/steps";
import makeStyles from "@mui/styles/makeStyles";
import type { OrderedMap, RecordOf } from "immutable";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import type { StoreWithSteps } from "../../selectors/limits/types/limits.types";
import {
  getCurrentStepId,
  getSteps,
} from "../../selectors/steps";

type OwnProps = $ReadOnly<{|
  onClick?: () => *,
  getStepContent: (step: string) => StatelessFunctionalComponent<{}>,
|}>;

type StateProps = $ReadOnly<{|
  items: OrderedMap<string, RecordOf<Step>>,
  selected: string,
|}>;

type DispatchProps = $ReadOnly<{|
  handleStepClick: (string) => StepClickAction,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|}>;

const mapStateToProps: (StoreWithSteps) => StateProps = (state) => ({
  items: getSteps(state),
  selected: getCurrentStepId(state),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchProps = (dispatch) => bindActionCreators({
  handleStepClick: stepClick,
}, dispatch);

const useStyles = makeStyles((theme) => ({
  containerSteps: {
    height: "calc(100vh - 64px)",
  },
  contentSteps: {
    backgroundColor: theme.palette.background.mainBg,
  },
  menuSteps: {
    backgroundColor: "#fff",
  },
}));

const StepsContainer: (Props) => Node = ({
  items,
  selected,
  handleStepClick,
  getStepContent,
}) => (
  <Steps
    // $FlowFixMe
    items={items.valueSeq().toJS()}
    selected={Number(selected) - 1}
    onClick={(id: number): StepClickAction => handleStepClick(String(id + 1))}
    getStepContent={(id: number): StatelessFunctionalComponent<{}> => getStepContent(String(id + 1))}
    classes={useStyles()}
  />
);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(StepsContainer);
