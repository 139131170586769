// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, type Node } from "react";
import {
  Container,
  Box,
} from "@mui/material";
import {
  SelectForm,
  TextFieldForm,
} from "@fas/ui-core/lib/Form";
import { useParams } from "react-router-dom";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import { FormHeader, Loader, SetupCard } from "@fas/ui-core";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import {
  useLoading,
  useDictionary,
  useList,
  useActions,
  useNotificationError,
} from "../../hooks";
import { FORM_KEY_MESSAGE_GROUP, GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants";
import Form from "../Form";
import MultiSelectForm from "../Form/MultiSelectForm";
import ListModalSetup from "../Form/ListModalSetupForm";
import MessagesList from "../../components/MessagesList";
import MessageView from "../../components/MessageView";
import AdditionalInfoFrom from "../Form/AdditionalInfoFrom";
import SelectWithConfirmForm from "../Form/SelectWithConfirmForm";
import { addTagSaga } from "../../actions/messageGroupsList";
import validate from "./validate";

const CreateMessageGroup = (): Node => {
  const {
    getFormDataSaga: onGetFormDataSaga,
    addTagSaga: onAddTagSaga,
  } = useActions({ getFormDataSaga, addTagSaga });

  useNotificationError("messages");

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      onGetFormDataSaga(FORM_KEY_MESSAGE_GROUP, id);
    }
  }, [id]);

  const { list: languages }: { list: DropDownObjItemType[] } = useDictionary("browserLanguages");
  const { list: placements }: { list: DropDownObjItemType[] } = useDictionary("placements");
  const { list: tags }: { list: DropDownObjItemType[] } = useDictionary("tags");
  const strategiesList = useList("strategiesList");

  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  return (
    <Form id={FORM_KEY_MESSAGE_GROUP} redirectOnSave="/messageGroups" validate={validate}>
      <FormHeader
        title={id ? `Edit message group: ${String(id)}` : "Create message group"}
        loading={loading}
        isActionVisible={!loading}
      />
      <Container maxWidth="md">
        <SetupCard title="General Info">
          <Box width={1} mt={1}>
            <Loader loading={loading}>
              <TextFieldForm
                name="name"
                label="Name"
              />
            </Loader>
          </Box>
        </SetupCard>

        <SetupCard title="Targeting">
          <Box width={1} mt={1}>
            <Loader loading={loading}>
              <MultiSelectForm
                name="browserLanguages"
                label="Browser Languages"
                options={languages}
              />
              <MultiSelectForm
                name="placements"
                label="Placements"
                options={placements}
              />
              <SelectWithConfirmForm
                name="tag"
                label="Tags"
                options={tags}
                onSubmit={onAddTagSaga}
                confirmTitle="New Tag"
                confirmMessage="This action will create a new tag in tags dictionary. Are you agree to do that?"
              />
            </Loader>
          </Box>
        </SetupCard>

        <SetupCard title="Sending strategy">
          <Box width={1} mt={1}>
            <Loader loading={loading}>
              <SelectForm
                name="sendStrategies"
                label="Strategies"
                options={strategiesList}
              />
            </Loader>
          </Box>
        </SetupCard>

        <Loader loading={loading}>
          <ListModalSetup
            name="messages"
            ListComponent={MessagesList}
            title="ADD MESSAGES"
            listName="messages"
            ViewList={({ items, handleDelete }) => items.map((item) => (
              <MessageView
                {...item}
                key={item.id}
                removeMessage={() => handleDelete(item.id)}
              />
            ))}
          />
        </Loader>

        <AdditionalInfoFrom name="usedInCampaigns" />
      </Container>
    </Form>
  );
};

export default CreateMessageGroup;
