/* eslint-disable import/max-dependencies */
// @flow
import React, { useState, useEffect } from "react";
import type { StatelessFunctionalComponent } from "react";
import { Multiselect } from "@fas/ui-core";
import {
  Grid,
  InputLabel,
  TextField,
  Divider,
  Box,
  Switch,
  Paper,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";
import type { RawBrowserLanguages } from "../../containers/PwaMotivationContentList";
import HtmlPreviewButton from "../HtmlPreviewButton";
import generalApi from "../../services/generalApi";
import type { RawPwaOfflinePage } from "../PwaOfflinePagesList";
import CodeEditor from "../CodeEditor";

const nameRowSpacing = 3;
const fieldRowSpacing = 8;

type Props = {
  ...RawPwaOfflinePage,
  onBrowserLanguagesChange: (Array<RawBrowserLanguages>) => void,
  onHeadChange: (headValue: string) => void,
  onBodyChange: (bodyValue: string) => void,
  onIsActiveChange: (isActive: boolean) => void,
  onNameChange: (name: string) => void,
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
}));

const PwaOfflinePageForm: StatelessFunctionalComponent<Props> = ({
  name: pwaOffName,
  onNameChange,

  BrowserLanguages: pwaBrowserLanguages,
  onBrowserLanguagesChange,

  head,
  onHeadChange,

  body,
  onBodyChange,

  isActive,
  onIsActiveChange,
}: Props) => {
  const [languages, setLanguages] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    generalApi.getBrowserLanguagesList()
      .then((browserLanguages) => {
        const allBrowserLanguages = browserLanguages.map(({
          id: value,
          language: label,
        }) => ({
          value,
          label,
        }));
        setLanguages(allBrowserLanguages);
      });
  }, []);

  return (
    <Paper className={classes.container}>
      <Box my={3} ml={4}>
        <Typography gutterBottom variant="h5" component="h2">
          PWA Offline Page General info
        </Typography>
      </Box>

      <Grid container rowSpacing={2}>
        <Grid container item justifyContent="center" alignItems="center">
          <Grid item xs={nameRowSpacing}>
            <InputLabel htmlFor="name">Name</InputLabel>
          </Grid>
          <Grid item xs={fieldRowSpacing}>
            <TextField
              size="small"
              name="name"
              variant="outlined"
              placeholder="Example: external_motiv_content_pwa_tag_en"
              fullWidth
              value={pwaOffName}
              onChange={(e) => onNameChange(e.target.value)}
              data-testid="name"
            />
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" alignItems="center">
          <Grid item xs={nameRowSpacing}>
            <InputLabel htmlFor="BrowserLanguages">
              Browser Languages
            </InputLabel>
          </Grid>
          <Grid item xs={fieldRowSpacing}>
            <Multiselect
              type="simpleMultiple"
              // $FlowFixMe
              selectedOptions={pwaBrowserLanguages}
              options={languages}
              // $FlowFixMe
              onChange={(selectedBL) => onBrowserLanguagesChange(selectedBL)}
              placeholder="Type languages or select from list"
              enableAddAllBtn
              clearIcon={<Close fontSize="small" />}
              enableAddMatchesBtn
              data-testid="browserLanguages"
            />
          </Grid>
        </Grid>

        <Box width={1} mb={2} mt={3}>
          <Divider />
        </Box>

        <Box my={1} ml={5}>
          <Typography gutterBottom variant="h5" component="h2">
            PWA Install Offline Page
          </Typography>
        </Box>

        <Grid container item justifyContent="center" alignItems="center">
          <Grid item xs={11}>
            <CodeEditor
              height="400px"
              tabs={[
                {
                  name: "head",
                  label: "head html",
                  value: head,
                  mode: "html",
                  onChange: onHeadChange,
                },
                {
                  name: "body",
                  label: "body html",
                  value: body,
                  mode: "html",
                  onChange: onBodyChange,
                },
              ]}
            />
            <HtmlPreviewButton
              title="Preview HEAD + BODY code"
              data-testid="html-css-preview-btn"
              htmlCode={head + body}
              cssCode=""
            />
          </Grid>
        </Grid>
      </Grid>

      <Box width={1} mb={2} mt={3}>
        <Divider />
      </Box>
      <Grid container item justifyContent="center" alignItems="center">
        <Grid item xs={nameRowSpacing}>
          <InputLabel htmlFor="isActive">Is Active</InputLabel>
        </Grid>
        <Grid item xs={fieldRowSpacing}>
          <Switch
            name="isActive"
            color="primary"
            checked={isActive}
            onChange={(e) => onIsActiveChange(e.target.checked)}
            data-testid="isActive"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PwaOfflinePageForm;
