// @flow
import type { Error } from "@fas/ui-framework/lib/redux/reducers/errors";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { FORM_KEY_SCHEDULE } from "../../../helpers/constants";
import {
  getCurrentStepId,
} from "../../../selectors/steps";
import type { ScheduleFormData } from "../../../containers/CreateSchedule";
import type { State } from "../../../pages/CreateSchedule";
import SheduleInfo from "../rules/SheduleInfo";
import Segments from "../rules/Segments";
import MessageGroups from "../rules/MessageGroups";

export type CollectionErrors = {
  [id: string]: Error,
};

export type Errors = {
  [key: string]: Error,
};

export type JoiError = {
  code: string,
  message: string,
  path: Array<string>,
  local: {
    key: string,
    label: string,
  }
};

export default class Schedule {
  _validateScheduleInfoStep(state: State): Errors {
    const {
      name,
      queueType,
      customScheduleAtHours,
      customScheduleAtMinutes,
    }: ScheduleFormData = getFormData(state, FORM_KEY_SCHEDULE);
    return new SheduleInfo().getErrors({
      name, queueType, customScheduleAtHours, customScheduleAtMinutes,
    });
  }

  _validateMessageGroups(state: State): Errors {
    const { webPushMessageGroups }: ScheduleFormData = getFormData(state, FORM_KEY_SCHEDULE);
    return new MessageGroups().getErrors(webPushMessageGroups);
  }

  _validateSegments(state: State): Errors {
    const { webPushSegments }: ScheduleFormData = getFormData(state, FORM_KEY_SCHEDULE);
    return new Segments().getErrors(webPushSegments);
  }

  validateCurrentStep(state: State): Errors {
    const currentStepId: string = getCurrentStepId(state);

    switch (currentStepId) {
      case "1": {
        return this._validateScheduleInfoStep(state);
      }
      case "2": {
        return this._validateMessageGroups(state);
      }
      case "3": {
        return this._validateSegments(state);
      }
      default:
        return {};
    }
  }
}
