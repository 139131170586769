/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import { useSelector } from "react-redux";
import {
  Container, Grid, Typography, Switch, Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { SetupCard, GridUc } from "@fas/ui-core";
import StepControl from "../StepControl";
import { useActions } from "../../hooks";
import { saveCampaignSaga } from "../../actions/createCampaign";
import type { Campaign } from "../CreateCampaign";
import { FORM_KEY_CAMPAIGN } from "../../helpers/constants";
import type { State } from "../../pages/CreateCampaign";
import NamedItem from "../../components/NamedItem";
import CampViewWebPush from "../../components/CampViewWebPush";
import CampViewPwaMotiv from "../../components/CampViewPwaMotiv";
import CampViewIppMotiv from "../../components/CampViewIppMotiv";
import CampViewPwaOfflinePages from "../../components/CampViewPwaOfflinePages";
import CampViewWPMessages from "../../components/CampViewWPMessages";
import CampViewWPTargetings from "../../components/CampViewWPTargetings";
import AdditionalInfo from "../../components/AdditionalInfo";

export type Classes = {
  root: string,
};

const useStyles: () => Classes = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
}));

const CampaignPreview: StatelessFunctionalComponent<{}> = () => {
  const classes = useStyles();
  const campaign: Campaign = useSelector((state: State) => getFormData(state, FORM_KEY_CAMPAIGN));

  const { saveCampaignSaga: onSaveCampaignSaga } = useActions({ saveCampaignSaga });

  return (
    <Container maxWidth="md">
      <SetupCard
        title="Campaign Info"
      >
        <Box pb={3} width="100%" className={classes.root}>
          {campaign.id && <NamedItem name="Id" value={campaign.id} />}
          <NamedItem name="Name" value={campaign.name} />
          {campaign.id && (
            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">isActive</Typography>
              </Grid>
              <Grid item xs={9}>
                <Switch checked={campaign.isActive} disabled />
              </Grid>
            </GridUc>
          )}
        </Box>
      </SetupCard>
      <AdditionalInfo usedInSchedules={campaign.usedInSchedulesAndSegments} blockTitle="Schedules&Segments Info" />
      {!!campaign.webPushMotivationContents.length && (
        <CampViewWebPush campaignContent={campaign.webPushMotivationContents} classes={classes} />
      )}
      {!!campaign.ippMotivationContents.length && (
        <CampViewIppMotiv campaignContent={campaign.ippMotivationContents} classes={classes} />
      )}
      {!!campaign.pwaMotivationContents.length && (
        <CampViewPwaMotiv campaignContent={campaign.pwaMotivationContents} classes={classes} />
      )}
      {!!campaign.pwaOfflinePages.length && (
        <CampViewPwaOfflinePages campaignContent={campaign.pwaOfflinePages} classes={classes} />
      )}
      <CampViewWPMessages campaignContent={campaign.webPushMessageGroups} classes={classes} />
      <CampViewWPTargetings campaignContent={campaign.webPushTargetings} classes={classes} />

      <Box mt={3}>
        <StepControl nextButtonText="Save" onNext={onSaveCampaignSaga} />
      </Box>
    </Container>
  );
};

export default CampaignPreview;
