// @flow
import environment from "environment";
import { requestService } from "../request";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { getPwaIconsList } from "../pwaIconApi/pwaIconApi";

export type Dropdown = { label: string, value: string };

export const getBrowserLanguagesList = (): Promise<DropDownObjItemType[]> => requestService
  .get(environment.endpoints.get.browserLanguages)
  .then(({ data: { data } }): DropDownObjItemType[] => data
    .map(({ language, id }) => ({ value: id, label: language })));

export const getPwaIcons: () => Promise<DropDownObjItemType[]> = () => getPwaIconsList(null, null, { isActive: true })
  .then(({ data: { data } }): DropDownObjItemType[] => data
    .map(({ id, name }) => ({ value: id, label: name })));

export const getPlacementsList = (): Promise<DropDownObjItemType[]> => requestService
  .get(environment.endpoints.get.placements)
  .then(({ data: { data } }): DropDownObjItemType[] => data
    .map(({ id, name }) => ({ value: id, label: name })));

export const getData1List: () => Promise<Array<DropDownObjItemType[]>> = () => requestService
  .get(environment.endpoints.get.data1)
  .then(({ data: { data } }): Array<DropDownObjItemType[]> => data.map((value) => ({ value, label: value })));

export const getTags: () => Promise<Array<DropDownObjItemType[]>> = () => requestService
  .get(environment.endpoints.get.tags)
  .then(({ data: { data } }): Array<DropDownObjItemType[]> => data.map((value) => ({ value, label: value })));

export const getSuggestDropdown: ({
  page?: number,
  limit?: number,
  filter?: Object,
  name: string,
}) => Promise<Array<DropDownObjItemType[]>> = ({
  name,
  ...params
}) => requestService
  .get(environment.endpoints.get.dictionaryByName.replace("{name}", name), { params: { ...params } })
  .then(({ data: { data } }) => data.map((value) => ({ value, label: value })));
