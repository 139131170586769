// @flow
import React, { useMemo, type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import MessageGroup from "../../containers/MessageGroupsView";
import Notification from "../../components/Notification";
import configureStore from "./store";

const MessageGroupsView: StatelessFunctionalComponent<{}> = () => {
  const store = useMemo(configureStore, []);

  return (
    <DocumentTitle title="Message Groups View">
      <Provider store={store}>
        <MessageGroup />
        <Notification />
      </Provider>
    </DocumentTitle>
  );
};

export default MessageGroupsView;
