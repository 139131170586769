// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetDictionarySaga from "../../sagas/getDictionary";
import watchGetFormDataSaga from "../../sagas/getFormDataSaga";
import watchSaveFormDataSaga from "../../sagas/saveFormDataSaga";
import watchAddData1 from "../../sagas/addData1";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetDictionarySaga),
    call(watchGetFormDataSaga),
    call(watchSaveFormDataSaga),
    call(watchAddData1),
  ]);
}
