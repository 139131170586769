// @flow
import type { Error } from "@fas/ui-framework/lib/redux/reducers/errors";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { FORM_KEY_CAMPAIGN } from "../../../helpers/constants";
import {
  getCurrentStepId,
} from "../../../selectors/steps";
import type { Campaign as CampaignType } from "../../../containers/CreateCampaign";
import type { State } from "../../../pages/CreateCampaign";
import CampaignNameValidator from "../rules/CampaignName";
import MotivationContents from "../rules/MotivationContents";
import MessageGroups from "../rules/MessageGroups";
import Targetings from "../rules/Targetings";

export type CollectionErrors = {
  [id: string]: Error,
};

export type Errors = {
  [key: string]: Error,
};

export type JoiError = {
  code: string,
  message: string,
  path: Array<string>,
  local: {
    key: string,
    label: string,
  }
};

export default class Campaign {
  _validateCampaignInfoStep(state: State): Errors {
    const { name }: CampaignType = getFormData(state, FORM_KEY_CAMPAIGN);
    return new CampaignNameValidator().getErrors(name);
  }

  _validateMotivationContents(state: State): Errors {
    const {
      webPushMotivationContents,
      ippMotivationContents,
      pwaMotivationContents,
      pwaOfflinePages,
    }: CampaignType = getFormData(state, FORM_KEY_CAMPAIGN);

    return new MotivationContents().getErrors({
      webPushMotivationContents,
      ippMotivationContents,
      pwaMotivationContents,
      pwaOfflinePages,
    });
  }

  _validateMessageGroups(state: State): Errors {
    const { webPushMessageGroups }: CampaignType = getFormData(state, FORM_KEY_CAMPAIGN);
    return new MessageGroups().getErrors(webPushMessageGroups);
  }

  _validateTargetings(state: State): Errors {
    const { webPushTargetings }: CampaignType = getFormData(state, FORM_KEY_CAMPAIGN);
    return new Targetings().getErrors(webPushTargetings);
  }

  validateCurrentStep(state: State): Errors {
    const currentStepId: string = getCurrentStepId(state);

    switch (currentStepId) {
      case "1": {
        return this._validateCampaignInfoStep(state);
      }
      case "2": {
        return this._validateMotivationContents(state);
      }
      case "3": {
        return this._validateMessageGroups(state);
      }
      case "4": {
        return this._validateTargetings(state);
      }
      default:
        return {};
    }
  }
}
