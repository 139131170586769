/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useState,
  useEffect,
  type Node,
  type StatelessFunctionalComponent,
} from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SetupCard, GridUc, Loader } from "@fas/ui-core";
import {
  Container, Grid, Box, Switch, Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import SendingStrategy from "../../components/SendingStrategyView";
import Collapse from "../../components/Collapse";
import MessageView from "../../components/MessageView";
import NamedItem from "../../components/NamedItem";
import externalRedirect from "../../components/App/ExternalRedirect";
import type { MessageGroupFormData } from "../CreateMessageGroup";
import AdditionalInfo from "../../components/AdditionalInfo";
import type { State } from "../../pages/MessageGroupsView";
import { useLoading } from "../../hooks";
import { GET_FORM_DATA_LOADING, FORM_KEY_MESSAGE_GROUP } from "../../helpers/constants";
import type { UseState } from "../../helpers/common.types";
import type { Classes } from "./MessageGroupsView.types";

const useStyles: () => Classes = makeStyles((theme) => ({
  pointer: {
    cursor: "pointer",
  },
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
}));

const MessageGroupsView: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();
  const [isShownMore, setIsShownMore]: UseState<boolean> = useState(false);

  const dispatch = useDispatch();
  const {
    name: msgGroupsName,
    browserLanguagesRaw = [],
    placements,
    sendStrategies,
    isActive,
    rotationType,
    messages,
    tag,
    usedInCampaigns,
  }: { ...MessageGroupFormData, rotationType: string } = useSelector(
    (state: State) => getFormData(state, FORM_KEY_MESSAGE_GROUP)
  );

  const messagesList = isShownMore ? messages : messages.slice(0, 2);

  const { id: urlId } = useParams();

  const toggleShowMore = () => setIsShownMore(!isShownMore);

  const getItemEditPath: () => void = () => {
    if (urlId) {
      externalRedirect({ to: `/messageGroups/edit/${urlId}`, target: "_blank" });
    }
  };

  const isLoading: boolean = useLoading(GET_FORM_DATA_LOADING);

  useEffect(() => {
    if (urlId) {
      dispatch(getFormDataSaga(FORM_KEY_MESSAGE_GROUP, urlId));
    }
  }, [urlId]);

  return (
    <>
      {isLoading && (
        <Loader loading size={35} mt={37} />
      )}
      {!isLoading && (
        <Container maxWidth="md">
          <SetupCard
            title="Message Groups info"
            showSecondaryBtn
            secondaryBtnText="Edit"
            onSecondaryBtnClick={getItemEditPath}
          >
            <Box width="100%" pb={3} className={classes.root}>
              <NamedItem name="Name" value={msgGroupsName} />
              <NamedItem name="Id" value={urlId || ""} />
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">isActive</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Switch checked={isActive} disabled />
                </Grid>
              </GridUc>
            </Box>
          </SetupCard>

          <SetupCard title="Configuration">
            <Box width="100%" pb={3} className={classes.root}>
              <SendingStrategy
                strategy={sendStrategies}
              />
              <NamedItem name="Rotation Type" value={rotationType} />
            </Box>
          </SetupCard>

          {messages.length > 0 && (
            <SetupCard title="Messages">
              <Box width="100%" p={3} className={classes.root} boxSizing="border-box">
                {messagesList.map((item): Node => (
                  <MessageView
                    {...item}
                    key={item.id}
                    isDeleteDisable
                  />
                ))}
                {messages.length > 2 && (
                  <Box mt={4}>
                    {messages.length > 2 && !isShownMore && (
                      <Typography
                        className={classes.pointer}
                        component="div"
                        display="block"
                        color="primary"
                        onClick={toggleShowMore}
                      >
                        +
                        {" "}
                        {messages.length - 2}
                        {" "}
                        more
                      </Typography>
                    )}
                    {messages.length > 2 && isShownMore && (
                      <Typography
                        className={classes.pointer}
                        component="div"
                        display="block"
                        color="primary"
                        onClick={toggleShowMore}
                      >
                        - Show less
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </SetupCard>
          )}

          <SetupCard title="Targeting">
            <Box width="100%" pb={3} className={classes.root}>
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">Browser languages:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Collapse
                    showMoreDisabled={false}
                    list={browserLanguagesRaw.map(({ language }): { name: string } => ({ name: language }))}
                  />
                </Grid>
              </GridUc>
              {placements && <NamedItem name="Placements" value={placements} />}
              {tag && <NamedItem name="Tag" value={tag} />}
            </Box>
          </SetupCard>
          <AdditionalInfo usedInCampaigns={usedInCampaigns} />
        </Container>
      )}
    </>
  );
};

export default MessageGroupsView;
