// @flow

type UIOperatorsList = "equal" | "in" | "notequal" | "notin" | "greaterthanorequal" | "lessthanorequal" | "exists";
type ServerOperatorsList = "$in" | "$ne" | "$nin" | "$gte" | "$lte" | "$exists";

type FromUIMapper = {
  [key: UIOperatorsList]: ServerOperatorsList,
};
type ToUIMapper = {
  [key: ServerOperatorsList]: UIOperatorsList,
};

export const fromUIOperatorMapper: FromUIMapper = {
  notequal: "$ne",
  in: "$in",
  notin: "$nin",
  greaterthanorequal: "$gte",
  lessthanorequal: "$lte",
  exists: "$exists",
};

export const toUIOperatorMapper: ToUIMapper = {
  $ne: "notequal",
  $in: "in",
  $nin: "notin",
  $gte: "greaterthanorequal",
  $lte: "lessthanorequal",
  $exists: "exists",
};
