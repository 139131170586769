// @flow
import {
  GET_IPP_LIST_SAGA,
  CHANGE_IPP_LIST_IS_ACTIVE_SAGA,
  DELETE_IPP_LIST_SAGA,
} from "../../helpers/constants";

export type GetIPPListSaga = {
  type: typeof GET_IPP_LIST_SAGA,
};

export type ChangeIPPisActiveSaga = {
  type: typeof CHANGE_IPP_LIST_IS_ACTIVE_SAGA,
  isActive: boolean,
};

export type DeleteIPPSaga = {|
  type: typeof DELETE_IPP_LIST_SAGA,
|};

export type Actions = GetIPPListSaga | ChangeIPPisActiveSaga | DeleteIPPSaga;

export const getIPPListSaga: () => GetIPPListSaga = () => ({
  type: GET_IPP_LIST_SAGA,
});

export const changeIPPisActiveSaga: (boolean) => ChangeIPPisActiveSaga = (isActive) => ({
  type: CHANGE_IPP_LIST_IS_ACTIVE_SAGA,
  isActive,
});

export const deleteIPPSaga: () => DeleteIPPSaga = () => ({
  type: DELETE_IPP_LIST_SAGA,
});
