/* eslint-disable import/max-dependencies */
// @flow
import {
  call, put, debounce, all, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
} from "@fas/ui-framework/lib/redux/selectors/table";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { GET_PWA_LIST_SAGA, PWA_LIST_TABLE } from "../../helpers/constants";
import type { PWAMotivationContent as PwaItem } from "../../containers/PwaMotivationContentList";
import PwaApi from "../../services/PwaApi";

export function* makeFetch(): Saga<void> {
  yield put(setLoading(PWA_LIST_TABLE, true));

  const page = yield select(getTablePage, PWA_LIST_TABLE);
  const limit: number = yield select(getTablePageSize, PWA_LIST_TABLE);
  const filters = yield select(getTableFilters, PWA_LIST_TABLE);

  try {
    const { data: response }: Response<{
      data: Array<PwaItem>,
      totalCount: number
    }> = yield call(
      PwaApi.getPWAList,
      page,
      limit,
      filters
    );

    yield all([
      put(setTableData(PWA_LIST_TABLE, response.data)),
      put(changeTableItemsTotalAmount(PWA_LIST_TABLE, response.totalCount)),
    ]);
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(PWA_LIST_TABLE, false));
  }
}

export default function* watchGetPWAListSaga(): Saga<void> {
  yield debounce(1000, GET_PWA_LIST_SAGA, (makeFetch: Function));
}
