/* eslint-disable import/max-dependencies */
// @flow
import {
  call, put, takeEvery, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import { SAVE_FORM_DATA_LOADING, FORM_KEY_SEGMENT, SAVE_SEGMENT_SAGA } from "../../helpers/constants";
import SegmentApi from "../../services/segmentApi";
import { setLoading } from "../../actions/loading";
import { getConditions } from "../../selectors/segmentConditions";
import type { ConditionRule, ConditionGroup } from "../../selectors/segmentConditions/types";
import externalRedirect from "../../components/App/ExternalRedirect";
import type { SegmentFormData, SegmentSaveDataShort } from "../../containers/CreateSegment";
import { toServerView } from "../../helpers/QueryBuilder";

export function* makeFetch(): Saga<void> {
  yield put(setLoading(SAVE_FORM_DATA_LOADING, true));
  const {
    id: segmentId,
    name,
    isActive,
    isLimit,
    minimum,
    maximum,
    webPushMessageGroups,
  }: SegmentFormData = yield select(getFormData, FORM_KEY_SEGMENT);

  const conditions: { [string]: ConditionRule | ConditionGroup } = yield select(getConditions);

  const body: SegmentSaveDataShort = {
    name,
    isActive,
    limit: isLimit ? {
      minimum: Number(minimum),
      maximum: Number(maximum),
      messageGroupIds: webPushMessageGroups.map(({ id }): string => id),
    } : null,
    criteria: JSON.stringify(toServerView(conditions)),
  };

  try {
    if (segmentId) {
      yield call(SegmentApi.updateSegment, segmentId, { ...body, id: segmentId });
      yield put(addNotification({ message: "Segment updated successfully", severity: "success" }));
    }
    else {
      yield call(SegmentApi.saveSegment, body);
      yield put(addNotification({ message: "Segment saved successfully", severity: "success" }));
    }
    yield put(setLoading(SAVE_FORM_DATA_LOADING, false));
    externalRedirect({ to: "/segments" });
  }
  catch (error) {
    yield put(setLoading(SAVE_FORM_DATA_LOADING, false));
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchSaveSegmentSaga(): Saga<void> {
  yield takeEvery(SAVE_SEGMENT_SAGA, (makeFetch: Function));
}
