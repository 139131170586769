// @flow
import { createSelector } from "reselect";
import type { RecordOf } from "immutable";
import type { OutputSelector } from "reselect";
import type { StoreWithTargeting } from "./types/targetingView.types";
import type { Targeting } from "../../containers/TargetingsList";

// eslint-disable-next-line import/prefer-default-export
export const getTargeting: OutputSelector<StoreWithTargeting, *, Targeting> = createSelector(
  (state: StoreWithTargeting): RecordOf<Targeting> => state.targeting,
  (targeting: RecordOf<Targeting>): Targeting => targeting.toJSON()
);
