// @flow
import React, { Fragment } from "react";
import {
  Card,
  CardContent, Link,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { toUIFactory } from "../../services/targeting";

type Props = {
  rule: Object,
};

const useStyles = makeStyles((theme) => ({
  textBlock: {
    userSelect: "text",
    margin: theme.spacing(1),
  },
  controlLabel: {
    userSelect: "text",
    fontSize: "14px",
    fontStyle: "italic",
    fontWeight: "lighter",
  },
  valueLabel: {
    userSelect: "text",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "bold",
  },
}));

export default ({ rule }: Props) => {
  const classes: Object = useStyles();
  const {
    label: name,
    operator,
    value,
  } = toUIFactory(rule).convert();

  return (
    <Card className={classes.textBlock}>
      <CardContent>
        <Typography className={classes.controlLabel} component="p">
          {`${name}${operator ? ` ${operator}` : ""}:`}
        </Typography>
        {name === "WebPushCampaign" ? (
          <Typography className={classes.valueLabel} component="p">
            {
              Array.isArray(value) && value.map((item, idx) => (
                <Fragment key={item}>
                  <Link
                    key={item}
                    href={`/campaigns/view/${item}`}
                    underline="hover"
                  >
                    {item}
                  </Link>
                  {value[idx + 1] ? ", " : ""}
                </Fragment>
              ))
            }
          </Typography>
        ) : (
          <Typography className={classes.valueLabel} component="p">
            {
              Array.isArray(value)
                ? value.join(", ")
                : [value].join(", ")
            }
          </Typography>
        )}

      </CardContent>
    </Card>
  );
};
