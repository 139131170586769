/* eslint-disable import/max-dependencies */
// @flow
import {
  call, put, takeEvery, all,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { get } from "lodash";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { GET_IPP_SAGA } from "../../helpers/constants";
import IppApi from "../../services/IPPApi";
import { fetchMotivationTemplate } from "../../services/motivationTemplateApi";
import type { GetIppSaga } from "../../actions/createIpp";
import { setIpp } from "../../actions/createIpp";
import { setModifiersState } from "../../actions/modifiers";
import type { IPP, IppSaveBody } from "../../components/IppList/types/IppList.types";
import { setLoading } from "../../actions/loading";
import externalRedirect from "../../components/App/ExternalRedirect";
import { convertModifiersToUi, type ModifiersStateData } from "../../containers/Modifiers";
import type { MotivationTemplateType, MotivationTemplateRaw, MotivationTemplateDropdown } from "../../containers/CreateMotivationTemplate";

export function* makeFetch(action: GetIppSaga): Saga<void> {
  const { id } = action;

  yield put(setLoading("ipp", true));
  try {
    const response = yield call(IppApi.getIpp, id);
    const {
      motivationTemplates = {},
      modifiers,
      ...rawIppItem
    }: IppSaveBody = get(response, "data.data.0");
    const browserLanguages = get(rawIppItem, "BrowserLanguages", []);

    const motivationTemplatesState: { [MotivationTemplateType]: MotivationTemplateDropdown } = {};
    const modifiersState: { [MotivationTemplateType]: ModifiersStateData } = {};

    if (motivationTemplates) {
      const templates: MotivationTemplateRaw[] = yield all(Object.keys(motivationTemplates)
        .map((type: MotivationTemplateType) => call(fetchMotivationTemplate, motivationTemplates[type])));

      Object.keys(motivationTemplates).forEach((type, index) => {
        const template: MotivationTemplateRaw = templates[index];
        motivationTemplatesState[type] = { label: template.name, value: template.id, ...template };
        modifiersState[type] = convertModifiersToUi({ modifiers: modifiers[type], type });
      });

      yield put(setModifiersState(modifiersState));
    }

    const item: IPP = {
      ...rawIppItem,
      motivationTemplates: motivationTemplatesState,
      clickAction: rawIppItem.clickAction || "default",
      BrowserLanguages: browserLanguages.map(
        ({ id: value, language: label }) => ({ value, label })
      ),
    };
    yield put(setIpp(item));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
    externalRedirect({ to: "/ipp" });
  }
  finally {
    yield put(setLoading("ipp", false));
  }
}

export default function* watchFetchIppSaga(): Saga<void> {
  yield takeEvery(GET_IPP_SAGA, (makeFetch: Function));
}
