// @flow
import {
  GET_MESSAGE_GROUPS_LIST_SAGA,
  CHANGE_MESSAGE_GROUP_IS_ACTIVE_SAGA,
  DELETE_MESSAGE_GROUPS_SAGA,
  ADD_TAG_SAGA,
} from "../../helpers/constants";

export type GetMessageGroupsListSaga = {|
  type: typeof GET_MESSAGE_GROUPS_LIST_SAGA,
|};

export type ChangeMessageGroupIsActiveSaga = {|
  type: typeof CHANGE_MESSAGE_GROUP_IS_ACTIVE_SAGA,
  isActive: boolean,
|};

export type DeleteMessageGroupsSaga = {|
  type: typeof DELETE_MESSAGE_GROUPS_SAGA,
|};

export type AddTagSaga = {|
  type: typeof ADD_TAG_SAGA,
  payload: string,
|};

export type Actions =
  GetMessageGroupsListSaga
  | ChangeMessageGroupIsActiveSaga
  | DeleteMessageGroupsSaga;

export const getMessageGroupsListSaga: () => GetMessageGroupsListSaga = () => ({ type: GET_MESSAGE_GROUPS_LIST_SAGA });

export const changeMessageGroupIsActiveSaga: (boolean) => ChangeMessageGroupIsActiveSaga = (isActive) => ({
  type: CHANGE_MESSAGE_GROUP_IS_ACTIVE_SAGA,
  isActive,
});

export const deleteMessageGroupsSaga: () => DeleteMessageGroupsSaga = () => ({
  type: DELETE_MESSAGE_GROUPS_SAGA,
});

export const addTagSaga: (string) => AddTagSaga = (newTag) => ({
  type: ADD_TAG_SAGA,
  payload: newTag,
});
