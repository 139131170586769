/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect } from "react";
import type { StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";
import { Container, Box } from "@mui/material";
import { LoadingButton, Loader } from "@fas/ui-core";
import { useParams } from "react-router-dom";
import type { State } from "../../pages/CreateIPP/store";
import {
  getId,
  getFormData,
  getIsLoading,
  getIsSaving,
} from "../../selectors/createIpp";
import CreateIppComponent from "../../components/CreateIpp";
import {
  setIppFormField,
  saveIppSaga,
  getIppSaga,
} from "../../actions/createIpp";
import { setModifiers } from "../../actions/modifiers";
import type { SetModifiers } from "../../actions/modifiers/actions.types";
import type {
  Actions,
  SetIppFormField,
  SaveIppSaga,
  GetIppSaga,
} from "../../actions/createIpp";
import type { IPP } from "../../components/IppList/types/IppList.types";
import type { MotivationTemplateType } from "../CreateMotivationTemplate";
import type { ModifiersStateData } from "../Modifiers";

type OwnProps = $ReadOnly<{||}>;

type StateProps = $ReadOnly<{|
  id: string,
  formData: IPP,
  isLoading: boolean,
  isSaving: boolean,
|}>;

type DispatchProps = $ReadOnly<{|
  onSetIppFormField: ($Shape<IPP>) => SetIppFormField,
  onSaveIpp: () => SaveIppSaga,
  getIpp: (id: string) => GetIppSaga,
  onSetModifiers: (MotivationTemplateType, ModifiersStateData) => SetModifiers,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|}>;

const mapStateToProps: (State) => StateProps = (state) => ({
  id: getId(state),
  formData: getFormData(state),
  isLoading: getIsLoading(state),
  isSaving: getIsSaving(state),
});

const mapDispatchToProps: (
  Dispatch<
    | Actions
    | SetIppFormField
    | SaveIppSaga
    | GetIppSaga
    | SetModifiers
  >
) => DispatchProps = (dispatch) => bindActionCreators(
  {
    onSaveIpp: saveIppSaga,
    getIpp: getIppSaga,
    onSetIppFormField: setIppFormField,
    onSetModifiers: setModifiers,
  },
  dispatch
);

const CreateIpp: StatelessFunctionalComponent<Props> = (props: Props) => {
  const {
    onSaveIpp, getIpp, isLoading, isSaving,
  }: Props = props;

  const { id } = useParams();

  useEffect((): (() => void) => {
    if (id) {
      getIpp(id);
    }
    return () => {};
  }, [getIpp, id]);

  return (
    <Container maxWidth="md">
      <Loader loading={isLoading} withoutWrapper>
        <CreateIppComponent {...props} />
        <Box display="flex" justifyContent="flex-end" mb={5}>
          <LoadingButton
            type="button"
            size="medium"
            variant="contained"
            color="primary"
            onClick={onSaveIpp}
            data-testid="save-button"
            loading={isSaving}
          >
            Save
          </LoadingButton>
        </Box>
      </Loader>
    </Container>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(CreateIpp);
