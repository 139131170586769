// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setFormData, type GetFormDataSagaType } from "@fas/ui-framework/lib/redux/actions/form";
import { GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import {
  FORM_KEY_TARGETING,
  FORM_KEY_CAMPAIGN,
  FORM_KEY_MESSAGE,
  FORM_KEY_SCHEDULE,
  FORM_KEY_MESSAGE_GROUP,
} from "../../helpers/constants";
import setLoading from "../../actions/loading/actions";
import { apiMapGetFormData } from "../../services/form";
import externalRedirect from "../../components/App/ExternalRedirect";

const redirectsMap = {
  [FORM_KEY_TARGETING]: "/targetings",
  [FORM_KEY_CAMPAIGN]: "/campaigns",
  [FORM_KEY_MESSAGE]: "/messages",
  [FORM_KEY_SCHEDULE]: "/schedules",
  [FORM_KEY_MESSAGE_GROUP]: "/messageGroups",
};

const cloneClearMap = {
  [FORM_KEY_MESSAGE]: { id: "", name: "" },
  [FORM_KEY_MESSAGE_GROUP]: { id: "", name: "", messages: [] },
};

export function* makeFetch(action: GetFormDataSagaType): Saga<void> {
  const { key, id }: GetFormDataSagaType = action;
  try {
    yield put(setLoading("GET_FORM_DATA_LOADING", true));
    const dataRaw = yield call(apiMapGetFormData[key], id);
    let data = dataRaw;

    const pathname = window.location.pathname.split("/");
    if (pathname.includes("clone")) {
      data = { ...dataRaw, ...(cloneClearMap[key] || {}) };
    }

    yield put(setFormData(key, data));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
    externalRedirect({ to: redirectsMap[key] });
  }
  finally {
    yield put(setLoading("GET_FORM_DATA_LOADING", false));
  }
}

export default function* watchGetFormDataSaga(): Saga<void> {
  yield takeEvery(GET_FORM_DATA_SAGA, (makeFetch: Function));
}
