// @flow
/* eslint-disable import/max-dependencies */
import { type StatelessFunctionalComponent } from "react";
import type { Url, CollapseItem } from "@fas/ui-core/lib/AppNavbar";
import { userService } from "../services/user";
import type { Permission } from "../services/Acl";
import {
  PERMISSION_WEB_PUSH_CAMPAIGNS,
  PERMISSION_SCHEDULE,
  PERMISSION_MESSAGES_DICTIONARY,
  PERMISSION_TARGETING_DICTIONARY,
  PERMISSION_SEGMENTS_DICTIONARY,
  PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
  PERMISSION_MESSAGE_GROUPS_DICTIONARY,
  PERMISSION_LIMITS_STAT,
  PERMISSION_PWA_ICONS,
  PERMISSION_PWA,
  PERMISSION_PWA_OFFLINE_PAGES,
  PERMISSION_IPP,
} from "../services/Acl";
import WPCampaignsGrid from "../pages/WebPushCampaignsList";
import CreateCampaign from "../pages/CreateCampaign";
import CampaignView from "../pages/CampaignView";
import WPSchedule from "../pages/CreateSchedule";
import MessagesList from "../pages/MessagesList";
import WPMessage from "../pages/CreateMessage";
import TargetingsList from "../pages/TargetingsList";
import CreateTargeting from "../pages/CreateTargeting";
import TargetingView from "../pages/TargetingView";
import SegmentsList from "../pages/SegmentsList";
import WPSegment from "../pages/CreateSegment";
import MotivationContentList from "../pages/MotivationContentList";
import WPMotivationContent from "../pages/CreateWpMotivationContent";
import WpMotivationContentView from "../pages/WpMotivationContentView";
import MessageGroupsList from "../pages/MessageGroupsList";
import WPMessageGroups from "../pages/CreateMessageGroup";
import LimitStat from "../pages/LimitStat";
import PwaIconsList from "../pages/PwaIconsList";
import PwaIconCreate from "../pages/CreatePwaIcon";
import SchedulesList from "../pages/SchedulesList";
import CreateIPP from "../pages/CreateIPP";
import PWAList from "../pages/PwaMotivationContentList";
import PWAForm from "../pages/CreatePwaMotivationContent";
import PWAView from "../pages/PwaMotivationView";
import IPPList from "../pages/IPPList";
import IppView from "../pages/IppView";
import ScheduleView from "../pages/ScheduleView";
import SegmentView from "../pages/SegmentView";
import MessageGroupsView from "../pages/MessageGroupsView";
import MessagesView from "../pages/MessagesView";
import PwaOfflinePagesList from "../pages/PwaOfflinePagesList";
import PwaOfflineForm from "../pages/CreatePwaOfflinePage";
import PwaOfflinePageView from "../pages/PwaOfflinePageView";
import MotivationTemplatesList from "../pages/MotivationTemplatesList";
import CreateMotivationTemplate from "../pages/CreateMotivationTemplate";
import MotivationTemplateView from "../pages/MotivationTemplateView";

type MenuItem = {
  title: string,
  permission: Permission,
  link: string,
  components: Array<{|
    component: StatelessFunctionalComponent<{}>,
    path: string,
  |}>,
};

type Link = {|
    text: string,
    permission: Permission,
    url: string,
|}

type UICoreMenuItem = {|
  text: string,
  url?: string,
  permission?: Permission,
  links?: Array<Link>,
|};

const menuItems: MenuItem[] = [
  {
    title: "Web push campaigns",
    permission: PERMISSION_WEB_PUSH_CAMPAIGNS,
    link: "/campaigns",
    components: [
      {
        component: WPCampaignsGrid,
        path: "/campaigns",
      },
      {
        component: CreateCampaign,
        path: "/campaigns/create",
      },
      {
        component: CreateCampaign,
        path: "/campaigns/edit/:id",
      },
      {
        component: CampaignView,
        path: "/campaigns/view/:id",
      },
    ],
  },
  {
    title: "Web push schedule",
    permission: PERMISSION_SCHEDULE,
    link: "/schedules",
    components: [
      {
        component: SchedulesList,
        path: "/schedules",
      },
      {
        component: WPSchedule,
        path: "/schedules/create",
      },
      {
        component: WPSchedule,
        path: "/schedules/edit/:id",
      },
      {
        component: ScheduleView,
        path: "/schedules/view/:id",
      },
    ],
  },
  {
    title: "Targeting",
    permission: PERMISSION_TARGETING_DICTIONARY,
    link: "/targetings",
    components: [
      {
        component: TargetingsList,
        path: "/targetings",
      },
      {
        component: CreateTargeting,
        path: "/targetings/create",
      },
      {
        component: CreateTargeting,
        path: "/targetings/edit/:id",
      },
      {
        component: TargetingView,
        path: "/targetings/view/:id",
      },
    ],
  },
  {
    title: "WebPush Motivation Content",
    permission: PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
    link: "/webpush",
    components: [
      {
        component: MotivationContentList,
        path: "/webpush",
      },
      {
        component: WPMotivationContent,
        path: "/webpush/create",
      },
      {
        component: WPMotivationContent,
        path: "/webpush/edit/:id",
      },
      {
        component: WpMotivationContentView,
        path: "/webpush/view/:id",
      },
    ],
  },
  {
    title: "Segments",
    permission: PERMISSION_SEGMENTS_DICTIONARY,
    link: "/segments",
    components: [
      {
        component: SegmentsList,
        path: "/segments",
      },
      {
        component: WPSegment,
        path: "/segments/create",
      },
      {
        component: WPSegment,
        path: "/segments/edit/:id",
      },
      {
        component: SegmentView,
        path: "/segments/view/:id",
      },
    ],
  },
  {
    title: "Messages",
    permission: PERMISSION_MESSAGES_DICTIONARY,
    link: "/messages",
    components: [
      {
        component: MessagesList,
        path: "/messages",
      },
      {
        component: WPMessage,
        path: "/messages/create",
      },
      {
        component: WPMessage,
        path: "/messages/edit/:id",
      },
      {
        component: MessagesView,
        path: "/messages/view/:id",
      },
      {
        component: WPMessage,
        path: "/messages/clone/:id",
      },
    ],
  },
  {
    title: "Message Groups",
    permission: PERMISSION_MESSAGE_GROUPS_DICTIONARY,
    link: "/messageGroups",
    components: [
      {
        component: MessageGroupsList,
        path: "/messageGroups",
      },
      {
        component: WPMessageGroups,
        path: "/messageGroups/create",
      },
      {
        component: WPMessageGroups,
        path: "/messageGroups/edit/:id",
      },
      {
        component: WPMessageGroups,
        path: "/messageGroups/clone/:id",
      },
      {
        component: MessageGroupsView,
        path: "/messageGroups/view/:id",
      },
    ],
  },
  {
    title: "Limits Stat",
    permission: PERMISSION_LIMITS_STAT,
    link: "/limitsStat",
    components: [
      {
        component: LimitStat,
        path: "/limitsStat",
      },
    ],
  },
  {
    title: "Pwa Icons",
    permission: PERMISSION_PWA_ICONS,
    link: "/pwaIcons",
    components: [
      {
        component: PwaIconsList,
        path: "/pwaIcons",
      },
      {
        component: PwaIconCreate,
        path: "/pwaIcons/create",
      },
      {
        component: PwaIconCreate,
        path: "/pwaIcons/edit/:id",
      },
    ],
  },
  {
    title: "IPP Motivation Content",
    permission: PERMISSION_IPP,
    link: "/ipp",
    components: [
      {
        component: CreateIPP,
        path: "/ipp/edit/:id",
      },
      {
        component: CreateIPP,
        path: "/ipp/create",
      },
      {
        component: IPPList,
        path: "/ipp",
      },
      {
        component: IppView,
        path: "/ipp/view/:id",
      },
    ],
  },
  {
    title: "PWA Motivation Content",
    permission: PERMISSION_PWA,
    link: "/pwa",
    components: [
      {
        component: PWAView,
        path: "/pwa/view/:id",
      },
      {
        component: PWAForm,
        path: "/pwa/edit/:id",
      },
      {
        component: PWAForm,
        path: "/pwa/create",
      },
      {
        component: PWAList,
        path: "/pwa",
      },
    ],
  },
  {
    title: "PWA Offline pages",
    permission: PERMISSION_PWA_OFFLINE_PAGES,
    link: "/pwaOfflinePages",
    components: [
      {
        component: PwaOfflinePagesList,
        path: "/pwaOfflinePages",
      },
      {
        component: PwaOfflineForm,
        path: "/pwaOfflinePages/edit/:id",
      },
      {
        component: PwaOfflineForm,
        path: "/pwaOfflinePages/create",
      },
      {
        component: PwaOfflinePageView,
        path: "/pwaOfflinePages/view/:id",
      },
    ],
  },
  {
    title: "Motivation Template",
    permission: PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
    link: "/motivationTemplates",
    components: [
      {
        component: MotivationTemplatesList,
        path: "/motivationTemplates",
      },
      {
        component: CreateMotivationTemplate,
        path: "/motivationTemplates/edit/:id",
      },
      {
        component: CreateMotivationTemplate,
        path: "/motivationTemplates/create",
      },
      {
        component: MotivationTemplateView,
        path: "/motivationTemplates/view/:id",
      },
    ],
  },
];

export default menuItems;

const uiCoreMenuItems: UICoreMenuItem[] = [
  {
    text: "Campaign Setup",
    links: [
      {
        text: "Web push campaigns",
        permission: PERMISSION_WEB_PUSH_CAMPAIGNS,
        url: "/campaigns",
      },
      {
        text: "Targeting",
        permission: PERMISSION_TARGETING_DICTIONARY,
        url: "/targetings",
      },
      {
        text: "Motivation Template",
        permission: PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
        url: "/motivationTemplates",
      },
      {
        text: "WebPush Motivation Content",
        permission: PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
        url: "/webpush",
      },
      {
        text: "IPP Motivation Content",
        permission: PERMISSION_IPP,
        url: "/ipp",
      },
      {
        text: "PWA Motivation Content",
        permission: PERMISSION_PWA,
        url: "/pwa",
      },
      {
        text: "PWA Offline Pages",
        permission: PERMISSION_PWA_OFFLINE_PAGES,
        url: "/pwaOfflinePages",
      },
      {
        text: "Pwa Icons",
        permission: PERMISSION_PWA_ICONS,
        url: "/pwaIcons",
      },
    ],
  },
  {
    text: "Messages Setup",
    links: [
      {
        text: "Message Groups",
        permission: PERMISSION_MESSAGE_GROUPS_DICTIONARY,
        url: "/messageGroups",
      },
      {
        text: "Messages",
        permission: PERMISSION_MESSAGES_DICTIONARY,
        url: "/messages",
      },
    ],
  },
  {
    text: "Schedules Setup",
    links: [
      {
        text: "Web push schedule",
        permission: PERMISSION_SCHEDULE,
        url: "/schedules",
      },
      {
        text: "Segments",
        permission: PERMISSION_SEGMENTS_DICTIONARY,
        url: "/segments",
      },
    ],
  },
  {
    text: "Limits Stat",
    permission: PERMISSION_LIMITS_STAT,
    url: "/limitsStat",
  },
];

export function getUiCoreMenuItems(): Array<Url | CollapseItem> {
  const filterByPermission: (UICoreMenuItem | Link) => boolean = ({ permission }) => {
    if (permission) {
      return userService.can([permission]);
    }
    return userService.can([]);
  };

  const mapToUrl: (Link) => Url = ({ url, text }) => ({ url, text });

  return uiCoreMenuItems
    .filter(filterByPermission)
    .map(({
      url = "",
      text,
      links,
    }: UICoreMenuItem): Url | CollapseItem => {
      if (links) {
        return ({ text, links: links.filter(filterByPermission).map(mapToUrl) });
      }
      return ({ text, url });
    }).filter((item: Url | CollapseItem): boolean => !item.links || item.links.length !== 0);
}
