/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import { useDispatch } from "react-redux";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { useTable } from "@fas/ui-framework/lib/services/table";
import { Loader, AddData, Table } from "@fas/ui-core";
import { Box } from "@mui/material";
import type { LimitStatItem } from "../../reducers/limitStatList";
import { getLimitStatSaga } from "../../actions/limitStatList";
import changeModalOpen from "../../actions/modals";
import { LIMIT_STAT_TABLE } from "../../helpers/constants";

export type Props = $ReadOnly<{||}>;

const LimitStatList: StatelessFunctionalComponent<Props> = () => {
  const dispatch = useDispatch();
  const { data, isLoading, ...tableProps } = useTable(LIMIT_STAT_TABLE);
  const handleLoad = () => dispatch(getLimitStatSaga());

  const columns: Column<LimitStatItem>[] = [
    {
      field: "minimum",
      label: "Minimum",
    },
    {
      field: "maximum",
      label: "Maximum",
    },
  ];

  if (data.some((item: LimitStatItem): boolean => item.WebPushSegment !== "")) {
    columns.unshift({
      field: "WebPushSegment",
      label: "Segment",
    });
  }

  if (data.some((item: LimitStatItem): boolean => item.date !== "")) {
    columns.unshift({
      field: "date",
      label: "Date",
    });
  }

  return (
    <>
      {data.length === 0 && (
        <Box pt={3}>
          <Loader loading={isLoading}>
            <AddData
              linkText="+ Click to change filters"
              title="No stat data found or filters are incorrect"
              onClick={() => {
                dispatch(changeModalOpen("isFiltersModalOpen", true));
              }}
            />
          </Loader>
        </Box>
      )}
      {data.length !== 0 && (
        <Table
          {...tableProps}
          data={data}
          isLoading={isLoading}
          title="Segments"
          columns={columns}
          onLoad={handleLoad}
          rowSelectAvailable={() => true}
          allSelectAvailable={() => true}
        />
      )}
    </>
  );
};

export default LimitStatList;
