// @flow
import {
  GET_MESSAGES_LIST_SAGA,
  CHANGE_MESSAGE_IS_ACTIVE_SAGA,
  DELETE_MESSAGES_SAGA,
} from "../../helpers/constants";

export type GetMessagesListSaga = {|
  type: typeof GET_MESSAGES_LIST_SAGA,
|};

export type ChangeMessageIsActiveSaga = {|
  type: typeof CHANGE_MESSAGE_IS_ACTIVE_SAGA,
  isActive: boolean,
|};

export type DeleteMessagesSaga = {|
  type: typeof DELETE_MESSAGES_SAGA,
|};

export type Actions =
  GetMessagesListSaga
  | ChangeMessageIsActiveSaga
  | DeleteMessagesSaga;

export const getMessagesListSaga: () => GetMessagesListSaga = () => ({ type: GET_MESSAGES_LIST_SAGA });

export const changeMessageIsActiveSaga: (boolean) => ChangeMessageIsActiveSaga = (isActive) => ({
  type: CHANGE_MESSAGE_IS_ACTIVE_SAGA,
  isActive,
});

export const deleteMessagesSaga: () => DeleteMessagesSaga = () => ({
  type: DELETE_MESSAGES_SAGA,
});
