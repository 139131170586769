// @flow
import type { Node } from "react";
import type { MotivationTemplates, MotivationTemplateType } from "../../containers/CreateMotivationTemplate";

type Props = {
  motivationTemplates: MotivationTemplates,
}

function MotivationTemplatesColumn({
  motivationTemplates,
}: Props): Node {
  if (!motivationTemplates) return "-";
  const names: string[] = Object.keys(motivationTemplates)
    .map((type: MotivationTemplateType): string => motivationTemplates[type].name);
  return names.join(", ");
}

export default MotivationTemplatesColumn;
