/* eslint-disable import/max-dependencies */
// @flow
import React, { type Element, type StatelessFunctionalComponent } from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import {
  Table,
  IsActiveLabel,
  ViewLink,
  SelectSearchComponent,
  ShowMoreItems,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import { TARGETINGS_LIST_TABLE } from "../../helpers/constants";
import { getTargetingsListSaga } from "../../actions/targetingsList";
import externalRedirect from "../../components/App/ExternalRedirect";
import { getFields } from "../../services/utils";
import type { Classes, Targeting as TargetingListItem } from "./types/TargetingsList.types";

type Props = $ReadOnly<{|
  Actions?: Element<*>,
  choicedData?: Array<TargetingListItem>,
  onSelect?: (TargetingListItem) => mixed,
  onDeselect?: (TargetingListItem) => mixed,
  onCreate?: () => mixed,
  allSelectAvailable?: () => boolean,
  rowSelectAvailable?: () => boolean,
|}>;

const useStyles: () => Classes = makeStyles((): {[$Keys<Classes>]: *} => ({
  fixedSizeColumn: {
    width: "20%",
  },
}));

const handleCreate: () => null = () => externalRedirect({ to: "/targetings/create", target: "_self" });

const TargetingsList: StatelessFunctionalComponent<Props> = ({
  Actions,
  choicedData,
  onSelect,
  onDeselect,
  onCreate = handleCreate,
  allSelectAvailable = (): boolean => true,
  rowSelectAvailable = (): boolean => true,
}) => {
  const classes: Classes = useStyles();
  const tableProps: TableProps = useTable(TARGETINGS_LIST_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const columns: Column<TargetingListItem>[] = [
    {
      label: "Status",
      field: "isActive",
      searchable: true,
      SearchComponent: (props: SearchComponentProps): Element<*> => (
        <SelectSearchComponent
          {...props}
          label="Select"
          values={[
            { value: "", title: "None" },
            { value: "true", title: "Active" },
            { value: "false", title: "Inactive" },
          ]}
        />
      ),
      render: IsActiveLabel,
    },
    {
      label: "Name",
      field: "name",
      searchable: true,
      render: (row: TargetingListItem): Element<*> => <ViewLink {...row} viewPath="/targetings/view/:id" />,
    },
    {
      field: "BrowserLanguages",
      label: "Browser Languages",
      searchable: true,
      className: classes.fixedSizeColumn,
      render: (row: TargetingListItem): Element<*> => <ShowMoreItems data={getFields(row.BrowserLanguages, "language")} />,
    },
    {
      field: "placement",
      label: "Placement",
      searchable: true,
    },
    {
      field: "platforms",
      label: "Platforms",
      render: (row: TargetingListItem): Element<*> => <div>{row.platforms.join(", ")}</div>,
    },
    {
      field: "data1",
      label: "Data1",
      searchable: true,
    },
  ];

  const onLoad: () => mixed = () => dispatch(getTargetingsListSaga());

  return (
    <Box m={2}>
      <Table
        {...tableProps}
        columns={columns}
        title="Targeting"
        onLoad={onLoad}
        onCreate={onCreate}
        selected={choicedData || tableProps.selected}
        onSelect={onSelect || tableProps.onSelect}
        onDeselect={onDeselect || tableProps.onDeselect}
        rowSelectAvailable={rowSelectAvailable}
        allSelectAvailable={allSelectAvailable}
        Actions={Actions}
      />
    </Box>
  );
};

export default TargetingsList;
