// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, type Node } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Loader, SelectCustom } from "@fas/ui-core";
import {
  Box,
  Grid,
  Typography,
  Divider,
  InputLabel,
  Paper,
  TextField,
  Container,
  Button,
} from "@mui/material";
import { SwitchFieldForm } from "@fas/ui-core/lib/Form";
import CodeEditor from "../../components/CodeEditor";
import { MOTIVATION_TEMPLATE_FORM } from "../../helpers/constants";
import {
  TEMPLATE_TYPES,
  POPUP_TYPES,
  type MotivationTemplateFormData,
  type Handlers,
  type MotivationTemplateType,
} from "./CreateMotivationTemplate.types";
import { getFormData } from "../../selectors/motivationTemplates";
import * as actions from "../../actions/createMotivationTemplate";
import type { Actions } from "../../actions/createMotivationTemplate/actions.types";
import { useActions } from "../../hooks/useActions";
import { useLoading } from "../../hooks/useLoading";
import Modifiers, { HtmlPreviewWithModifiers } from "../Modifiers";
import AdditionalInfo from "../../components/AdditionalInfo/AdditionalInfo";

function CreateMotivationTemplate(): Node {
  const {
    name,
    type,
    isActive,
    html,
    css,
    motivationContents,
  }: MotivationTemplateFormData = useSelector(getFormData);

  const {
    setMotivationTemplateField: onSetMotivationTemplateField,
    getMotivationTemplateSaga: onGetMotivationTemplate,
    saveMotivationTemplateSaga: onSaveMotivationTemplate,
    // $FlowFixMe
  }: Handlers = useActions<Actions>(actions);

  const { id: templateId } = useParams();
  const isLoading: boolean = useLoading(MOTIVATION_TEMPLATE_FORM);

  useEffect(() => {
    if (templateId) {
      onGetMotivationTemplate(templateId);
    }
  }, [templateId]);

  const tabs = POPUP_TYPES.includes(type)
    ? [{
      name: "html-editor",
      label: "html",
      value: html,
      mode: "html",
      onChange: (value: string) => {
        onSetMotivationTemplateField({ html: value });
      },
    },
    {
      name: "css-editor",
      label: "css",
      value: css,
      mode: "css",
      onChange: (value: string) => {
        onSetMotivationTemplateField({ css: value });
      },
    }]
    : [{
      name: "css-editor",
      label: "head",
      value: css,
      mode: "html",
      onChange: (value: string) => {
        onSetMotivationTemplateField({ css: value });
      },
    },
    {
      name: "html-editor",
      label: "body",
      value: html,
      mode: "html",
      onChange: (value: string) => {
        onSetMotivationTemplateField({ html: value });
      },
    }];

  return (
    <Container maxWidth="md">
      <Loader loading={isLoading} withoutWrapper>
        <Paper sx={{ mt: 6, mb: 3, p: 3 }}>
          <Box mb={3}>
            <Typography gutterBottom variant="h5" component="h2">
              Motivation Template
            </Typography>
          </Box>

          <Box mb={2} width={1}>
            <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
              <Grid item xs={3}>
                <Typography>Name</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  data-testid="name"
                  fullWidth
                  size="small"
                  name="name"
                  placeholder="Example: newName"
                  value={name}
                  onChange={({ target: { value } }: SyntheticInputEvent<HTMLInputElement>) => {
                    onSetMotivationTemplateField({ name: value });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={2} width={1}>
            <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
              <Grid item xs={3}>
                <Typography>Type</Typography>
              </Grid>
              <Grid item xs={9}>
                <SelectCustom
                  title=""
                  dataTestid="type"
                  value={type}
                  values={TEMPLATE_TYPES}
                  disabled={Boolean(templateId)}
                  onTypeChange={({ target: { value } }: { target: { value: MotivationTemplateType } }) => {
                    onSetMotivationTemplateField({ type: value });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          {templateId && (
            <Box mb={2} width={1}>
              <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
                <Grid item xs={3}>
                  <InputLabel htmlFor="isActive">Is Active</InputLabel>
                </Grid>
                <Grid item xs={9}>
                  <SwitchFieldForm
                    name="isActive"
                    label=""
                    value={isActive}
                    onChange={(value: boolean) => {
                      onSetMotivationTemplateField({ isActive: value });
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          <Box width={1} mb={2} mt={3}>
            <Divider />
          </Box>
          <Box my={1} width={1}>
            <Typography gutterBottom variant="h5" component="h2">
              Setup
            </Typography>
          </Box>
          <Grid container justifyContent="center">
            <Grid item xs={12} justifyContent="center">
              <CodeEditor
                height="400px"
                tabs={tabs}
              />
            </Grid>
            <Box mb={2} width={1} display="flex" justifyContent="flex-end">
              <HtmlPreviewWithModifiers
                templateType={type}
                title="Motivation Content preview"
                data-testid="motivation-content-preview-btn"
                html={html}
                css={css}
              />
            </Box>
          </Grid>

          <Box width={1} mb={2} mt={3}>
            <Divider />
          </Box>

          <Modifiers templateType={type} />
        </Paper>

        <AdditionalInfo usedInMotivationContents={motivationContents} />

        <Box display="flex" justifyContent="flex-end" mb={5}>
          <Button
            type="button"
            size="medium"
            variant="contained"
            color="primary"
            onClick={onSaveMotivationTemplate}
            data-testid="save-button"
            disabled={isLoading}
          >
            Save
          </Button>
        </Box>
      </Loader>
    </Container>
  );
}

export default CreateMotivationTemplate;
