// @flow

import React, { useMemo, type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import Notification from "../../components/Notification";
import CreateScheduleContainer from "../../containers/CreateSchedule";

const CreateSchedule: StatelessFunctionalComponent<{}> = () => {
  const store = useMemo(configureStore, []);
  return (
    <DocumentTitle title="Create Schedule">
      <Provider store={store}>
        <CreateScheduleContainer />
        <Notification />
      </Provider>
    </DocumentTitle>
  );
};
export default CreateSchedule;
