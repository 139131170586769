export default (baseUrl, adsBoxApiUrl, adsBoxApiKey) => ({
  baseUrl,
  adsBoxApiUrl,
  adsBoxApiKey,
  appLinks: {
    dmp: "https://rel-admin-dmp.insigit.com/",
    jus: "https://jus.rel.insigit.com/",
    cpa: "https://cpa-admin.rel.insigit.com/",
    webPush: "https://webpush.rel.noclef.com/",
    adsBox: "https://adsbox.rel.insigit.com/",
    reports: "https://reports.rel.insigit.com/managersReport",
    crm: "https://crm.rel.insigit.com/",
  },
  links: {
    login: "/login",
    cdnDomain: "https://cdn.gen-ref.com",
  },
  endpoints: {
    get: {
      signOut: `${baseUrl}/v2/auth/signout`,
      browserLanguages: `${baseUrl}/v2/api/dictionaries/browserLanguages`,
      placements: `${baseUrl}/v2/api/dictionaries/placements`,
      messageGroupsList: `${baseUrl}/v2/api/webpush/messageGroups`,
      messageGroupById: `${baseUrl}/v2/api/webpush/messageGroups/{id}`,
      motivationContentList: `${baseUrl}/v2/api/webpush/motivationContents`,
      motivationContentById: `${baseUrl}/v2/api/webpush/motivationContents/{id}`,
      targetingsList: `${baseUrl}/v2/api/webpush/targetings`,
      targetingById: `${baseUrl}/v2/api/webpush/targetings/{id}`,
      messagesList: `${baseUrl}/v2/api/webpush/messages`,
      messageById: `${baseUrl}/v2/api/webpush/messages/{id}`,
      tags: `${baseUrl}/v2/api/tags`,
      campaignsList: `${baseUrl}/v2/api/webpush/campaigns`,
      campaignById: `${baseUrl}/v2/api/webpush/campaigns/{id}`,
      schedulesList: `${baseUrl}/v2/api/webpush/schedules`,
      scheduleById: `${baseUrl}/v2/api/webpush/schedules/{id}`,
      segmentsList: `${baseUrl}/v2/api/webpush/segments`,
      segmentById: `${baseUrl}/v2/api/webpush/segments/{id}`,
      queryBuilder: `${baseUrl}/v2/api/dictionaries/querybuilder`,
      limits: `${baseUrl}/v2/api/limits`,
      limitIncidents: `${baseUrl}/v2/api/webpush/limitIncidents`,
      checkImage: `${adsBoxApiUrl}/api/images/check/{hash}`,
      pwaIconsList: `${baseUrl}/v2/api/webpush/pwa/icons`,
      pwaIconById: `${baseUrl}/v2/api/webpush/pwa/icons/{id}`,
      pwaList: `${baseUrl}/v2/api/pwa/motivationContents`,
      pwaById: `${baseUrl}/v2/api/pwa/motivationContents/{id}`,
      pwaOfflinePagesList: `${baseUrl}/v2/api/pwa/offlinePages`,
      pwaOfflinePageById: `${baseUrl}/v2/api/pwa/offlinePages/{id}`,
      ippList: `${baseUrl}/v2/api/ipp/motivationContents`,
      ippById: `${baseUrl}/v2/api/ipp/motivationContents/{id}`,
      data1: `${baseUrl}/v2/api/data1`,
      browsersInfo: `${baseUrl}/v2/api/dictionaries/browsers`,
      devicesInfo: `${baseUrl}/v2/api/dictionaries/devices`,
      dictionaryByName: `${baseUrl}/v2/api/dictionaries/{name}`,
      getMotivationTemplatesList: `${baseUrl}/v2/api/motivationTemplates`,
      getMotivationTemplateById: `${baseUrl}/v2/api/motivationTemplates/{id}`,
      getDropdownList: `${baseUrl}/v2/api/newDictionaries/{dictionaryName}`,
    },
    post: {
      token: `${baseUrl}/v2/auth/token`,
      signUp: `${baseUrl}/v2/auth/signup`,
      messageGroups: `${baseUrl}/v2/api/webpush/messageGroups`,
      motivationContent: `${baseUrl}/v2/api/webpush/motivationContents`,
      targetings: `${baseUrl}/v2/api/webpush/targetings`,
      messages: `${baseUrl}/v2/api/webpush/messages`,
      campaigns: `${baseUrl}/v2/api/webpush/campaigns`,
      schedules: `${baseUrl}/v2/api/webpush/schedules`,
      segments: `${baseUrl}/v2/api/webpush/segments`,
      duplicateData: `${baseUrl}/v2/api/duplicateData`, // clarify requirements
      saveTag: `${baseUrl}/v2/api/tags`,
      adsBoxImage: `${adsBoxApiUrl}/api/images`,
      pwaIcons: `${baseUrl}/v2/api/webpush/pwa/icons`,
      pwa: `${baseUrl}/v2/api/pwa/motivationContents`,
      pwaOfflinePages: `${baseUrl}/v2/api/pwa/offlinePages`,
      ipp: `${baseUrl}/v2/api/ipp/motivationContents`,
      data1: `${baseUrl}/v2/api/data1`,
      saveMotivationTemplate: `${baseUrl}/v2/api/motivationTemplates`,
    },
    put: {
      messages: `${baseUrl}/v2/api/webpush/messages/{id}`,
      segments: `${baseUrl}/v2/api/webpush/segments/{id}`,
      targetings: `${baseUrl}/v2/api/webpush/targetings/{id}`,
      schedules: `${baseUrl}/v2/api/webpush/schedules/{id}`,
      pwaIcons: `${baseUrl}/v2/api/webpush/pwa/icons/{id}`,
      motivationContent: `${baseUrl}/v2/api/webpush/motivationContents/{id}`,
      campaign: `${baseUrl}/v2/api/webpush/campaigns/{id}`,
      pwa: `${baseUrl}/v2/api/pwa/motivationContents/{id}`,
      pwaOfflinePages: `${baseUrl}/v2/api/pwa/offlinePages/{id}`,
      ipp: `${baseUrl}/v2/api/ipp/motivationContents/{id}`,
      messageGroups: `${baseUrl}/v2/api/webpush/messageGroups/{id}`,
      updateMotivationTemplate: `${baseUrl}/v2/api/motivationTemplates/{id}`,
    },
    delete: {
      campaigns: `${baseUrl}/v2/api/webpush/campaigns`,
      schedules: `${baseUrl}/v2/api/webpush/schedules`,
      messageGroups: `${baseUrl}/v2/api/webpush/messageGroups`,
      messages: `${baseUrl}/v2/api/webpush/messages`,
      motivationContent: `${baseUrl}/v2/api/webpush/motivationContents`,
      targetings: `${baseUrl}/v2/api/webpush/targetings`,
      segments: `${baseUrl}/v2/api/webpush/segments`,
      pwaIcons: `${baseUrl}/v2/api/webpush/pwa/icons`,
      pwa: `${baseUrl}/v2/api/pwa/motivationContents`,
      pwaOfflinePages: `${baseUrl}/v2/api/pwa/offlinePages`,
      ipp: `${baseUrl}/v2/api/ipp/motivationContents`,
      data1: `${baseUrl}/v2/api/data1`,
      deleteMotivationTemplate: `${baseUrl}/v2/api/motivationTemplates`,
    },
  },
});
