// @flow
import { fromUIOperatorMapper } from "../..";
import type { FromUI } from "../..";
import type { UIRule, ServerRule } from "../types";

export default class MultivalueBased implements FromUI<UIRule, ServerRule> {
  rule: UIRule;

  constructor(rule: UIRule) {
    this.rule = rule;
  }

  convert(): ServerRule {
    const { field, operator, value } = this.rule;

    const serverOperator: string = fromUIOperatorMapper[operator];

    return {
      [field]: {
        [serverOperator]: value,
      },
    };
  }
}
