/* eslint-disable import/prefer-default-export */
// @flow
import type { Targeting, TargetingShort } from "../containers/TargetingsList";
import type { BrowserLanguage } from "../containers/CreateMessageGroup";

export const targetingToShort: (Targeting) => TargetingShort = (targeting) => {
  const {
    name,
    isActive,
    data1,
    placement,
    platforms,
    BrowserLanguages,
  }: Targeting = targeting;

  return {
    name,
    isActive,
    data1,
    placement,
    platforms,
    BrowserLanguages: BrowserLanguages.map(({ id }: BrowserLanguage): string => id),
  };
};
