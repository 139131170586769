// @flow
export const VIEW_MOTIV_LOADING: "viewMotivLoading" = "viewMotivLoading";

export const GET_MOTIV_CONTENT_SAGA: "GET_MOTIV_CONTENT_SAGA" = "GET_MOTIV_CONTENT_SAGA";
export const SET_MOTIV_CONTENT: "SET_MOTIV_CONTENT" = "SET_MOTIV_CONTENT";
export const SET_PWA_ICONS: "SET_PWA_ICONS" = "SET_PWA_ICONS";

export const STEP_CLICK: "STEP_CLICK" = "STEP_CLICK";
export const STEP_CHANGE: "STEP_CHANGE" = "STEP_CHANGE";
export const SET_STEP_ERROR: "SET_STEP_ERROR" = "SET_STEP_ERROR";

export const CHANGE_LIMIT_NAME: "CHANGE_LIMIT_NAME" = "CHANGE_LIMIT_NAME";
export const CHANGE_LIMIT_IS_ACTIVE: "CHANGE_LIMIT_IS_ACTIVE" = "CHANGE_LIMIT_IS_ACTIVE";

export const SET_SEGMENTS: "SET_SEGMENTS" = "SET_SEGMENTS";
export const DELETE_SEGMENT: "DELETE_SEGMENT" = "DELETE_SEGMENT";

export const CHANGE_MODAL_OPEN: "CHANGE_MODAL_OPEN" = "CHANGE_MODAL_OPEN";

export const CHANGE_MIN_MESSAGE_LIMIT: "CHANGE_MIN_MESSAGE_LIMIT" = "CHANGE_MIN_MESSAGE_LIMIT";
export const CHANGE_MAX_MESSAGE_LIMIT: "CHANGE_MAX_MESSAGE_LIMIT" = "CHANGE_MAX_MESSAGE_LIMIT";

export const SET_LIMITS: "SET_LIMITS" = "SET_LIMITS";
export const GET_LIMITS_LIST_SAGA: "GET_LIMITS_LIST_SAGA" = "GET_LIMITS_LIST_SAGA";

export const CHANGE_TABLE_PAGE: "CHANGE_TABLE_PAGE" = "CHANGE_TABLE_PAGE";
export const CHANGE_TABLE_SORTING: "CHANGE_TABLE_SORTING" = "CHANGE_TABLE_SORTING";
export const CHANGE_TABLE_FILTERS: "CHANGE_TABLE_FILTERS" = "CHANGE_TABLE_FILTERS";
export const CHANGE_TABLE_PAGE_SIZE: "CHANGE_TABLE_PAGE_SIZE" = "CHANGE_TABLE_PAGE_SIZE";

export const MESSAGE_GROUPS_VIEW_LOADING: "MESSAGE_GROUPS_VIEW_LOADING" = "MESSAGE_GROUPS_VIEW_LOADING";
export const GET_MESSAGE_GROUPS_SAGA: "GET_MESSAGE_GROUPS_SAGA" = "GET_MESSAGE_GROUPS_SAGA";
export const SET_MESSAGE_GROUPS: "SET_MESSAGE_GROUPS" = "SET_MESSAGE_GROUPS";
export const DELETE_MESSAGE_GROUP: "DELETE_MESSAGE_GROUP" = "DELETE_MESSAGE_GROUP";
export const FORM_KEY_MESSAGE_GROUP: "FORM_KEY_MESSAGE_GROUP" = "FORM_KEY_MESSAGE_GROUP";
export const ADD_TAG_SAGA: "ADD_TAG_SAGA" = "ADD_TAG_SAGA";

export const CHANGE_LIMIT_STAT_FILTER: "CHANGE_LIMIT_STAT_FILTER" = "CHANGE_LIMIT_STAT_FILTER";
export const CHANGE_LIMIT_STAT_GROUP_BY: "CHANGE_LIMIT_STAT_GROUP_BY" = "CHANGE_LIMIT_STAT_GROUP_BY";
export const LIMIT_STAT_TABLE: "LIMIT_STAT_TABLE" = "LIMIT_STAT_TABLE";

export const SET_ERRORS: "SET_ERRORS" = "SET_ERRORS";

export const SET_SEGMENTS_LIST: "SET_SEGMENTS_LIST" = "SET_SEGMENTS_LIST";
export const SET_SEGMENTS_COUNT: "SET_SEGMENTS_COUNT" = "SET_SEGMENTS_COUNT";
export const CHANGE_SEGMENTS_LOADING: "CHANGE_SEGMENTS_LOADING" = "CHANGE_SEGMENTS_LOADING";
export const GET_SEGMENTS_LIST_SAGA: "GET_SEGMENTS_LIST_SAGA" = "GET_SEGMENTS_LIST_SAGA";
export const CHANGE_SEGMENT_IS_ACTIVE_SAGA: "CHANGE_SEGMENT_IS_ACTIVE_SAGA" = "CHANGE_SEGMENT_IS_ACTIVE_SAGA";
export const DELETE_SEGMENTS_SAGA: "DELETE_SEGMENTS_SAGA" = "DELETE_SEGMENTS_SAGA";
export const SEGMENTS_LIST_TABLE: "SEGMENTS_LIST_TABLE" = "SEGMENTS_LIST_TABLE";
export const GET_TARGETINGS_LIST_SAGA: "GET_TARGETINGS_LIST_SAGA" = "GET_TARGETINGS_LIST_SAGA";
export const TARGETINGS_LIST_TABLE: "TARGETINGS_LIST_TABLE" = "TARGETINGS_LIST_TABLE";

export const SET_MESSAGE_GROUPS_LIST: "SET_MESSAGE_GROUPS_LIST" = "SET_MESSAGE_GROUPS_LIST";
export const SET_MESSAGE_GROUPS_COUNT: "SET_MESSAGE_GROUPS_COUNT" = "SET_MESSAGE_GROUPS_COUNT";
export const CHANGE_MESSAGE_GROUPS_LOADING: "CHANGE_MESSAGE_GROUPS_LOADING" = "CHANGE_MESSAGE_GROUPS_LOADING";
export const GET_MESSAGE_GROUPS_LIST_SAGA: "GET_MESSAGE_GROUPS_LIST_SAGA" = "GET_MESSAGE_GROUPS_LIST_SAGA";
export const CHANGE_MESSAGE_GROUP_IS_ACTIVE_SAGA: "CHANGE_MESSAGE_GROUP_IS_ACTIVE_SAGA" = "CHANGE_MESSAGE_GROUP_IS_ACTIVE_SAGA";
export const DELETE_MESSAGE_GROUPS_SAGA: "DELETE_MESSAGE_GROUPS_SAGA" = "DELETE_MESSAGE_GROUPS_SAGA";
export const MESSAGE_GROUPS_LIST_TABLE: "MESSAGE_GROUPS_LIST_TABLE" = "MESSAGE_GROUPS_LIST_TABLE";

export const SET_PWA_ICONS_LIST: "SET_PWA_ICONS_LIST" = "SET_PWA_ICONS_LIST";
export const SET_PWA_ICONS_COUNT: "SET_PWA_ICONS_COUNT" = "SET_PWA_ICONS_COUNT";
export const CHANGE_PWA_ICONS_LOADING: "CHANGE_PWA_ICONS_LOADING" = "CHANGE_PWA_ICONS_LOADING";
export const GET_PWA_ICONS_LIST_SAGA: "GET_PWA_ICONS_LIST_SAGA" = "GET_PWA_ICONS_LIST_SAGA";
export const CHANGE_PWA_ICON_IS_ACTIVE_SAGA: "CHANGE_PWA_ICON_IS_ACTIVE_SAGA" = "CHANGE_PWA_ICON_IS_ACTIVE_SAGA";
export const DELETE_PWA_ICONS_SAGA: "DELETE_PWA_ICONS_SAGA" = "DELETE_PWA_ICONS_SAGA";
export const PWA_ICONS_LIST_TABLE: "PWA_ICONS_LIST_TABLE" = "PWA_ICONS_LIST_TABLE";

export const SET_LIMIT_STAT_LIST: "SET_LIMIT_STAT_LIST" = "SET_LIMIT_STAT_LIST";
export const CHANGE_LIMIT_STAT_LOADING: "CHANGE_LIMIT_STAT_LOADING" = "CHANGE_LIMIT_STAT_LOADING";
export const GET_LIMIT_LIST_SAGA: "GET_LIMIT_LIST_SAGA" = "GET_LIMIT_LIST_SAGA";

export const SET_CHOICED_ITEMS: "SET_CHOICED_ITEMS" = "SET_CHOICED_ITEMS";
export const DELETE_CHOICED_ITEM: "DELETE_CHOICED_ITEM" = "DELETE_CHOICED_ITEM";
export const CLEAR_CHOICED_ITEMS: "CLEAR_CHOICED_ITEMS" = "CLEAR_CHOICED_ITEMS";

export const GET_SEGMENTS_SAGA: "GET_SEGMENTS_SAGA" = "GET_SEGMENTS_SAGA";

export const CHANGE_PWA_ICON_NAME: "CHANGE_PWA_ICON_NAME" = "CHANGE_PWA_ICON_NAME";
export const CHANGE_PWA_ICON_SHORT_NAME: "CHANGE_PWA_ICON_SHORT_NAME" = "CHANGE_PWA_ICON_SHORT_NAME";
export const CHANGE_PWA_ICON_PATH: "CHANGE_PWA_ICON_PATH" = "CHANGE_PWA_ICON_PATH";
export const CHANGE_PWA_ICON_IS_ACTIVE: "CHANGE_PWA_ICON_IS_ACTIVE" = "CHANGE_PWA_ICON_IS_ACTIVE";
export const SAVE_PWA_ICON_SAGA: "SAVE_PWA_ICON_SAGA" = "SAVE_PWA_ICON_SAGA";
export const SET_PWA_ICON_FORM_FIELD: "SET_PWA_ICON_FORM_FIELD" = "SET_PWA_ICON_FORM_FIELD";
export const SET_PWA_ICON_IMAGES: "SET_PWA_ICON_IMAGES" = "SET_PWA_ICON_IMAGES";

export const SET_MOTIVATION_CONTENTS_LIST: "SET_MOTIVATION_CONTENTS_LIST" = "SET_MOTIVATION_CONTENTS_LIST";
export const SET_MOTIVATION_CONTENTS_COUNT: "SET_MOTIVATION_CONTENTS_COUNT" = "SET_MOTIVATION_CONTENTS_COUNT";
export const CHANGE_MOTIVATION_CONTENTS_LOADING: "CHANGE_MOTIVATION_CONTENTS_LOADING" = "CHANGE_MOTIVATION_CONTENTS_LOADING";
export const MOTIVATION_CONTENTS_TABLE: "MOTIVATION_CONTENTS_TABLE" = "MOTIVATION_CONTENTS_TABLE";
export const GET_MOTIVATION_CONTENTS_SAGA: "GET_MOTIVATION_CONTENTS_SAGA" = "GET_MOTIVATION_CONTENTS_SAGA";

export const IPP_LIST_TABLE: "IPP_LIST_TABLE" = "IPP_LIST_TABLE";
export const CHANGE_IPP_LIST_IS_ACTIVE_SAGA: "CHANGE_IPP_LIST_IS_ACTIVE_SAGA" = "CHANGE_IPP_LIST_IS_ACTIVE_SAGA";
export const DELETE_IPP_LIST_SAGA: "DELETE_IPP_LIST_SAGA" = "DELETE_IPP_LIST_SAGA";
export const GET_IPP_LIST_SAGA: "GET_IPP_LIST_SAGA" = "GET_IPP_LIST_SAGA";

// PWA Motivation Content
export const PWA_MOTIV_LOADING: "PWA_MOTIV_LOADING" = "PWA_MOTIV_LOADING";
export const GET_PWA_SAGA: "GET_PWA_SAGA" = "GET_PWA_SAGA";
export const SET_PWA_ICON_ID: "SET_PWA_ICON_ID" = "SET_PWA_ICON_ID";
export const SAVE_PWA_SAGA: "SAVE_PWA_SAGA" = "SAVE_PWA_SAGA";
export const SET_PWA_MOTIV: "SET_PWA_MOTIV" = "SET_PWA_MOTIV";
export const SET_PWA_FORM_FIELD: "SET_PWA_FORM_FIELD" = "SET_PWA_FORM_FIELD";

export const PWA_LIST_TABLE: "PWA_LIST_TABLE" = "PWA_LIST_TABLE";
export const PWA_LIST_LOADING: "pwaListLoading" = "pwaListLoading";
export const GET_PWA_LIST_SAGA: "GET_PWA_LIST_SAGA" = "GET_PWA_LIST_SAGA";

// PWA Offline pages
export const GET_PWA_OFFLINE_LIST_SAGA: "GET_PWA_OFFLINE_LIST_SAGA" = "GET_PWA_OFFLINE_LIST_SAGA";
export const GET_PWA_OFFLINE_PAGE_SAGA: "GET_PWA_OFFLINE_PAGE_SAGA" = "GET_PWA_OFFLINE_PAGE_SAGA";
export const SET_PWA_OFFLINE_PAGE: "SET_PWA_OFFLINE_PAGE" = "SET_PWA_OFFLINE_PAGE";
export const SET_PWA_OFFLINE_IS_ACTIVE: "SET_PWA_OFFLINE_IS_ACTIVE" = "SET_PWA_OFFLINE_IS_ACTIVE";
export const SET_PWA_OFFLINE_NAME: "SET_PWA_OFFLINE_NAME" = "SET_PWA_OFFLINE_NAME";
export const SET_PWA_OFFLINE_BROWSER_LANGUAGES: "SET_PWA_OFFLINE_BROWSER_LANGUAGES" = "SET_PWA_OFFLINE_BROWSER_LANGUAGES";
export const SET_PWA_OFFLINE_HEAD: "SET_PWA_OFFLINE_HEAD" = "SET_PWA_OFFLINE_HEAD";
export const SET_PWA_OFFLINE_BODY: "SET_PWA_OFFLINE_BODY" = "SET_PWA_OFFLINE_BODY";
export const SAVE_PWA_OFFLINE_PAGE_SAGA: "SAVE_PWA_OFFLINE_PAGE_SAGA" = "SAVE_PWA_OFFLINE_PAGE_SAGA";
export const CHANGE_PWA_OFFLINE_IS_ACTIVE_SAGA: "CHANGE_PWA_OFFLINE_IS_ACTIVE_SAGA" = "CHANGE_PWA_OFFLINE_IS_ACTIVE_SAGA";
export const DELETE_PWA_OFFLINE_SAGA: "DELETE_PWA_OFFLINE_SAGA" = "DELETE_PWA_OFFLINE_SAGA";

export const PWA_OFFLINE_LIST_TABLE: "PWA_OFFLINE_LIST_TABLE" = "PWA_OFFLINE_LIST_TABLE";
export const PWA_OFFLINE_LIST_LOADING: "pwaOfflineListLoading" = "pwaOfflineListLoading";
export const PWA_OFFLINE_FORM_LOADING: "PWA_OFFLINE_FORM_LOADING" = "PWA_OFFLINE_FORM_LOADING";

export const SET_LOADING: "SET_LOADING" = "SET_LOADING";

export const SAVE_IPP_SAGA: "SAVE_IPP_SAGA" = "SAVE_IPP_SAGA";
export const GET_IPP_SAGA: "GET_IPP_SAGA" = "GET_IPP_SAGA";
export const SET_IPP: "SET_IPP" = "SET_IPP";
export const SET_IPP_FORM_FIELD: "SET_IPP_FORM_FIELD" = "SET_IPP_FORM_FIELD";

export const SCHEDULES_LIST_TABLE: "SCHEDULES_LIST_TABLE" = "SCHEDULES_LIST_TABLE";
export const GET_SCHEDULES_SAGA: "GET_SCHEDULES_SAGA" = "GET_SCHEDULES_SAGA";

export const MESSAGES_VIEW_LOADING: "SET_MESSAGES_VIEW_LOADING" = "SET_MESSAGES_VIEW_LOADING";
export const GET_MESSAGE_VIEW_SAGA: "GET_MESSAGES_VIEW_SAGA" = "GET_MESSAGES_VIEW_SAGA";
export const SET_MESSAGE_VIEW: "GET_MESSAGES_VIEW_SAGA" = "GET_MESSAGES_VIEW_SAGA";

export const SET_MESSAGES_LIST: "SET_MESSAGES_LIST" = "SET_MESSAGES_LIST";
export const SET_MESSAGES_COUNT: "SET_MESSAGES_COUNT" = "SET_MESSAGES_COUNT";
export const GET_MESSAGES_LIST_SAGA: "GET_MESSAGES_LIST_SAGA" = "GET_MESSAGES_LIST_SAGA";
export const CHANGE_MESSAGE_IS_ACTIVE_SAGA: "CHANGE_MESSAGE_IS_ACTIVE_SAGA" = "CHANGE_MESSAGE_IS_ACTIVE_SAGA";
export const DELETE_MESSAGES_SAGA: "DELETE_MESSAGES_SAGA" = "DELETE_MESSAGES_SAGA";
export const MESSAGES_LIST_TABLE: "MESSAGES_LIST_TABLE" = "MESSAGES_LIST_TABLE";
export const FORM_KEY_MESSAGE: "FORM_KEY_MESSAGE" = "FORM_KEY_MESSAGE";

export const SET_CAMPAIGN: "SET_CAMPAIGN" = "SET_CAMPAIGN";
export const GET_CAMPAIGN_SAGA: "GET_CAMPAIGN_SAGA" = "GET_CAMPAIGN_SAGA";
export const SET_CAMPAIGN_FORM_FIELD: "SET_CAMPAIGN_FORM_FIELD" = "SET_CAMPAIGN_FORM_FIELD";
export const SAVE_CAMPAIGN_SAGA: "SAVE_CAMPAIGN_SAGA" = "SAVE_CAMPAIGN_SAGA";

export const WEBPUSH_CAMPAIGNS_TABLE: "WEBPUSH_CAMPAIGNS_TABLE" = "WEBPUSH_CAMPAIGNS_TABLE";
export const GET_WEBPUSH_CAMPAIGNS_LIST_SAGA: "GET_WEBPUSH_CAMPAIGNS_LIST_SAGA" = "GET_WEBPUSH_CAMPAIGNS_LIST_SAGA";

export const SET_SCHEDULE: "SET_SCHEDULE" = "SET_SCHEDULE";
export const GET_SCHEDULE_SAGA: "GET_SCHEDULE_SAGA" = "GET_SCHEDULE_SAGA";
export const FORM_KEY_SCHEDULE: "FORM_KEY_SCHEDULE" = "FORM_KEY_SCHEDULE";

export const GET_SEGMENT_FORM_DATA_SAGA: "GET_SEGMENT_FORM_DATA_SAGA" = "GET_SEGMENT_FORM_DATA_SAGA";
export const SAVE_SEGMENT_SAGA: "SAVE_SEGMENT_SAGA" = "SAVE_SEGMENT_SAGA";

export const SET_TARGETING: "SET_TARGETING" = "SET_TARGETING";
export const GET_TARGETING_SAGA: "GET_TARGETING_SAGA" = "GET_TARGETING_SAGA";
export const GET_FORM_DATA_LOADING: "GET_FORM_DATA_LOADING" = "GET_FORM_DATA_LOADING";
export const SAVE_FORM_DATA_LOADING: "SAVE_FORM_DATA_LOADING" = "SAVE_FORM_DATA_LOADING";
export const SAVE_DATA1_SAGA: "SAVE_DATA1_SAGA" = "SAVE_DATA1_SAGA";
export const FORM_KEY_TARGETING: "FORM_KEY_TARGETING" = "FORM_KEY_TARGETING";
export const FORM_KEY_CAMPAIGN: "FORM_KEY_CAMPAIGN" = "FORM_KEY_CAMPAIGN";
export const FORM_KEY_SEGMENT: "FORM_KEY_SEGMENT" = "FORM_KEY_SEGMENT";

export const CHANGE_IS_ACTIVE_SAGA: "CHANGE_IS_ACTIVE_SAGA" = "CHANGE_IS_ACTIVE_SAGA";
export const DELETE_SAGA: "DELETE_SAGA" = "DELETE_SAGA";

export const MOTIVATION_TEMPLATES_LIST: "MOTIVATION_TEMPLATES_LIST" = "MOTIVATION_TEMPLATES_LIST";
export const CHANGE_MOTIVATION_TEMPLATE_STATUS_SAGA: "CHANGE_MOTIVATION_TEMPLATE_STATUS_SAGA" = "CHANGE_MOTIVATION_TEMPLATE_STATUS_SAGA";
export const DELETE_MOTIVATION_TEMPLATE_SAGA: "DELETE_MOTIVATION_TEMPLATE_SAGA" = "DELETE_MOTIVATION_TEMPLATE_SAGA";

export const MOTIVATION_TEMPLATE_FORM: "MOTIVATION_TEMPLATE_FORM" = "MOTIVATION_TEMPLATE_FORM";
export const GET_MOTIVATION_TEMPLATE_SAGA: "GET_MOTIVATION_TEMPLATE_SAGA" = "GET_MOTIVATION_TEMPLATE_SAGA";
export const SAVE_MOTIVATION_TEMPLATE_SAGA: "SAVE_MOTIVATION_TEMPLATE_SAGA" = "SAVE_MOTIVATION_TEMPLATE_SAGA";
export const SET_MOTIVATION_TEMPLATE: "SET_MOTIVATION_TEMPLATE" = "SET_MOTIVATION_TEMPLATE";
export const SET_MOTIVATION_TEMPLATE_FIELD: "SET_MOTIVATION_TEMPLATE_FIELD" = "SET_MOTIVATION_TEMPLATE_FIELD";

export const SET_MODIFIERS: "SET_MODIFIERS" = "SET_MODIFIERS";
export const SET_MODIFIERS_STATE: "SET_MODIFIERS_STATE" = "SET_MODIFIERS_STATE";
export const SET_MODIFIER_SIMPLE: "SET_MODIFIER_SIMPLE" = "SET_MODIFIER_SIMPLE";
export const ADD_MODIFIER_BUTTON: "ADD_MODIFIER_BUTTON" = "ADD_MODIFIER_BUTTON";
export const SET_MODIFIER_BUTTON: "SET_MODIFIER_BUTTON" = "SET_MODIFIER_BUTTON";
export const REMOVE_MODIFIER_BUTTON: "REMOVE_MODIFIER_BUTTON" = "REMOVE_MODIFIER_BUTTON";
export const ADD_MODIFIER_IMAGE: "ADD_MODIFIER_IMAGE" = "ADD_MODIFIER_IMAGE";
export const SET_MODIFIER_IMAGE: "SET_MODIFIER_IMAGE" = "SET_MODIFIER_IMAGE";
export const REMOVE_MODIFIER_IMAGE: "REMOVE_MODIFIER_IMAGE" = "REMOVE_MODIFIER_IMAGE";

export const SET_WP_FORM_FIELD: "SET_WP_FORM_FIELD" = "SET_WP_FORM_FIELD";
export const SAVE_WP_SAGA: "SAVE_WP_SAGA" = "SAVE_WP_SAGA";
export const GET_WP_SAGA: "GET_WP_SAGA" = "GET_WP_SAGA";
export const SET_WP: "SET_WP" = "SET_WP";
export const WP_MOTIV_LOADING: "WP_MOTIV_LOADING" = "WP_MOTIV_LOADING";

export const SET_DICTIONARY: "SET_DICTIONARY" = "SET_DICTIONARY";
export const GET_DICTIONARY_SAGA: "GET_DICTIONARY_SAGA" = "GET_DICTIONARY_SAGA";

export const SET_CONDITIONS: "SET_CONDITIONS" = "SET_CONDITIONS";
export const SET_VALUES_LISTS: "SET_VALUES_LISTS" = "SET_VALUES_LISTS";
export const ADD_CONDITION: "ADD_CONDITION" = "ADD_CONDITION";
export const ADD_CONDITION_GROUP: "ADD_CONDITION_GROUP" = "ADD_CONDITION_GROUP";
export const CHANGE_CONDITION_GROUP_OPERATOR: "CHANGE_CONDITION_GROUP_OPERATOR" = "CHANGE_CONDITION_GROUP_OPERATOR";
export const DELETE_CONDITION_RULE: "DELETE_CONDITION_RULE" = "DELETE_CONDITION_RULE";
export const DELETE_CONDITION_GROUP: "DELETE_CONDITION_GROUP" = "DELETE_CONDITION_GROUP";
export const CHANGE_CONDITION_RULE: "CHANGE_CONDITION_RULE" = "CHANGE_CONDITION_RULE";
export const CHANGE_CONDITION_OPERATOR: "CHANGE_CONDITION_OPERATOR" = "CHANGE_CONDITION_OPERATOR";
export const CHANGE_CONDITION_VALUE: "CHANGE_CONDITION_VALUE" = "CHANGE_CONDITION_VALUE";

export const DYNAMIC_CDN_REF: "{%=cdnDomain%}" = "{%=cdnDomain%}";

export const TOP_LEFT_CSS = "position: fixed; top: 0; left: 0;";
export const TOP_RIGHT_CSS = "position: fixed; top: 0; left: 100%; transform: translate(-100%, 0);";
export const TOP_CENTER_CSS = "position: fixed; top: 0; left: 50%; transform: translate(-50%, 0);";
export const CENTER_CSS = "position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);";
export const BOTTOM_LEFT_CSS = "position: fixed; top: 100%; left: 0; transform: translate(0, -100%);";
export const BOTTOM_RIGHT_CSS = "position: fixed; top: 100%; left: 100%; transform: translate(-100%, -100%);";
export const BOTTOM_CENTER_CSS = "position: fixed; top: 100%; left: 50%; transform: translate(-50%, -100%);";

export const CAMPAIGN: "campaign" = "campaign";
export const SCHEDULE: "schedule" = "schedule";
