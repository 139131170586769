// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { IdList } from "../generalApi/types/General.type";
import type { WebPushCampaignListItem } from "../../containers/WebPushCampaignsList/types/WebPushCampaignsList.types";
import type { Campaign, CampaignSaveBody } from "../../containers/CreateCampaign";

export const getCampaignsList = (
  page: number,
  pageSize: number,
  filterList: Object
): PromiseResponse<mixed, { data: WebPushCampaignListItem[], totalCount: number }> => requestService
  .get(environment.endpoints.get.campaignsList, {
    params: {
      page,
      pageSize,
      ...filterList,
    },
  });

export const getCampaignById = (id: string): Promise<Campaign> => requestService
  .get(environment.endpoints.get.campaignById.replace("{id}", id))
  .then(({ data: { data } }: { data: { data: Campaign[] } }): Campaign => data[0]);

export const saveCampaign = (campaign: CampaignSaveBody): PromiseResponse<mixed> => requestService
  .post(environment.endpoints.post.campaigns, {
    ...campaign,
  });

export const deleteCampaignsByIds = (idList: IdList): PromiseResponse<mixed> => requestService
  .delete(environment.endpoints.delete.campaigns, {
    data: { idList },
  });

export const updateCampaign: (string, $Shape<CampaignSaveBody>) => PromiseResponse<mixed> = (
  id,
  campaign
) => requestService.put(environment.endpoints.put.campaign.replace("{id}", id), { id, ...campaign });
