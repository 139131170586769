// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetDictionarySaga from "../../sagas/getDictionary";
import watchGetWp from "../../sagas/getWp";
import watchSaveWp from "../../sagas/saveWp";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetWp),
    call(watchSaveWp),
    call(watchGetDictionarySaga),
  ]);
}
