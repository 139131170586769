// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import getFormDataSaga from "../../sagas/getFormDataSaga";
import saveFormDataSaga from "../../sagas/saveFormDataSaga";
import getMessageGroupsList from "../../sagas/getMessageGroupsList";
import getSegments from "../../sagas/getSegments";
import watchStepsClick from "../../sagas/stepsSaga";
import { SCHEDULE } from "../../helpers/constants";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(getFormDataSaga),
    call(saveFormDataSaga),
    call(getMessageGroupsList),
    call(getSegments),
    call(watchStepsClick, SCHEDULE),
  ]);
}
