// @flow
import React from "react";
import { SelectedItem } from "../SelectedItem";

type Props = {
  items: Object[],
  handleDelete: Function,
};

export default ({ items, handleDelete }: Props) => (
  // $FlowFixMe
  items.map((item) => <SelectedItem key={item.id} data={item} handleDelete={handleDelete} />)
);
