// @flow
import {
  SET_MODIFIERS,
  SET_MODIFIERS_STATE,
  SET_MODIFIER_SIMPLE,

  ADD_MODIFIER_BUTTON,
  SET_MODIFIER_BUTTON,
  REMOVE_MODIFIER_BUTTON,

  ADD_MODIFIER_IMAGE,
  SET_MODIFIER_IMAGE,
  REMOVE_MODIFIER_IMAGE,
} from "../../helpers/constants";
import type {
  ButtonTypes,
  ButtonPropTypes,
  ModifiersStateData,
  Simple,
} from "../../containers/Modifiers";
import type { MotivationTemplateType } from "../../containers/CreateMotivationTemplate";
import type {
  SetModifiers,
  SetModifiersState,
  SetModifierSimple,
  AddModifierButton,
  SetModifierButton,
  RemoveModifierButton,
  AddModifierImage,
  SetModifierImage,
  RemoveModifierImage,
} from "./actions.types";

export const setModifiers: (
  key: MotivationTemplateType, modifiers: ModifiersStateData,
) => SetModifiers = (key, modifiers) => ({
  type: SET_MODIFIERS,
  payload: {
    key,
    modifiers,
  },
});

export const setModifiersState: (
  modifiersState: { [MotivationTemplateType]: ModifiersStateData },
) => SetModifiersState = (modifiersState) => ({
  type: SET_MODIFIERS_STATE,
  payload: modifiersState,
});

export const setModifierSimple: (
  key: MotivationTemplateType, modifier: $Shape<Simple>,
) => SetModifierSimple = (key, modifier) => ({
  type: SET_MODIFIER_SIMPLE,
  payload: {
    key,
    modifier,
  },
});

export const addModifierButton: (
  key: MotivationTemplateType,
  buttonType: ButtonTypes
) => AddModifierButton = (key, buttonType) => ({
  type: ADD_MODIFIER_BUTTON,
  payload: {
    buttonType,
    key,
  },
});

export const setModifierButton: (
  key: MotivationTemplateType,
  button: {
    idx: string,
    buttonType: ButtonTypes,
    buttonPropType: ButtonPropTypes,
    value: string,
  }
) => SetModifierButton = (key, button) => ({
  type: SET_MODIFIER_BUTTON,
  payload: {
    ...button,
    key,
  },
});

export const removeModifierButton: (
  key: MotivationTemplateType,
  buttonType: ButtonTypes
) => RemoveModifierButton = (key, buttonType) => ({
  type: REMOVE_MODIFIER_BUTTON,
  payload: {
    buttonType,
    key,
  },
});

export const addModifierImage: (MotivationTemplateType) => AddModifierImage = (key) => ({
  type: ADD_MODIFIER_IMAGE,
  payload: key,
});

export const setModifierImage: (
  key: MotivationTemplateType,
  image: {
    idx: string,
    value: string,
  }
) => SetModifierImage = (key, image) => ({
  type: SET_MODIFIER_IMAGE,
  payload: {
    ...image,
    key,
  },
});

export const removeModifierImage: (MotivationTemplateType) => RemoveModifierImage = (key) => ({
  type: REMOVE_MODIFIER_IMAGE,
  payload: key,
});
