// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record } from "immutable";
import type { Reducer } from "redux";
import type { Actions, SetWpFormField, SetWp } from "../../actions/createWp";
import {
  SET_WP_FORM_FIELD,
  SET_WP,
} from "../../helpers/constants";
import type { WPFormData } from "../../containers/CreateWPMotivationContent";

export type DefaultState = {
  ...WPFormData,
};

const defaultValues: DefaultState = {
  id: "",
  name: "",
  BrowserLanguages: [],
  initTimeout: 0,
  inlineContentClickBehavior: "tag",
  visualisationType: "inline_with_manifest",
  behaviorToAction: "action_automatic",
  inline_html: "",
  inline_css: "",
  head: "",
  body: "",
  isActive: false,
  isTemplate: false,
  usedInCampaigns: [],
  motivationTemplates: {
    WPMotivationPopUp: {},
    WPSubscriptionPage: {},
  },
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initCreateWpState(): State {
  return initialState;
}

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_WP: {
      const { payload: wpFormData }: SetWp = action;
      return state.mergeDeep(wpFormData);
    }
    case SET_WP_FORM_FIELD: {
      const { payload: field }: SetWpFormField = action;
      return state.merge(field);
    }
    default:
      return state;
  }
};

export default reducer;
