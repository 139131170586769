// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import getMessageGroupsList from "../../sagas/getMessageGroupsList";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import { MESSAGE_GROUPS_LIST_TABLE } from "../../helpers/constants";
import MessageGroupApi from "../../services/messageGroupApi";
import { getMessageGroupsListSaga } from "../../actions/messageGroupsList";
import { messageGroupListItemToShort } from "../../helpers/messageGroup";
import type {
  MessageGroupListItem,
  MessageGroupShort,
} from "../../components/MessageGroupsList/types/MessageGroupsList.types";
import getDeleteSaga from "../../sagas/deleteSaga";

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<MessageGroupListItem, MessageGroupShort>({
  tableKey: MESSAGE_GROUPS_LIST_TABLE,
  updateMethod: MessageGroupApi.updateMessageGroup,
  getListAction: getMessageGroupsListSaga,
  convert: (group: MessageGroupListItem): MessageGroupShort => ({ ...messageGroupListItemToShort(group) }),
  dictionaryName: "Message Group",
});

const deleteSaga: () => Saga<void> = getDeleteSaga({
  tableKey: MESSAGE_GROUPS_LIST_TABLE,
  deleteMethod: MessageGroupApi.deleteMessageGroupsByIds,
  getListAction: getMessageGroupsListSaga,
  dictionaryName: "Message Group",
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(getMessageGroupsList),
    call(changeIsActiveSaga),
    call(deleteSaga),
  ]);
}
