// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import Notification from "../../components/Notification";
import configureStore from "./store";
import IppContainer from "../../containers/IppView";

const ViewIpp: StatelessFunctionalComponent<{}> = () => (
  <DocumentTitle title="Ipp View">
    <Provider store={configureStore()}>
      <IppContainer />
      <Notification />
    </Provider>
  </DocumentTitle>
);
export default ViewIpp;
