// @flow
import { toUIOperatorMapper } from "../..";
import type { ToUI } from "../..";
import type {
  UIRule,
  ServerRule,
  ValueMapper,
  UIOperatorList,
} from "../types";

export default class DateBased implements ToUI<UIRule, ServerRule> {
  rule: ServerRule;

  constructor(rule: ServerRule) {
    this.rule = rule;
  }

  convert(): UIRule {
    const field = Object.keys(this.rule)[0];
    const label = field;
    const type: string = "string";

    const serverRule = this.rule[field];
    if (serverRule === null) {
      return {
        field,
        label,
        type,
        value: "",
        operator: "isnull",
      };
    }

    const serverValue = typeof serverRule === "string" ? serverRule : serverRule[Object.keys(serverRule)[0]];

    // $FlowFixMe
    const operator: UIOperatorList = typeof serverRule === "string" ? "equal" : toUIOperatorMapper[Object.keys(serverRule)[0]];
    const value: string = this.valueMapper[serverValue];

    return {
      field,
      label,
      type,
      operator,
      value,
    };
  }

  get valueMapper(): ValueMapper {
    return {
      "@1MinAgo": "1 minute ago",
      "@1DayAgo": "1 day ago",
      "@2DaysAgo": "2 days ago",
      "@30DaysAgo": "30 days ago",
      "@1HourAgo": "1 hour ago",
      "@2HoursAgo": "2 hours ago",
      "@3HoursAgo": "3 hours ago",
      "@4HoursAgo": "4 hours ago",
      "@5HoursAgo": "5 hours ago",
      "@6HoursAgo": "6 hours ago",
      "@7HoursAgo": "7 hours ago",
      "@8HoursAgo": "8 hours ago",
      "@9HoursAgo": "9 hours ago",
      "@10HoursAgo": "10 hours ago",
      "@11HoursAgo": "11 hours ago",
      "@12HoursAgo": "12 hours ago",
      "@13HoursAgo": "13 hours ago",
      "@14HoursAgo": "14 hours ago",
      "@15HoursAgo": "15 hours ago",
      "@16HoursAgo": "16 hours ago",
      "@17HoursAgo": "17 hours ago",
      "@18HoursAgo": "18 hours ago",
      "@19HoursAgo": "19 hours ago",
      "@20HoursAgo": "20 hours ago",
      "@21HoursAgo": "21 hours ago",
      "@22HoursAgo": "22 hours ago",
      "@23HoursAgo": "23 hours ago",
      "@3DaysAgo": "3 days ago",
      "@4DaysAgo": "4 days ago",
      "@5DaysAgo": "5 days ago",
      "@6DaysAgo": "6 days ago",
      "@7DaysAgo": "7 days ago",
      "@8DaysAgo": "8 days ago",
      "@9DaysAgo": "9 days ago",
      "@10DaysAgo": "10 days agо",
      "@15DaysAgo": "15 days ago",
      "@60DaysAgo": "60 days ago",
      "@100DaysAgo": "100 days ago",
      "@150DaysAgo": "150 days ago",
    };
  }
}
