/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers, applyMiddleware, createStore, compose,
} from "redux";
import type { Dispatch, CombinedReducer, Store } from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import notifications, { initNotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import mainSaga from "./saga";
import ipp, { initCreateIppState } from "../../reducers/createIPP";
import type { State as IPPState } from "../../reducers/createIPP";
import loading, { initLoadingState } from "../../reducers/loading";
import type { State as LoadingState } from "../../reducers/loading";
import type { Actions as CreateIppActions } from "../../actions/createIpp";
import type { Actions as LoadingActions } from "../../actions/loading";
import modifiers, {
  type State as ModifiersState,
  initModifiersState,
} from "../../reducers/modifiers";
import dictionaries, {
  type State as DictionariesState,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";
import { convertToDropdownList } from "../../helpers/generators";

export type State = $ReadOnly<{
  notifications: NotificationsState,
  ipp: IPPState,
  loading: LoadingState<"ipp"|"ippSaving">,
  modifiers: ModifiersState,
  dictionaries: DictionariesState,
}>;

export type Actions = CreateIppActions | LoadingActions<"ipp"|"ippSaving">;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  ipp,
  loading,
  modifiers,
  dictionaries,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export function mapIppToState(): State {
  return {
    loading: initLoadingState<"ipp"|"ippSaving">({ ipp: false, ippSaving: false }),
    notifications: initNotificationsState(),
    ipp: initCreateIppState(),
    modifiers: initModifiersState({
      IPPMotivationPopUp: {},
    }),
    dictionaries: initDictionariesState({
      behaviorToActions: convertToDropdownList([
        "automatic",
        "on_any_click",
        "motivation_content_deny",
        "subscription",
        "subscription_decline",
        "install",
        "already_subscribed",
        "notifications_denied",
      ]),
      funnelActions: [
        { value: "default", label: "Default" },
        { value: "redirect_to_offer", label: "Redirect to offer" },
        { value: "next_step", label: "Next step of jump" },
      ],
    }),
  };
}

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  })
    : compose;

  const store: Store<State, Actions> = createStore<
    State,
    Actions,
    Dispatch<Actions>
  >(reducers, mapIppToState(), composeEnhancers(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(mainSaga);

  return store;
};
