// @flow
import getCustomAttributes, { type CustomAttributes } from "./getCustomAttributes";

type Field = {
  name: string,
  value: string,
};

type Fields = {
  [key: string]: string,
};

type ValidatedFields = {
  [key: string]: boolean,
};

/* eslint-disable complexity */
function isFieldValid(params: Field): boolean {
  // const urlReg = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const { name, value }: Field = params;
  let result: boolean = true;
  switch (name) {
    case "password":
    case "username":
    case "phone":
    case "login":
      result = value.length > 0;
      break;
    case "email":
      // eslint-disable-next-line no-useless-escape
      result = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
      break;
    case "name":
      result = /^[A-Za-z0-9_-\s]{1,60}$/.test(value);
      break;
    case "clickUrl":
      if (value.length) {
        result = /^[A-Za-z0-9\s]/.test(value);
        break;
      }
      result = true;
      break;
    case "customAttributes":
      result = isCustomAttributesValid(value);
      break;
    case "title":
      result = value.length < 60 && value.length > 0;
      break;
    case "body":
      result = value.length < 120 && value.length > 0;
      break;
    case "image":
    case "icon":
    case "badge":
    default:
      result = true;
  }
  return result;
}

function validateAllFields(fields: Fields): ValidatedFields {
  const result: ValidatedFields = {};
  Object.keys(fields).forEach((key: string) => {
    result[key] = !isFieldValid({ name: key, value: fields[key] });
  });
  return result;
}

function isCustomAttributesValid(inputValue: string): boolean {
  const forbiddenAttributes: Array<string> = [
    "tds_campaign",
    "utm_source",
    "utm_campaign",
    "tds_cid",
    "data2",
    "tds_ac_id",
    "utm_term",
    "utm_content",
    "s1",
    "tags",
  ];

  const attributes: CustomAttributes = getCustomAttributes(inputValue);
  const isAttrForbidden = forbiddenAttributes.some((key) => Object.prototype.hasOwnProperty.call(attributes, key));
  return !isAttrForbidden && inputValue.length < 60;
}

function isFormValid(fields: ValidatedFields): boolean {
  return !Object.keys(fields).find((key: string): boolean | string => fields[key] || fields[key] === null);
}

function isNameFieldValid(value: string): boolean {
  return /^[A-Za-z0-9\s_-]{1,64}$/.test(value);
}

export const isInitTimeoutValid: (number) => boolean = (value) => value !== null && value >= 0;

export {
  isFieldValid,
  validateAllFields,
  isFormValid,
  isNameFieldValid,
};
