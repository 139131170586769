// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Outlet,
} from "react-router-dom";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import AppHeader from "../AppHeader";

type Classes = {
  baseContainer: string,
}

const useStyles: () => Classes = makeStyles((theme: Theme): { [key: $Keys<Classes>]: mixed } => ({
  baseContainer: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}));

const HomePage: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();

  return (
    <div className={classes.baseContainer}>
      <AppHeader />
      <Outlet />
    </div>
  );
};

export default HomePage;
