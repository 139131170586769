// @flow
import { Record, OrderedMap } from "immutable";
import type { RecordFactory } from "immutable";
import { reducer as reducerWrapper, makeStep } from "@fas/ui-framework/lib/redux/reducers/steps";
import type { State, DefaultState } from "@fas/ui-framework/lib/redux/reducers/steps";

export const defaultValues: DefaultState = {
  byId: OrderedMap({
    "1": makeStep({
      title: "Schedule Info",
      done: false,
      error: false,
    }),
    "2": makeStep({
      title: "Message Groups",
      done: false,
      error: false,
    }),
    "3": makeStep({
      title: "Segments",
      done: false,
      error: false,
    }),
    "4": makeStep({
      title: "Schedule Preview",
      done: false,
      error: false,
    }),
  }),
  current: "1",
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export const initialState: State = makeState(defaultValues);

export default reducerWrapper(initialState);
