// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Grid,
  Box,
  InputLabel as Label,
} from "@mui/material";
import { TextFieldForm } from "@fas/ui-core/lib/Form";
import { SetupCard } from "@fas/ui-core";
import StepControl from "../StepControl";
import CounterForm from "../Form/CounterForm";

const CampaignInfoStep: StatelessFunctionalComponent<{}> = () => (
  <Grid container>
    <Grid item container justifyContent="center" alignItems="center">
      <Grid item xs={8}>
        <SetupCard title="General Info">
          <Grid container spacing={2}>
            <Grid item container alignItems="center">
              <TextFieldForm name="name" label="Campaign name" />
            </Grid>
            <Grid item container alignItems="center" data-testid="ratio">
              <Box mr={4}>
                <Label>Ratio</Label>
              </Box>
              <CounterForm
                name="ratio"
                min={1}
                max={10}
              />
            </Grid>
          </Grid>
        </SetupCard>
      </Grid>
    </Grid>
    <Grid item container justifyContent="center" alignItems="center">
      <Grid item xs={8}>
        <Box display="flex" justifyContent="flex-end">
          <StepControl />
        </Box>
      </Grid>
    </Grid>
  </Grid>
);

export default CampaignInfoStep;
