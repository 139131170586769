// @flow
import {
  SET_PWA_ICONS_LIST,
  CHANGE_PWA_ICONS_LOADING,
  GET_PWA_ICONS_LIST_SAGA,
  SET_PWA_ICONS_COUNT,
  CHANGE_PWA_ICON_IS_ACTIVE_SAGA,
  DELETE_PWA_ICONS_SAGA,
} from "../../helpers/constants";
import type { PwaIconListItem } from "../../components/PwaIconsList/types/PwaIconsList.types";

export type SetPwaIcons = {|
  type: typeof SET_PWA_ICONS_LIST,
  list: PwaIconListItem[],
|};

export type ChangePwaIconsLoading = {|
  type: typeof CHANGE_PWA_ICONS_LOADING,
  loading: boolean,
|};

export type GetPwaIconsListSaga = {|
  type: typeof GET_PWA_ICONS_LIST_SAGA,
|};

export type SetPwaIconsCount = {|
  type: typeof SET_PWA_ICONS_COUNT,
  value: number,
|};

export type ChangePwaIconIsActiveSaga = {|
  type: typeof CHANGE_PWA_ICON_IS_ACTIVE_SAGA,
  isActive: boolean,
|};

export type DeletePwaIconsSaga = {|
  type: typeof DELETE_PWA_ICONS_SAGA,
|};

export type Actions = SetPwaIcons
  | ChangePwaIconsLoading
  | GetPwaIconsListSaga
  | SetPwaIconsCount
  | ChangePwaIconIsActiveSaga
  | DeletePwaIconsSaga;

export const setPwaIcons: (PwaIconListItem[]) => SetPwaIcons = (list) => ({
  type: SET_PWA_ICONS_LIST,
  list,
});

export const changePwaIconsLoading: (boolean) => ChangePwaIconsLoading = (loading) => ({
  type: CHANGE_PWA_ICONS_LOADING,
  loading,
});

export const getPwaIconsListSaga: () => GetPwaIconsListSaga = () => ({
  type: GET_PWA_ICONS_LIST_SAGA,
});

export const setPwaIconsCount: (number) => SetPwaIconsCount = (value) => ({
  type: SET_PWA_ICONS_COUNT,
  value,
});

export const changePwaIconIsActiveSaga: (boolean) => ChangePwaIconIsActiveSaga = (isActive) => ({
  type: CHANGE_PWA_ICON_IS_ACTIVE_SAGA,
  isActive,
});

export const deletePwaIconsSaga: () => DeletePwaIconsSaga = () => ({
  type: DELETE_PWA_ICONS_SAGA,
});
