// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import fetchTargetingsListSaga from "../../sagas/getTargetingsList";
import getDeleteSaga from "../../sagas/deleteSaga";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import { TARGETINGS_LIST_TABLE } from "../../helpers/constants";
import TargetingApi from "../../services/targetingsApi";
import { getTargetingsListSaga } from "../../actions/targetingsList";
import { targetingToShort } from "../../helpers/targeting";
import { type Targeting as TargetingListItem, type TargetingShort } from "../../containers/TargetingsList";

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<TargetingListItem, TargetingShort>({
  tableKey: TARGETINGS_LIST_TABLE,
  updateMethod: TargetingApi.updateTargeting,
  getListAction: getTargetingsListSaga,
  convert: targetingToShort,
  dictionaryName: "Targeting",
});

const deleteSaga: () => Saga<void> = getDeleteSaga({
  tableKey: TARGETINGS_LIST_TABLE,
  deleteMethod: TargetingApi.deleteTargetingsByIds,
  getListAction: getTargetingsListSaga,
  dictionaryName: "Targeting",
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(fetchTargetingsListSaga),
    call(deleteSaga),
    call(changeIsActiveSaga),
  ]);
}
