// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { MessageListItem } from "../../components/MessagesList/types/MessagesList.types";
import type { MessageFormData } from "../../containers/CreateMessage";

export type AdsBoxImageType = {
  imageUrl: string,
  bannerId: string,
  shortImageUrl: string,
};

export const getMessagesList: (number, number, { [key: string]: mixed }) => PromiseResponse<mixed, {
  data: MessageListItem[],
  totalCount: number
}> = (page, limit, filters) => requestService.get(environment.endpoints.get.messagesList, {
  params: {
    ...filters,
    page,
    limit,
  },
});

export const getMessageById: (string) => Promise<*> = (id) => requestService
  .get(environment.endpoints.get.messageById.replace("{id}", id))
  .then(({ data: { data } }: { data: { data: MessageListItem[] } }): MessageListItem => data[0]);

export const updateMessage: (string, MessageListItem) =>
  PromiseResponse<MessageListItem> = (id, message) => requestService
    .put(environment.endpoints.put.messages.replace("{id}", id), { ...message });

export async function saveMessageForm({
  id,
  ...body
}: MessageFormData) {
  if (id) {
    await requestService.put(environment.endpoints.put.messages.replace("{id}", id), { ...body, id });
  }
  else {
    await requestService.post(environment.endpoints.post.messages, body)
      .then(({ data }) => data.id);
  }
}

export const deleteMessagesByIds: (string[]) => PromiseResponse<mixed> = (idList) => requestService
  .delete(environment.endpoints.delete.messages, { data: { idList } });

export const sendImage: (string | null | ArrayBuffer, string) => PromiseResponse<mixed, AdsBoxImageType> = (
  data, url
) => (requestService.getInstance())({
  url: environment.endpoints.post.adsBoxImage,
  method: "post",
  headers: {
    "X-Api-Key": environment.adsBoxApiKey,
  },
  data: {
    picture: {
      data,
      url,
    },
  },
});

export const checkImage: (string | null | ArrayBuffer) => PromiseResponse<mixed, null | AdsBoxImageType> = (
  data
) => (requestService.getInstance())({
  url: environment.endpoints.get.checkImage.replace("{hash}", String(data)),
  method: "get",
  headers: {
    "X-Api-Key": environment.adsBoxApiKey,
  },
});
