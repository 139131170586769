/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import {
  GET_SEGMENT_FORM_DATA_SAGA,
  GET_FORM_DATA_LOADING,
  FORM_KEY_SEGMENT,
} from "../../helpers/constants";
import { toUiView } from "../../helpers/QueryBuilder";
import { type GetSegmentFormDataSaga } from "../../actions/segmentView";
import { setValuesLists, setConditions } from "../../actions/segmentConditions";
import SegmentApi from "../../services/segmentApi";
import MessageGroupApi from "../../services/messageGroupApi";
import type { Segment } from "../../components/SegmentsList/types/SegmentsList.types";
import type { MessageGroupListItem } from "../../components/MessageGroupsList/types/MessageGroupsList.types";
import externalRedirect from "../../components/App/ExternalRedirect";
import { enrichQueryBuilder, type Error } from "./utils";

export function* makeFetch({ id }: GetSegmentFormDataSaga): Saga<void> {
  yield put(setLoading(GET_FORM_DATA_LOADING, true));

  try {
    const { data: { data: config } } = yield call(SegmentApi.getQueryBuilderColumnsConfigs);

    yield put(setValuesLists(config));

    if (id) {
      const { limit, criteria, ...segment }: Segment = yield call(SegmentApi.getSegmentById, id);
      let webPushMessageGroups: MessageGroupListItem[] = [];

      if (limit && limit.messageGroupIds) {
        const { data: { data: messageGroups } }: Response<{ data: MessageGroupListItem[] }> = yield call(
          MessageGroupApi.getMessageGroupList,
          null,
          null,
          { ids: limit.messageGroupIds }
        );

        webPushMessageGroups = messageGroups;
      }

      const errors: Error[] = enrichQueryBuilder(criteria, config);
      for (const error of errors) {
        const { message, severity }: Error = error;
        yield put(addNotification({ message, severity }));
      }

      const parsedCriteria = toUiView(JSON.parse(criteria));

      yield put(setConditions(parsedCriteria));

      yield put(setFormData(FORM_KEY_SEGMENT, {
        ...segment,
        isLimit: Boolean(limit),
        minimum: limit ? limit.minimum : 0,
        maximum: limit ? limit.maximum : 0,
        webPushMessageGroups,
        criteriaRaw: criteria,
      }));
    }
    else {
      yield put(setFormData(FORM_KEY_SEGMENT, { config }));
    }
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
    // eslint-disable-next-line
    console.error(err);
    externalRedirect({ to: "/segments" });
  }
  finally {
    yield put(setLoading(GET_FORM_DATA_LOADING, false));
  }
}

export default function* watchFetchSegmentSaga(): Saga<void> {
  yield takeEvery(GET_SEGMENT_FORM_DATA_SAGA, (makeFetch: Function));
}
