// @flow
import { fromUIOperatorMapper } from "../..";
import type { FromUI } from "../..";
import type { UIRule, ServerRule } from "../types";

export default class ManualvalueBased implements FromUI<UIRule, ServerRule> {
  rule: UIRule;

  constructor(rule: UIRule) {
    this.rule = rule;
  }

  convert(): ServerRule {
    const { field, operator, value } = this.rule;

    if (operator !== "notequal") {
      return {
        [field]: value,
      };
    }

    const serverOperator: string = fromUIOperatorMapper[operator];

    return {
      [field]: {
        [serverOperator]: value,
      },
    };
  }
}
