// @flow
import React, { Component } from "react";
import { withDomain } from "../../helpers/utils";

type Props = {
  src: string,
  fallbackSrc: string,
};

type State = {
  src: string,
  errored: boolean,
};

class Image extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      src: props.src,
      errored: false,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.src !== state.src) {
      return {
        src: props.src,
        errored: false,
      };
    }
    return null;
  }

  onError = () => {
    const { errored } = this.state;
    if (!errored) {
      this.setState({
        errored: true,
      });
    }
  };

  render() {
    const { errored } = this.state;
    const {
      src,
      fallbackSrc,
      ...props
    } = this.props;

    return (
      <img
        {...props}
        src={errored ? fallbackSrc : withDomain(src)}
        onError={this.onError}
      />
    );
  }
}

export default Image;
