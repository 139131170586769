/* eslint-disable import/max-dependencies */
// @flow
import React, { useState } from "react";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { useDispatch } from "react-redux";
import {
  Box,
  IconButton,
} from "@mui/material";
import {
  Visibility as ViewIcon,
  FileCopy as FileCopyIcon,
} from "@mui/icons-material";
import InputUpload from "../../components/InputUpload";
import { copyToClipboard } from "../../helpers/utils";
import type { UseState } from "../../helpers/common.types";
import UploadFileModal from "../../components/UploadFileModal";
import PreviewImageModal from "../../components/PreviewImageModal";

type Props = {
  name: string,
  label: string,
  value: string,
  onChange: (string) => *,
}

function InputUploadForm({
  name,
  label,
  value,
  onChange,
  ...props
}: Props) {
  const dispatch = useDispatch();
  const [isImageUploadModalOpen, setIsImageUploadModalOpen]: UseState<boolean> = useState(false);
  const [isImagePreviewModalOpen, setIsImagePreviewModalOpen]: UseState<boolean> = useState(false);

  return (
    <>
      <Box display="flex" alignItems="center">
        <InputUpload
          {...props}
          data-testid={name}
          name={name}
          label={label}
          margin="dense"
          size="small"
          value={value}
          onChange={onChange}
          onUpload={() => {
            setIsImageUploadModalOpen(true);
          }}
        />
        <Box ml={1}>
          <IconButton
            color="primary"
            aria-label="view image picture"
            component="span"
            onClick={(): mixed => {
              setIsImagePreviewModalOpen(true);
            }}
            size="small"
            data-testid={`${name}-preview-button`}
            disabled={!value}
          >
            <ViewIcon />
          </IconButton>
        </Box>
        <Box ml={1}>
          <IconButton
            color="primary"
            aria-label="copy image url"
            component="span"
            onClick={(): mixed => copyToClipboard(value)}
            data-testid={`${name}-copy-button`}
            size="small"
            disabled={!value}
          >
            <FileCopyIcon />
          </IconButton>
        </Box>
      </Box>

      <UploadFileModal
        open={isImageUploadModalOpen}
        onSave={(url: string) => {
          onChange(url);
          setIsImageUploadModalOpen(false);
        }}
        onClose={(): void => setIsImageUploadModalOpen(false)}
        onUploadError={(error) => {
          dispatch(addNotification({ message: error.message, severity: "error" }));
        }}
      />

      <PreviewImageModal
        open={isImagePreviewModalOpen}
        url={value}
        onClose={(): mixed => setIsImagePreviewModalOpen(false)}
      />
    </>
  );
}

InputUploadForm.defaultProps = {
  onChange: () => {},
  value: "",
};

export default withFormField<*>(InputUploadForm);
