// @flow
const ROLE_ADMIN: "admin" = "admin";
const ROLE_EXTENSION_DEV: "extension_dev" = "extension_dev";
const ROLE_DOMAINS_VIEWER: "domains_viewer" = "domains_viewer";
const ROLE_CID_USERS: "cid_users" = "cid_users";
const ROLE_PIXELS_OWNER: "pixels_owner" = "pixels_owner";
const ROLE_SIMILAR_WEB_USER: "similar_web_user" = "similar_web_user";
const ROLE_SECONDARY_OFFER_USER: "secondary_offer_user" = "secondary_offer_user";
const ROLE_MACHINES_ADMIN: "machines_admin" = "machines_admin";
const ROLE_MACHINES_CUSTOMER: "machines_customer" = "machines_customer";
const ROLE_WEBPUSH_USER: "webpush_user" = "webpush_user";
const ROLE_WEBPUSH_API: "webpush_api" = "webpush_api";
const ROLE_EXPERIMENTAL_USER: "experimental" = "experimental";
const ROLE_MONITORING: "monitoring" = "monitoring";
const ROLE_SYSTEM: "system" = "system";
const ROLE_TRAFFIC_SCORING: "traffic_scoring" = "traffic_scoring";
const ROLE_AFFILIATE: "affiliate" = "affiliate";
const ROLE_CLICKER_OWNER: "clicker" = "clicker";
const ROLE_RT_LISTS: "rt_lists" = "rt_lists";
const ROLE_PUMA_USER: "puma_user" = "puma_user";
const ROLE_COSTS: "costs" = "costs";
const ROLE_GUEST: "guest" = "guest";

export type Role =
  typeof ROLE_ADMIN | typeof ROLE_EXTENSION_DEV |
  typeof ROLE_DOMAINS_VIEWER | typeof ROLE_CID_USERS |
  typeof ROLE_PIXELS_OWNER | typeof ROLE_SIMILAR_WEB_USER |
  typeof ROLE_SECONDARY_OFFER_USER | typeof ROLE_MACHINES_ADMIN |
  typeof ROLE_MACHINES_CUSTOMER | typeof ROLE_WEBPUSH_USER |
  typeof ROLE_WEBPUSH_API | typeof ROLE_EXPERIMENTAL_USER |
  typeof ROLE_MONITORING | typeof ROLE_SYSTEM |
  typeof ROLE_TRAFFIC_SCORING | typeof ROLE_AFFILIATE |
  typeof ROLE_CLICKER_OWNER | typeof ROLE_RT_LISTS |
  typeof ROLE_PUMA_USER | typeof ROLE_COSTS |
  typeof ROLE_GUEST;

export const PERMISSION_WEB_PUSH_CAMPAIGNS: "webpush_campaigns" = "webpush_campaigns";
export const PERMISSION_SCHEDULE: "schedule" = "schedule";
export const PERMISSION_MESSAGES_DICTIONARY: "messages_dictionary" = "messages_dictionary";
export const PERMISSION_TARGETING_DICTIONARY: "targeting_dictionary" = "targeting_dictionary";
export const PERMISSION_SEGMENTS_DICTIONARY: "segments_dictionary" = "segments_dictionary";
export const PERMISSION_MOTIVATION_CONTENT_DICTIONARY: "motivation_content_dictionary" = "motivation_content_dictionary";
export const PERMISSION_MESSAGE_GROUPS_DICTIONARY: "message_groups_dictionary" = "message_groups_dictionary";
export const PERMISSION_LIMITS_STAT: "limits_stat" = "limits_stat";
export const PERMISSION_PWA_ICONS: "pwa_icons" = "pwa_icons";

export const PERMISSION_PWA:"pwa" = "pwa";
export const PERMISSION_PWA_OFFLINE_PAGES:"pwaOfflinePages" = "pwaOfflinePages";
export const PERMISSION_IPP:"ipp" = "ipp";

export type Permission =
  typeof PERMISSION_WEB_PUSH_CAMPAIGNS | typeof PERMISSION_SCHEDULE |
  typeof PERMISSION_MESSAGES_DICTIONARY | typeof PERMISSION_TARGETING_DICTIONARY |
  typeof PERMISSION_SEGMENTS_DICTIONARY | typeof PERMISSION_MOTIVATION_CONTENT_DICTIONARY |
  typeof PERMISSION_MESSAGE_GROUPS_DICTIONARY | typeof PERMISSION_LIMITS_STAT |
  typeof PERMISSION_PWA_ICONS | typeof PERMISSION_PWA | typeof PERMISSION_IPP |
  typeof PERMISSION_PWA_OFFLINE_PAGES;

type RoleToPermissionMapperType = {
  [key: Role]: Permission[],
};

function roleToPermissions(role: Role): Permission[] {
  const mapper: RoleToPermissionMapperType = {
    [ROLE_ADMIN]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_EXTENSION_DEV]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_DOMAINS_VIEWER]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_CID_USERS]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_PIXELS_OWNER]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_SIMILAR_WEB_USER]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_SECONDARY_OFFER_USER]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_MACHINES_ADMIN]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_MACHINES_CUSTOMER]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_WEBPUSH_USER]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_WEBPUSH_API]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_EXPERIMENTAL_USER]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_MONITORING]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_SYSTEM]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_TRAFFIC_SCORING]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_AFFILIATE]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_CLICKER_OWNER]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_RT_LISTS]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_PUMA_USER]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_COSTS]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
    [ROLE_GUEST]: [
      PERMISSION_WEB_PUSH_CAMPAIGNS,
      PERMISSION_SCHEDULE,
      PERMISSION_MESSAGES_DICTIONARY,
      PERMISSION_TARGETING_DICTIONARY,
      PERMISSION_SEGMENTS_DICTIONARY,
      PERMISSION_MOTIVATION_CONTENT_DICTIONARY,
      PERMISSION_MESSAGE_GROUPS_DICTIONARY,
      PERMISSION_LIMITS_STAT,
      PERMISSION_PWA_ICONS,
      PERMISSION_PWA,
      PERMISSION_PWA_OFFLINE_PAGES,
      PERMISSION_IPP,
    ],
  };

  return mapper[role] || [];
}

export default {
  roleToPermissions,
};
