// @flow
import {
  SET_LIMIT_STAT_LIST,
  CHANGE_LIMIT_STAT_LOADING,
  GET_LIMIT_LIST_SAGA,
} from "../../helpers/constants";
import type { LimitStatItem } from "../../reducers/limitStatList";

export type SetLimitStatList = {|
  type: typeof SET_LIMIT_STAT_LIST,
  list: LimitStatItem[],
|};

export type ChangeLimitStatLoading = {|
  type: typeof CHANGE_LIMIT_STAT_LOADING,
  loading: boolean,
|};

export type GetLimitStatSaga = {|
  type: typeof GET_LIMIT_LIST_SAGA
|};

export type Actions = SetLimitStatList | ChangeLimitStatLoading | GetLimitStatSaga;

export const setLimitStatList: (LimitStatItem[]) => SetLimitStatList = (list) => ({
  type: SET_LIMIT_STAT_LIST,
  list,
});

export const changeLimitStatLoading: (boolean) => ChangeLimitStatLoading = (loading) => ({
  type: CHANGE_LIMIT_STAT_LOADING,
  loading,
});

export const getLimitStatSaga: () => GetLimitStatSaga = () => ({
  type: GET_LIMIT_LIST_SAGA,
});
