// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetDictionarySaga from "../../sagas/getDictionary";
import watchGetFormDataSaga from "../../sagas/getFormDataSaga";
import watchSaveFormDataSaga from "../../sagas/saveFormDataSaga";
import getMessagesList from "../../sagas/getMessagesList";
import addTag from "../../sagas/addTag";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetDictionarySaga),
    call(watchGetFormDataSaga),
    call(watchSaveFormDataSaga),
    call(getMessagesList),
    call(addTag),
  ]);
}
