// @flow
import type {
  MessageGroupListItem,
  MessageGroupShort,
  MessageGroup,
  MessageGroupViewItem,
} from "../components/MessageGroupsList/types/MessageGroupsList.types";
import type { BrowserLanguage } from "../containers/CreateMessageGroup";
import type { MessageListItem } from "../components/MessagesList/types/MessagesList.types";

export type Strategy = {
  label: string,
  value: number,
};

export const strategiesList: Strategy[] = [
  { label: "", value: 0 },
  { label: "5 minutes after subscription", value: 1 },
  { label: "10 minutes after subscription", value: 2 },
  { label: "Exactly after subscription", value: 3 },
  { label: "When user goes online", value: 4 },
  { label: "When user get message", value: 5 },
  { label: "When user click on message", value: 6 },
  { label: "24 hours after subscription", value: 7 },
  { label: "Scheduling based", value: 8 },
  { label: "15 minutes after subscription", value: 9 },
  { label: "20 minutes after subscription", value: 10 },
  { label: "25 minutes after subscription", value: 11 },
  { label: "30 minutes after subscription", value: 12 },
  { label: "45 minutes after subscription", value: 13 },
  { label: "60 minutes after subscription", value: 14 },
  { label: "5 minutes after registration", value: 15 },
  { label: "10 minutes after registration", value: 16 },
  { label: "15 minutes after registration", value: 17 },
  { label: "20 minutes after registration", value: 18 },
  { label: "25 minutes after registration", value: 19 },
  { label: "30 minutes after registration", value: 20 },
  { label: "45 minutes after registration", value: 21 },
  { label: "60 minutes after registration", value: 22 },
  { label: "24 hours after registration", value: 23 },
  { label: "5 minutes after payment", value: 24 },
  { label: "10 minutes after payment", value: 25 },
  { label: "15 minutes after payment", value: 26 },
  { label: "20 minutes after payment", value: 27 },
  { label: "25 minutes after payment", value: 28 },
  { label: "30 minutes after payment", value: 29 },
  { label: "45 minutes after payment", value: 30 },
  { label: "60 minutes after payment", value: 31 },
  { label: "24 hours after payment", value: 32 },
];

export const messageGroupListItemToShort: (MessageGroupListItem) => MessageGroupShort = (messageGroup) => {
  const {
    name,
    isActive,
    browserLanguages,
    placements,
    sendStrategies,
    messages,
    tag,
  }: MessageGroupListItem = messageGroup;

  return {
    name,
    isActive,
    browserLanguages: browserLanguages.map((item: BrowserLanguage): string => item.id),
    placements,
    strategies: [
      (strategiesList.find((item: Strategy): boolean => item.label === sendStrategies) || {}).value
      || -1,
    ],
    messages: messages.map((item: MessageListItem): string => item.id),
    tag,
  };
};

export const messageGroupToListItem: (MessageGroup) => MessageGroupListItem = (messageGroup) => {
  const {
    id,
    name,
    isActive,
    messages,
    browserLanguages,
    placements,
    tag,
    sendStrategies,
    usedInCampaigns,
  }: MessageGroup = messageGroup;

  return {
    id,
    name,
    isActive,
    messages,
    browserLanguages,
    placements,
    tag,
    usedInCampaigns,
    sendStrategies: sendStrategies.name,
  };
};

export const messageGroupToViewItem: (MessageGroup) => MessageGroupViewItem = (messageGroup) => {
  const {
    id,
    name,
    isActive,
    rotationType,
    messages,
    browserLanguages,
    placements,
    tag,
    usedInCampaigns,
    sendStrategies,
  }: MessageGroup = messageGroup;

  return {
    id,
    name,
    isActive,
    rotationType,
    messages,
    browserLanguages,
    placements,
    tag,
    usedInCampaigns,
    sendStrategies: sendStrategies.name,
  };
};
