/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import { Multiselect } from "@fas/ui-core";
import {
  Grid,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Divider,
  Box,
  Switch,
  Paper,
  Typography,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";
import type { PWAFormData } from "../../containers/PwaMotivationContentList";
import HtmlPreviewButton from "../HtmlPreviewButton";
import CodeEditor from "../CodeEditor";
import TemplateBlock from "../TemplateBlock";
import { type MotivationTemplateType, type MotivationTemplateDropdown } from "../../containers/CreateMotivationTemplate";
import {
  convertModifiersToUi,
  type ModifiersStateData,
} from "../../containers/Modifiers";
import { useDictionary, useList } from "../../hooks";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

const nameRowSpacing = 3;
const fieldRowSpacing = 8;

type Props = {
  formData: PWAFormData,
  onSetPwaFormField: (filed: $Shape<PWAFormData>) => mixed,
  onSetModifiers: (MotivationTemplateType, ModifiersStateData) => *,
};

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100%",
    borderRadius: "4px",
    borderColor: "#ccc",
    boxSizing: "border-box",
  },
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
}));

const PwaMotivForm: StatelessFunctionalComponent<Props> = ({
  formData: {
    iconId,
    name,
    BrowserLanguages,
    body,
    head,
    initTimeout,
    toTimeout,
    inline_css: inlineCss,
    inline_html: inlineHtml,
    isActive,
    isBo,
    isExit,
    isSo,
    soId,
    isTo,
    behaviorToAction,
    visualisationType,
    isIos,
    ios_inline_html: iosInlineHtml,
    ios_inline_css: iosInlineCss,
    ios_head: iosHead,
    ios_body: iosBody,
    isTemplate,
    motivationTemplates,
  },
  onSetPwaFormField,
  onSetModifiers,
}: Props) => {
  const classes = useStyles();

  const { list: languages }: { list: DropDownObjItemType[] } = useDictionary("browserLanguages");
  const { list: pwaIconsList }: { list: DropDownObjItemType[] } = useDictionary("pwaIcons");
  const pwaVisualisationTypeList: DropDownObjItemType[] = useList("visualizationTypes");
  const behaviorToActions: DropDownObjItemType[] = useList("behaviorToActions");

  const handleChangeTemplate: (MotivationTemplateType, MotivationTemplateDropdown) => void = (
    type, template
  ) => {
    const modifiers: ModifiersStateData = convertModifiersToUi({ modifiers: template.modifiers, type });
    onSetModifiers(type, modifiers);
    onSetPwaFormField({
      motivationTemplates: {
        ...motivationTemplates,
        // $FlowFixMe
        [type]: template,
      },
    });
  };

  return (
    <Paper className={classes.container}>
      <Box my={3} ml={4}>
        <Typography gutterBottom variant="h5" component="h2">
          PWA Motivation Content General info
        </Typography>
      </Box>

      <Grid container rowSpacing={2}>
        <Grid container item justifyContent="center" alignItems="center">
          <Grid item xs={nameRowSpacing}>
            <InputLabel htmlFor="name">Name</InputLabel>
          </Grid>
          <Grid item xs={fieldRowSpacing}>
            <TextField
              size="small"
              name="name"
              variant="outlined"
              placeholder="Example: external_motiv_content_pwa_tag_en"
              fullWidth
              value={name}
              onChange={(e) => onSetPwaFormField({ name: e.target.value })}
              data-testid="name"
            />
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" alignItems="center">
          <Grid item xs={nameRowSpacing}>
            <InputLabel htmlFor="BrowserLanguages">
              Browser Languages
            </InputLabel>
          </Grid>
          <Grid item xs={fieldRowSpacing}>
            <Multiselect
              type="simpleMultiple"
              // $FlowFixMe
              selectedOptions={BrowserLanguages}
              options={languages}
              // $FlowFixMe
              onChange={(selectedBL) => onSetPwaFormField({ BrowserLanguages: selectedBL })}
              placeholder="Type languages or select from list"
              enableAddAllBtn
              clearIcon={<Close fontSize="small" />}
              enableAddMatchesBtn
              data-testid="browserLanguages"
            />
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" alignItems="center">
          <Grid item xs={nameRowSpacing}>
            <InputLabel htmlFor="initTimeout">Init Timeout</InputLabel>
          </Grid>
          <Grid item xs={fieldRowSpacing}>
            <TextField
              size="small"
              name="initTimeout"
              variant="outlined"
              placeholder="Example: 100"
              fullWidth
              type="number"
              value={initTimeout}
              onChange={(e) => onSetPwaFormField({ initTimeout: e.target.valueAsNumber })}
              inputProps={{
                min: "0",
                step: "1",
              }}
              data-testid="initTimeout"
            />
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" alignItems="center">
          <Grid item xs={nameRowSpacing}>
            <InputLabel htmlFor="behaviorToAction">
              Behavior To Action
            </InputLabel>
          </Grid>
          <Grid item xs={fieldRowSpacing}>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select
                name="behaviorToAction"
                displayEmpty
                value={behaviorToAction}
                onChange={(e) => onSetPwaFormField({ behaviorToAction: e.target.value })}
                data-testid="behaviorToAction"
              >
                <MenuItem value="" disabled>
                  Example: automatic
                </MenuItem>
                {behaviorToActions.map(({ label, value }: DropDownObjItemType): mixed => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" alignItems="center">
          <Grid item xs={nameRowSpacing}>
            <InputLabel htmlFor="visualisation-type">
              Visualisation Type
            </InputLabel>
          </Grid>
          <Grid item xs={fieldRowSpacing}>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select
                data-testid="visualization-type"
                id="visualization-type"
                value={visualisationType}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetPwaFormField({
                  visualisationType: e.target.value,
                })}
                placeholder="Select from list"
              >
                {pwaVisualisationTypeList.map(({ label, value }: DropDownObjItemType): mixed => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" alignItems="center">
          <Grid item xs={nameRowSpacing}>
            <InputLabel htmlFor="pwa-icon">
              PWA Icon
            </InputLabel>
          </Grid>
          <Grid item xs={fieldRowSpacing}>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select
                id="pwa-icon"
                data-testid="pwa-icon"
                value={iconId}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetPwaFormField({
                  iconId: e.target.value,
                })}
                placeholder="Select PWA icon from list"
              >
                {pwaIconsList.map(({ label, value }: DropDownObjItemType): mixed => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" alignItems="center">
          <Grid container item justifyContent="center" alignItems="center">
            <Grid item xs={nameRowSpacing}>
              <InputLabel htmlFor="back-offer">Back Offer</InputLabel>
            </Grid>
            <Grid item xs={fieldRowSpacing}>
              <Switch
                id="back-offer"
                data-testid="back-offer"
                color="primary"
                checked={isBo}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetPwaFormField({
                  isBo: e.target.checked,
                })}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" alignItems="center">
          <Grid container item justifyContent="center" alignItems="center">
            <Grid item xs={nameRowSpacing}>
              <InputLabel htmlFor="secondary-offer">Secondary Offer</InputLabel>
            </Grid>
            <Grid item xs={1}>
              <Switch
                id="secondary-offer"
                data-testid="secondary-offer"
                color="primary"
                checked={isSo}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetPwaFormField({
                  isSo: e.target.checked,
                })}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                id="so-id"
                data-testid="so-id"
                placeholder="Enter button ID"
                value={soId}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetPwaFormField({
                  soId: e.target.value,
                })}
                size="small"
                variant="outlined"
                inputProps={{ "aria-label": "bare" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" alignItems="center">
          <Grid container item justifyContent="center" alignItems="center">
            <Grid item xs={nameRowSpacing}>
              <InputLabel htmlFor="timeout-offer">Timeout Offer</InputLabel>
            </Grid>
            <Grid item xs={1}>
              <Switch
                id="timeout-offer"
                data-testid="timeout-offer"
                color="primary"
                checked={isTo}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetPwaFormField({
                  isTo: e.target.checked,
                })}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
            <Grid item xs={7}>
              <Tooltip
                title="min 1 max 900 sec"
                placement="right"
              >
                <TextField
                  id="to-id"
                  data-testid="to-id"
                  value={toTimeout}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetPwaFormField({
                    toTimeout: Number(e.target.valueAsNumber),
                  })}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    "aria-label": "bare",
                    max: 900,
                    min: 1,
                  }}
                  type="number"
                  placeholder="Enter timeout in MS"
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" alignItems="center">
          <Grid container item justifyContent="center" alignItems="center">
            <Grid item xs={nameRowSpacing}>
              <InputLabel htmlFor="exit-offer">Exit Offer</InputLabel>
            </Grid>
            <Grid item xs={fieldRowSpacing}>
              <Switch
                id="exit-offer"
                data-testid="exit-offer"
                color="primary"
                checked={isExit}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetPwaFormField({
                  isExit: e.target.checked,
                })}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" alignItems="center">
          <Grid container item justifyContent="center" alignItems="center">
            <Grid item xs={nameRowSpacing}>
              <InputLabel htmlFor="back-offer">Is Template</InputLabel>
            </Grid>
            <Grid item xs={fieldRowSpacing}>
              <Switch
                id="back-offer"
                data-testid="is-template"
                color="primary"
                checked={isTemplate}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onSetPwaFormField({
                  isTemplate: e.target.checked,
                })}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Box width={1} mb={2} mt={3}>
          <Divider />
        </Box>

        <Box my={1} ml={4}>
          <Typography gutterBottom variant="h5" component="h2">
            Android/WEB PWA Motivation Content
          </Typography>
        </Box>

        {!isTemplate && (
          <Grid container justifyContent="center">
            <Grid item xs={11} justifyContent="center">
              <CodeEditor
                height="400px"
                tabs={[
                  {
                    name: "html-editor",
                    label: "html",
                    value: inlineHtml,
                    mode: "html",
                    onChange: (value: string) => {
                      onSetPwaFormField({ inline_html: value });
                    },
                  },
                  {
                    name: "css-editor",
                    label: "css",
                    value: inlineCss,
                    mode: "css",
                    onChange: (value: string) => {
                      onSetPwaFormField({ inline_css: value });
                    },
                  },
                ]}
              />
              <HtmlPreviewButton
                title="Preview HTML + CSS code"
                data-testid="html-css-preview-btn"
                htmlCode={inlineHtml}
                cssCode={inlineCss}
              />
            </Grid>
          </Grid>
        )}

        {isTemplate && (
          <Box px={4} width={1}>
            <TemplateBlock
              handleChangeTemplate={handleChangeTemplate}
              template={motivationTemplates.PWAMotivationPopUp}
              type="PWAMotivationPopUp"
            />
          </Box>
        )}

        {visualisationType === "inline_without_manifest" && (
          <>
            <Box my={1} ml={4}>
              <Typography gutterBottom variant="h5" component="h2">
                Android/WEB PWA Install Page
              </Typography>
            </Box>

            {!isTemplate && (
              <Grid container justifyContent="center">
                <Grid item xs={11} justifyContent="center">
                  <CodeEditor
                    height="400px"
                    tabs={[
                      {
                        name: "head",
                        label: "head html",
                        value: head,
                        mode: "html",
                        onChange: (value: string) => {
                          onSetPwaFormField({ head: value });
                        },
                      },
                      {
                        name: "body",
                        label: "body html",
                        value: body,
                        mode: "html",
                        onChange: (value: string) => {
                          onSetPwaFormField({ body: value });
                        },
                      },
                    ]}
                  />
                  <HtmlPreviewButton
                    title="Preview HEAD + BODY code"
                    data-testid="html-css-preview-btn"
                    htmlCode={head + body}
                    cssCode=""
                  />
                </Grid>
              </Grid>
            )}

            {isTemplate && (
              <Box px={4} width={1}>
                <TemplateBlock
                  handleChangeTemplate={handleChangeTemplate}
                  template={motivationTemplates.PWAInstallPage}
                  type="PWAInstallPage"
                />
              </Box>
            )}
          </>
        )}

        <Box width={1} mb={2} mt={3}>
          <Divider />
        </Box>

        <Grid container item justifyContent="center" alignItems="center">
          <Grid item xs={nameRowSpacing}>
            <InputLabel htmlFor="isIos">PWA on IOS</InputLabel>
          </Grid>
          <Grid item xs={fieldRowSpacing}>
            <Switch
              name="isIos"
              color="primary"
              checked={isIos}
              onChange={(e) => onSetPwaFormField({ isIos: e.target.checked })}
              data-testid="isIos"
            />
          </Grid>
        </Grid>

        {isIos && (
          <>
            <Box my={1} ml={4}>
              <Typography gutterBottom variant="h5" component="h2">
                IOS PWA Motivation Content
              </Typography>
            </Box>

            {!isTemplate && (
              <Grid container justifyContent="center">
                <Grid item xs={11} justifyContent="center">
                  <CodeEditor
                    height="400px"
                    tabs={[
                      {
                        name: "ios-html-editor",
                        label: "html",
                        value: iosInlineHtml,
                        mode: "html",
                        onChange: (value) => {
                          onSetPwaFormField({ ios_inline_html: value });
                        },
                      },
                      {
                        name: "ios-css-editor",
                        label: "css",
                        value: iosInlineCss,
                        mode: "css",
                        onChange: (value) => {
                          onSetPwaFormField({ ios_inline_css: value });
                        },
                      },
                    ]}
                  />
                  <HtmlPreviewButton
                    title="Preview HTML + CSS code"
                    data-testid="html-css-preview-btn"
                    htmlCode={iosInlineHtml}
                    cssCode={iosInlineCss}
                  />
                </Grid>
              </Grid>
            )}

            {isTemplate && (
              <Box px={4} width={1}>
                <TemplateBlock
                  handleChangeTemplate={handleChangeTemplate}
                  template={motivationTemplates.PWAMotivationPopUpIos}
                  type="PWAMotivationPopUpIos"
                  fetchType="PWAMotivationPopUp"
                />
              </Box>
            )}

            {visualisationType === "inline_without_manifest" && (
              <>
                <Box my={1} ml={4}>
                  <Typography gutterBottom variant="h5" component="h2">
                    IOS PWA Install Page
                  </Typography>
                </Box>

                {!isTemplate && (
                  <Grid container justifyContent="center">
                    <Grid item xs={11} justifyContent="center">
                      <CodeEditor
                        height="400px"
                        tabs={[
                          {
                            name: "ios-head",
                            label: "head html",
                            value: iosHead,
                            mode: "html",
                            onChange: (value) => {
                              onSetPwaFormField({ ios_head: value });
                            },
                          },
                          {
                            name: "ios-body",
                            label: "body html",
                            value: iosBody,
                            mode: "html",
                            onChange: (value) => {
                              onSetPwaFormField({ ios_body: value });
                            },
                          },
                        ]}
                      />
                      <HtmlPreviewButton
                        title="Preview HEAD + BODY code"
                        data-testid="html-css-preview-btn"
                        htmlCode={iosHead + iosBody}
                        cssCode=""
                      />
                    </Grid>
                  </Grid>
                )}

                {isTemplate && (
                  <Box px={4} width={1}>
                    <TemplateBlock
                      handleChangeTemplate={handleChangeTemplate}
                      template={motivationTemplates.PWAInstallPageIos}
                      type="PWAInstallPageIos"
                      fetchType="PWAInstallPage"
                    />
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Grid>

      <Box width={1} mb={2} mt={3}>
        <Divider />
      </Box>

      <Grid container item justifyContent="center" alignItems="center">
        <Grid item xs={nameRowSpacing}>
          <InputLabel htmlFor="isActive">Is Active</InputLabel>
        </Grid>
        <Grid item xs={fieldRowSpacing}>
          <Switch
            name="isActive"
            color="primary"
            checked={isActive}
            onChange={(e) => onSetPwaFormField({ isActive: e.target.checked })}
            data-testid="isActive"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PwaMotivForm;
