// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetFormDataSaga from "../../sagas/getMotivationTemplate";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetFormDataSaga),
  ]);
}
