/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type {
  Dispatch,
  CombinedReducer,
  Store,
} from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import type { OutputSelector } from "reselect";

import notifications, { initNotificationsState, type State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";

import loading, { initLoadingState, type State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";

import targeting, { initTargetingViewState, type State as TargetingState } from "../../reducers/targetingView";
import type { Actions as TargetingActions } from "../../actions/targetingView";

import mainSaga from "./saga";

export type TargetingViewKeys = "isTargetingLoading";

export type State = $ReadOnly<{
  notifications: NotificationsState,
  loading: LoadingState<TargetingViewKeys>,
  targeting: TargetingState,
}>;

export type Actions = NotificationsActions | LoadingActions<TargetingViewKeys> | TargetingActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  loading,
  targeting,
});

export const getLoading: OutputSelector<
  State,
  TargetingViewKeys,
  boolean
> = createLoadingSelector<TargetingViewKeys>();

export const mapState: () => State = () => ({
  notifications: initNotificationsState(),
  loading: initLoadingState({ isTargetingLoading: true }),
  targeting: initTargetingViewState(),
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
