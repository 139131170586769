// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, type Node } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Box,
} from "@mui/material";
import SetupCard from "@fas/ui-core/lib/SetupCard";
import { useParams } from "react-router-dom";
import { useFormField } from "@fas/ui-framework/lib/services/form";
import {
  TextFieldForm,
  SwitchFieldForm,
} from "@fas/ui-core/lib/Form";
import { FormHeader, Loader, AddData } from "@fas/ui-core";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { useLoading } from "../../hooks";
import {
  FORM_KEY_SEGMENT,
  GET_FORM_DATA_LOADING,
  SAVE_FORM_DATA_LOADING,
} from "../../helpers/constants";
import { getSegmentFormDataSaga, saveSegmentSaga } from "../../actions/segmentView";
import Form from "../Form";
import validate from "./validate";
import type { State } from "../../pages/CreateTargeting";
import AddMessageGroups from "../AddMessageGroups";
import SegmentConditions from "../SegmentConditions";

const CreateSegment = (): Node => {
  const dispatch: <A>(A) => A = useDispatch();
  const { id: objectId } = useParams();

  const { value: isLimit, onChange: onChangeIsLimit } = useFormField(FORM_KEY_SEGMENT, "isLimit");

  useEffect(() => {
    dispatch(getSegmentFormDataSaga(objectId));
  }, [dispatch, objectId]);

  const id: string = useSelector((state: State) => getFormField(state, FORM_KEY_SEGMENT, "id"));
  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  const addLimit: () => void = () => {
    onChangeIsLimit(true);
  };

  const removeLimit: () => void = () => {
    onChangeIsLimit(false);
  };

  const onSave: () => void = () => {
    dispatch(saveSegmentSaga());
  };

  return (
    <Form id={FORM_KEY_SEGMENT} validate={validate} onSave={onSave}>
      <FormHeader
        title={id ? `Edit segment: ${String(id)}` : "Create segment"}
        loading={loading}
        isActionVisible={!loading}
      />
      <Container maxWidth="md">
        <SetupCard title="General Info">
          <Box width={1} my={1}>
            <Loader loading={loading}>
              <TextFieldForm
                name="name"
                label="Name"
              />
              <Box ml={1} my={1} width={120}>
                <SwitchFieldForm
                  name="isActive"
                  label="Is Active"
                />
              </Box>
            </Loader>

            <SegmentConditions />
          </Box>
        </SetupCard>

        {!isLimit && (
          <Box mt={3}>
            <AddData
              linkText="+ add limits"
              title="You can add limits"
              onClick={addLimit}
            />
          </Box>
        )}

        {isLimit && (
          <>
            <Loader loading={loading}>
              <SetupCard title="Messages" showSecondaryBtn secondaryBtnText="Delete" onSecondaryBtnClick={removeLimit}>
                <Box width={1} mt={1}>
                  <TextFieldForm
                    name="maximum"
                    label="Maximum"
                    type="number"
                  />
                  <TextFieldForm
                    name="minimum"
                    label="Minimum"
                    type="number"
                  />
                </Box>
              </SetupCard>
            </Loader>

            <Loader loading={loading}>
              <AddMessageGroups formKey={FORM_KEY_SEGMENT} />
            </Loader>
          </>
        )}
      </Container>
    </Form>
  );
};

export default CreateSegment;
