// @flow
import React, { useState, type Node } from "react";
import {
  Box,
  TextField,
  Popover,
  InputAdornment,
  type PopoverProps,
  type StandardInputProps,
} from "@mui/material";
import { SketchPicker, type SketchPickerProps } from "react-color";
import type { UseState } from "../../helpers/common.types";

type Props = {
  label: string,
  name: string,
  value: string,
  error: string,
  inputProps: StandardInputProps,
  popoverProps: PopoverProps,
  pickerProps: SketchPickerProps,
  onChange: (string) => mixed,
  classes: { input?: string, popover?: string, picker?: string },
  disabled?: boolean,
  readOnly?: boolean,
}

function ColorPicker({
  label,
  name,
  value,
  inputProps,
  popoverProps,
  pickerProps,
  error,
  classes,
  onChange,
  disabled,
  readOnly,
}: Props): Node {
  const [localError, setLocalError]: UseState<boolean> = useState(false);
  const [anchorEl, setAnchorEl]: UseState<HTMLElement | null> = useState(null);
  const hexRegExp: RegExp = new RegExp("^#[0-9A-Fa-f]{6}$");
  const editable: boolean = !(disabled || readOnly);

  const handleChange: (string) => void = (newValue) => {
    setLocalError(!hexRegExp.test(newValue));
    onChange(newValue);
  };
  const handleClick: (SyntheticEvent<HTMLInputElement>) => void = (e) => {
    if (editable) {
      setAnchorEl(e.currentTarget);
    }
  };
  const handleClosePopover: () => void = () => {
    setAnchorEl(null);
  };

  const open: boolean = Boolean(anchorEl);

  return (
    <>
      <TextField
        fullWidth
        className={classes.input}
        data-testid={name}
        label={label}
        name={name}
        size="small"
        error={localError || Boolean(error)}
        helperText={error}
        value={value}
        onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
          handleChange(e.target.value);
        }}
        disabled={disabled}
        InputProps={{
          ...inputProps,
          readOnly,
          endAdornment: (
            <InputAdornment position="end">
              <Box
                data-testid={`${name}-color-btn`}
                width={30}
                height={30}
                backgroundColor={value}
                border="1px solid #E0DADA"
                borderRadius={1}
                sx={{
                  cursor: editable ? "pointer" : "not-allowed",
                }}
                onClick={handleClick}
              />
            </InputAdornment>
          ),
        }}

      />
      <Popover
        {...popoverProps}
        data-testid="color-picker-popover"
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <SketchPicker
          {...pickerProps}
          className={classes.picker}
          data-testid="color-picker"
          color={value || "#000000"}
          onChange={({ hex }: { hex: string }) => {
            handleChange(hex);
          }}
          onChangeComplete={({ hex }: { hex: string }) => {
            handleChange(hex);
          }}
          disableAlpha
        />
      </Popover>
    </>
  );
}

ColorPicker.defaultProps = {
  label: "",
  name: "",
  inputProps: {},
  popoverProps: {},
  pickerProps: {},
  classes: {},
  error: "",
  onChange: () => {},
  disabled: false,
  readOnly: false,
};

export default ColorPicker;
