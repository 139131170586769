// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { ScheduleListItem, ScheduleShort } from "../../containers/SchedulesList/types/SchedulesList.types";
import type { ScheduleFormData } from "../../containers/CreateSchedule";
import { convertToUi, convertToServer } from "../../helpers/schedule";

export const getSchedulesList: (number, number, { [key: string]: mixed }) => PromiseResponse<mixed, {
  data: ScheduleListItem[],
  totalCount: number
}> = (
  page,
  limit,
  filterList
) => requestService.get(environment.endpoints.get.schedulesList, {
  params: {
    ...filterList,
    page,
    limit,
  },
});

export const getScheduleById: (string) => Promise<*> = (id) => requestService
  .get(environment.endpoints.get.scheduleById.replace("{id}", id))
  .then(({ data: { data } }) => convertToUi(data[0]));

export const updateSchedule: (string, ScheduleShort) => PromiseResponse<ScheduleShort> = (
  id,
  schedule
) => requestService.put(environment.endpoints.put.schedules.replace("{id}", id), schedule);

export async function saveScheduleForm({
  id,
  ...body
}: ScheduleFormData) {
  if (id) {
    await requestService.put(environment.endpoints.put.schedules.replace("{id}", id), convertToServer(body));
  }
  else {
    await requestService.post(environment.endpoints.post.schedules, convertToServer(body));
  }
}

export const deleteSchedulesByIds: (string[]) => PromiseResponse<mixed> = (idList) => requestService
  .delete(environment.endpoints.delete.schedules, { data: { idList } });
