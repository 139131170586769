/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect } from "react";
import type { StatelessFunctionalComponent } from "react";
import { SetupCard, GridUc, Loader } from "@fas/ui-core";
import {
  Container,
  Grid,
  Divider,
  Box,
  Switch,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import type {
  GetIppSaga,
} from "../../actions/createIpp";
import Collapse from "../Collapse";
import NamedItem from "../NamedItem";
import externalRedirect from "../App/ExternalRedirect";
import type { Classes, ViewBrowserLanguage } from "./types/ViewIpp.types";
import type { IPP } from "../IppList/types/IppList.types";
import HtmlPreviewButton from "../HtmlPreviewButton";
import AdditionalInfo from "../AdditionalInfo";
import CodeEditor from "../CodeEditor";
import TemplateBlock from "../TemplateBlock";

type Props = {
  formData: IPP,
  isLoading: boolean,
  getIpp: (id: string) => GetIppSaga,
};

const useStyles: () => Classes = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
  codeRoot: {
    boxSizing: "border-box",
  },
}));

const IppView: StatelessFunctionalComponent<Props> = ({
  formData: {
    id: ippId,
    name: ippName,
    BrowserLanguages,
    initTimeout,
    behaviorToAction,
    isActive,
    shouldChangeDomain,
    inline_css: inlineCss,
    inline_html: inlineHtml,
    usedInCampaigns,
    isTemplate,
    motivationTemplates: {
      IPPMotivationPopUp,
    },
  },
  isLoading,
  getIpp,
}: Props) => {
  const classes: Classes = useStyles();
  const { id: urlId } = useParams();

  const getItemEditPath: (string) => void = () => {
    if (urlId) {
      externalRedirect({ to: `/ipp/edit/${urlId}`, target: "_blank" });
    }
  };

  useEffect(() => {
    if (urlId) {
      getIpp(urlId);
    }
  }, [getIpp, urlId]);

  return (
    <>
      {isLoading && (
        <Loader loading size={35} mt={37} />
      )}
      {!isLoading && (
        <Container maxWidth="md">
          <SetupCard
            title="IPP Motivation Content info"
            showSecondaryBtn
            secondaryBtnText="Edit"
            onSecondaryBtnClick={() => getItemEditPath(ippId)}
          >
            <Box width="100%" pb={3} className={classes.root}>
              <NamedItem name="Name" value={ippName} />
              <NamedItem name="Id" value={ippId} />
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">isActive</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Switch checked={isActive} disabled />
                </Grid>
              </GridUc>
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">Domain Changing</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Switch checked={shouldChangeDomain} disabled />
                </Grid>
              </GridUc>
              <Box mb={4} mt={3} px={3}>
                <Divider />
              </Box>
              <NamedItem name="Init timeout" value={initTimeout.toString()} />
              <NamedItem name="Behavior to action" value={behaviorToAction} />
              <GridUc
                mt={3}
                px={3}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">Browser languages:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Collapse
                    showMoreDisabled={false}
                    list={BrowserLanguages.map(
                      ({
                        value: id,
                        label: name,
                      }: { value: string, label: string }): ViewBrowserLanguage => ({
                        id,
                        name,
                      })
                    )}
                  />
                </Grid>
              </GridUc>
              <Box px={3} className={classes.codeRoot}>
                <Box mb={4} mt={3}>
                  <Divider />
                </Box>

                {!isTemplate && (
                  <Box mb={2} width={1}>
                    <Grid container justifyContent="center">
                      <Grid item xs={12}>
                        <CodeEditor
                          height="400px"
                          tabs={[
                            {
                              name: "html-editor",
                              label: "html",
                              value: inlineHtml,
                              mode: "html",
                              readOnly: true,
                            },
                            {
                              name: "css-editor",
                              label: "css",
                              value: inlineCss,
                              mode: "css",
                              readOnly: true,
                            },
                          ]}
                        />
                        <HtmlPreviewButton
                          title="Preview HTML + CSS code"
                          data-testid="html-css-preview-btn"
                          htmlCode={inlineHtml}
                          cssCode={inlineCss}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {isTemplate && (
                  <TemplateBlock
                    template={IPPMotivationPopUp}
                    type="IPPMotivationPopUp"
                    readOnly
                  />
                )}
              </Box>
            </Box>
          </SetupCard>
          <AdditionalInfo usedInCampaigns={usedInCampaigns} />
        </Container>
      )}
    </>
  );
};

export default IppView;
