// @flow
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDictionaryLoadingKey } from "../helpers/generators";
import { getOptionList } from "../selectors/dictionaries";
import { getDictionarySaga } from "../actions/dictionary";
import { useLoading } from "./useLoading";
import type { DropDownObjItemType } from "../reducers/dictionaries";

export function useList(listKey: string): DropDownObjItemType[] {
  return useSelector((state) => getOptionList(state, listKey));
}

export function useDictionary(dictionaryName: string): { list: DropDownObjItemType[], loading: boolean } {
  const dispatch = useDispatch();
  const loading: boolean = useLoading<string>(getDictionaryLoadingKey(dictionaryName));
  const list: DropDownObjItemType[] = useSelector((state) => getOptionList(state, dictionaryName));

  useEffect(() => {
    dispatch(getDictionarySaga(dictionaryName));
  }, [dispatch, dictionaryName]);

  return { list, loading };
}
