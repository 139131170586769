// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import fetchSegmentsSaga from "../../sagas/getSegments";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import { SEGMENTS_LIST_TABLE } from "../../helpers/constants";
import type { SegmentSaveData } from "../../containers/CreateSegment";
import SegmentApi from "../../services/segmentApi";
import { getSegmentsSaga } from "../../actions/segmentsList";

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<SegmentSaveData, SegmentSaveData>({
  tableKey: SEGMENTS_LIST_TABLE,
  updateMethod: SegmentApi.updateSegment,
  getListAction: getSegmentsSaga,
  convert: (segment: SegmentSaveData) => segment,
  dictionaryName: "Segments",
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(fetchSegmentsSaga),
    call(changeIsActiveSaga),
  ]);
}
