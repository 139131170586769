// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, type Node } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Box,
} from "@mui/material";
import SetupCard from "@fas/ui-core/lib/SetupCard";
import { useParams } from "react-router-dom";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import {
  SelectForm,
  TextFieldForm,
} from "@fas/ui-core/lib/Form";
import { FormHeader, Loader } from "@fas/ui-core";
import { useLoading, useList } from "../../hooks";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { FORM_KEY_MESSAGE, GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants";
import Form from "../Form";
import EmojiTextFieldForm from "../Form/EmojiTextFieldForm";
import InputUploadForm from "../Form/InputUploadForm";
import MessagePreviewForm from "../Form/MessagePreviewForm";
import ActionsForm from "./ActionsForm";
import validate from "./validate";

const CreateMessage = (): Node => {
  const dispatch: <A>(A) => A = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getFormDataSaga(FORM_KEY_MESSAGE, id));
    }
  }, [dispatch, id]);

  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  const priorityList: DropDownObjItemType[] = useList("priorityList");
  const timeToLiveList: DropDownObjItemType[] = useList("timeToLiveList");

  return (
    <Form id={FORM_KEY_MESSAGE} redirectOnSave="/messages" validate={validate}>
      <FormHeader
        title={id ? `Edit massege: ${String(id)}` : "Create message"}
        loading={loading}
        isActionVisible={!loading}
      />
      <Container maxWidth="md">
        <SetupCard title="General Info">
          <Box width={1} mt={1}>
            <Loader loading={loading}>
              <TextFieldForm
                name="name"
                label="Name"
              />
              <SelectForm
                name="timeToLive"
                label="Time to live"
                options={timeToLiveList}
              />
              <SelectForm
                name="priority"
                label="Priority"
                options={priorityList}
              />
            </Loader>
          </Box>
        </SetupCard>

        <SetupCard title="Message content" style={{ overflow: "visible" }}>
          <Box width={1} mt={1}>
            <Loader loading={loading}>
              <TextFieldForm
                name="clickUrl"
                label="Default click url"
              />
              <TextFieldForm
                name="customAttributes"
                label="Custom attributes"
              />
              <EmojiTextFieldForm
                name="title"
                label="Message title"
              />
              <EmojiTextFieldForm
                name="body"
                label="Message body"
              />
              <InputUploadForm
                name="image"
                label="Message image"
              />
              <InputUploadForm
                name="icon"
                label="Icon"
              />
              <InputUploadForm
                name="badge"
                label="Message badge"
              />
              <TextFieldForm
                name="tag"
                label="Tags"
              />
            </Loader>
          </Box>
        </SetupCard>

        <ActionsForm name="actions" />

        <SetupCard title="Preview Android / Chrome">
          <Box width="100%" py={2}>
            <MessagePreviewForm formKey={FORM_KEY_MESSAGE} />
          </Box>
        </SetupCard>
      </Container>
    </Form>
  );
};

export default CreateMessage;
