// @flow
import {
  SET_WP,
  SET_WP_FORM_FIELD,
  SAVE_WP_SAGA,
  GET_WP_SAGA,
} from "../../helpers/constants";
import type {
  WPFormData,
} from "../../containers/CreateWPMotivationContent";

export type SetWp = {|
  type: typeof SET_WP,
  payload: WPFormData,
|};

export type SetWpFormField = {|
  type: typeof SET_WP_FORM_FIELD,
  payload: $Shape<WPFormData>,
|};

export type SaveWpSaga = {|
  type: typeof SAVE_WP_SAGA,
|};

export type GetWpSaga = {|
  type: typeof GET_WP_SAGA,
  id: string,
|};

export type Actions =
  | SetWp
  | SetWpFormField
  | SaveWpSaga
  | GetWpSaga;

export const setWpFormField: (
  field: $Shape<WPFormData>
) => SetWpFormField = (field) => ({
  type: SET_WP_FORM_FIELD,
  payload: field,
});

export const saveWpSaga: () => SaveWpSaga = () => ({ type: SAVE_WP_SAGA });

export const getWpSaga: (id: string) => GetWpSaga = (id: string) => ({
  type: GET_WP_SAGA,
  id,
});

export const setWp: (wp: WPFormData) => SetWp = (wp) => ({
  type: SET_WP,
  payload: wp,
});
