/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect, type Node } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "@fas/ui-core/lib";
import { stepChange } from "@fas/ui-framework/lib/redux/actions/steps";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import Form from "../Form";
import {
  FORM_KEY_CAMPAIGN,
  GET_FORM_DATA_LOADING,
  SAVE_FORM_DATA_LOADING,
} from "../../helpers/constants";
import {
  CampaignInfoStep as CampaignInfo,
  MessageGroupsCampaignStep,
  MotivationContentStep as MotivationContents,
  TargetingsStep as Targetings,
  CampaignPreviewStep as CampaignPreview,
} from "..";
import { useLoading, useActions } from "../../hooks";
import Steps from "../Steps";

const stepsMap = {
  "1": CampaignInfo,
  "2": MotivationContents,
  "3": MessageGroupsCampaignStep,
  "4": Targetings,
  "5": CampaignPreview,
};

function WpCampaign(): Node {
  const { id: objectId } = useParams();

  useEffect(() => {
    if (objectId) {
      onGetFormDataSaga(FORM_KEY_CAMPAIGN, objectId);
    }
  }, [objectId]);

  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  const {
    getFormDataSaga: onGetFormDataSaga,
    stepChange: onStepChange,
  } = useActions({
    getFormDataSaga,
    stepChange,
  });

  return (
    <Form id={FORM_KEY_CAMPAIGN}>
      <Loader loading={loading}>
        <Steps
          onClick={onStepChange}
          getStepContent={(stepKey) => stepsMap[stepKey]}
        />
      </Loader>
    </Form>
  );
}

export default WpCampaign;
