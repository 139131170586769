// @flow
import React, {
  type StatelessFunctionalComponent,
  type Element,
} from "react";
import {
  TextField,
  Grid,
  Avatar,
  Button,
  Typography,
  Container,
  Link as UiLink,
  FormHelperText,
  Card,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Link } from "react-router-dom";

type Field = {
  label: string,
  value: string,
  type: string,
  error: boolean
}

type Props = {
  fields: Field[],
  handleChange: (SyntheticInputEvent<HTMLInputElement>) => void,
  formError: {
    message: string,
    isValid: boolean
  },
  formName: string,
  redirectLink: {
    url: string,
    label: string
  },
  handleSubmit: (SyntheticKeyboardEvent<HTMLFormElement>) => void,
};

const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  card: {
    marginTop: theme.spacing(8),
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    padding: "15px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const AuthForm: StatelessFunctionalComponent<Props> = ({
  fields,
  handleChange,
  formError,
  formName,
  redirectLink,
  handleSubmit,
}: Props) => {
  const classes = useStyles();
  const onKeyPress: (event: SyntheticKeyboardEvent<HTMLFormElement>) => void = (event) => {
    if (event.charCode === 13) {
      handleSubmit(event);
    }
  };

  return (
    <Container component="main" maxWidth="xs" onKeyPress={onKeyPress}>
      <Card className={classes.card}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login form
          </Typography>
          <form className={classes.form} noValidate>
            <FormHelperText error={!formError.isValid}>
              {formError.message}
            </FormHelperText>
            {fields.map((item: Field, index: number): Element<typeof TextField> => (
              <TextField
              /* eslint-disable-next-line react/no-array-index-key */
                key={item.label + index}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id={item.label}
                label={item.label}
                name={item.label}
                autoFocus={!index}
                onChange={handleChange}
                value={item.value}
                type={item.type}
                error={item.error}
              />
            ))}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              {formName}
            </Button>
            <Grid container>
              <Grid item>
                <UiLink component={Link} to={redirectLink.url} variant="body2">
                  {redirectLink.label}
                </UiLink>
              </Grid>
            </Grid>
          </form>
        </div>
      </Card>
    </Container>
  );
};

export default AuthForm;
