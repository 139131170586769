/* eslint-disable import/max-dependencies */
// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import fetchIPPList from "../../sagas/getIPPList";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import { IPP_LIST_TABLE } from "../../helpers/constants";
import IPPApi from "../../services/IPPApi";
import type { IPPListData } from "../../components/IppList";
import { getIPPListSaga } from "../../actions/ippList";
import type { IppRequestBody } from "../../services/IPPApi/IPPApi";
import getDeleteSaga from "../../sagas/deleteSaga";
import type { MotivationTemplateType } from "../../containers/CreateMotivationTemplate";

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<IPPListData, IppRequestBody>({
  tableKey: IPP_LIST_TABLE,
  updateMethod: IPPApi.updateIpp,
  getListAction: getIPPListSaga,
  convert: ({ BrowserLanguages, motivationTemplates, ...ippItem }: IPPListData) => ({
    ...ippItem,
    BrowserLanguages: BrowserLanguages?.map(({ id: langId }) => langId)
      .filter(Boolean),
    motivationTemplates: motivationTemplates
      ? Object.keys(motivationTemplates)
        // $FlowFixMe
        .reduce((acc, key: MotivationTemplateType) => ({ ...acc, [key]: motivationTemplates[key].id }), {})
      : {},
  }),
  dictionaryName: "IPP Motivation content",
});

const deleteSaga: () => Saga<void> = getDeleteSaga({
  tableKey: IPP_LIST_TABLE,
  deleteMethod: IPPApi.deleteIpp,
  getListAction: getIPPListSaga,
  dictionaryName: "IPP Motivation content",
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(fetchIPPList),
    call(deleteSaga),
    call(changeIsActiveSaga),
  ]);
}
