// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { Targeting, TargetingShort } from "../../containers/TargetingsList";
import type { TargetingFormData, TargetingFormDataShort } from "../../containers/CreateTargeting";

export const getTargetingsList: (number, number, { [key: string]: mixed }) => PromiseResponse<mixed, {
    data: Targeting[],
    totalCount: number
  }
> = (
  page,
  limit,
  filters
) => requestService.get(environment.endpoints.get.targetingsList, {
  params: {
    ...filters,
    page,
    limit,
  },
});

const convertToUI: (Targeting) => TargetingFormData = ({
  // eslint-disable-next-line no-unused-vars
  isData1TargetingIgnore, createdAt, updatedAt, ...data
}) => ({
  ...data,
  BrowserLanguages: data.BrowserLanguages.map(({ id }) => id),
  placement: data.placement[0],
  data1: data.data1 ? data.data1.split(",") : [],
});

const convertToServer: (TargetingFormDataShort) => TargetingShort = ({
  // eslint-disable-next-line no-unused-vars
  usedInCampaigns, ...data
}) => ({
  ...data,
  placement: [data.placement],
  data1: data.data1.join(","),
});

export const getTargetingFormData = (id: string) => requestService
  .get(environment.endpoints.get.targetingById.replace("{id}", id))
  .then(({ data: { data } }: { data: { data: Targeting[] } }): TargetingFormData => convertToUI(data[0]));

export const getTargetingById: (string) => PromiseResponse<mixed, { data: Targeting[] }> = (id) => requestService
  .get(environment.endpoints.get.targetingById.replace("{id}", id));

export const updateTargeting: (string, TargetingShort) => PromiseResponse<TargetingShort> = (
  id,
  targeting
) => requestService.put(environment.endpoints.put.targetings.replace("{id}", id), targeting);

export const deleteTargetingsByIds: (string[]) => PromiseResponse<mixed> = (idList) => requestService
  .delete(environment.endpoints.delete.targetings, { data: { idList } });

export const getData1List: (?string) => PromiseResponse<mixed, { data: string[] }> = (name) => requestService
  .get(environment.endpoints.get.data1, { params: { name } });

export const saveData1: (string) => PromiseResponse<{ name: string }> = (name) => requestService
  .post(environment.endpoints.get.data1, { name });

export const deleteData1ByName: (string) => PromiseResponse<mixed> = (name) => requestService
  .delete(`${environment.endpoints.delete}/${name}`);

export async function saveTargetingForm({
  id,
  ...body
}: TargetingFormData) {
  if (id) {
    await requestService.put(environment.endpoints.put.targetings.replace("{id}", id), convertToServer(body));
  }
  else {
    await requestService.post(environment.endpoints.post.targetings, convertToServer(body))
      .then(({ data }) => data.id);
  }
}
