/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type {
  Dispatch,
  CombinedReducer,
  Store,
} from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import type { OutputSelector } from "reselect";

import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";

import notifications, { initNotificationsState, type State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";

import loading, { initLoadingState, type State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";

import type { Actions as CampaignActions } from "../../actions/createCampaign";
import { FORM_KEY_CAMPAIGN, GET_FORM_DATA_LOADING } from "../../helpers/constants";

import mainSaga from "./saga";
import { defaultFromData } from "../CreateCampaign/store";

export type CampaignViewKeys = typeof GET_FORM_DATA_LOADING;

export type State = $ReadOnly<{
  notifications: NotificationsState,
  loading: LoadingState<CampaignViewKeys>,
  form: FormState,
}>;

export type Actions = NotificationsActions | LoadingActions<CampaignViewKeys> | CampaignActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
  loading,
  form,
});

export const getLoading: OutputSelector<
  State,
  CampaignViewKeys,
  boolean
> = createLoadingSelector<CampaignViewKeys>();

export const mapState: () => State = () => ({
  notifications: initNotificationsState(),
  loading: initLoadingState({ GET_FORM_DATA_LOADING: true }),
  form: initFormState({
    [FORM_KEY_CAMPAIGN]: defaultFromData,
  }),
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
