/* eslint-disable import/max-dependencies */
// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import saveCampaign from "../../sagas/saveCampaign";
import getIPPList from "../../sagas/getIPPList";
import getMotivationContents from "../../sagas/getMotivationContents";
import getPwaOfflinePagesList from "../../sagas/getPwaOfflinePagesList";
import getPwaList from "../../sagas/getPwaList";
import getMessageGroupsList from "../../sagas/getMessageGroupsList";
import getTargetingsList from "../../sagas/getTargetingsList";
import watchStepsClick from "../../sagas/stepsSaga";
import watchGetFormDataSaga from "../../sagas/getFormDataSaga";
import { CAMPAIGN } from "../../helpers/constants";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetFormDataSaga),
    call(saveCampaign),
    call(getIPPList),
    call(getMotivationContents),
    call(getPwaList),
    call(getMessageGroupsList),
    call(getTargetingsList),
    call(getPwaOfflinePagesList),
    call(watchStepsClick, CAMPAIGN),
  ]);
}
