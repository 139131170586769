/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect } from "react";
import type { StatelessFunctionalComponent } from "react";
import {
  GridUc, Loader, SetupCard,
} from "@fas/ui-core";
import {
  Grid,
  Divider,
  Box,
  Switch,
  Typography,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import HtmlPreviewButton from "../HtmlPreviewButton";
import externalRedirect from "../App/ExternalRedirect";
import NamedItem from "../NamedItem";
import Collapse from "../Collapse/Collapse";
import type { ViewBrowserLanguage } from "../IppView/types/ViewIpp.types";
import type { RawPwaOfflinePage } from "../PwaOfflinePagesList";
import CodeEditor from "../CodeEditor";

type Props = {
  ...RawPwaOfflinePage,
  isLoading: boolean,
  getPwaOffline: (pwaId: string) => mixed
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.blueBackgroundLight,
    borderWidth: "1px",
    borderRadius: theme.borderRadius,
  },
  codeRoot: {
    boxSizing: "border-box",
  },
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
}));

const PwaOfflinePageView: StatelessFunctionalComponent<Props> = ({
  id: offId,
  name: pwaOffName,
  BrowserLanguages: offLineBrowserLanguages,
  head,
  body,
  isActive,
  isLoading,
  getPwaOffline,
}: Props) => {
  const classes = useStyles();
  const { id: urlId } = useParams();

  const getItemEditPath: (string) => void = () => {
    if (urlId) {
      externalRedirect({
        to: `/pwaOfflinePages/edit/${urlId}`,
        target: "_blank",
      });
    }
  };

  useEffect(() => {
    if (urlId) {
      getPwaOffline(urlId);
    }
  }, [urlId]);

  return (
    <Container maxWidth="md">
      <Loader loading={isLoading} withoutWrapper>
        <SetupCard
          title="PWA Offline page info"
          showSecondaryBtn
          secondaryBtnText="Edit"
          onSecondaryBtnClick={() => getItemEditPath(offId)}
        >
          <Box width="100%" pb={3} className={classes.root}>
            <NamedItem name="Name" value={pwaOffName} />
            <NamedItem name="Id" value={offId} />

            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">isActive</Typography>
              </Grid>
              <Grid item xs={9}>
                <Switch checked={isActive} disabled />
              </Grid>
            </GridUc>

            <GridUc
              mt={3}
              px={3}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">Browser languages:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Collapse
                  showMoreDisabled={false}
                  list={offLineBrowserLanguages.map(
                    ({
                      value: id,
                      label: name,
                    }: { value: string, label: string }): ViewBrowserLanguage => ({
                      id,
                      name,
                    })
                  )}
                />
              </Grid>
            </GridUc>

            <Box mb={4} mt={3} px={3}>
              <Divider />
            </Box>

            <Box className={classes.codeRoot}>
              <Box mb={2} width={1}>
                <Grid container justifyContent="center">
                  <Grid item xs={11}>
                    <CodeEditor
                      height="400px"
                      tabs={[
                        {
                          name: "head",
                          label: "head html",
                          value: head,
                          mode: "html",
                          readOnly: true,
                        },
                        {
                          name: "body",
                          label: "body html",
                          value: body,
                          mode: "html",
                          readOnly: true,
                        },
                      ]}
                    />
                    <HtmlPreviewButton
                      title="Preview HTML + CSS code"
                      data-testid="html-css-preview-btn"
                      htmlCode={head + body}
                      cssCode=""
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </SetupCard>
      </Loader>
    </Container>
  );
};

export default PwaOfflinePageView;
