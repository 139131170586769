/* eslint-disable import/max-dependencies */
// @flow
import {
  call, put, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { get } from "lodash";

import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";

import { GET_PWA_OFFLINE_PAGE_SAGA, PWA_OFFLINE_FORM_LOADING } from "../../helpers/constants";

import type { GetPwaOfflinePageSaga } from "../../actions/createPwaOfflinePage";
import { setPwaOfflinePage } from "../../actions/createPwaOfflinePage";

import type {
  BrowserLanguages,
} from "../../containers/PwaMotivationContentList";
import type { PwaOfflinePagesType as PwaOfflineItem, RawPwaOfflinePage } from "../../components/PwaOfflinePagesList/types/PwaOfflinePages.types";
import PwaApi from "../../services/PwaApi";
import externalRedirect from "../../components/App/ExternalRedirect";

export function* makeFetch(action: GetPwaOfflinePageSaga): Saga<void> {
  const { id } = action;
  yield put(setLoading(PWA_OFFLINE_FORM_LOADING, true));

  try {
    const response = yield call(PwaApi.getPwaOfflinePage, id);

    const pwaOffItem: PwaOfflineItem = get(response, "data.data.0");
    const browserLanguages: BrowserLanguages[] = get(pwaOffItem, "BrowserLanguages", []);

    const item: RawPwaOfflinePage = {
      ...pwaOffItem,
      BrowserLanguages: browserLanguages.map(({ id: value, language: label }) => ({ value, label })),
    };
    yield put(setPwaOfflinePage(item));
  }
  catch (error) {
    yield put(addNotification({
      message: error.errorMessage,
      severity: "error",
    }));
    // eslint-disable-next-line no-console
    console.error(error);
    externalRedirect({ to: "/pwaOfflinePages" });
  }
  finally {
    yield put(setLoading(PWA_OFFLINE_FORM_LOADING, false));
  }
}

export default function* watchFetchPwaOfflineSaga(): Saga<void> {
  yield takeEvery(GET_PWA_OFFLINE_PAGE_SAGA, (makeFetch: Function));
}
