// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import fetchCampaignsList from "../../sagas/getWebpushCampaignsList";
import getDeleteSaga from "../../sagas/deleteSaga";
import getIsActiveSaga from "../../sagas/changeIsActiveSaga";
import { WEBPUSH_CAMPAIGNS_TABLE } from "../../helpers/constants";
import CampaignApi from "../../services/campaignApi";
import { getWebpushCampaignsListSaga } from "../../actions/webpushCampaignsList";
import { type WebPushCampaignListItem } from "../../containers/WebPushCampaignsList/types/WebPushCampaignsList.types";

type WebPushCampaignShort = { id: string, isActive: boolean };

const changeIsActiveSaga: () => Saga<void> = getIsActiveSaga<WebPushCampaignListItem, WebPushCampaignShort>({
  tableKey: WEBPUSH_CAMPAIGNS_TABLE,
  updateMethod: CampaignApi.updateCampaign,
  getListAction: getWebpushCampaignsListSaga,
  convert: ({ id, isActive }: WebPushCampaignListItem): WebPushCampaignShort => ({ id, isActive }),
  dictionaryName: "Campaign",
});

const deleteSaga: () => Saga<void> = getDeleteSaga({
  tableKey: WEBPUSH_CAMPAIGNS_TABLE,
  deleteMethod: CampaignApi.deleteCampaignsByIds,
  getListAction: getWebpushCampaignsListSaga,
  dictionaryName: "Campaign",
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(fetchCampaignsList),
    call(deleteSaga),
    call(changeIsActiveSaga),
  ]);
}
