// @flow

import React, { useMemo, type StatelessFunctionalComponent } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import Notification from "../../components/Notification";
import CreateWpContainer from "../../containers/CreateWPMotivationContent";

const CreateWp: StatelessFunctionalComponent<{}> = () => {
  const store = useMemo(configureStore, []);
  return (
    <DocumentTitle title="Create WP">
      <Provider store={store}>
        <CreateWpContainer />
        <Notification />
      </Provider>
    </DocumentTitle>
  );
};
export default CreateWp;
