/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  debounce,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  getTableFilters, getTablePage, getTablePageSize,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { changeTableItemsTotalAmount, setTableData } from "@fas/ui-framework/lib/redux/actions/table";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import type { PwaIconListItem } from "../../components/PwaIconsList/types/PwaIconsList.types";
import PwaIconApi from "../../services/pwaIconApi";
import {
  GET_PWA_ICONS_LIST_SAGA, PWA_ICONS_LIST_TABLE,
} from "../../helpers/constants";
import type { PWAIconsLoadingKeys } from "../../pages/PwaIconsList/store";

export function* makeFetch(): Saga<void> {
  yield put(setLoading<PWAIconsLoadingKeys>(PWA_ICONS_LIST_TABLE, true));

  const page: number = yield select(getTablePage, PWA_ICONS_LIST_TABLE);
  const limit: number = yield select(getTablePageSize, PWA_ICONS_LIST_TABLE);
  const filters: Filters = yield select(getTableFilters, PWA_ICONS_LIST_TABLE);

  try {
    const { data: response }: Response<{
      data: PwaIconListItem[],
      totalCount: number
      // $FlowFixMe
    }> = yield call(
      PwaIconApi.getPwaIconsList,
      page,
      limit,
      filters
    );

    yield put(setTableData(PWA_ICONS_LIST_TABLE, response.data));
    yield put(changeTableItemsTotalAmount(PWA_ICONS_LIST_TABLE, response.totalCount));
  }
  catch (err) {
    yield put(addNotification({ message: parseResponse(err.errorMessage), severity: "error" }));
    // eslint-disable-next-line
    console.error(err);
  }

  yield put(setLoading<PWAIconsLoadingKeys>(PWA_ICONS_LIST_TABLE, false));
}

export default function* watchFetchSegmentsListSaga(): Saga<void> {
  // $FlowFixMe
  yield debounce(1000, GET_PWA_ICONS_LIST_SAGA, makeFetch);
}
