// @flow
import {
  GET_MOTIVATION_TEMPLATE_SAGA,
  SAVE_MOTIVATION_TEMPLATE_SAGA,
  SET_MOTIVATION_TEMPLATE,
  SET_MOTIVATION_TEMPLATE_FIELD,
} from "../../helpers/constants";
import type { MotivationTemplateFormData } from "../../containers/CreateMotivationTemplate";
import type {
  GetMotivationTemplateSaga,
  SetMotivationTemplate,
  SetMotivationTemplateField,
  SaveMotivationTemplateSaga,
} from "./actions.types";

export const saveMotivationTemplateSaga: () => SaveMotivationTemplateSaga = () => ({
  type: SAVE_MOTIVATION_TEMPLATE_SAGA,
});

export const getMotivationTemplateSaga: (id: string) => GetMotivationTemplateSaga = (id) => ({
  type: GET_MOTIVATION_TEMPLATE_SAGA,
  payload: id,
});

export const setMotivationTemplate: (
  motivationTemplate: MotivationTemplateFormData
) => SetMotivationTemplate = (motivationTemplate) => ({
  type: SET_MOTIVATION_TEMPLATE,
  payload: motivationTemplate,
});

export const setMotivationTemplateField: (
  field: $Shape<MotivationTemplateFormData>
) => SetMotivationTemplateField = (field) => ({
  type: SET_MOTIVATION_TEMPLATE_FIELD,
  payload: field,
});
