// @flow
import {
  GET_PWA_SAGA,
  SAVE_PWA_SAGA,
  SET_PWA_MOTIV,
  SET_PWA_FORM_FIELD,
} from "../../helpers/constants";
import type { PWAFormData } from "../../containers/PwaMotivationContentList";

export type GetPwaSaga = {|
  type: typeof GET_PWA_SAGA,
  id: string,
|};

export type SetPwa = {|
  type: typeof SET_PWA_MOTIV,
  pwa: PWAFormData,
|};

export type SetPwaFormField = {|
  type: typeof SET_PWA_FORM_FIELD,
  payload: $Shape<PWAFormData>,
|};

export type SavePwaSaga = {|
  type: typeof SAVE_PWA_SAGA,
|};

export type Actions =
  | GetPwaSaga
  | SavePwaSaga
  | SetPwa
  | SetPwaFormField

export const savePwaSaga: () => SavePwaSaga = () => ({ type: SAVE_PWA_SAGA });

export const getPwaSaga: (id: string) => GetPwaSaga = (id: string) => ({
  type: GET_PWA_SAGA,
  id,
});

export const setPwa: (pwa: PWAFormData) => SetPwa = (pwa) => ({
  type: SET_PWA_MOTIV,
  pwa,
});

export const setPwaFormField: (field: $Shape<PWAFormData>) => SetPwaFormField = (field) => ({
  type: SET_PWA_FORM_FIELD,
  payload: field,
});
