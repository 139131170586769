/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import { Grid } from "@mui/material";
import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import MotivationContentsList from "../MotivationContentList";
import IPPList from "../../components/IppList";
import PwaOfflinePagesList from "../../components/PwaOfflinePagesList";
import ListModalSetup from "../Form/ListModalSetupForm";
import ShowByCondition from "../Form/ShowByCondition";
import PwaMotivationContentList from "../PwaMotivationContentList";
import StepControl from "../StepControl";
import { useNotificationError } from "../../hooks";
import { FORM_KEY_CAMPAIGN } from "../../helpers/constants";

const MotivationContentStep: StatelessFunctionalComponent<{}> = () => {
  const { value: pwaOfflinePages, onChange } = useFormField(FORM_KEY_CAMPAIGN, "pwaOfflinePages");
  const { value: pwaMotivationContents } = useFormField(FORM_KEY_CAMPAIGN, "pwaMotivationContents");

  useEffect(() => {
    // $FlowFixMe
    if (pwaMotivationContents.length === 0 && pwaOfflinePages.length > 0) {
      onChange([]);
    }
  }, [pwaMotivationContents, pwaOfflinePages]);

  useNotificationError("motivationContents");

  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item container justifyContent="center" alignItems="center">
        <ListModalSetup
          name="webPushMotivationContents"
          ListComponent={MotivationContentsList}
          title="ADD MOTIVATION CONTENT"
          listName="webpush"
        />
        <ListModalSetup
          name="ippMotivationContents"
          ListComponent={IPPList}
          title="ADD IPP MOTIVATION CONTENT"
          listName="ipp"
        />
        <ListModalSetup
          name="pwaMotivationContents"
          ListComponent={PwaMotivationContentList}
          title="ADD PWA MOTIVATION CONTENT"
          listName="pwa"
        />
        <ShowByCondition name="pwaMotivationContents" check={(value) => value.length > 0}>
          <ListModalSetup
            name="pwaOfflinePages"
            ListComponent={PwaOfflinePagesList}
            title="ADD PWA OFFLINE PAGE"
            listName="pwaOfflinePages"
            multiple={false}
          />
        </ShowByCondition>
        <Grid item xs={8}>
          <StepControl />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MotivationContentStep;
