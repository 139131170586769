/* eslint-disable import/max-dependencies */
// @flow
import React, { type Node } from "react";
import { useSelector } from "react-redux";
import {
  IconButton,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import ColorPicker from "../../components/ColorPicker";
import { TypeToMotiveTypeMap, getSufixIdx } from "./utils";
import type {
  ButtonsByType,
  Handlers,
  MotivTypes,
  ModifierButtonsProps,
} from "./Modifier.types";
import { useActions } from "../../hooks/useActions";
import * as actions from "../../actions/modifiers";
import type { Actions } from "../../actions/modifiers/actions.types";
import { getButtonModifiers } from "../../selectors/modifiers";

export default function ModifierButtons({
  templateType,
  buttonType,
  maxCount,
  disabled = false,
  readOnly = false,
}: ModifierButtonsProps): Node {
  const buttons: ButtonsByType = useSelector(
    (state) => getButtonModifiers(state, templateType, buttonType)
  );

  const {
    addModifierButton: onAddModifierButton,
    setModifierButton: onSetModifierButton,
    removeModifierButton: onRemoveModifierButton,
    // $FlowFixMe
  }: Handlers = useActions<Actions>(actions);

  const motivType: MotivTypes = TypeToMotiveTypeMap[templateType];
  const count: number = Object.entries(buttons).length;
  const editable: boolean = !(disabled || readOnly);
  return (
    <>
      {Object.keys(buttons).map((idx: string): Node => {
        const modificatorText: string = `${motivType}_${buttonType}${getSufixIdx(idx)}`;
        const modificatorBgColor: string = `${motivType}_${buttonType}${getSufixIdx(idx)}_bg_color`;
        const modificatorTextColor: string = `${motivType}_${buttonType}${getSufixIdx(idx)}_text_color`;

        return (
          <Box
            sx={(theme) => ({
              border: `1px dashed ${theme.palette.text.primary}`,
              borderRadius: 3,
              display: "flex",
              p: 2,
              mb: 1,
            })}
            key={modificatorText}
          >
            <Box flex={1} mr={1}>
              <Box display="flex" mb={2} gap={1} justifyContent="space-between" alignItems="center">
                <Box flexGrow={1}>
                  <ColorPicker
                    name={modificatorBgColor}
                    label={modificatorBgColor}
                    size="small"
                    value={buttons[idx].bg_color || ""}
                    onChange={(newValue: string) => {
                      onSetModifierButton(templateType, {
                        idx,
                        buttonType,
                        buttonPropType: "bg_color",
                        value: newValue,
                      });
                    }}
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                </Box>
                <Box flexGrow={1}>
                  <ColorPicker
                    name={modificatorTextColor}
                    label={modificatorTextColor}
                    size="small"
                    value={buttons[idx].text_color || ""}
                    onChange={(newValue: string) => {
                      onSetModifierButton(templateType, {
                        idx,
                        buttonType,
                        buttonPropType: "text_color",
                        value: newValue,
                      });
                    }}
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                </Box>
              </Box>
              <TextField
                fullWidth
                data-testid={modificatorText}
                name={modificatorText}
                label={modificatorText}
                size="small"
                value={buttons[idx].text || ""}
                onChange={({ target: { value: newValue } }: SyntheticInputEvent<HTMLInputElement>) => {
                  onSetModifierButton(templateType, {
                    idx,
                    buttonType,
                    buttonPropType: "text",
                    value: newValue,
                  });
                }}
                disabled={disabled}
                InputProps={{
                  readOnly,
                }}
              />
            </Box>
            {editable && (
              <Box alignSelf="start">
                <IconButton
                  data-testid={`delete-button-btn-${idx}`}
                  onClick={() => {
                    onRemoveModifierButton(templateType, buttonType);
                  }}
                  disabled={idx !== String(count) || idx === "1" || disabled}
                >
                  <DeleteOutline />
                </IconButton>
              </Box>
            )}
          </Box>
        );
      })}
      {editable && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            data-testid="add-button-btn"
            variant="text"
            disabled={count >= maxCount || disabled}
            onClick={() => {
              onAddModifierButton(templateType, buttonType);
            }}
          >
            + Add Button
          </Button>
        </Box>
      )}
    </>
  );
}
