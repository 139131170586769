// @flow
import {
  TOP_LEFT_CSS,
  TOP_RIGHT_CSS,
  TOP_CENTER_CSS,
  CENTER_CSS,
  BOTTOM_LEFT_CSS,
  BOTTOM_RIGHT_CSS,
  BOTTOM_CENTER_CSS,
} from "../../helpers/constants";
import type { DropdownObj } from "../../helpers/common.types";
import type { MotivationTemplateType } from "../CreateMotivationTemplate";

export type Modifier = { [string]: string };

export type ButtonTypes = "confirm" | "deny" | "motiv_install";
export type ButtonPropTypes = "text" | "bg_color" | "text_color";
export type Button = { [ButtonPropTypes]: string };
export type MotivTypes = "wp" | "pwa" | "ipp";

export type Simple = {
  popup_position: string,
  text_title: string,
  text_body: string,
  bg_color: string,
  text_color: string,
}

export type ButtonsByType = {
  [string]: {
    [ButtonPropTypes]: string,
  }
}

export type Buttons = {
  [ButtonTypes] : ButtonsByType
}

export type Images = { [string]: string }

export const POSITIONS: DropdownObj[] = [
  { label: "Top Left", value: TOP_LEFT_CSS },
  { label: "Top Right", value: TOP_RIGHT_CSS },
  { label: "Top Center", value: TOP_CENTER_CSS },
  { label: "Center", value: CENTER_CSS },
  { label: "Bottom Left", value: BOTTOM_LEFT_CSS },
  { label: "Bottom Right", value: BOTTOM_RIGHT_CSS },
  { label: "Bottom Center", value: BOTTOM_CENTER_CSS },
];

export type ModifiersStateData = {|
  simple: Simple,
  images: Images,
  buttons: Buttons
|};

export type Props = {
  templateType: MotivationTemplateType,
  disabled?: boolean,
  readOnly?: boolean,
};

export type ModifierButtonsProps = {
  templateType: MotivationTemplateType,
  buttonType: ButtonTypes,
  maxCount: number,
  disabled?: boolean,
  readOnly?: boolean,
}

export type Handlers = {
  setModifierSimple: (key: MotivationTemplateType, $Shape<Simple>) => *,
  addModifierButton: (key: MotivationTemplateType, buttonType: ButtonTypes) => *,
  setModifierButton: (key: MotivationTemplateType, {
    idx: string,
    buttonType: ButtonTypes,
    buttonPropType: ButtonPropTypes,
    value: string,
  }) => *,
  removeModifierButton: (key: MotivationTemplateType, buttonType: ButtonTypes) => *,
  addModifierImage: (key: MotivationTemplateType) => *,
  setModifierImage: (key: MotivationTemplateType, { idx: string, value: string }) => *,
  removeModifierImage: (key: MotivationTemplateType) => *,
}
