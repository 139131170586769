// @flow
import { takeEvery, select, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { DELETE_PWA_OFFLINE_SAGA, PWA_OFFLINE_LIST_TABLE } from "../../helpers/constants";
import { getPwaOfflineListSaga } from "../../actions/pwaOfflinePagesList";
import PwaApi from "../../services/PwaApi";
import { deleteFn } from "../deleteSaga/deleteSaga";
import { deleteSaga } from "../../actions/tableActions/actions";

export function* makeFetch(): Saga<void> {
  const ids = yield select(getTableSelections, PWA_OFFLINE_LIST_TABLE);
  yield call(deleteFn, {
    tableKey: PWA_OFFLINE_LIST_TABLE,
    deleteMethod: PwaApi.deletePwaOfflinePages,
    getListAction: getPwaOfflineListSaga,
    dictionaryName: "PWA Offline list",
  },
  deleteSaga(ids.map((i) => i.id)));
}

export default function* watchDeletePwaOfflinePageSaga(): Saga<void> {
  yield takeEvery(DELETE_PWA_OFFLINE_SAGA, makeFetch);
}
