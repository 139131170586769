// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import {
  tableReducer as tables,
  initTableState,
  type TableState,
} from "@fas/ui-framework/lib/redux/reducers/table";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import mainSaga from "./saga";
import { MOTIVATION_TEMPLATES_LIST } from "../../helpers/constants";

export type State = $ReadOnly<{|
  tables: TableState,
  loading: LoadingState<string>,
  notifications: NotificationsState,
|}>

function mapMotivationTemplatesToState(): State {
  return {
    tables: initTableState({
      [MOTIVATION_TEMPLATES_LIST]: {
        pageSize: 20,
      },
    }),
    loading: initLoadingState({
      [MOTIVATION_TEMPLATES_LIST]: false,
    }),
    notifications: initNotificationsState(),
  };
}

export type Actions = LoadingActions<string>
  | TableActions
  | NotificationsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  tables,
  loading,
  notifications,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapMotivationTemplatesToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
