// @flow
import React, { useState } from "react";
import { Typography, Link } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import key from "weak-key";

type Props = {
  limit?: number,
  list: Array<{name: string, id?: string, link?: ""}>,
  classes: Object,
  showMoreDisabled?: boolean,
};

const Collapse = ({
  limit,
  list,
  classes,
  showMoreDisabled,
}: Props) => {
  const [isShownMore, setIsShownMore] = useState(false);
  const toggleShowMore = showMoreDisabled ? null : () => setIsShownMore(!isShownMore);
  const visualizationList = isShownMore ? list : list.slice(0, limit);

  return (
    <div className={classes.visualizeList}>
      {visualizationList.map((item) => (item.link ? (
        <Link key={key(item)} target="_blank" href={`${item.link}`} underline="hover">
          {item.name}
          <br />
        </Link>
      ) : (
        <div key={key(item)}>
          {item.name}
        </div>
      )))}

      {list.length > Number(limit) && !isShownMore && (
        <Typography
          className={showMoreDisabled ? "" : classes.pointer}
          component="div"
          display="block"
          color="primary"
          onClick={toggleShowMore}
        >
          +
          {" "}
          { list.length - Number(limit) }
          {" "}
          more
        </Typography>
      )}
      {list.length > Number(limit) && isShownMore && (
        <Typography
          className={showMoreDisabled ? "" : classes.pointer}
          component="div"
          display="block"
          color="primary"
          onClick={toggleShowMore}
        >
          - Show less
        </Typography>
      )}
    </div>
  );
};

Collapse.defaultProps = {
  limit: 5,
  showMoreDisabled: true,
};

export default withStyles({
  visualizeList: {
    whiteSpace: "pre-wrap",
  },
  pointer: {
    cursor: "pointer",
  },
})(Collapse);
